import { Layout } from "antd";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./dashboardLayout.css";
import SideBar from "./adminsidebar";
import NavHeader from "./Header";
import GuardSideBar from "./guardsidebar";
import SupervisorSideBar from "./supervisorsidebar";
import ManagerSideBar from "./managersidebar";
import AdminSideBar from "./adminsidebar";
import { useSelector,useDispatch } from 'react-redux';
import { SiteManagementAction } from "../../store/actions";


const DashboardLayout = ({ children }) => {
  const user = useSelector((state) => state.AppReducer?.user)
  const isRole = user?.data?.userRole[0]
  const { Content } = Layout;
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(0)
  const dispatch = useDispatch()

  window.addEventListener('resize', () => {
    if (window.innerWidth > 990 && window.innerWidth < 1002) {
      setWindowWidth(window.innerWidth)
    } else {
      setWindowWidth(0)
    }
  })

  
// street types storing globally.
let streetType = [
  {
      label: 'Road',
      value: 'Road'
  },
  {
      label: 'Street',
      value: 'Street'
  },
  {
      label: 'Parkway',
      value: 'Parkway'
  },
  {
      label: 'Avenue',
      value: 'Avenue'
  },
  {
      label: 'Way',
      value: 'Way'
  },
  {
      label: 'Boulevard',
      value: 'Boulevard'
  },
  {
      label: 'Place',
      value: 'Place'
  },
  {
      label: 'Lane',
      value: 'Lane'
  },
  {
      label: 'Drive',
      value: 'Drive'
  },
  {
      label: 'Court',
      value: 'Court'
  },
  {
      label: 'Terrace',
      value: 'Terrace'
  },
  {
      label: 'Alley',
      value: 'Alley'
  },
  {
      label: 'Connector',
      value: 'Connector'
  },
  {
      label: 'Highway',
      value: 'Highway'
  },
  {
      label: 'Route',
      value: 'Route'
  },
  {
      label: 'Thruway',
      value: 'Thruway'
  },
  {
      label: 'Frontage Road',
      value: 'Frontage Road'
  }
]

  useEffect(() => {
    dispatch(SiteManagementAction?.AddAddressType(streetType))
  },[])

  return (
    <div className="App">
      {/* if user is not login it will not render the layout . */}
      {
        location.pathname !== '/login' ?

          <Layout>
            {isRole === 'guard' && <GuardSideBar />}
            {isRole === 'supervisor' && <SupervisorSideBar />}
            {isRole === 'manager' && <ManagerSideBar />}
            {isRole === 'admin' && <AdminSideBar />}
            {/* <SideBar /> */}

            <Layout
              className="site-layout"
              style={{
                // marginLeft: '240px'
              }}
            >

              {/* <NavHeader /> */}

              <Content
                className="overflow-auto px-7 py-4"
                style={{
                  // margin: '0 24px 16px 16px',
                  marginLeft: windowWidth != 0 ? '200px' : '2px',
                  height: '100vh',
                  background: '#FAFAFB',
                }}
              >
                {children}
              </Content>

            </Layout>
          </Layout>

          :

          ""
      }

    </div >
  );
};
export default DashboardLayout;


// 
















