import React from "react";
import { Input } from "antd";
import { Colors } from "../../config";

const { TextArea } = Input;

function Textarea({
  rows,
  onChange,
  placeholder,
  value,
  style,
  className,
  labelClass,
  labeltext,
  field,
  ...props
}) {
  return (
    <>
      <label
        className={labelClass}
        style={{ color: Colors.LabelColor }}
      >
        {labeltext}
      </label>
      <TextArea
        field={field}
        rows={rows}
        // maxLength={}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        style={{ background: Colors.LightGray, outline: 'none', borderWidth: "0px" }}
        className={className}
        {...props}
      />
    </>
  );
}

export default Textarea;
