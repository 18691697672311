import React, { useState, useEffect } from 'react'
import BasicDrawer from '../../../components/Drawer'
import { Controller, useForm } from 'react-hook-form'
import { Button, TextField } from '../../../components'
import LabelWithSelect from '../../../components/LabelWithSelect'
import { ApiCaller, Colors, Utils } from '../../../config'
import { Col, ConfigProvider, DatePicker, Row, Select } from 'antd'
import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'
import dayjs from 'dayjs'
import RangeDatePicker from '../../../components/Picker/RangePicker'
import { TimePicker } from 'antd';
import { useMutation, useQuery } from 'react-query'
import { getAllProperties, getAllUsers, getEligibiity } from '../../../config/getApis'
import LabelWithField from '../../../components/LabelWithField'
import { AssignNormalShiftSchema, AssignOpenShiftSchema } from '../../../config/Schema'
import { useDispatch, useSelector } from 'react-redux'
import { ShiftSchedulerAction, SiteManagementAction } from '../../../store/actions'
import avatar from '../../../assets/images/avatar.jpg'


const AssignShiftDrawer = ({ drawerStates, setDrawerStates, shiftState, setShiftState }) => {

    const { data: usersOptions } = useQuery('users', getAllUsers, { enabled: drawerStates.typeOfShift == 'normal' });
    const { data: propertiesOptions } = useQuery('properties', getAllProperties, { enabled: drawerStates.typeOfShift == 'normal' || drawerStates.typeOfShift == 'open' });
    const { data: elegibilityOptions } = useQuery('eligibilites', getEligibiity, { enabled: drawerStates.typeOfShift == 'normal' || drawerStates.typeOfShift == 'open' });

    const schema = drawerStates.typeOfShift == 'open' ? AssignOpenShiftSchema : AssignNormalShiftSchema;

    const defaultOpenShiftValues = {
        break: '',
        numberOfAssignees: '',
        propertyId: '',
        eligibilityId: '',
        recurringFrequency: '',
        notes: '',
        locationId: '',
        assignTo: ''
    }
    const defaultNormalShiftValues = {
        break: '',
        propertyId: '',
        eligibilityId: '',
        notes: '',
        locationId: '',
        assignTo: ''
    }
    const { handleSubmit, control, reset, setValue } = useForm(
        {
            resolver: yupResolver(schema),
            mode: 'onChange',
            defaultValues: drawerStates.typeOfShift == 'open' ? defaultOpenShiftValues : defaultNormalShiftValues
        }
    )

    const dispatch = useDispatch();
    const { potentialTakersLoader, potentialTakersData } = useSelector(state => state?.ShiftSchedulerReducer);
    const { locationData } = useSelector(state => state?.SiteManagementReducer);

    // state and effect to get the location and address id from the locationData, as we need to send the
    // location address to see where actually the shift is assigned.
    const [locations, setLocations] = useState([])
    useEffect(() => {
        let optionsArray = []
        if (locationData?.length > 0) {
            for (let location of locationData) {
                let loc_id = location?.id;
                for (let address of location.address) {
                    optionsArray.push({
                        label: `${address.streetNumber}, ${address.streetName}-${address.state}-${address.buildingNumber}`,
                        value: `location_id:${loc_id}, address_id:${address.id}`
                    })
                }
            }
        }
        setLocations(optionsArray)
    }, [locationData])

    // using useEffect pushing 'All' object in our data so on click of All, All the users will be selected
    // the fuctionality is written down in handledChange function.
    useEffect(() => {
        const isAllExist = potentialTakersData.find(item => item?.user?.firstName === 'All' || item?.user?.id === 'All');
        if (!isAllExist) {
            potentialTakersData.push(
                {
                    user:
                    {
                        firstName: 'All',
                        id: 'All'
                    }
                }
            );
        }
    }, [potentialTakersData]);

    // for time and date only, we are making them controlled component like this, rest will be handle by useform.
    const [state, setState] = useState({
        startTime: '',
        endTime: '',
        startDate: '',
        endDate: '',
        shiftDate: ''
    })

    // validations only for time and date, rest will be handle by the useForm and yup.
    const [validation, setValidations] = useState({
        startTime: '',
        endTime: '',
        startDate: '',
        endDate: '',
        shiftDate: ''
    })

    // All the checked values
    const [checkedValues, setCheckedValues] = useState([]);

    // After getting the potential takers we are on a different drawer so we have to  migrate the 
    // shift data that was previously filled in the open store drawer so we can make the final Api call
    // for open shifts.
    const [openShiftDataStore, setOpenShiftDataStore] = useState(null)

    // normal shift creation
    const normalShiftMutation = useMutation(async (data) => {
        let users = [];
        let assign = data?.assignTo;
        users.push(assign);
        data.assignTo = users;
        if (data?.numberOfAssignees == '' || data?.recurringFrequency || data?.startDate == '') {
            let { numberOfAssignees, startDate, recurringFrequency, endDate, ...restData } = data;
            let resp = await ApiCaller.Post('/shift', restData);
            return resp;
        }
        else {
            let resp = await ApiCaller.Post('/shift', data);
            return resp;
        }

    },
        {
            onSuccess: (resp) => {
                const { schedule, user, eligiblity } = shiftState?.filterDrawer;
                Utils.showMessage('success', resp?.data?.message);
                setDrawerStates((prev) => ({ ...prev, shiftTypeDrawer: false, typeOfShift: '' }))
                setShiftState((prev) => ({ ...prev, page: shiftState?.page, filterDrawer: { schedule: schedule, user: user, eligiblity: eligiblity } }));
                reset(drawerStates.typeOfShift == 'open' ? defaultOpenShiftValues : defaultNormalShiftValues)
                setValue('startTime', '')
                setValue('endTime', '')
                setValue('startDate', '')
                setValue('endDate', '')
                setValue('shiftDate', '')
                setState({
                    startTime: '',
                    endTime: '',
                    startDate: '',
                    endDate: '',
                    shiftDate: ''
                })
                dispatch(ShiftSchedulerAction.GetShifts({ limit: 5, page: 1 }))
                setValidations(
                    {
                        startTime: '',
                        endTime: '',
                        shiftDate: '',
                        startDate: '',
                        endDate: ''
                    }
                )
                return
            },
            onError: (err) => {
                Utils.showMessage('error', err?.response?.data?.message);
            }

        }

    )

    const shiftSaveHandler = (data) => {
        let { locationId } = data;
        let addressStr = locationId.split(',');
        let address = addressStr[1];
        let location = addressStr[0];
        let address_id = address.split(':');
        let location_id = location.split(':');
        data.addressId = address_id[1];
        data.locationId = location_id[1];

        if (drawerStates?.typeOfShift == 'normal') {
            if (!state?.startTime || !state?.endTime || !state?.shiftDate) {
                setValidations({
                    startTime: !state?.startTime ? 'Start time is required' : '',
                    endTime: !state?.endTime ? 'End time is required' : '',
                    shiftDate: !state?.shiftDate ? 'Shift Date is required' : '',
                    startDate: '',
                    endDate: ''
                })
                return
            }
            else if (state?.startTime && state?.endTime && state?.shiftDate) {
                setValidations(
                    {
                        startTime: '',
                        endTime: '',
                        shiftDate: '',
                        startDate: '',
                        endDate: ''
                    }
                )
                normalShiftMutation.mutate(data)
            }
        }

        else {
            if (!state?.startTime || !state?.endTime || !state?.startDate) {
                setValidations({
                    startTime: !state?.startTime ? 'Start time is required' : '',
                    endTime: !state?.endTime ? 'End time is required' : '',
                    shiftDate: '',
                    startDate: !state?.startDate ? 'Date is required' : '',
                    endDate: ''
                })
                return
            }
            else if (state?.startTime && state?.endTime && state?.startDate) {
                setValidations(
                    {
                        startTime: '',
                        endTime: '',
                        shiftDate: '',
                        startDate: '',
                        endDate: ''
                    }
                )
                const { startTime, endTime, startDate, propertyId, eligibilityId } = data;
                const payload = {
                    startTime: startTime,
                    endTime: endTime,
                    startDate: startDate,
                    propertyId: propertyId,
                    eligibilityId: eligibilityId
                }
                dispatch(ShiftSchedulerAction.GetPotentialTakers(payload, () => {
                    setDrawerStates({ ...drawerStates, potentialTakers: true, shiftTypeDrawer: false })
                    setOpenShiftDataStore(data)
                }))
            }
        }
    }


    const shiftCloseHandler = () => {
        setDrawerStates((prev) => ({ ...prev, shiftTypeDrawer: false }))
        reset(drawerStates.typeOfShift == 'open' ? defaultOpenShiftValues : defaultNormalShiftValues)
        setState({
            startTime: '',
            endTime: '',
            startDate: '',
            endDate: '',
            shiftDate: ''
        })
        setValidations(
            {
                startTime: '',
                endTime: '',
                shiftDate: '',
                startDate: '',
                endDate: ''
            }
        )
    }

    // potential takers data drawer closer function.
    const potentialTakersCloseHandler = () => {
        setDrawerStates({ ...drawerStates, shiftTypeDrawer: true, typeOfShift: 'open', potentialTakers: false });
        setCheckedValues([])
        setOpenShiftDataStore(null)
    }


    const handledChange = (e) => {
        let item = e.target.value.toString(); // Convert item to string explicitly
        let checkedItemsList = document.querySelectorAll('.checked--Item');
        let testArray = [...checkedValues]; // Use spread operator to clone array

        if (testArray.includes(item)) {
            if (item === 'All') {
                checkedItemsList.forEach((checkItem) => {
                    checkItem.checked = false;
                });
                testArray = [];
                setCheckedValues([]);
            } else {
                testArray.splice(testArray.indexOf(item), 1);
                setCheckedValues(testArray);
            }
        } else {
            if (item === 'All') {
                setCheckedValues([]);
                testArray = [];
                checkedItemsList.forEach((checkItem) => {
                    checkItem.checked = true;
                });
                potentialTakersData?.map((opt) => testArray.push(opt?.user?.id.toString())); // Convert IDs to strings
                setCheckedValues(testArray);
            } else {
                testArray.push(item);
                setCheckedValues(testArray);
            }
        }
    };

    // open shift create
    const openShiftMutation = useMutation(async (data) => {
        if (data?.shiftDate == '') {
            let { shiftDate, ...restData } = data;
            let resp = await ApiCaller.Post('/shift', restData);
            return resp;
        }
        else {
            let resp = await ApiCaller.Post('/shift', data);
            return resp;
        }
    },
        {
            onSuccess: (resp) => {
                const { schedule, user, eligiblity } = shiftState?.filterDrawer;
                Utils.showMessage('success', resp?.data?.message);
                setDrawerStates((prev) => ({ ...prev, shiftTypeDrawer: false, potentialTakers: false, typeOfShift: '' }));
                setShiftState((prev) => ({ ...prev, page: shiftState?.page, filterDrawer: { schedule: schedule, user: user, eligiblity: eligiblity } }));
                reset(drawerStates.typeOfShift == 'open' ? defaultOpenShiftValues : defaultNormalShiftValues);
                setValue('startTime', '')
                setValue('endTime', '')
                setValue('startDate', '')
                setValue('endDate', '')
                setValue('shiftDate', '')
                setState({
                    startTime: '',
                    endTime: '',
                    startDate: '',
                    endDate: '',
                    shiftDate: ''
                })
                setValidations(
                    {
                        startTime: '',
                        endTime: '',
                        shiftDate: '',
                        startDate: '',
                        endDate: ''
                    }
                )
                dispatch(ShiftSchedulerAction.GetShifts({ limit: 5, page: 1 }))
                return
            },
            onError: (err) => {
                Utils.showMessage('error', err?.response?.data?.message);
            }

        }

    )

    // open shift handler after selecting the potential takers
    const openShiftHandler = () => {
        if (openShiftDataStore !== null && checkedValues.length > 0) {
            let newCheckedValues = checkedValues?.filter((item) => {
                return item !== 'All'
            })
            openShiftDataStore.assignTo = newCheckedValues;
            openShiftDataStore.isRecurring = true;
            openShiftMutation.mutate(openShiftDataStore)
        }
    }

    const propertyApiHandler = (value) => {
        let id = value;
        localStorage.setItem('PropertyKey', id)
        dispatch(SiteManagementAction.GetLocation());
    }

    return (
        <>
            <BasicDrawer
                openDrawer={drawerStates?.shiftTypeDrawer}
                width={500}
                title={drawerStates?.typeOfShift == 'open' ? 'Open Shift' : 'Normal Shift'}
                onClose={() => shiftCloseHandler()}
                children={
                    <>
                        <form>

                            <Row gutter={[16]} className='mb-4'>
                                {
                                    drawerStates?.typeOfShift == 'normal' ?
                                        <Col lg={13} md={13} sm={24} xs={24}>
                                            <Controller
                                                name='assignTo'
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <LabelWithSelect options={usersOptions ? usersOptions : []} labeltext={'Assign To'} field={field} labelClass={'font-normal'} inputClass={'outline-none py-1 rounded h-9'} />
                                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error?.message}</p>}
                                                    </>
                                                )}
                                            />
                                        </Col>
                                        :
                                        <Col lg={13} md={13} sm={24} xs={24}>
                                            <Controller
                                                name='numberOfAssignees'
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <LabelWithField labeltext={'Number Of Shifts'} field={field} labelClass={'font-normal'} inputClass={'outline-none py-1 rounded h-9'} />
                                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error?.message}</p>}
                                                    </>
                                                )}
                                            />
                                        </Col>
                                }

                                <Col lg={11} md={11} sm={24} xs={24}>
                                    <Controller
                                        name='break'
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <div className=''>
                                                <LabelWithSelect options={
                                                    [
                                                        {
                                                            value: '30',
                                                            label: '30',
                                                        },
                                                        {
                                                            value: '40',
                                                            label: '40',
                                                        },
                                                        {
                                                            value: '50',
                                                            label: '50',
                                                        },
                                                        {
                                                            value: '60',
                                                            label: '60',
                                                        }
                                                    ]
                                                } labeltext={'Un-paid Break'} field={field} labelClass={'font-normal'} inputClass={'outline-none py-1 rounded h-9'} />
                                                {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                            </div>
                                        )}

                                    />

                                </Col>
                            </Row>

                            <Row gutter={[16, 32]} className=''>

                                <Col lg={13} md={13} sm={24} xs={24}>
                                    <Controller
                                        name='startTime'
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState }) => (
                                            <>
                                                <div className='flex flex-col'>
                                                    <label style={{ color: Colors.LabelColor }} className='font-normal'>Start Time</label>
                                                    <ConfigProvider
                                                        theme={{
                                                            components: {
                                                                DatePicker: {
                                                                    optionSelectedBg: Colors.Yellow,
                                                                    optionActiveBg: Colors.LightGray,
                                                                    optionSelectedColor: Colors.White
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <TimePicker
                                                            value={state?.startTime ? state?.startTime : dayjs()}
                                                            use12Hours
                                                            format="h:mm A"
                                                            onChange={(time) => {
                                                                setState((prev) => ({ ...prev, startTime: time }));
                                                                setValue('startTime', time?.format('HH:mm'));
                                                            }}
                                                            className='outline-none py-1 rounded-md h-9'
                                                        />
                                                    </ConfigProvider>
                                                    {validation?.startTime && <p style={{ color: Colors.Error }}>{validation?.startTime}</p>}
                                                </div>
                                            </>
                                        )}

                                    />
                                </Col>

                                <Col lg={11} md={11} sm={24} xs={24}>
                                    <Controller
                                        name='endTime'
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState }) => (
                                            <>
                                                <div className='flex flex-col'>
                                                    <label style={{ color: Colors.LabelColor }} className='font-normal'>End Time</label>
                                                    <ConfigProvider
                                                        theme={{
                                                            components: {
                                                                DatePicker: {
                                                                    optionSelectedBg: Colors.Yellow,
                                                                    optionActiveBg: Colors.LightGray,
                                                                    optionSelectedColor: Colors.White
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <TimePicker
                                                            value={state?.endTime ? state?.endTime : dayjs()}
                                                            use12Hours
                                                            format="h:mm A"
                                                            onChange={(time) => {
                                                                setState((prev) => ({ ...prev, endTime: time }));
                                                                setValue('endTime', time?.format('HH:mm'));

                                                            }}
                                                            className='outline-none py-1 rounded-md h-9'
                                                        />
                                                    </ConfigProvider>
                                                    {validation?.endTime && <p style={{ color: Colors.Error }}>{validation?.endTime}</p>}
                                                </div>
                                            </>
                                        )}

                                    />
                                </Col>

                            </Row>

                            <Row gutter={[16]} className='my-2 flex items-center'>
                                {/* <Col lg={13} md={13} sm={24} xs={24}>
                                    <Controller
                                        name='propertyId'
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <LabelWithSelect options={propertiesOptions ? propertiesOptions : []} labeltext={'Property Site'} field={field} labelClass={'font-normal'} inputClass={'outline-none py-1 rounded h-9'} />
                                                {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error?.message}</p>}
                                            </>
                                        )}
                                    />
                                </Col> */}

                                <Col lg={13} md={13} sm={24} xs={24} className='mt-4'>
                                    <Controller
                                        name='propertyId'
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState }) => (
                                            <>
                                                <label className='font-normal' style={{ color: Colors.LabelColor }}>Property Site</label>
                                                <ConfigProvider
                                                    theme={{
                                                        components: {
                                                            Select: {
                                                                optionSelectedBg: Colors.Yellow,
                                                                optionActiveBg: Colors.LightGray,
                                                                optionSelectedColor: Colors.White
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <Select
                                                        allowClear={true}
                                                        className='w-full h-9 outline-none rounded'
                                                        value={value}
                                                        onChange={(value) => {
                                                            onChange(value);
                                                            propertyApiHandler(value)
                                                        }}
                                                        options={propertiesOptions ? propertiesOptions : []}
                                                    />
                                                </ConfigProvider>
                                                {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error?.message}</p>}
                                            </>
                                        )}
                                    />
                                </Col>


                                <Col lg={11} md={11} sm={24} xs={24}>
                                    <Controller
                                        name='eligibilityId'
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <div className=''>
                                                <LabelWithSelect options={elegibilityOptions ? elegibilityOptions : []} labeltext={'Position'} field={field} labelClass={'font-normal'} inputClass={'outline-none py-1 rounded h-9'} />
                                                {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </Col>

                            </Row>

                            <Row gutter={[8, 32]} className='flex items-center justify-between w-full' style={{}}>
                                {
                                    drawerStates?.typeOfShift !== 'open' ?

                                        <Col lg={13} md={13} sm={24} xs={24} className='w-full'>
                                            <label className='font-normal' style={{ color: Colors.LabelColor }}>Shift Date</label>
                                            <div className='w-full' style={{ width: '100%' }}>
                                                <Controller
                                                    name='shiftDate'
                                                    control={control}
                                                    render={({ field: { value, onChange }, fieldState }) => (
                                                        <>
                                                            <DatePicker
                                                                className='outline-none py-1 rounded-md h-9 w-full'
                                                                allowClear={true}
                                                                format={'DD-MM-YYYY'}
                                                                defaultValue={state?.shiftDate ? dayjs(state?.shiftDate) : dayjs()}
                                                                onChange={(date, dateString) => { setState((prev) => ({ ...prev, shiftDate: dateString })); setValue('shiftDate', dateString) }}
                                                                disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))}
                                                            />
                                                            {validation?.shiftDate && <p style={{ color: Colors.Error }}>{validation.shiftDate}</p>}
                                                        </>
                                                    )}

                                                />

                                            </div>
                                        </Col>
                                        :
                                        <>
                                            <Col lg={13} md={13} sm={24} xs={24}>
                                                <label className='font-normal' style={{ color: Colors.LabelColor }}>Date Range</label>
                                                <div>
                                                    <Controller
                                                        name='startDate'
                                                        control={control}
                                                        render={({ field: { value, onChange }, fieldState }) => (
                                                            <>
                                                                <RangeDatePicker
                                                                    allowClear={true}
                                                                    format={'DD-MM-YYYY'}
                                                                    className={'outline-none py-1 rounded-md h-9 w-full'}
                                                                    defaultValue={state?.startDate ? [dayjs(state?.startDate), dayjs(state?.endDate)] : [dayjs(), dayjs()]}
                                                                    onChange={(date, dateStrings) => { setState((prev) => ({ ...prev, startDate: date && date[0], endDate: date && date[1] })); setValue('startDate', dateStrings && dateStrings[0]); setValue('endDate', dateStrings && dateStrings[1]) }}
                                                                    disabledDate={(current) => current?.isBefore(moment().subtract(1, "day"))}
                                                                />
                                                                {validation?.startDate && <p style={{ color: Colors.Error }}>{validation?.startDate}</p>}
                                                            </>
                                                        )}
                                                    />

                                                </div>
                                            </Col>
                                            <Col lg={11} md={11} sm={24} xs={24} className='w-full mb-2' style={{ paddingRight: 0, paddingLeft: '4px' }}>
                                                <Controller
                                                    name='recurringFrequency'
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <div className='w-full' style={{ paddingRight: 0, paddingLeft: '4px' }}>
                                                            <LabelWithSelect options={
                                                                [
                                                                    {
                                                                        value: 'daily',
                                                                        label: 'Daily',
                                                                    },
                                                                    {
                                                                        value: 'weekly',
                                                                        label: 'Weekly',
                                                                    },
                                                                    {
                                                                        value: 'biweekly',
                                                                        label: 'Biweekly',
                                                                    },
                                                                    {
                                                                        value: 'monthly',
                                                                        label: 'Monthly',
                                                                    },
                                                                    {
                                                                        value: 'yearly',
                                                                        label: 'Yearly',
                                                                    }
                                                                ]
                                                            } labeltext={'Repeat Shift'} field={field} labelClass={'font-normal'} inputClass={'outline-none py-1 rounded h-9'} style={{ paddingTop: 0, paddingBottom: 0 }} />
                                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                                        </div>
                                                    )}

                                                />

                                            </Col>
                                        </>
                                }

                            </Row>

                            <Row className='mb-4'>
                                <Col lg={13} md={11} sm={24} xs={24}>
                                    <Controller
                                        name='locationId'
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <div className=''>
                                                <LabelWithSelect options={locations ? locations : []} labeltext={'Location'} field={field} labelClass={'font-normal'} inputClass={'outline-none py-1 rounded h-9'} />
                                                {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </Col>
                            </Row>

                            <Row gutter={[16, 32]}>
                                <Col span={24}>
                                    <Controller
                                        name='notes'
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState }) => (
                                            <div className=''>
                                                <TextField.Textarea
                                                    style={{ color: Colors.LightGray }}
                                                    placeholder={''}
                                                    value={value}
                                                    onChange={onChange}
                                                    labeltext={'Note'}
                                                    className={'outline-none h-10 rounded'}
                                                />
                                                {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                            </div>
                                        )}
                                    />

                                </Col>

                            </Row>

                            <Row gutter={[16, 32]} className='my-6'>
                                <Col span={24} className='flex items-center justify-end'>
                                    <Button.Basic
                                        onClick={handleSubmit(shiftSaveHandler)}
                                        htmlType="submit"
                                        loader={normalShiftMutation?.isLoading || potentialTakersLoader}
                                        size={'large'} text={drawerStates?.typeOfShift == 'open' ? 'Save & Continue' : 'Save'} buttonStyle={{ backgroundColor: Colors.Yellow, color: 'white' }} className={'px-12 rounded-md h-12'} />
                                </Col>
                            </Row>
                        </form>
                    </>
                }
            />

            <BasicDrawer
                openDrawer={drawerStates?.potentialTakers}
                width={300}
                title={'Potential Takers'}
                onClose={() => potentialTakersCloseHandler()}
                children={
                    <>
                        <Row className='w-full flex flex-col flex-nowrap items-start justify-between h-full'>
                            <Row className='w-full h-80 overflow-y-auto'>
                                <Col span={24}>
                                    {
                                        potentialTakersData &&
                                        potentialTakersData?.map((opt) => {
                                            return (
                                                <div className="flex items-center gap-2 mb-2 w-full" key={opt?.user?.id}>
                                                    <input className="checked--Item" type="checkbox" id={'myCheckbox'} name={opt?.user?.id} value={opt?.user?.id} onChange={handledChange} />
                                                    <img style={{ width: '25px', height: '25px', borderRadius: '50%' }} src={opt?.user?.profileImg ? opt?.user?.profileImg[0]?.url : avatar} />
                                                    <label for={opt?.user?.id} name={opt?.user?.id}>{opt?.user?.firstName !== 'All' ? opt?.user?.firstName + ' ' + opt?.user?.lastName + ' - ' + opt?.remainingHours + 'hrs' : 'All'}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </Col>
                            </Row>

                            <Row className='my-6 self-center w-full'>
                                <Col span={24} className='w-full'>
                                    <Button.Basic
                                        onClick={openShiftHandler}
                                        htmlType="submit"
                                        loader={openShiftMutation?.isLoading}
                                        size={'large'} text={'Submit'} buttonStyle={{ backgroundColor: Colors.Yellow, color: 'white' }} className={'px-12 rounded-md h-12 w-full'} />
                                </Col>
                            </Row>
                        </Row>
                    </>
                }
            />
        </>
    )
}

export default AssignShiftDrawer