import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  CommingSoon,
  Dashboard,
  Users
} from "../../containers";
import UserMangement from "../../containers/UserManagement";
import SiteManagement from "../../containers/SiteManagement";
import AddProperty from "../../components/StepForm/index";
import ReactScheduler from "../../components/Scheduler";
import Sitemanagementdetail from "../../containers/SiteManagement/sitemanagementdetail";
import EditProfile from "../../containers/Dashboard/Edit Profile";
import MyAvailability from "../../containers/Dashboard/My Availability";
import ChangePassword from "../../containers/Dashboard/Change Password";
import ClientMangement from "../../containers/ClientManagement";
import Settings from "../../containers/Settings";
import SecurityIssues from "../../containers/SecurityIssues";
import ManageIncidents from "../../containers/SecurityIssues/ManageIncident";
import IncidentDetail from "../../containers/SecurityIssues/ManageIncident/incidentDetail";
import WorkForceManagement from "../../containers/WorkForceManagement";
import ShiftScheduler from "../../containers/WorkForceManagement/Scheduler";


const AppRoutes = ({
  isRole
}) => {
  console.log("rorleee", isRole);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Dashboard />} />
        {
          isRole === "admin" || isRole == 'manager' || isRole == "supervisor" ? (
            <>
              <Route path="/users-management" element={<UserMangement />} />
              <Route path="/EditProfile" element={<EditProfile />} />
              <Route path="/MyAvailability" element={<MyAvailability />} />
              <Route path="/ChangePassword" element={<ChangePassword />} />
              <Route path="/site-management" element={<SiteManagement />} />
              <Route path="/property-details/:id" element={<Sitemanagementdetail />} />
              <Route path="/add-property" element={<AddProperty />} />
              <Route path="/emergency-alert" element={<CommingSoon />} />
              <Route path="/emergency-alert" element={<CommingSoon />} />
              <Route path="/users-management" element={<UserMangement />} />
              <Route path="/client-support" element={<ClientMangement />} />
              <Route path="/workforce-management" element={<WorkForceManagement />} />
              <Route path="/workforce-management/shift-scheduler" element={<ShiftScheduler />} />
              <Route path="/attendance-management" element={<CommingSoon />} />
              <Route path="/security-issues" element={<SecurityIssues />} />
              <Route path='/security-issues/manage-incidents' element={<ManageIncidents />} />
              <Route path='/security-issues/manage-incidents/report/:id' element={<IncidentDetail />} />
              <Route path="/connectivity-hub" element={<CommingSoon />} />
              <Route path="/setting" element={<Settings />} />
            </>


          ) : isRole === "guard" ? (
            // Handle the case for the "guard" role if needed
            null
          ) : (
            // Handle any other cases, or you can put a default route here
            null
          )
        }
        <Route path="*" element={<Navigate to="/" />} />
      </Routes >
    </div >
  )
}

export default AppRoutes
// class AppRoutes extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }

//   render() {
//     return (
//       <div className="App">
//          <Routes>
//               <Route path="/" element={<Dashboard />} />
//               <Route path="/users" element={<Users />} />
//               <Route path="*" element={<Navigate to="/" />} />
//             </Routes>
//       </div>
//     );
//   }
// }

// export default connect((state) => ({ storeState: state }))(AppRoutes);