import React, { useState, useEffect } from 'react'
import { Row, Col, Avatar, Form } from "antd";
import { CameraOutlined } from '@ant-design/icons';
import { Button, Text, TextField } from '..';
import { FaArrowLeftLong } from "react-icons/fa6";
import LabelWithField from '../LabelWithField';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { UserSignin } from '../../config/Schema';
import LabelWithSelect from '../LabelWithSelect';
import { FaPlus } from "react-icons/fa6";
import { Colors } from '../../config';
import fileUpload from '../../assets/images/fileUpload.png'
import { AddDocuments } from '../../config/Schema';
import { useDispatch, useSelector } from 'react-redux';
import { SiteManagementAction } from '../../store/actions';
import { useParams } from 'react-router-dom';

const Documents = ({ documentData, onCloseDrawer, title, isDocumentDetail = false }) => {

    console.log("document edit drawer", isDocumentDetail, title)

    console.log(documentData)

    // const { id: docId, ...restData } = documentData;
    const { id } = useParams();

    const docLoader = useSelector(state => state?.SiteManagementReducer?.loader);

    const [watchDoc, setWatchDoc] = useState(false);
    const [documentValidate, setDocumentValidate] = useState('')

    useEffect(() => {
        if (documentData) {
            reset({
                name: documentData?.name,
                accessibility: documentData?.accessibility,
                description: documentData?.description,
                mediaId: documentData?.media && documentData?.media[0]?.id
            })
        }
        else {
            return
        }


    }, [documentData])

    const schema = AddDocuments;
    const dispatch = useDispatch()

    const { handleSubmit, control, reset, setValue, watch } = useForm(
        {
            resolver: yupResolver(schema),
            mode: "onChange"
        }
    )

    const documentSubmitHandler = (data) => {
        if(data?.mediaId){
            if (title == 'Add') {
                dispatch(SiteManagementAction.AddDocument(data, id, () => {
                    dispatch(SiteManagementAction.GetPropertyDetail(id))
                    onCloseDrawer()
                }))
            }
            else {
                dispatch(SiteManagementAction.EditDocument(data, documentData?.id, () => {
                    if (!isDocumentDetail) {
                        console.log('andar')
                        dispatch(SiteManagementAction.GetDocument())
                        reset()
                        onCloseDrawer()
                        setWatchDoc(false)
                        setValue('mediaId', null)
                    }
                    else {
                        dispatch(SiteManagementAction.GetPropertyDetail(id))
                    }
                    onCloseDrawer()
                }))
            }
        }
        else{
            setDocumentValidate('Document is required');
            return
        }


    };

    const fileHandler = (e) => {
        const file = e?.target.files[0];
        if (file !== undefined) {
            setValue('mediaId', file)
            setWatchDoc(true)
            setDocumentValidate('')
        }
        else {
            setDocumentValidate('Document is required')
            setValue('mediaId', '')
        }

    };

    return (
        <Row className=''>
            <form layout="vertical" className='w-full'>
                <Row gutter={16} className='flex justify-between items-center'>
                    <Col lg={12} xs={24} className='mt-2 relative h-16 rounded outline-none flex flex-col justify-start' style={{ padding: 0 }}>
                        <label className='font-normal p-0 m-0' style={{ color: Colors.LabelColor, padding: 0 }}>Upload File</label>

                        <Controller
                            control={control}
                            name='mediaId'
                            defaultValue={''}
                            render={({ }) => {
                                return (
                                    <input
                                        type="file"
                                        className='z-0 cursor-pointer h-10 absolute outline-none border-0 rounded left-0 bottom-0 py-2 px-2'
                                        style={{
                                            width: '100%',
                                            zIndex: 998,
                                            background: Colors.LightGray
                                        }}

                                        onChange={(e) => {
                                            fileHandler(e)
                                        }}
                                        accept=".pdf, .docx"
                                    />)
                            }}
                        />
                        {/* <img src={fileUpload} className='absolute bottom-0 right-0 h-10' style={{ zIndex: 999 }} width={40} /> */}
                    </Col>
                    <Col lg={11} xs={24} className='' style={{ paddingLeft: 0 }} >
                        <Controller
                            control={control}
                            name='name'
                            render={({ field, fieldState }) => (
                                <>
                                    <LabelWithField
                                        labeltext={'Document Name'}
                                        field={field}
                                        labelClass={'font-normal p-0 mt-0'}
                                        inputClass={'outline-none h-10 rounded'} />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>
                            )}
                        />
                    </Col>
                    <Col span={24} className='mb-1' style={{ padding: 0 }}>
                        {
                            !watchDoc && documentData?.media ?
                                <a href={documentData?.media ? documentData?.media[0]?.url : ''} target='_blank' className='cursor-pointer break-all' style={{ color: '#BFD2FA' }}>
                                    media
                                </a>
                                :
                                ''
                        }
                        {
                            (!watchDoc || watchDoc) && documentValidate ?
                                <p style={{ color: Colors.Error }}>{documentValidate}</p>
                                :
                                ''
                        }
                    </Col>

                </Row>

                <Row gutter={16} className='mb-4' >
                    <Col lg={12} xs={24} style={{ paddingLeft: 0 }}>
                        <Controller
                            control={control}
                            name='accessibility'
                            render={({ field, fieldState }) => (
                                <>
                                    <LabelWithSelect
                                        options={optionsSelect}
                                        labeltext={'Acessibility'}
                                        field={field}
                                        labelClass={'font-normal p-0 m-0'}
                                        inputClass={'outline-none h-10 rounded'}
                                    />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>

                            )}
                        />
                    </Col>

                </Row>

                <Row gutter={16}>
                    <Col span={24} style={{ paddingLeft: 0 }}>
                        <Controller
                            control={control}
                            name='description'
                            render={({ field, fieldState }) => (
                                <>
                                    <TextField.Textarea
                                        style={{ color: Colors.LightGray }}
                                        placeholder={''}
                                        {...field}
                                        labeltext={'Description'}
                                        className={'outline-none h-10 rounded'}
                                    />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>
                            )}
                        />
                    </Col>
                </Row>
                <br />
                <Row gutter={16} className='justify-end'>
                    <Col lg={24} className='flex justify-end'>
                        <Button.Basic
                            onClick={handleSubmit(documentSubmitHandler)}
                            htmlType="submit"
                            className="h-12 px-6 yellow-bg border-none text-white border-transparent"
                            text={title == 'Edit' ? 'Edit' : "Save"}
                            type={"submit"}
                            loader={docLoader}
                        />
                    </Col>
                </Row>
                {/* <Row gutter={16}>
                    <Col span={24} className='flex align-middle justify-end items-center underline'>
                        <FaPlus className='' /><span className=' font-bold'>Add More</span>
                    </Col>
                </Row> */}
            </form >

        </Row >
    )
}

export default Documents

var optionsSelect = [
    {
        value: 'admin',
        label: 'Admin',
    },
    {
        value: 'all',
        label: 'All',
    },

]
