import React, { useState } from 'react'
import { Row, Col, Avatar } from 'antd'
import { TextField } from '../../../components'
import { IoIosArrowForward, IoIosSearch } from 'react-icons/io'
import { HiOutlineBellAlert } from 'react-icons/hi2'
import DropDown from '../../../components/DropDown'
import { UserOutlined } from '@ant-design/icons'
import { Colors } from '../../../config'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { UserPasswordChange } from '../../../config/Schema'
import LabelWithField from '../../../components/LabelWithField'
import LabelWithFieldIcon from '../../../components/LabelWithFieldIcon'
import { IoIosEyeOff } from "react-icons/io";
import { IoIosEye } from "react-icons/io";



const ChangePassword = () => {

    const schema = UserPasswordChange;
    const { control, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    })

    const formHandler = (data) => {
        console.log(data)
    }

    const navigate = useNavigate();

    const [state, setState] = useState({
        oldPassword: false,
        newPassword: false,
        confirmPassword: false
    })

    const options = [
        {
            label: 'My Availability',
            value: '1'
        },
        {
            label: 'Edit Profile',
            value: '2'
        },
        {
            label: 'Change Password',
            value: '3'
        },
    ]


    const dropDownSelector = (e) => {
        if (e.key === "tmp-0") {
            navigate('/MyAvailability')
            return
        }
        else if (e.key === "tmp-1") {
            navigate('/EditProfile')
            return
        }
        else if (e.key === 'tmp-2') {
            navigate('/ChangePassword')
            return
        }
    }
    return (
        <>
            <Row className="w-full flex items-center flex-wrap mb-8">
                <Col lg={6} md={4} style={{}} className="my-2">
                    <h1 className='text-xl font-semibold'>Change Password</h1>
                </Col>

                <Col lg={18} md={18} className="flex justify-end items-center py-3 px-2 gap-4 flex-wrap" style={{}}>
                    <TextField.Basic placeholder={'Search here'} suffix={<IoIosSearch />} style={{ background: '#EFF2F5', border: '1px solid red' }} className='w-8/12 py-3' />
                    <div className="py-3 px-3 rounded-md" style={{
                        background: Colors.White,
                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'
                    }}>
                        <HiOutlineBellAlert size={20} />
                    </div>

                    <div className="py-2 px-4 rounded-md" style={{
                        background: Colors.White,
                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'
                    }}>

                        <DropDown placement={'bottom'} onClick={(e) => dropDownSelector(e)} items={options} children={
                            <div className="flex items-center gap-2 justify-between">
                                <div>
                                    <Avatar icon={<UserOutlined />} />
                                </div>
                                <div className="flex">
                                    <span>Mark Smith <IoIosArrowForward className="inline" /></span>
                                </div>
                            </div>
                        } />
                    </div>
                </Col>
            </Row>

            <Row style={{ background: Colors.White }} justify={'center'} align={'middle'} className='w-full rounded-lg py-6 px-2'>
                <form className='md:w-1/2 mx-auto px-2 w-full' onSubmit={handleSubmit(formHandler)} style={{}}>
                    <Controller
                        name='oldPassword'
                        control={control}
                        render={({ field: { value, onChange }, fieldState }) => {
                            return (
                                <>
                                    <LabelWithFieldIcon
                                        type={state.oldPassword ? 'text' : 'password'}
                                        value={value}
                                        onChange={onChange}
                                        inputClass='py-2 px-2 rounded-md w-full md:w-10/12'
                                        labeltext={'Current Password'}
                                        suffix={state.oldPassword ? <IoIosEye size={18} className='cursor-pointer' color={Colors.LabelColor} onClick={() => setState({ ...state, oldPassword: false })} /> : <IoIosEyeOff size={18} className='cursor-pointer' color={Colors.LabelColor} onClick={() => setState({ ...state, oldPassword: true })} />}
                                    />
                                    {fieldState.error ? <p className='' style={{ color: Colors.Error }}>{fieldState.error.message}</p> : ''}
                                </>
                            )
                        }}
                    />

                    <Controller
                        name='newPassword'
                        control={control}
                        render={({ field: { value, onChange }, fieldState }) => {
                            return (
                                <>
                                    <LabelWithFieldIcon
                                        type={state.newPassword ? 'text' : 'password'}
                                        value={value}
                                        onChange={onChange}
                                        inputClass='py-2 px-2 rounded-md w-full md:w-10/12'
                                        labeltext={'New Password'}
                                        suffix={state.newPassword ? <IoIosEye size={18} color={Colors.LabelColor} className='cursor-pointer' onClick={() => setState({ ...state, newPassword: false })} /> : <IoIosEyeOff size={18} color={Colors.LabelColor} className='cursor-pointer' onClick={() => setState({ ...state, newPassword: true })} />}
                                    />
                                    {fieldState.error ? <p className='' style={{ color: Colors.Error }}>{fieldState.error.message}</p> : ''}

                                </>
                            )
                        }}
                    />

                    <Controller
                        name='confirmPassword'
                        control={control}
                        render={({ field: { value, onChange }, fieldState }) => {
                            return (
                                <>
                                    <LabelWithFieldIcon
                                        type={state.confirmPassword ? 'text' : 'password'}
                                        value={value}
                                        onChange={onChange}
                                        inputClass='py-2 px-2 rounded-md w-full md:w-10/12'
                                        labeltext={'Confirm Password'}
                                        suffix={state.confirmPassword ? <IoIosEye size={18} color={Colors.LabelColor} className='cursor-pointer' onClick={() => setState({ ...state, confirmPassword: false })} /> : <IoIosEyeOff size={18} className='cursor-pointer' color={Colors.LabelColor} onClick={() => setState({ ...state, confirmPassword: true })} />}
                                    />
                                    {fieldState.error ? <p className='' style={{ color: Colors.Error }}>{fieldState.error.message}</p> : ''}

                                </>
                            )
                        }}
                    />

                    <Row className=' flex items-center justify-center w-10/12 my-6'>
                        <input type='submit' value={'Save'} className='px-11 py-3 rounded-md cursor-pointer' style={{ background: Colors.Yellow, color: Colors.White }} />
                    </Row>
                </form>
            </Row>
        </>
    )
}

export default ChangePassword