import React from 'react';
import { Input } from 'antd';
const Basic = ({
  placeholder,
  value,
  onChange,
  size,
  type,
  autoComplete,
  style,
  ...props
}) =>

  <Input
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    autoComplete={autoComplete}
    type={type}
    size={size}
    style={style}
    {...props}
  />;
export default Basic;