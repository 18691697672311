import { Button, ConfigProvider } from "antd";


import React from 'react'

const ButtonLoader = ({ loader, style, className, text, btnHandler, isDisabled, icon }) => {
    return (
        <ConfigProvider theme={{
            components: {
                Button: {
                    colorPrimaryBorderHover: 'white',
                    colorPrimaryHover: 'white',
                    colorPrimary: 'white',
                    colorPrimaryActive: 'white',
                    colorPrimaryTextHover: 'white',
                    defaultHoverBg: "",
                    defaultActiveBg: ""
                }
            }
        }}>
            <Button
                htmlType="submit"
                loading={loader && true}
                style={style}
                className={className}
                onClick={btnHandler}
                disabled={isDisabled}
                icon={icon}
            >{text}
            </Button>
        </ConfigProvider>
    )
}

export default ButtonLoader