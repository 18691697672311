import React, { useState } from 'react'
import { Select } from 'antd'
import { Colors } from '../../config'

const LabelWithMultipleSelect = ({ labelClass, inputClass, labeltext,value, onChange,options,defaultValue }) => {

  // const [optValues, setOptValues] = useState([]);

  // const handleChange = (value) => {
  //   let AllValues = [];
  //   for (let opt of value) {
  //     if (opt == 'All') {
  //       console.log('All', value)
  //       for (let val of addressOption) {
  //         if (val.value == 'All') continue
  //         AllValues.push(val.value)
  //       }
  //       console.log(AllValues)
  //       setOptValues(AllValues)
  //     }
  //     else if (opt !== 'All') {
  //       setOptValues(value)
  //       console.log(value)
  //       console.log(AllValues)
  //     }
  //   }


  // }

  return (
    <div className="flex flex-col py-4 w-full select--All--functionality">
      <label className={labelClass} style={{ color: Colors.LabelColor }}>{labeltext}</label>
      <Select
        defaultValue={defaultValue}
        mode="multiple"
        onChange={onChange}
        options={options}
        className={inputClass}
        showSearch={false}
        value={value}
      >
      </Select>

    </div>

  )
}

export default LabelWithMultipleSelect

// let addressOption = [
//   {
//     label: 'All',
//     value: 'All'
//   },
//   {
//     label: 'Street',
//     value: 'Street'
//   },
//   {
//     label: 'Parkway',
//     value: 'Parkway'
//   },
//   {
//     label: 'Avenue',
//     value: 'Avenue'
//   },
//   {
//     label: 'Way',
//     value: 'Way'
//   },
//   {
//     label: 'Boulevard',
//     value: 'Boulevard'
//   },
//   {
//     label: 'Place',
//     value: 'Place'
//   },
//   {
//     label: 'Lane',
//     value: 'Lane'
//   },
//   {
//     label: 'Drive',
//     value: 'Drive'
//   },
//   {
//     label: 'Court',
//     value: 'Court'
//   },
//   {
//     label: 'Terrace',
//     value: 'Terrace'
//   },
//   {
//     label: 'Alley',
//     value: 'Alley'
//   },
//   {
//     label: 'Connector',
//     value: 'Connector'
//   },
//   {
//     label: 'Highway',
//     value: 'Highway'
//   },
//   {
//     label: 'Route',
//     value: 'Route'
//   },
//   {
//     label: 'Thruway',
//     value: 'Thruway'
//   },
//   {
//     label: 'Frontage Road',
//     value: 'Frontage Road'
//   },
//   {
//     value: 'Other',

//     label: 'Other',
//   },
// ]

