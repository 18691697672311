import React, { useState, useEffect, useCallback } from 'react'
import { Row, Col, Avatar, Form } from "antd";
import { CameraOutlined } from '@ant-design/icons';
import { Button, TextField, Text } from '..';
import { FaArrowLeftLong } from "react-icons/fa6";
import LabelWithField from '../LabelWithField';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { AddGeoFence } from '../../config/Schema';
import LabelWithSelect from '../LabelWithSelect';
import { FaPlus } from "react-icons/fa6";
import { ApiCaller, Colors, Utils } from '../../config';
import CheckBoxField from '../Select/Checkbox';
import QRCode from "react-qr-code";
import GoogleMapReact from 'google-map-react';
import { useNavigate } from 'react-router-dom';
import BasicDrawer from '../Drawer';
import Geofence from '../DrawerForms/geofence';
import { useMutation } from 'react-query';
import { UseDispatch, useDispatch, useSelector } from 'react-redux';
import Circular from '../Loader/Circular';
import { SiteManagementAction } from '../../store/actions';
import GoogleMapComp from '../GoogleMap';




const mapOptions = {
    zoomControl: false, // Disable zoom control
    fullscreenControl: false, // Disable fullscreen control
    streetViewControl: false, // Disable street view control
    mapTypeControl: false, // Disable map type control
    draggable: false, // Disable dragging
    scrollwheel: false // Disable scrollwheel zoom
};


const MapLocation = ({
    nextStep,
    previousStep,
    currenStep

}) => {

    const [currLocationJs, setCurrLocationJs] = useState(
        {
            lat: '',
            lng: ''
        }
    );

    const [position, setPosition] = useState([])

    const [drawerState, setDrawerState] = useState(false);
    const [qrValue, setQrValue] = useState('hello');

    const [btnDisabled, setBtnDisabled] = useState({
        next: false,
        save: false,
        addMore: false,
        saveGeoFence: false
    })

    const [mapEditData, setMapEditData] = useState({})

    // # CASE  
    // have to call the cleaner function because whenever the component render it gets the data from
    // which was previously stored in reducer. Case because if user is in the middle of the form and 
    // clicks to navigate to somewhere else the cleaner function will not going to call on that situation
    // it was getting called only on save btn and continue btn, So thats why it will get the data whenever
    // the component get render so to avoid that we called this function in useEffect whenever the 
    // component get first render on mounting stage this will trigger and by chance if any state is there
    // in reducer it will clear it.
    useEffect(() => {
        dispatch(SiteManagementAction.GetGeofenceDataCleaner())
    }, [])


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mapData = useSelector(state => state?.SiteManagementReducer?.geofenceData || []);
    const mapLoader = useSelector(state => state?.SiteManagementReducer?.loader);

    const defaultValues = {
        name: '',
        longitude: position && position[0]?.lng,
        latitude: position && position[0]?.lat,
    }

    const schema = AddGeoFence;
    const { handleSubmit, control, reset, setValue, watch } = useForm(
        {
            resolver: yupResolver(schema),
            defaultValues: defaultValues
        }
    )

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    useEffect(() => {
        getLocationJs();
    }, []);

    const renderMarkers = (map, maps, positions) => {
        const markers = positions.map(position => {
            return new maps.Marker({
                position,
                map
            });
        });

        return markers;
    };

    const getLocationJs = () => {
        navigator.geolocation.getCurrentPosition(position => {
            const { latitude, longitude } = position.coords;
            setPosition([{ lat: latitude, lng: longitude }]);
            setValue('latitude', latitude);
            setValue('longitude', longitude);
        });

    };


    const mapClickHandler = useCallback(
        (e) => {
            setPosition([e.latLng.toJSON()]);
            setValue('latitude', e?.latLng.toJSON()?.lat);
            setValue('longitude', e?.latLng.toJSON()?.lng);
        },
        [setPosition]
    );


    const defaultProps = {
        center: {
            lat: 32.7767,
            lng: 96.7970
        },
        zoom: 11
    };

    const geofenceMutation = useMutation(async (data) => {

        const { buttonName, ...restData } = data;

        try {
            let id = localStorage.getItem('PropertyKey');
            let res = await ApiCaller.Post(`/property/${id}/geofence`, restData);

            if (buttonName == 'next') {
                dispatch(SiteManagementAction.GetGeofenceDataCleaner())
                setBtnDisabled({
                    save: false,
                    next: false,
                    addMore: false,
                    saveGeoFence: false
                });
                reset(defaultValues);
                nextStep();
            }

            else if (buttonName == 'save') {
                dispatch(SiteManagementAction.GetGeofenceDataCleaner())
                setBtnDisabled({
                    save: false,
                    next: false,
                    addMore: false,
                    saveGeoFence: false
                });
                localStorage.removeItem('PropertyKey')
                reset(defaultValues);
                navigate('/site-management')
            }

            else if (buttonName == 'more') {
                dispatch(SiteManagementAction?.GetGeofence());
                setBtnDisabled({
                    save: false,
                    next: false,
                    addMore: false,
                    saveGeoFence: false
                });
                reset(defaultValues)
            }

            else if (buttonName == 'saveGeofence') {
                dispatch(SiteManagementAction.GetGeofenceDataCleaner())
                setBtnDisabled({
                    save: false,
                    next: false,
                    addMore: false,
                    saveGeoFence: false
                });
                reset(defaultValues)
                nextStep()
            }
        }

        catch (err) {
            setBtnDisabled({
                save: false,
                next: false,
                addMore: false,
                saveGeoFence: false
            });
            Utils.showMessage('error', err?.response?.data?.message);
            return
        }

    })

    const SaveGeofenceHandler = (data, name) => {
        // it will save the geo fence and will move to the next form
        console.log(data)
        setBtnDisabled({
            addMore: false,
            next: false,
            save: false,
            saveGeoFence: true
        })
        data.buttonName = name;
        geofenceMutation.mutate(data)
    }

    const nextHandler = (data, name) => {
        setBtnDisabled({
            save: false,
            addMore: false,
            next: true,
            saveGeoFence: false
        })
        // post api hit and continue
        console.log(data)
        data.buttonName = name;
        geofenceMutation.mutate(data)
    }

    const skipHandler = () => {
        setBtnDisabled({
            next: false,
            save: false,
            addMore: false,
            saveGeoFence: false
        })
        nextStep()
        reset()
    }

    const saveHandler = (data, name) => {
        setBtnDisabled({
            addMore: false,
            next: false,
            save: true,
            saveGeoFence: false
        })
        // post api hit and back to listing.
        data.buttonName = name;
        geofenceMutation.mutate(data)
    }

    const SaveAndAddMoreHandler = (data, name) => {
        // it will save and make the form available to add more.
        setBtnDisabled({
            next: true,
            save: true,
            addMore: true,
            saveGeoFence: true
        })
        data.buttonName = name
        geofenceMutation.mutate(data)
    }

    const EditGeoFenceHandler = (map) => {
        setMapEditData(map)
        setDrawerState(true)
    }

    const GeofenceDataHandler = (data) => { }

    const closeDrawerHandler = () => {
        setDrawerState(false)
    }

    return (
        <div className='bg-white'>
            {
                !mapLoader ?
                    mapData?.map((map) => {
                        return (
                            <Row gutter={16} className='px-4 md:px-10 mb-2' key={map?.id}>
                                <Col span={24} className='gry-bg p-6 rounded-lg'>
                                    <Row className='px-2'>
                                        <Col lg={8} md={8} sm={24} xs={24} className='mb-2'>
                                            <Text.Label
                                                text={'GeoFence Name'}
                                            />
                                            <p className='text-xs'>{map?.name}</p>
                                        </Col>
                                        <Col lg={6} md={6} sm={24} xs={24} className='mb-2'>
                                            <Text.Label
                                                text={'Latitude'}
                                            />
                                            <p className='text-xs'>{map?.latitude}</p>
                                        </Col>
                                        <Col lg={6} md={6} sm={24} xs={24} className='mb-2'>
                                            <Text.Label
                                                text={'Longitude'}
                                            />
                                            <p className='text-xs'>{map?.longitude}</p>
                                        </Col>
                                        <Col lg={4} md={4} sm={24} xs={24} className='mb-2'>
                                            <Button.Basic
                                                // onClick={() => }
                                                className="w-full h-10 yellow-bg border-none text-white border-transparent"
                                                text={"Edit GeoFence"}
                                                onClick={() => EditGeoFenceHandler(map)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    })

                    :
                    <Circular
                        spinning={mapLoader}
                        size={40}
                        color={Colors.Yellow}
                        containerStyle={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    />
            }

            <Row className='px-2 md:px-10'>
                <form layout="vertical" className='w-full'>
                    <Row gutter={16}>
                        <Col lg={16} md={16} sm={24} xs={24} className='h-28 md:h-auto'>

                            <GoogleMapComp
                                pointerPosition={position}
                                mapClickHandler={mapClickHandler}
                            >
                            </GoogleMapComp>
                        </Col>
                        <Col lg={8} md={8} sm={24} xs={24} style={{ background: '#F9F9FA' }} className='p-3'>
                            <Row>

                                <Col lg={24} md={24} sm={0} xs={0} className='h-28'>
                                    <GoogleMapComp />
                                </Col>

                                <Col span={24}>
                                    <Controller
                                        control={control}
                                        name='name'
                                        defaultValue={''}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <LabelWithField
                                                    labeltext={'Geofence Name'}
                                                    field={field}
                                                    labelClass={'font-normal'}
                                                    inputClass={'h-10 rounded outline-none'}
                                                    bgColor='white'
                                                />
                                                {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                            </>
                                        )}
                                    />
                                </Col>

                                <Col span={24}>
                                    <Controller
                                        control={control}
                                        name='latitude'
                                        defaultValue={''}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <LabelWithField
                                                    labeltext={'Latitude'}
                                                    field={field}
                                                    labelClass={'font-normal'}
                                                    inputClass={'h-10 rounded outline-none'}
                                                    bgColor='white'
                                                />
                                                {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                            </>
                                        )}
                                    />
                                </Col>

                                <Col span={24}>
                                    <Controller
                                        control={control}
                                        name='longitude'
                                        defaultValue={''}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <LabelWithField
                                                    labeltext={'Longitude'}
                                                    field={field}
                                                    labelClass={'font-normal'}
                                                    inputClass={'h-10 rounded outline-none'}
                                                    bgColor='white'
                                                />
                                                {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                            </>
                                        )}
                                    />
                                </Col>


                                <Col span={24} className='my-4'>
                                    <Button.Basic
                                        className="w-full green-bg h-12 border-none text-white border-transparent"
                                        onClick={handleSubmit((data) => SaveGeofenceHandler(data, 'saveGeofence'))}
                                        text={"Save Geofence"}
                                        disabled={btnDisabled.next || btnDisabled.addMore || btnDisabled.save ? true : false}
                                        loader={btnDisabled.next || btnDisabled.addMore || btnDisabled.save ? false : geofenceMutation?.isLoading}
                                    />
                                </Col>
                                <Col span={24}>
                                    <p className='text-center mt-4 text-gray-100 px-10' style={{ color: "#8A94A6" }}>
                                        Click and Drag the red map marker to move the GeoFence
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <br />
                    <Row gutter={16}>
                        <Col span={24} className='flex align-middle justify-end items-center'>
                            <span className={`cursor-pointer hover:underline font-bold ${geofenceMutation?.isLoading && 'pointer-events-none'}`} onClick={handleSubmit((data) => SaveAndAddMoreHandler(data, 'more'))}><span className='font-bold text-base'> + </span>Save And Add More</span>
                        </Col>
                    </Row>
                </form>

            </Row >

            <br />


            <Row gutter={[16, 16]} className='px-2 md:px-10' >
                <Col xs={24} lg={3} className='flex align-middle items-center'>
                    <FaArrowLeftLong className='mr-2' />
                    <span onClick={() => previousStep()} className={`cursor-pointer ${geofenceMutation?.isLoading && 'pointer-events-none'}`}
                    >Back</span>
                </Col>

                <Col xs={24} lg={{ span: 4, offset: 9 }} >
                    <Button.Basic
                        onClick={handleSubmit((data) => saveHandler(data, 'save'))}
                        className="w-full h-12 yellow-bg border-none text-white border-transparent"
                        text={"Save"}
                        type={'submit'}
                        disabled={btnDisabled.next || btnDisabled.addMore || btnDisabled.saveGeoFence ? true : false}
                        loader={btnDisabled.next || btnDisabled.addMore || btnDisabled.saveGeoFence ? false : geofenceMutation?.isLoading}
                    />
                </Col>
                <Col xs={24} lg={4} >
                    <Button.Basic
                        className="w-full h-12  orange-bg border-none text-white border-transparent"
                        onClick={handleSubmit((data) => nextHandler(data, 'next'))}
                        text={"Continue"}
                        type={'submit'}
                        disabled={btnDisabled.save || btnDisabled.addMore || btnDisabled.saveGeoFence ? true : false}
                        loader={btnDisabled.save || btnDisabled.addMore || btnDisabled.saveGeoFence ? false : geofenceMutation?.isLoading}
                    />
                </Col>
                <Col xs={24} lg={4} >
                    <Button.Basic
                        className="w-full h-12 black-bg border-none text-white border-transparent"
                        onClick={skipHandler}
                        text={"Next"}
                        disabled={btnDisabled.next || btnDisabled.save || btnDisabled.addMore || btnDisabled.saveGeoFence ? true : false}
                    />
                </Col>
            </Row>
            <br />

            <BasicDrawer
                openDrawer={drawerState}
                title={'Edit GeoFence'}
                onClose={closeDrawerHandler}
                width={'800'}
            >

                <Geofence
                    mapEditData={mapEditData}
                    closeDrawerHandler={closeDrawerHandler}
                />
            </BasicDrawer>
        </div >
    )
}

export default MapLocation