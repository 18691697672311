import React from 'react'
import BasicDrawer from '../../components/Drawer'
import Circular from '../../components/Loader/Circular'
import { Avatar, Col, Divider, Row } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import BasicText from '../../components/Text/BasicText'
import { Colors } from '../../config'
import moment from 'moment'
import { Button } from '../../components'
import { CiEdit } from 'react-icons/ci'

const UserDetailDrawer = ({
    drawerStates,
    onCloseDrawer,
    detailDrawerReset,
    userDetailLoader,
    userDetailData,
    UserEditHandler,
    isRole
}) => {


    return (
        <BasicDrawer
            openDrawer={drawerStates?.userDetailDrawer}
            title={'User Details'}
            onClose={onCloseDrawer}
            afterOpenChange={detailDrawerReset}
            width={'600'}
        >

            {
                userDetailLoader ?

                    <Circular
                        spinning={userDetailLoader}
                        size={40}
                        color={Colors.Yellow}
                        containerStyle={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    />

                    :

                    <Row className='w-full'>

                        <Col className='w-full flex flex-col items-center' span={24} >
                            {userDetailData?.profileImg ?
                                <Avatar size={55} src={userDetailData?.profileImg[0]?.url} />
                                :
                                <Avatar size={55} icon={<UserOutlined />} />
                            }
                            <BasicText className='text-xl font-semibold text-center' text={userDetailData?.userName ? userDetailData?.userName : '-'} />
                            <span>{userDetailData?.userRole ? userDetailData?.userRole[0]?.name : '-'}</span>

                        </Col>

                        <Divider />

                        <Row className='w-full' justify={'center'} align={'middle'}>
                            <Col span={9} className='w-2/4 flex flex-col'>
                                <span className='text-sm font-semibold' style={{ color: Colors.LabelColor }}>First Name</span>
                                <BasicText className='text-sm font-medium' text={userDetailData?.firstName ? userDetailData?.firstName : '-'} />
                            </Col>

                            <Col span={9} className='w-2/4 flex flex-col'>
                                <span className='text-sm font-semibold' style={{ color: Colors.LabelColor }}>Last Name</span>
                                <BasicText className='text-sm font-medium' text={userDetailData?.lastName ? userDetailData?.lastName : '-'} />
                            </Col>

                            <Col span={6} className='w-2/4 flex flex-col'>
                                <span className='text-sm font-semibold' style={{ color: Colors.LabelColor }}>Role</span>
                                <div className='flex items-center justify-start gap-1 flex-wrap'>
                                    {
                                        userDetailData?.userRole?.map((role, key) => {
                                            return (
                                                <>
                                                    <BasicText key={role?.id} className='text-sm font-medium' text={role?.name} />
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </Col>
                        </Row>

                        <Divider />

                        <Row className='w-full' justify={'center'} align={'start'}>
                            <Col span={9} className='w-2/4 flex flex-col'>
                                <span className='text-sm font-semibold' style={{ color: Colors.LabelColor }}>Phone Number</span>
                                <BasicText className='text-sm font-medium' text={userDetailData?.phoneNo ? userDetailData?.phoneNo : '-'} />
                            </Col>

                            <Col span={9} className='w-2/4 flex flex-col'>
                                <span className='text-sm font-semibold' style={{ color: Colors.LabelColor }}>Email</span>
                                <BasicText className='text-sm font-medium break-words' text={userDetailData?.email ? userDetailData?.email : '-'} />
                            </Col>

                            <Col span={6} className='w-2/4 flex flex-col'>
                                <span className='text-sm font-semibold' style={{ color: Colors.LabelColor }}>User Name</span>
                                <BasicText className='text-sm font-medium' text={userDetailData?.userName ? userDetailData?.userName : '-'} />
                            </Col>
                        </Row>

                        <Divider />

                        <Row className='w-full' justify={'center'} align={'middle'}>
                            <Col span={24} className='flex flex-col'>
                                <span className='text-sm font-semibold' style={{ color: Colors.LabelColor }}>Eligibility</span>
                                <div className='flex items-center justify-start gap-1 flex-wrap'>
                                    {
                                        userDetailData?.eligibilities?.map((eligibility, key) => {
                                            return (
                                                <>
                                                    <BasicText key={eligibility?.id} className='text-sm font-medium' text={eligibility?.name} />
                                                    {key < userDetailData?.eligibilities?.length - 1 && <span>,</span>}
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </Col>

                        </Row>


                        <Divider />

                        <Row className='w-full' justify={'start'} align={'start'}>
                            <Col span={9} className='flex flex-col'>
                                <span className='text-sm font-semibold' style={{ color: Colors.LabelColor }}>Schedules</span>
                                <div className='flex items-center justify-start gap-1 flex-wrap'>
                                    {
                                        userDetailData?.schedules?.map((schedule, key) => {
                                            return (
                                                <>
                                                    <BasicText key={schedule?.id} className='text-sm font-medium' text={schedule?.name} />
                                                    {key < userDetailData?.schedules?.length - 1 && <span>,</span>}
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </Col>

                            <Col span={9} className='flex flex-col'>
                                <span className='text-sm font-semibold' style={{ color: Colors.LabelColor }}>Created Date</span>
                                <BasicText className='text-sm font-medium' text={userDetailData?.schedules ? moment(userDetailData?.schedules[0]?.createdAt).format('DD-MM-YYYY') : '-'} />
                            </Col>
                        </Row>

                        <Divider />

                        <BasicText className='w-full mb-5 text-base font-semibold' text={'Certification Info:'} />

                        <Row className='w-full' justify={'center'} align={'middle'}>
                            <Col span={9} className='flex flex-col'>
                                <span className='text-sm font-semibold' style={{ color: Colors.LabelColor }}>Unarmed expiration:</span>
                                <BasicText className='text-sm font-medium' text={userDetailData?.certificateInfo?.unarmedExpiration ? moment(userDetailData?.certificateInfo?.unarmedExpiration).format('DD-MM-YYYY') : '-'} />
                            </Col>

                            <Col span={15} className='flex flex-col'>
                                <span className='text-sm font-semibold' style={{ color: Colors.LabelColor }}>Armed expiration:</span>
                                <BasicText className='text-sm font-medium' text={userDetailData?.certificateInfo?.armedExpiration ? moment(userDetailData?.certificateInfo?.armedExpiration).format('DD-MM-YYYY') : '-'} />
                            </Col>

                            {/* <Col span={6} className='flex flex-col'>
                                <span className='text-sm font-semibold' style={{ color: Colors.LabelColor }}>OJT:</span>
                                <BasicText className='text-sm font-medium' text={userDetailData?.certificateInfo?.ojt ? moment(userDetailData?.certificateInfo?.ojt).format('DD-MM-YYYY') : '-'} />
                            </Col> */}
                        </Row>

                        <Divider />

                        <Row className='w-full' justify={'start'} align={'middle'}>
                            <Col span={9} className='flex flex-col'>
                                <span className='text-sm font-semibold' style={{ color: Colors.LabelColor }}>OJT-Annual-1:</span>
                                <BasicText className='text-sm font-medium' text={userDetailData?.certificateInfo?.annualOne ? moment(userDetailData?.certificateInfo?.annualOne).format('DD-MM-YYYY') : '-'} />
                            </Col>

                            <Col span={15} className='flex flex-col'>
                                <span className='text-sm font-semibold' style={{ color: Colors.LabelColor }}>OJT-Annual-2:</span>
                                <BasicText className='text-sm font-medium' text={userDetailData?.certificateInfo?.annualTwo ? moment(userDetailData?.certificateInfo?.annualTwo).format('DD-MM-YYYY') : '-'} />
                            </Col>
                        </Row>

                        <Divider />

                        {
                            isRole !== 'supervisor' && isRole !== 'manager' ?
                                <Row className='w-full' justify={'end'} align={'middle'}>
                                    <Col span={6}>
                                        <Button.Basic onClick={() => UserEditHandler()} style={{ width: "100%", height: "40px", color: Colors.White }} icon={<CiEdit size={18} />} className={'h-11 flex items-center justify-center outline-none border-0 rounded-none yellow-bg'} text={'Edit User'} />
                                    </Col>

                                </Row>
                                : <></>
                        }

                    </Row>


            }


        </BasicDrawer>

    )
}

export default UserDetailDrawer
