
import { GET_SCHEDULES, GET_SCHEDULES_SUCCESS, GET_SCHEDULES_FAILURE, GET_ELEGIBILITIES, GET_ELEGIBILITIES_SUCCESS, GET_ELEGIBILITIES_FAILURE } from "../constants";

const initialState = {
    loader: false,
    scheduleData: [],
    elegibilityData: []
}

export default function SettingReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SCHEDULES:
            state = {
                ...state,
                loader: true,
            }
            break;

        case GET_SCHEDULES_SUCCESS:
            state = {
                ...state,
                loader: false,
                scheduleData: action.payload
            }
            break;

        case GET_SCHEDULES_FAILURE:
            state = {
                ...state,
                loader: false,
            }
            break;

        case GET_ELEGIBILITIES:
            state = {
                ...state,
                loader: true
            }
            break;

        case GET_ELEGIBILITIES_SUCCESS:
            state = {
                ...state,
                loader: false,
                elegibilityData: action.payload
            }
            break;

        case GET_ELEGIBILITIES_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;

        default:
            break;
    }
    return state;
}