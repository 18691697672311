import React from 'react'
import BasicText from '../../components/Text/BasicText'
import { Col, Row } from 'antd'
import { Colors } from '../../config'
import securityAlert from '../../assets/images/securityAlert.png'
import report from '../../assets/images/report.png'
import { useNavigate } from 'react-router-dom'

const SecurityIssues = () => {
    const navigate = useNavigate();
    return (
        <>
            <Row align={"middle"} justify={"start"} className='mt-2'>
                <BasicText text={'Security Issues'} className='text-xl font-semibold' />
            </Row>

            <Row className='w-full mt-10' gutter={[32, 16]}>
                <Col xl={7} lg={7} md={8} sm={10} xs={24}
                    onClick={() => navigate('/security-issues/manage-incidents')}
                    style={{ background: Colors.White, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} className='mr-4 flex items-center gap-3 px-4 py-6 rounded-md cursor-pointer hover:scale-x-105 hover:scale-y-105 duration-500 ease-in-out delay-75'>
                    <img src={securityAlert} height={50} width={50} />
                    <div>
                        <BasicText text={'Manage Incidents'} className='text-lg font-semibold'
                        />
                        <span className='' style={{ fontSize: '12px' }}>and issues monitor</span>
                    </div>
                </Col>

                <Col xl={7} lg={7} md={8} sm={10} xs={24}
                    style={{ background: Colors.White, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} className='flex items-center gap-3 px-4 py-6 rounded-md cursor-pointer hover:scale-x-105 hover:scale-y-105 duration-500 ease-in-out delay-75'>
                    <img src={report} height={50} width={50} />
                    <div>
                        <BasicText text={'Reports'} className='text-lg font-semibold'
                        />
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default SecurityIssues