import React, { useState } from "react";
import BasicDrawer from "../../components/Drawer";
import { Button, TextField } from "../../components/index";
import { useForm } from "react-hook-form";
import { Row, Col } from "antd";
import { ApiCaller, Colors, Utils } from "../../config";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { SettingAction } from "../../store/actions";

const AddEligibilityDrawer = ({ visible, onClose, setState }) => {

  const { handleSubmit, control, reset, setValue, watch } = useForm({
    mode: "onChange",
  });

  const dispatch = useDispatch()

  const [eligibilityName, setEligibilityName] = useState('')
  const [error, setError] = useState('')

  const AddEligibilityMutate = useMutation(async (data) => {
    let payload = {
      name: data
    }
    let res = await ApiCaller.Post('/eligibility', payload);
    return res;
  },
    {
      onSuccess: (res) => {
        Utils.showMessage('success', res?.data?.message)
        onClose()
        setEligibilityName('')
        setError('')
        setState({ current: 1, searchVal: '', sortBy: 'id', sortOrder: 'asc', limit: 10, tabName: "eligibility", tabKey: 2 })
        dispatch(SettingAction.GetEligibilities({ current: 1, searchVal: '', sortBy: 'id', sortOrder: 'asc', limit: 10 }))
      },
      onError: (err) => {
        Utils.showMessage('error', err?.response?.data?.message)
      }
    }

  )

  const eligibilitySaveHandler = () => {
    if (eligibilityName.length == 0) {
      setError('Eligibility Name is required')
      return
    }
    else if (eligibilityName?.length > 50) {
      setError('Length should be maximum of 50 characters')
    }
    else {
      AddEligibilityMutate.mutate(eligibilityName)
    }
  }


  const eligibilityAddHandler = (value) => {
    if (!value) {
      setError('Eligibility Name is required')
    }
    else if (value.length > 50) {
      setError('Length should be maximum of 50 characters')
    }
    else if (value.length < 50) {
      setError('')
    }
    setEligibilityName(value)
  }

  const afterDrawerCloseHandler = () => {
    if (!visible) {
      setEligibilityName('');
      setError('')
    }
  }

  return (
    <>
      <BasicDrawer
        title="Add Eligibility/Position"
        openDrawer={visible}
        onClose={onClose}
        width={400}
        afterOpenChange={afterDrawerCloseHandler}
      >
        <>
          <Row gutter={16} className="mb-4">
            <Col lg={24} xs={24} className="">
              <TextField.Basic value={eligibilityName} onChange={(e) => eligibilityAddHandler(e.target.value)} className={'custom--input'} placeholder={'Eligibility Name'} style={{ width: '100%', borderRadius: '4px', padding: '7px 10px', background: '#F9F9FA' }} />
              {error && <p style={{ color: Colors.Error }}>{error}</p>}
            </Col>
          </Row>

          <br />
          <Row gutter={16} className="justify-end">
            <Col lg={24} className="flex justify-end">
              <Button.Basic
                onClick={eligibilitySaveHandler}
                style={{ width: "7vw" }}
                className="h-12 yellow-bg border-none text-white border-transparent"
                type={"primary"}
                text={"Save"}
                loader={AddEligibilityMutate?.isLoading}
              >
              </Button.Basic>
            </Col>
          </Row>
        </>
      </BasicDrawer>

    </>
  );
};

export default AddEligibilityDrawer;
