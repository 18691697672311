import React, { useState, useRef, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Select, Space, Button, ConfigProvider } from 'antd';
import { Colors } from '../../config';
import { useDispatch,useSelector } from 'react-redux';
import { SiteManagementAction } from '../../store/actions';
let index = 0;

const LabelWithCustomSelect = ({ labelClass, inputClass, labeltext, field, options, loading, placeholder }) => {

    const streetType = useSelector(state => state?.SiteManagementReducer?.addressTypeData || []);
    const [items, setItems] = useState(streetType && streetType);
    const [name, setName] = useState('');
    const inputRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        setItems(streetType)
    },[streetType])

    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const addItem = (e) => {
        e.preventDefault();
        if (name) {
            setItems([...items, {
                label: name,
                value: name
            }]);
            let arr = [...items, {
                label: name,
                value: name
            }]
            setName('');
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
            dispatch(SiteManagementAction.AddAddressType(arr))
        }
    };

    return (
        <div className="flex flex-col py-4 w-full">
            <label className={labelClass} style={{ color: Colors.LabelColor }}>{labeltext}</label>
            <ConfigProvider
                theme={{
                    token: {
                        /* here is your global tokens */
                        // colorPrimary: Colors,
                    },
                    components: {
                        Select: {
                            /* here is your component tokens */
                            optionSelectedBg: Colors.Yellow,
                            optionActiveBg: Colors.LightGray,
                            optionSelectedColor: Colors.White
                        },
                    },
                }}

            >
                <Select
                    className={`${inputClass}`}
                    style={{ background: Colors.LightGray }}
                    {...field}
                    showSearch={false}
                    loading={loading}
                    placeholder={placeholder}
                    dropdownRender={(menu) => (
                        <>
                            {menu}
                            <Divider
                                style={{
                                    margin: '8px 0',
                                }}
                            />
                            <Space
                                style={{
                                    padding: '0 8px 4px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Input
                                    placeholder="Add item"
                                    ref={inputRef}
                                    className='border--input'
                                    value={name}
                                    onChange={onNameChange}
                                    onKeyDown={(e) => e.stopPropagation()}
                                />
                                <Button type="text" className='' onClick={addItem}>
                                    Add
                                </Button>
                            </Space>
                        </>
                    )}
                    options={items}
                />
            </ConfigProvider>

        </div>);
};
export default LabelWithCustomSelect;