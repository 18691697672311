import React from 'react';
import { Row, Col } from 'antd';
import TextField from '../TextField';
import SelectField from '../../components/TextField/SelectField';
import Button from '../Button';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Colors } from '../../config';

const NewFilterBar = ({
  btnText1Handler,
  btnText2Handler,
  SelectFilterHandler,
  handleInputChange,
  SelectFilterValues,
  isRole,
  style,
  defaultValue,
  value,
  SearchValue,
  isManageSchedule,
  ...props
}) => {

  const defaultBtnStyle = {
    height: "40px", 
    background: Colors.Yellow, 
    color: Colors.White
  }

  const mergedStyle = {...defaultBtnStyle, ...style}

  return (
    <Row gutter={[8, 16]} style={{ background: '' }} align={'middle'} justify={'space-between'} className='w-full mb-12 py-2 py-4'>
      <Col lg={isRole === "admin" || isRole === "manager" ? 5 : 10} xl={isRole === "admin" || isRole === "manager" ? 5 : 10} md={24} sm={24} xs={24}>
        <Row align={"middle"} justify={"start"}>
          <h1 className='text-xl font-semibold'>{props.title}</h1>
        </Row>
      </Col>

      <Col lg={6} xl={7} md={6} sm={12} xs={24}>
        <TextField.Basic
          style={{ background: "#eff2f5", width: "100%" }}
          suffix={<SearchOutlined size={48} />}
          placeholder="Search"
          onChange={(e) => handleInputChange(e)}
          id="search"
          name="search"
          value={SearchValue}
          className='placeholder-red outline-none w-full py-2 px-4'
        />
      </Col>

      <Col lg={6} xl={6} md={7} sm={12} xs={24}>
        <SelectField
          defaultValue={defaultValue}
          value={value}
          style={{ backgroundColor: "#eff2f5" }}
          className='w-full h-10'
          onChange={SelectFilterHandler}
          options={SelectFilterValues}
          placeholder={'View All'}
        />
      </Col>

      {isRole === "admin" || isRole === "manager" ? (
        <Col lg={6} xl={5} md={7} sm={24} xs={24}>
          <Button.Basic
            className="text-white outline-none border-none w-full cursor-pointer"
            style={mergedStyle}
            text={isManageSchedule == '1' ? props.btnText1 : props.btnText2}
            icon={<PlusOutlined />}
            onClick={isManageSchedule == '1' ? btnText1Handler : btnText2Handler}
          />
        </Col>
      ) : null}
    </Row>
  );
};

export default NewFilterBar;
