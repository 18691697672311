import { Checkbox, ConfigProvider } from "antd";
import React from "react";

const CheckBoxField = ({
  options, //for checkbox group
  onChange,
  value,
  checked,
  defaultValue, //for checkbox group
  disabled,
  label,
  containerStyle,
  style,
  ...props
}) => {
  return (

    <div style={containerStyle}>
      {options?.length > 0 ? (
        <ConfigProvider
          theme={{
            token: {
              // colorPrimaryHover: 'yellow'
            },
          }}
        >
          <Checkbox.Group
            defaultValue={defaultValue}
            //   value={value}
            onChange={onChange}
            options={options}
            disabled={disabled}
            style={style}
            {...props}
          />
        </ConfigProvider>

      ) : (
        <Checkbox
          onChange={onChange}
          //   value={value}
          // checked={checked}
          disabled={disabled}
          style={style}
          {...props}
        >
          {label}
        </Checkbox>
      )}
    </div>
  );
};

export default CheckBoxField;
