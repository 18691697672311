import React from 'react';
import { Dropdown, Menu } from 'antd';
import { Colors } from '../../config';
import { BsThreeDots } from "react-icons/bs";


const MyDropdown = ({ items, onClick, placement, labelStyle, isRole }) => {
    const menuItems = items?.map((item) => {
        return <Menu.Item key={item.key}
            onClick={onClick}
            style={{
                backgroundColor: item.backgroundColor, color: item.color, marginBottom: "0.3rem",
                display: (isRole == 'supervisor') && (item?.label == 'Activate' || item?.label == "Deactivate" || item?.label == 'Delete') ? 'none' : ''

            }}
        >
            <div className="flex items-center" >
                <span className="mr-2"> {item.icon}</span>
                <span>{item.label}</span>
            </div>
        </Menu.Item>
    });




    const menu = (
        <Menu>
            {menuItems}
        </Menu>
    );

    return (
        <Dropdown overlay={menu} placement={placement}>
            <a labelStyle={labelStyle} >
                <BsThreeDots color={Colors.Yellow} size={20} />
            </a>
        </Dropdown>
    );
};

export default React.memo(MyDropdown);
