import { useEffect, useReducer, useState } from "react";
import {
  Scheduler,
  SchedulerData,
  ViewType,
  AddMorePopover,
  // DemoData,
  wrapperFun,
} from "./react-big-schedule";
import "./react-big-schedule/dist/css/style.css";
import moment from "moment";

let schedulerData;



const initialState = {
  showScheduler: false,
  viewModel: {},
};

function reducer(state, action) {
  switch (action.type) {
    case "INITIALIZE":
      return { showScheduler: true, viewModel: action.payload };
    case "UPDATE_SCHEDULER":
      return { ...state, viewModel: action.payload };
    default:
      return state;
  }
}

function App({ events, resources }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [popoverState, setPopoverState] = useState({
    headerItem: undefined,
    left: 0,
    top: 0,
    height: 0,
  });


  // const [user, setUsers] = useState([])
  // const [event, setEvents] = useState([])

  // useEffect(() => {
  //   setUsers(resources);
  //   setEvents(events)
  // },[])

  useEffect(() => {
    schedulerData = new SchedulerData(
      moment.now(),
      ViewType.Week,
      false,
      false,
      {
        views: [
          {
            viewName: "Day",
            viewType: ViewType.Day,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Week",
            viewType: ViewType.Week,
            showAgenda: false,
            isEventPerspective: false,
          },
        ],
      }
    );
    schedulerData.localeDayjs.locale("en");
    schedulerData.setResources(resources);
    schedulerData.setEvents(events);
    schedulerData.config.minuteStep = 30;
    dispatch({ type: "INITIALIZE", payload: schedulerData });
  }, [resources,events]);

  const prevClick = (schedulerData) => {
    schedulerData.prev();
    schedulerData.setEvents(events);
    dispatch({ type: "UPDATE_SCHEDULER", payload: schedulerData });
  };

  const nextClick = (schedulerData) => {
    schedulerData.next();
    schedulerData.setEvents(events);
    dispatch({ type: "UPDATE_SCHEDULER", payload: schedulerData });
  };

  const onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    schedulerData.setEvents(events);
    dispatch({ type: "UPDATE_SCHEDULER", payload: schedulerData });
  };

  const onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(events);
    dispatch({ type: "UPDATE_SCHEDULER", payload: schedulerData });
  };

  const eventClicked = (schedulerData, event) => {
    alert(
      `You just clicked an event: {id: ${event.id}, title: ${event.title}}`
    );
  };

  const ops1 = (schedulerData, event) => {
    alert(
      `You just executed ops1 to event: {id: ${event.id}, title: ${event.title}}`
    );
  };

  const ops2 = (schedulerData, event) => {
    alert(
      `You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}`
    );
  };

  const newEvent = (
    schedulerData,
    slotId,
    slotName,
    start,
    end,
    type,
    item
  ) => {
    if (
      alert(
        `Do you want to create a new event? {slotId: ${slotId}, slotName: ${slotName}, start: ${start}, end: ${end}, type: ${type}, item: ${item}}`
      )
    ) {
      let newFreshId = 0;
      schedulerData.events.forEach((item) => {
        if (item.id >= newFreshId) newFreshId = item.id + 1;
      });

      let newEvent = {
        id: newFreshId,
        title: "New event you just created",
        start: start,
        end: end,
        resourceId: slotId,
        bgColor: "purple",
      };
      schedulerData.addEvent(newEvent);
      dispatch({ type: "UPDATE_SCHEDULER", payload: schedulerData });
    }
  };

  const updateEventStart = (schedulerData, event, newStart) => {
    if (
      alert(
        `Do you want to adjust the start of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newStart: ${newStart}}`
      )
    ) {
      schedulerData.updateEventStart(event, newStart);
    }
    dispatch({ type: "UPDATE_SCHEDULER", payload: schedulerData });
  };

  const updateEventEnd = (schedulerData, event, newEnd) => {
    if (
      alert(
        `Do you want to adjust the end of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newEnd: ${newEnd}}`
      )
    ) {
      schedulerData.updateEventEnd(event, newEnd);
    }
    dispatch({ type: "UPDATE_SCHEDULER", payload: schedulerData });
  };

  const moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    if (
      alert(
        `Do you want to move the event? {eventId: ${event.id}, eventTitle: ${event.title}, newSlotId: ${slotId}, newSlotName: ${slotName}, newStart: ${start}, newEnd: ${end}`
      )
    ) {
      schedulerData.moveEvent(event, slotId, slotName, start, end);
      dispatch({ type: "UPDATE_SCHEDULER", payload: schedulerData });
    }
  };

  const onSetAddMoreState = (newState) => {
    if (newState === undefined) {
      setPopoverState({
        headerItem: undefined,
        left: 0,
        top: 0,
        height: 0,
      });
    } else {
      setPopoverState({
        ...newState,
      });
    }
  };

  const toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    dispatch({ type: "UPDATE_SCHEDULER", payload: schedulerData });
  };

  let popover = <div />;
  if (popoverState.headerItem !== undefined) {
    popover = (
      <AddMorePopover
        headerItem={popoverState.headerItem}
        eventItemClick={eventClicked}
        viewEventClick={ops1}
        viewEventText="Ops 1"
        viewEvent2Click={ops2}
        viewEvent2Text="Ops 2"
        schedulerData={state.viewModel}
        // closeAction={onSetAddMoreState}
        left={popoverState.left}
        top={popoverState.top}
        height={popoverState.height}
        moveEvent={moveEvent}
      />
    );
  }

  return (
    <>
      {state.showScheduler && (
        <div className="w-full" style={{width:'100%'}}>
          <Scheduler
            schedulerData={state.viewModel}
            prevClick={prevClick}
            nextClick={nextClick}
            onSelectDate={onSelectDate}
            onViewChange={onViewChange}
            // eventItemClick={eventClicked}
            // viewEventClick={ops1}
            // viewEventText="Ops 1"
            // viewEvent2Text="Ops 2"
            // viewEvent2Click={ops2}
            // updateEventStart={updateEventStart}
            // updateEventEnd={updateEventEnd}
            // moveEvent={moveEvent}
            // newEvent={newEvent}
            // onSetAddMoreState={onSetAddMoreState}
            toggleExpandFunc={toggleExpandFunc}
          />
          {popover}
        </div>
      )}
    </>
  );
}

export default wrapperFun(App);
