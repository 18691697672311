import React from 'react'
import BasicDrawer from '../../components/Drawer'
import { Divider, Row, Col } from 'antd'
import BasicText from '../../components/Text/BasicText'
import { Colors } from '../../config'
import { useNavigate } from 'react-router-dom'
import { CiEdit } from 'react-icons/ci'
import { Button } from '../../components'
import RoleCheck from '../../components/RoleCheck/RoleCheck'
import Circular from '../../components/Loader/Circular'

const CompanyDetailDrawer = ({ openDrawer, onCloseDrawer, companyDetailData, width, CompanyeditHandler, isLoading }) => {
    const navigate = useNavigate()

    return (

        <BasicDrawer
            title={'Company Details'}
            openDrawer={openDrawer}
            onClose={onCloseDrawer}
            width={width}
            children={

                isLoading ?

                    <Circular
                        spinning={isLoading}
                        size={40}
                        color={Colors.Yellow}
                        containerStyle={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    />

                    :
                    <>
                        <Divider />

                        <Row className='w-full'>
                            <Col span={12}>
                                <span className='text-sm font-semibold' style={{ color: Colors.LabelColor }}>Company Name</span>
                                <BasicText className='text-sm font-medium' text={companyDetailData?.name ? companyDetailData?.name : '-'} />
                            </Col>

                            <Col span={12}>
                                <span className='text-sm font-semibold' style={{ color: Colors.LabelColor }}>URL</span>
                                <BasicText className='text-sm font-medium' text={companyDetailData?.url ? companyDetailData?.url : '-'} />
                            </Col>
                        </Row>

                        <Divider />

                        <Row>
                            <Col span={12}>
                                <span className='text-sm font-semibold' style={{ color: Colors.LabelColor }}>Phone Number</span>
                                <BasicText className='text-sm font-medium' text={companyDetailData?.phoneNumber ? companyDetailData?.phoneNumber : '-'} />
                            </Col>

                            <Col span={12}>
                                <span className='text-sm font-semibold' style={{ color: Colors.LabelColor }}>Fax</span>
                                <BasicText className='text-sm font-medium' text={companyDetailData?.fax ? companyDetailData?.fax : '-'} />
                            </Col>
                        </Row>

                        <Divider />

                        <Row>
                            <Col span={12}>
                                <span className='text-sm font-semibold' style={{ color: Colors.LabelColor }}>Address</span>
                                <BasicText className='text-sm font-medium break-all pr-6' text={companyDetailData?.address ? `${companyDetailData?.address[0]?.streetNumber}, ${companyDetailData?.address[0]?.streetName}, ${companyDetailData?.address[0]?.city} ${companyDetailData?.address[0]?.zip}` : '-'} />
                            </Col>

                            <Col span={12}>
                                <span className='text-sm font-semibold' style={{ color: Colors.LabelColor }}>Address Type</span>
                                <BasicText className='text-sm font-medium' text={companyDetailData?.address ? companyDetailData?.address[0]?.addressType : '-'} />
                            </Col>
                        </Row>

                        <Divider />

                        <Row className='w-full'>
                            <span className='text-md font-semibold' style={{ color: Colors.Black }}>Properties</span>
                            <Col span={24} className='h-24 overflow-auto'>
                                {
                                    companyDetailData?.properties?.length > 0 ?

                                        companyDetailData?.properties?.map((property) => {

                                            return <BasicText
                                                className='text-md font-medium w-full pr-6 hover:underline cursor-pointer py-1'
                                                onClick={() => navigate(`/property-details/${property?.id}`)}
                                                style={{ color: Colors.Yellow }}
                                                text={
                                                    property?.name
                                                } />

                                        })
                                        :
                                        '-'
                                }
                            </Col>
                        </Row>

                        <Divider />

                        <Row className='w-full'>
                            <span className='text-md font-semibold' style={{ color: Colors.Black }}>Incident Reported</span>
                            <Col span={24} className='h-24 overflow-auto'>
                                {
                                    companyDetailData?.incidents?.length > 0 ?

                                        companyDetailData?.properties?.map((incident) => {

                                            return <BasicText
                                                className='text-md font-medium w-full pr-6 hover:underline cursor-pointer py-1'
                                                onClick={''}
                                                style={{ color: Colors.Yellow }}
                                                text={
                                                    incident?.name
                                                } />
                                        })
                                        :
                                        '-'
                                }
                            </Col>
                        </Row>

                        <RoleCheck
                            children={
                                <Row className='w-full' justify={'start'} align={'middle'}>
                                    <Col>
                                        <Button.Basic onClick={() => CompanyeditHandler()} style={{ width: "100%", height: "40px", color: Colors.White }} icon={<CiEdit size={18} />} className={'h-11 flex items-center justify-center outline-none border-0 rounded-none yellow-bg'} text={'Edit Company'} />
                                    </Col>

                                </Row>
                            }
                            task={'Edit'}
                        />
                    </>
            }



        />
    )
}

export default CompanyDetailDrawer