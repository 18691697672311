import ApiCaller from "../api"

export const getEligibiity = async () => {
    try {
        const res = await ApiCaller.Get('/all-eligibilities');
        console.log(res)
        const resData = res?.data?.data;
        let elegibilityOptions = [];

        const elegibility = resData?.map((item) => {
            elegibilityOptions?.push({
                label: item.name,
                value: item.id
            })
        })
        return elegibilityOptions

    } catch (e) {
        return []
    }
}

export const getSchedules = async () => {
    try {
        const res = await ApiCaller.Get('/all-schedules');
        const resData = res?.data?.data;
        let scheduleOption = [];

        const schedule = resData?.map((item) => {
            scheduleOption?.push({
                label: item.name,
                value: item.id
            })
        })
        return scheduleOption
    } catch (e) {
        console.log(e)
        return []
    }
}

export const getUserRoles = async () => {
    try {
        const res = await ApiCaller.Get('/roles');
        const resData = res?.data?.data;
        let rolesOption = [];
        const role = resData?.map((item) => {
            rolesOption?.push({
                label: item.name,
                value: item.id
            })
        })
        return rolesOption
    }
    catch (e) {
        console.log(e)
        return []
    }
}


export const getCompanies = async () => {
    try {
        const res = await ApiCaller.Get('/companies');
        const resData = res?.data?.data;
        let companiesOption = [];
        const role = resData?.map((item) => {
            companiesOption?.push({
                label: item.name,
                value: item.id
            })
        })
        return companiesOption
    }
    catch (e) {
        console.log(e)
        return []
    }
}


export const getAddresses = async () => {
    try {
        const id = localStorage.getItem('PropertyKey');
        const res = await ApiCaller.Get(`/property/${id}/addresses`);
        console.log(res)
        const resData = res?.data?.data?.addresses;
        console.log(resData?.addresses)
        let addressesOption = [];
        const role = resData?.map((item) => {
            addressesOption?.push({
                label: `${item.addressType} - ${item.streetType} - ${item.buildingNumber}`,
                value: item.id
            })
        })
        return addressesOption
    }
    catch (e) {
        console.log(e)
        return []
    }
}

// only for location forms as i have to show 'All' key there and i cannot do it in the component
// as whenever the component render it will add 'All' key, so we might have duplicate 'All' key so 
// created a seperate function for location form.
export const getAddressesLoc = async () => {
    try {
        const id = localStorage.getItem('PropertyKey');
        const res = await ApiCaller.Get(`/property/${id}/addresses`);
        console.log(res)
        const resData = res?.data?.data?.addresses;
        console.log(resData?.addresses)
        let addressesOption = [];
        const role = resData?.map((item) => {
            addressesOption?.push({
                label: `${item.addressType} - ${item.streetType} - ${item.buildingNumber}`,
                value: item.id
            })
        })
        console.log(addressesOption)
        addressesOption.length > 0 && addressesOption.push({
            label: 'All',
            value: 'All'
        })
        return addressesOption
    }
    catch (e) {
        console.log(e)
        return []
    }
}

export const getIncidents = async () => {
    try {
        const id = localStorage.getItem('PropertyKey');
        const res = await ApiCaller.Get(`/property/${id}/incident-type`);
        const resData = res?.data?.data;
        let incidentOptions = [];
        const role = resData?.map((item) => {
            incidentOptions?.push({
                label: item.name,
                value: item.id
            })
        })
        return incidentOptions
    }
    catch (e) {
        console.log(e)
        return []
    }
}

export const getAllIncidents = async () => {
    try {
        let res = await ApiCaller.Get(`/all-incident-type`);
        if (res.status == 200) {
            const resData = res?.data?.data;
            let incidentOptions = [];
            const role = resData?.map((item) => {
                incidentOptions?.push({
                    label: item.name,
                    value: item.id
                })
            })
            return incidentOptions
        }
    }
    catch (err) {
        console.log(err)
        return []
    }
}

export const getAllUsers = async (roles) => {
    try {
        let res = await ApiCaller.Get(`/users/all?${roles?.admin ? 'roles[]=admin&' : ''}${roles?.guard ? 'roles[]=guard&' : ''}${roles?.supervisor ? 'roles[]=supervisor&': ''}${roles?.manager ? 'roles[]=manager' : ''}`);
        if (res.status == 200) {
            const resData = res?.data?.data;
            let userOptions = [];
            const role = resData?.map((item) => {
                userOptions?.push({
                    label: item.userName,
                    value: item.id
                })
            })
            return userOptions
        }
    }
    catch (err) {
        console.log(err)
        return []
    }
}


export const getAllProperties = async () => {
    try {
        let res = await ApiCaller.Get(`/properties/all`);
        if (res.status == 200) {
            const resData = res?.data?.data;
            let propertiesOptions = [];
            const role = resData?.map((item) => {
                propertiesOptions?.push({
                    label: item.name,
                    value: item.id
                })
            })
            return propertiesOptions
        }
    }
    catch (err) {
        console.log(err)
        return []
    }
}

