import { put } from "redux-saga/effects";
import { ShiftSchedulerAction } from "../actions";
import { ApiCaller, Utils } from "../../config";

export default class ShiftSchedulerMiddleware {
    static *GetPotentialTakers(params) {
        const { payload, cb } = params;
        try {
            let res = yield ApiCaller.Get('/potential-takers', payload);
            if (res.status == 200) {
                yield put(ShiftSchedulerAction.GetPotentialTakersSuccess(res?.data?.data));
                cb && cb()
            }
        } catch (error) {
            yield put(ShiftSchedulerAction.GetPotentialTakersFailure());
            Utils.showMessage('error', error?.response?.data?.message)
        }
    }

    static *GetShifts(params) {
        const { payload, cb } = params;
        try{
            let res = yield ApiCaller.Get(`/shifts?perPage=${payload?.limit}&page=${payload?.page}&user_id=${payload?.filterDrawer?.user ? payload?.filterDrawer?.user : ''}&eligibility_id=${payload?.filterDrawer?.eligiblity ? payload?.filterDrawer?.eligiblity : ''}&schedule_id=${payload?.filterDrawer?.schedule ? payload?.filterDrawer?.schedule : ''}`);
            if(res.status == 200){
                yield put(ShiftSchedulerAction.GetShiftsSuccess(res?.data));
            }
        }
        catch(err){
            yield put(ShiftSchedulerAction.GetShiftsFailure());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }
}
