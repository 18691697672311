import {
    GET_USERS, GET_USERS_SUCCESS, GET_USERS_FAILURE,
    GET_USER_DETAIL,GET_USER_DETAIL_SUCCESS,GET_USER_DETAIL_FAILURE,
    ADD_USER, ADD_USER_SUCCESS, ADD_USER_FAILURE,
    UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE,
    UPDATE_USER_STATUS,UPDATE_USER_STATUS_SUCCESS,UPDATE_USER_STATUS_FAILURE, USER_DETAIL_RESET
} from '../constants'

export default class UserManagementAction {
    static GetUsers(payload) {
        console.log(payload)
        return {
            type: GET_USERS,
            payload
        }
    }

    static GetUsersSuccess(payload) {
        return {
            type: GET_USERS_SUCCESS,
            payload
        }
    }

    static GetUsersFailure() {
        return {
            type: GET_USERS_FAILURE
        }
    }

    static GetUserDetail(payload) {
        
        return {
            type: GET_USER_DETAIL,
            payload
        }
    }

    static GetUserDetailSuccess(payload) {
        console.log(payload)
        return{
            type: GET_USER_DETAIL_SUCCESS,
            payload
        }
    }

    static GetUserDetailFailure() {
        return{
            type: GET_USER_DETAIL_FAILURE
        }
    }

    static AddUsers(payload,cb) {
        console.log(payload)
        return {
            type: ADD_USER,
            payload,
            cb
        }
    }

    static AddUserSuccess() {
        return {
            type: ADD_USER_SUCCESS
        }
    }

    static AddUserFailure() {
        return {
            type: ADD_USER_FAILURE
        }
    }

    static UpdateUser(payload,id,cb) {
        return {
            type: UPDATE_USER,
            payload,
            id,
            cb
        }
    }

    static UpdateUserSuccess() {
        return {
            type: UPDATE_USER_SUCCESS
        }
    }

    static UpdateUserFailure() {
        return {
            type: UPDATE_USER_FAILURE
        }
    }

    static UpdateUserStatus(payload,cb) {
        return { 
            type: UPDATE_USER_STATUS,
            payload,
            cb
        }
    }

    static UpdateUserStatusSuccess(payload) {
        return { 
            type: UPDATE_USER_STATUS_SUCCESS,
            payload
        }
    }

    static UpdateUserStatusFailure() {
        return { 
            type: UPDATE_USER_STATUS_FAILURE,
        }
    }

    static UserDetailReset() {
        return {
            type: USER_DETAIL_RESET
        }
    }


}