import { Col, Row } from "antd";
import React, { useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Button, Text, TextField } from "../../components";
import { Colors, Utils } from "../../config";
import login from '../../assets/images/login.png'
import siteLogo from '../../assets/images/siteLogo.png'
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserEmail } from "../../config/Schema";
import { AppAction } from "../../store/actions";
import ButtonLoader from "../../components/Button/ButtonLoader";

const ForgetPasswordEmail = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loader = useSelector(state => state?.AppReducer.loader)

    let schema = UserEmail;
    const { control, reset, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    })

    const submitHandler = (data) => {
        console.log(data)
        dispatch(AppAction.EmailVerification(data, () => {
            navigate('/verify-password')
        }))

    };

    return (

        <Row>
            <Col lg={12} md={12} sm={24} xs={24} className="h-screen" >

                <section className="h-full flex">
                    <div style={{}} className="flex flex-col items-center justify-center px-6 mx-auto md:h-screen lg:py-0 w-full">
                        <a href="#" className="mt-3" >
                            <img className="xl:w-80 md:w-80 xl:h-32 md:h-32 h-24 w-72 mr-2" src={siteLogo} alt="logo" />
                        </a>
                        <div className="md:mt-0 sm:max-w-md xl:p-0 w-full">
                            <div className="pt-3 mx-auto flex items-center flex-col justify-center w-10/12" style={{}}>
                                <h1 className="mb-3 text-center text-2xl font-bold">
                                    Enter Your Email
                                </h1>
                                <p className="text-center text-sm mb-5" style={{ color: Colors.Gray }}>
                                    Enter your register email to get verification code
                                </p>
                                <form className="space-y-4 md:space-y-6 w-full" style={{}}>
                                    <div>
                                        <Controller
                                            name='email'
                                            control={control}
                                            render={({ field, fieldState }) => {
                                                return (
                                                    <>
                                                        <input
                                                            type={"email"}
                                                            placeholder={"Email"}
                                                            {...field}
                                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-3"
                                                        />
                                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                                    </>
                                                )
                                            }}
                                        />
                                    </div>
                                    <ButtonLoader loader={loader} btnHandler={handleSubmit(submitHandler)} text={'SUBMIT'} style={{ background: "#2f3032" }} className="cursor-pointer w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 h-12 text-base tracking-wider" />
                                </form>

                                <div className="flex items-center justify-center mt-4">
                                    <a href="#" className="text-sm font-medium text-primary-600 hover:underline" style={{ color: Colors.Yellow }} onClick={() => navigate('/login')}>Back to Login</a>
                                </div>

                                <p className="text-xs text-center mt-16">
                                    Copyright 2024 <span style={{ color: "#f0bf1f" }}>Sentinel</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

            </Col>

            <Col lg={12} md={12} sm={0} xs={0} className="h-screen rounded-none md:rounded-3xl">
                <div className=" w-full h-full rounded-none md:rounded-l-3xl">
                    <img src={login} className="h-full max-w-full aspect-[4/3] object-top object-cover rounded-none md:rounded-l-3xl" />
                </div>
            </Col>


        </Row>
        // <div
        //   style={{
        //     display: "flex",
        //     // height: "100vh",
        //     justifyContent: "center"
        //   }}
        // >
        //   <Row gutter={[0, 25]} justify="center" style={styles.container}>
        //     <Col span={24}>
        //       <Text.Basic
        //         text="Welcome"
        //         fontWeight={"bold"}
        //         fontSize={20}
        //         color={Colors.White}
        //       />
        //     </Col>
        //     <Col span={24}>
        //       <Text.Basic
        //         text="Please Login to Continue"
        //         fontSize={18}
        //         color={Colors.White}
        //       />
        //     </Col>
        //     <Col span={24}>
        //       <Row justify={"center"}>
        //         <Col span={14} style={styles.flex}>
        //           <TextField.Basic
        //             type={"email"}
        //             value={state.email}
        //             placeholder={"Email"}
        //             onChange={(e) => validateEmail(e.target.value)}
        //             label="Email"
        //             errorText={!state.validEmail && state.emailErrMsg}
        //           />
        //         </Col>
        //       </Row>
        //     </Col>
        //     <Col span={24}>
        //       <Row justify={"center"}>
        //         <Col span={14} style={styles.flex}>
        //           <TextField.Basic
        //             type={"password"}
        //             value={state.password}
        //             placeholder={"Password"}
        //             onChange={(e) => validatePass(e.target.value)}
        //             label="Password"
        //             password
        //             errorText={!state.validPass && state.passErrMsg}
        //           />
        //         </Col>
        //       </Row>
        //     </Col>
        //     <Col span={24}>
        //       <Row justify={"center"}>
        //         <Col>
        //           <Button.Basic variant="outlined" text="Login" onClick={signin} />
        //         </Col>
        //       </Row>
        //     </Col>
        //   </Row>
        // </div>
    );
};

export default ForgetPasswordEmail;
