import React, { useState, useEffect, useCallback } from 'react'
import TableWrapper from '../../components/Grid'

import { Avatar, Col, Row, Form, Select, Tabs, Divider, Tooltip } from 'antd'
import { Link } from 'react-router-dom';
import { LoadingOutlined, UserOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { TextField } from '../../components';
import BasicText from '../../components/Text/BasicText';
import DropDown from '../../components/DropDown';
import FilterBar from '../../components/FilterBar';
import { AddUserSchema } from '../../config/Schema';
import moment from 'moment';
// import LoadingOutlined from 'react-icons/ai'
// import AiOutlineSearch from 'react-icons/ai'
// import { Avatar } from '../../components';
import { AiOutlineLoading } from "react-icons/ai";
import { LuUser } from 'react-icons/lu';
import { FaCamera } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { FaBan } from "react-icons/fa";
import { IoIosPower } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import camera from '../../assets/images/camera.png'

// import { TextField } from '../../components';
import BasicDrawaer from '../../components/Drawer';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { AdduserForm } from '../../config/Schema';
import LabelWithField from '../../components/LabelWithField';
import LabelWithSelect from '../../components/LabelWithSelect';
import LabelWithDate from '../../components/LabelWithDate';
import Button from '../../components/Button';
import Text from '../../components/Text';
import { Colors } from '../../config';
import { getEligibiity, getSchedules, getUserRoles } from '../../config/getApis';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { UserManagementAction } from '../../store/actions';
import { useNavigate } from "react-router";
import { Spin } from 'antd';
import Circular from '../../components/Loader/Circular';
import ButtonLoader from '../../components/Button/ButtonLoader';
import dayjs from 'dayjs';
import ActionDropdown from '../../components/DropDown/actiondropdown';
import UserDetailDrawer from './UserDetailDrawer';
import UserAddnEditDrawer from './UserAddnEditDrawer';


const { TabPane } = Tabs

const UserMangement = () => {

    const dispatch = useDispatch();

    const userData = useSelector(state => state?.UserManagementReducer?.UserData);
    const loader = useSelector(state => state?.UserManagementReducer?.loader);
    const userDetailData = useSelector(state => state?.UserManagementReducer?.UserDetailData);
    const userAddLoader = useSelector(state => state?.UserManagementReducer?.AddUserLoader);
    const userDetailLoader = useSelector(state => state?.UserManagementReducer?.DetailUserLoader);
    const user = useSelector((state) => state.AppReducer?.user)
    const isRole = user?.data?.userRole[0]


    const tableData = userData?.data?.data;
    const paginate = userData?.data?.paginate;

    const [state, setState] = useState({
        current: 1,
        limit: 10,
        searchVal: "",
        tabName: 'active',
        roleType: ""
    })


    useEffect(() => {
        dispatch(UserManagementAction.GetUsers(state))
    }, [state.current])


    const { data: eligibilityData } = useQuery('eligibility', getEligibiity);
    const { data: scheduleData } = useQuery('schedules', getSchedules);
    const { data: rolesData } = useQuery('roles', getUserRoles);
    console.log(eligibilityData)

    if (rolesData && isRole === 'manager') {
        for (let i = 0; i < rolesData.length; i++) {
            if (rolesData[i]?.label === 'admin') {
                rolesData.splice(i, 1);
                i--;
            }
        }

    }

    // img work
    const [img, setimg] = useState(null);
    const [readFile, setReadFile] = useState(null);


    // Drawers maintaining states.
    const [drawerStates, setDrawerStates] = useState({
        openDrawer: false,
        userDetailDrawer: false,
        drawerkey: null,
        userid: null
    })

    // Image Validation handling
    const [imgValue, setImageValue] = useState({
        imgSizeValidation: '',
        imgTypeValidation: '',
        isRequired: '',
    });


    // Tab Change handling
    const tabChange = (key) => {
        if (key == 1) {
            dispatch(UserManagementAction.GetUsers({ ...state, current: 1, limit: 10, searchVal: "", tabName: 'active' }))
            setState({ ...state, current: 1, limit: 10, searchVal: '', tabName: 'active' });
        }
        else {
            dispatch(UserManagementAction.GetUsers({ ...state, current: 1, limit: 10, searchVal: "", tabName: 'inActive' }))
            setState({ ...state, current: 1, limit: 10, searchVal: '', tabName: 'inActive' });
        }
    }

    // Add user Drawer
    const onCloseDrawer = () => {
        if (drawerStates?.drawerkey == 1 && drawerStates.userDetailDrawer) {
            setDrawerStates({ ...drawerStates, openDrawer: false, userDetailDrawer: false, drawerkey: null, userid: null })
            setImageValue({ imgSizeValidation: '', imgTypeValidation: '', isRequired: '' })
            reset()
        }

        else {
            setDrawerStates({ ...drawerStates, openDrawer: false, userDetailDrawer: false, userid: null })
            setImageValue({ imgSizeValidation: '', imgTypeValidation: '', isRequired: '' })
            dispatch(UserManagementAction.UserDetailReset())
            reset()
        }
    }

    // this will run only after closing of Add or Edit drawer.
    // the purpose of using this only in add or edit drawer case is becasue when user click on details
    // the drawer key is set to 1 which discriminate Add user or Edit user but the Edit drawer will only
    // open when the openDrawer state is true and this will happen only when user clicked on edit button
    // so it will maintain the drawer key which is 1 and open edit modal but on click of close btn
    // the state is getting set to drawer key = null again which is default so by doing so it rapidly changes
    // the drawer text from edit drawer to Add drawer as the condition goes if drawerKey == null ? Ádd': 'Edit'.
    // so that is why this function is used and handled different cases as well.
    const drawerKeyHandler = (isOpen,reset) => {
        if (!isOpen) {
            setDrawerStates({ ...state, drawerkey: null })
            setReadFile(null)
            reset()
        }
    }

    // this will run only after closing of detail drawer.
    const detailDrawerReset = (isOpen) => {
        if (!isOpen && !drawerStates.openDrawer) {
            dispatch(UserManagementAction.UserDetailReset())
        }
    }

    // Form related Working
    let schema = AddUserSchema;
    const { handleSubmit, control, reset, setValue, watch, setError, clearErrors, formState: { errors } } = useForm(
        {
            resolver: yupResolver(schema),
            // mode: 'onChange'
        }
    )

    // form data
    const FormDataHandler = (data) => {
        const file = watch(data?.userimg)


        if (Object.keys(errors).length > 0) {
            setError('phoneNo', {
                type: 'format',
                message: errors?.phoneNo?.message,
            })
            return;
        }

        else if (imgValue?.imgSizeValidation || imgValue.imgTypeValidation) {
            return
        }

        //setting the key null is important as when the buttons inside the drawers clicked the key is already 
        //select to 1 from the dropdown selection and for the future actions it will remain 1 if we didn't -
        //handle it.
        else {

            if (drawerStates.drawerkey == 1) {
                // for dispatching form action about UPDATE USER CASE.
                let certificationInfo = {};
                for (const key in data) {
                    if (data[key] instanceof Date) {
                        certificationInfo[key] = moment(data[key]).format('DD-MM-YYYY');
                        delete data[key]
                    }
                }

                data['certificationInfo'] = certificationInfo;
                const id = Number(drawerStates?.userid);

                certificationInfo &&
                    dispatch(UserManagementAction.UpdateUser(data, id, () => {
                        dispatch(UserManagementAction.GetUsers(state));
                        dispatch(UserManagementAction.UserDetailReset());
                        setDrawerStates({ ...drawerStates, openDrawer: false, userDetailDrawer: false, drawerkey: null })
                        reset()
                        setReadFile(null)
                    }))
                return
            }

            else {
                // for dispatching form action about ADD USER CASE.
                // setting an object, as api expects date in a different objects so modifying for it.

                let certificationInfo = {};
                for (const key in data) {
                    if (data[key] instanceof Date) {
                        certificationInfo[key] = moment(data[key]).format('DD-MM-YYYY');
                        delete data[key]
                    }
                }

                data['certificationInfo'] = certificationInfo;


                // if certificationInfo contain some data i.e dates so we'll dispatch an actions only.
                certificationInfo &&
                    dispatch(UserManagementAction.AddUsers(data, () => {
                        dispatch(UserManagementAction.GetUsers(state));
                        setDrawerStates({ ...drawerStates, openDrawer: false, userDetailDrawer: false, drawerkey: null })
                        reset()
                        setReadFile(null)
                    }))

            }

            return
        }
    }


    // user Select values filter handler
    const SelectFilterHandler = (value) => {
        const { tabName } = state;
        dispatch(UserManagementAction.GetUsers({ ...state, current: 1, limit: 10, searchVal: "", tabName: tabName, roleType: value }));
        setState({ ...state, current: 1, limit: 10, searchVal: "", tabName: tabName, roleType: value })
    };

    // user Select Values
    const SelectFilterValues = [
        {
            value: 'admin',
            label: 'Admin',
        },
        {
            value: 'supervisor',
            label: 'Supervisor',
        },
        {
            value: 'guard',
            label: 'Guard',
        },
        {
            value: 'manager',
            label: 'Manager',
        },
        {
            value: '',
            label: 'View All'
        }
    ]

    const debouceHandler = (query, delay) => {
        let timeout;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            dispatch(UserManagementAction.GetUsers({ ...state, searchVal: query, current: 1 }))
        }, delay);
    }

    // user search bar filter handler
    const handleInputChange = (e) => {
        const query = e?.target.value;
        setState({ ...state, current: 1, searchVal: e.target.value })
        debouceHandler(query, 3000)
    }

    // items for Action.
    // items for Action.
    const items = [
        {
            label: 'User Detail',
            key: 1,
            icon: <FaEye color='#6A9BFF' />,
            backgroundColor: '#eef4ff',
            color: '#4582ff',
        },
        {
            label: state?.tabName == 'active' ? 'Deactivate' : 'Activate',
            key: 2,
            icon: state?.tabName == 'active' ? <FaBan color='red' /> : <IoIosPower size={15} />,
            backgroundColor: state?.tabName == 'active' ? '#fef3f5' : '#D5EBD9',
            color: state?.tabName == 'active' ? '#e93148' : "#7FC18D",
        }

    ]
    // for Action dropdown 
    const ActionHandler = (e, record) => {

        const { current, limit, searchVal } = state;

        if (e.key == '1') {
            let payload = {
                id: Number(record?.id),
            }
            setDrawerStates({ ...drawerStates, openDrawer: false, userDetailDrawer: true, drawerkey: 1, userid: record?.id });
            dispatch(UserManagementAction.GetUserDetail(payload, () => {
                setState({ ...state, current: current, limit: limit, searchVal: searchVal })
            }))
        }

        else {
            // for inactivating or activating user.
            if (state?.tabName == 'active') {
                let payload = {
                    id: Number(record?.id),
                    status: "inActive"
                }
                dispatch(UserManagementAction.UpdateUserStatus(payload, () => {
                    dispatch(UserManagementAction.GetUsers(state))
                    setState({ ...state, current: current, limit: limit, searchVal: searchVal })
                }))
            }
            else {
                let payload = {
                    id: Number(record?.id),
                    status: "active"
                }
                dispatch(UserManagementAction.UpdateUserStatus(payload, () => {
                    dispatch(UserManagementAction.GetUsers(state))
                    setState({ ...state, current: current, limit: limit, searchVal: searchVal })
                }))
            }
        }

    };


    // edit user button-handler in a drawer
    const UserEditHandler = () => {
        console.log(drawerStates)
        setDrawerStates({ ...drawerStates, userDetailDrawer: false, openDrawer: true })
        if (userDetailData?.profileImg[0]?.url) {
            setReadFile(userDetailData?.profileImg[0]?.url)
        }
        // reset(userDetailData)
    }

    // Add New User handler
    const AddUserHandler = () => {
        if (readFile) {
            setReadFile(null)
            setDrawerStates({ ...drawerStates, userDetailDrawer: false, openDrawer: true })
        }
        else {
            setDrawerStates({ ...drawerStates, userDetailDrawer: false, openDrawer: true })
        }
    }


    // ACTIVE USERS COLUMNS
    const columns = [
        {
            title: "Name",
            dataIndex: "userName",
            align: "left",
            render: (text, record) => (

                <Row justify={"center"} style={{ display: 'flex', alignItems: "center" }}>
                    <Col span={24} className='flex justify-start items-center' >
                        {
                            record?.profileImg ?
                                <Avatar className='mr-5' size={40} src={record?.profileImg[0]?.url} />
                                :
                                <Avatar className='mr-5' size={40} icon={<UserOutlined />} />
                        }
                        <span>{record?.firstName + ' ' + record?.lastName}</span>
                    </Col>

                </Row >

            )
        },
        {
            title: 'Phone#',
            dataIndex: 'phoneNo',
            key: 'phone',
            render: (text, record) => {
                return (
                    <Col span={24} className='flex justify-start align-center'>
                        <span>{record?.phoneNo}</span>
                    </Col>
                )
            }
        },
        {
            title: 'Role',
            dataIndex: 'userRole',
            key: 'role',
            render: (text, record) => {
                return (
                    <Col span={24} className='flex justify-start align-center'>
                        <span>{record?.userRole[0]?.name}</span>
                    </Col>
                )
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text, record) => {
                return (
                    <Col span={24} className='w-full flex justify-start align-center flex-wrap'>
                        <span className='break-all'>{record?.email}</span>
                    </Col>

                )
            }

        },

        {
            title: 'Eligibility',
            dataIndex: 'eligibilities',
            key: 'elegibility',
            render: (text, record) => {
                const eligibilityNames = record?.eligibilities?.map(eligibility => eligibility.name).join(', ');
                return (
                    <Col span={24} className='flex justify-start align-center'>
                        <Tooltip placement="bottom" title={`${eligibilityNames}`}>
                            <span className=''>{record?.eligibilities[0]?.name ? record?.eligibilities[0]?.name : '-'}</span>
                        </Tooltip>
                    </Col>
                )
            }
        },

        {
            title: 'Schedules',
            dataIndex: 'schedules',
            key: 'schedules',
            render: (text, record) => {
                const scheduleNames = record?.schedules?.map(schedule => schedule.name).join(', ');
                return (
                    <Col span={24} className='flex justify-start align-center'>
                        <Tooltip placement="bottom" title={`${scheduleNames}`}>
                            <span className=''>{record?.schedules[0]?.name ? record?.schedules[0]?.name : '-'}</span>
                        </Tooltip>
                    </Col>
                )
            }

        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => {
                return (
                    <Col span={24} className='flex justify-center align-center' style={{}}>
                        <ActionDropdown
                            items={items}
                            placement={'bottom'}
                            labelStyle={{ color: Colors.Yellow, fontSize: '1.2rem', marginBottom: '7px' }}
                            onClick={(e) => ActionHandler(e, record)}
                            isRole={isRole}
                        />
                    </Col>
                )
            }
        },
    ];


    const handlePaginationChange = (page, pageSize) => {
        console.log(page, pageSize)
        dispatch(UserManagementAction.GetUsers({ ...state, current: page, limit: pageSize }))
        setState({ ...state, current: page, limit: pageSize })
        return
    }

    const phoneNumberFormat = (number) => {
        const cleaned = ('', number).replace(/\D/g, '');
        let phoneNumber;

        if (cleaned.length === 10) {
            return phoneNumber = '(' + cleaned.substr(0, 3) + ') ' + cleaned.substr(3, 3) + '-' + cleaned.substr(6, 4);
        }

        else {
            return
        }

    }


    return (
        <>
            <UserAddnEditDrawer
                drawerStates={drawerStates}
                drawerKeyHandler={drawerKeyHandler}
                onCloseDrawer={onCloseDrawer}
                userDetailData={userDetailData}
                phoneNumberFormat={phoneNumberFormat}
                FormDataHandler={FormDataHandler}
                userAddLoader={userAddLoader}
                eligibilityData={eligibilityData}
                scheduleData={scheduleData}
                rolesData={rolesData}
                img={img}
                setimg={setimg}
                imgValue={imgValue}
                setImageValue={setImageValue}
                readFile={readFile}
                setReadFile={setReadFile}
            />

            <UserDetailDrawer
                onCloseDrawer={onCloseDrawer}
                detailDrawerReset={detailDrawerReset}
                drawerStates={drawerStates}
                userDetailLoader={userDetailLoader}
                userDetailData={userDetailData}
                UserEditHandler={UserEditHandler}
                isRole={isRole}
            />



            <FilterBar
                SelectFilterHandler={SelectFilterHandler}
                SelectFilterValues={SelectFilterValues}
                AddUserHandler={AddUserHandler}
                handleInputChange={(e) => handleInputChange(e)}
                SearchValue={state?.searchVal}
                title='User Management'
                btnText="Add New User"
                isRole={isRole}
                defaultValue={''}
                classname={'mb-12'}
            />

            <Row gutter={[8, 32]} className='w-fit sm:w-fit lg:w-fit xl:w-full' style={{ background: "white", padding: '16px', borderRadius: "10px" }}>

                <Col lg={24}>
                    <Row className='w-full'>
                        <Col lg={24}>


                            <Tabs
                                defaultActiveKey="1"
                                itemSelectedColor="black"
                                style={{ marginTop: '-4rem', fontWeight: "500" }}
                                onChange={tabChange}
                                items={[
                                    {
                                        label: 'Active Users',
                                        key: '1',
                                        children: <>


                                            <TableWrapper
                                                tableStyle={{ borderRadius: "40px", width: '100%' }}
                                                headerbg={"red"}
                                                tableColumns={columns}
                                                tableData={tableData}
                                                total={paginate?.total}
                                                pageSize={state.limit}
                                                currentPage={state.current}
                                                handlePaginationChange={handlePaginationChange}
                                                loading={loader && <Spin />}
                                                rowClassName={'row-antd'}

                                            />

                                        </>,
                                    },

                                    {
                                        label: 'Inactive Users',
                                        key: '2',
                                        children: <>

                                            <TableWrapper
                                                tableStyle={{ borderRadius: "40px", }}
                                                headerbg={"red"}
                                                tableColumns={columns}
                                                tableData={tableData}
                                                total={paginate?.total}
                                                pageSize={state.limit}
                                                currentPage={state.current}
                                                handlePaginationChange={handlePaginationChange}
                                                loading={loader && <Spin />}
                                                rowClassName={'row-antd'}
                                            />

                                        </>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row >


        </>
    )
}

export default UserMangement



