import React, { useEffect, useState } from 'react'
import BasicDrawer from '../../components/Drawer'
import Circular from '../../components/Loader/Circular'
import { Row, Avatar, Col, Typography } from 'antd'
import { Colors } from '../../config'
import { AddUserSchema } from '../../config/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import LabelWithSelect from '../../components/LabelWithSelect';
import { UserOutlined } from '@ant-design/icons';
import { UseDispatch, useDispatch, useSelector } from 'react-redux'
import LoadOnScroll from '../../components/LoadOnScroll'
import UserDetailDrawer from '../UserManagement/UserDetailDrawer'
import UserAddnEditDrawer from '../UserManagement/UserAddnEditDrawer'
import { useNavigate } from 'react-router-dom'
import { phoneNumberFormat } from '../../config/util/PhoneNumberFormatter'
import moment from 'moment'
import { UserManagementAction } from '../../store/actions'



const { Title, Text } = Typography;

const EligibilityDetailDrawer = ({
    drawerStates,
    setDrawerStates,
    onCloseDrawer,
    EligibilityDetailDrawer,
    eventName,
    eligibilityData,
    scheduleData,
    rolesData
}) => {

    const dispatch = useDispatch()

    // for user scroll items
    const [userScrollItems, setUserScrollItems] = useState([])
    const [userHasMore, setUserHasMore] = useState(true)

    const [img, setimg] = useState(null);
    const [readFile, setReadFile] = useState(null);
    // Image Validation handling
    const [imgValue, setImageValue] = useState({
        imgSizeValidation: '',
        imgTypeValidation: '',
        isRequired: '',
    });

    const userDetailData = useSelector(state => state?.UserManagementReducer?.UserDetailData);
    const userDetailLoader = useSelector(state => state?.UserManagementReducer?.DetailUserLoader);
    const userAddLoader = useSelector(state => state?.UserManagementReducer?.AddUserLoader);

    const user = useSelector((state) => state.AppReducer?.user)
    const isRole = user?.data?.userRole[0]


    if (rolesData && isRole === 'manager') {
        for (let i = 0; i < rolesData.length; i++) {
            if (rolesData[i]?.label === 'admin') {
                rolesData.splice(i, 1);
                i--;
            }
        }
    }

    const userScrollDataHandler = (data) => {
        let newData = data?.data?.data;
        setUserScrollItems(prevItems => [...prevItems, ...newData]);
        return newData
    }


    const userDetailHandler = (record) => {
        if (drawerStates?.drawerkey == 1) {
            setDrawerStates({ ...drawerStates, userDetailDrawer: true, userid: record })
            let payload = {
                id: Number(record),
            }
            dispatch(UserManagementAction.GetUserDetail(payload))
        }
    }

    const userDetailDrawerClose = () => {
        setDrawerStates({ ...drawerStates, userDetailDrawer: false })
    }

    const userEditDrawerClose = () => {
        if (drawerStates?.drawerkey == 1) {
            setDrawerStates({ ...drawerStates, openDrawer: false, userDetailDrawer: false, userid: null })
            setImageValue({ imgSizeValidation: '', imgTypeValidation: '', isRequired: '' })
        }

    }


    const UserEditHandler = () => {
        setDrawerStates({ ...drawerStates, userDetailDrawer: false, openDrawer: true })
        if (userDetailData?.profileImg[0]?.url) {
            setReadFile(userDetailData?.profileImg[0]?.url)
        }
    }

    // form data
    const FormDataHandler = (data) => {
        if (imgValue?.imgSizeValidation || imgValue.imgTypeValidation) {
            return
        }
        //setting the key null is important as when the buttons inside the drawers clicked the key is already 
        //select to 1 from the dropdown selection and for the future actions it will remain 1 if we didn't -
        //handle it.
        else {
            if (drawerStates.drawerkey == 1) {
                // for dispatching form action about UPDATE USER CASE.
                let certificationInfo = {};
                for (const key in data) {
                    if (data[key] instanceof Date) {
                        certificationInfo[key] = moment(data[key]).format('DD-MM-YYYY');
                        delete data[key]
                    }
                }

                data['certificationInfo'] = certificationInfo;
                const id = Number(drawerStates?.userid);

                certificationInfo &&
                    dispatch(UserManagementAction.UpdateUser(data, id, () => {
                        dispatch(UserManagementAction.UserDetailReset());
                        setDrawerStates({ ...drawerStates, openDrawer: false, userDetailDrawer: false, userid: null })
                        setReadFile(null)
                    }))
                return
            }
            return
        }
    }

    const afterDrawerCloseHandler = () => {
        if (!drawerStates?.EligibilityDetailDrawer) {
            setUserScrollItems([])
            setUserHasMore(true)
        }
    }

    return (
        <>

            <BasicDrawer
                openDrawer={drawerStates?.EligibilityDetailDrawer}
                title={'Select Eligibility/Positions'}
                onClose={onCloseDrawer}
                afterOpenChange={afterDrawerCloseHandler}
                width={'400'}
            >

                {
                    EligibilityDetailDrawer ?

                        <Circular
                            spinning={EligibilityDetailDrawer}
                            size={40}
                            color={"#FF9900"}
                            containerStyle={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        />

                        :

                        <Row className='w-full'>

                            <Row gutter={16} className="mb-4 w-full">
                                <Col lg={24} xs={24} className="">
                                    <span
                                        className={"h-10 rounded w-full font-normal flex items-center px-2 cursor-pointer"}
                                        style={{ background: '#F9F9FA', color: '#C0C5CE' }}
                                    >{eventName && eventName}</span>
                                </Col>
                            </Row>


                            <Row className='w-full mt-4 break-normal mb-4'>
                                <Title level={5}>Assigned Users</Title>

                                <LoadOnScroll
                                    endPoint={`/eligibility/${drawerStates?.actionid}/users`}
                                    hasMore={userHasMore}
                                    height={250}
                                    style={{ marginTop: '5px' }}
                                    setHasMore={setUserHasMore}
                                    scrollItems={userScrollItems}
                                    scrollDataHandler={userScrollDataHandler}
                                    children={
                                        userScrollItems?.map((user, index) => (
                                            <div key={index} onClick={() => userDetailHandler(user?.id)} className='flex gap-2 items-center mb-3 cursor-pointer'>
                                                {
                                                    user?.profileImg ?
                                                        <Avatar src={user?.profileImg && user?.profileImg[0]?.url} />
                                                        :
                                                        <Avatar icon={<UserOutlined />} />
                                                }
                                                <span>{user?.firstName + ' ' + user?.lastName}</span>
                                            </div>
                                        ))
                                    }
                                />
                            </Row>


                        </Row>
                }
            </BasicDrawer>

            <UserDetailDrawer
                drawerStates={drawerStates?.EligibilityDetailDrawer && drawerStates}
                onCloseDrawer={userDetailDrawerClose}
                userDetailLoader={userDetailLoader}
                userDetailData={userDetailData}
                UserEditHandler={UserEditHandler}
            />

            <UserAddnEditDrawer
                drawerStates={drawerStates?.EligibilityDetailDrawer && drawerStates}
                onCloseDrawer={userEditDrawerClose}
                userDetailData={userDetailData}
                phoneNumberFormat={phoneNumberFormat}
                FormDataHandler={FormDataHandler}
                userAddLoader={userAddLoader}
                eligibilityData={eligibilityData}
                scheduleData={scheduleData}
                rolesData={rolesData}
                img={img}
                setimg={setimg}
                imgValue={imgValue}
                setImageValue={setImageValue}
                readFile={readFile}
                setReadFile={setReadFile}
            />
        </>
    )
}

export default React.memo(EligibilityDetailDrawer)


