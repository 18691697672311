import React, { useState, useEffect } from 'react'
import TableWrapper from '../../components/Grid'
import { Col, Row, Avatar } from 'antd'
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined, UserOutlined, } from '@ant-design/icons';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { AddUserSchema } from '../../config/Schema';
import FilterBar from '../../components/FilterBar';
import { Colors } from '../../config';
import { FaEye } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import ActionDropdown from '../../components/DropDown/actiondropdown';
import { IoIosPower } from "react-icons/io";
import { SiteManagementAction } from '../../store/actions';
import { Spin } from 'antd';


const SiteManagement = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const user = useSelector((state) => state.AppReducer?.user)
    const isRole = user?.data?.userRole[0]

    const { properties, loader } = useSelector(state => state?.SiteManagementReducer);



    const [state, setState] = useState({
        current: 1,
        limit: 10,
        searchVal: "",
        tabName: 'active',
        sortBy: 'id',
        sortOrder: "asc"
    })

    useEffect(() => {
        dispatch(SiteManagementAction.GetProperty(state))
        // dispatch(SiteManagementAction.UpdatePropertyStatus())

    }, [])

    // user Select values filter handler
    const SelectFilterHandler = (value) => {

        dispatch(SiteManagementAction.GetProperty({ ...state, current: 1, limit: 10, sortOrder: value, sortBy: 'id' }))
        setState({ ...state, current: 1, limit: 10, sortBy: 'id', sortOrder: value })
        return
    };
    console.log("");
    // Add New User handler
    const AddProperty = () => navigate('/add-property')

    const items = [
        {
            label: 'View Details',
            key: 1,
            icon: <FaEye color='#6A9BFF' />,
            backgroundColor: '#eef4ff',
            color: '#4582ff',
        },
        {
            label: 'Delete',
            key: 2,
            icon: <FaTrash color='#E71D36' />,
            backgroundColor: '#fef3f5',
            color: '#e93148'
        }

    ]

    const ActionHandler = (e, record) => {
        console.log(record)
        console.log("key", e)
        const { current, limit, searchVal } = state;
        if (e.key == '1') {
            navigate(`/property-details/${record?.id}`)
            return
        }
        else {
            let payload = {
                id: Number(record?.id),
                status: "active"
            }
            dispatch(SiteManagementAction.UpdatePropertyStatus(payload, () => {
                dispatch(SiteManagementAction.GetProperty(state))
                setState({ ...state, current: current, limit: limit, searchVal: searchVal })
            }))
        }

    };


    const handlePaginationChange = (page, pageSize) => {
        console.log(page, pageSize)
        dispatch(SiteManagementAction.GetProperty({ ...state, current: page, limit: pageSize }))
        setState({ ...state, current: page, limit: pageSize })
        return
    }

    const debouceHandler = (query, delay) => {
        let timeout;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            dispatch(SiteManagementAction.GetProperty({ ...state, searchVal: query, current: 1 }))
        }, delay);
    }

    const handleInputChange = (e) => {
        const query = e?.target.value;
        setState({ ...state, current: 1, searchVal: e.target.value })
        debouceHandler(query, 3000)
    }

    const columns = [
        {
            title: 'Client',
            dataIndex: 'propertyImg',
            key: 'propertyImg',
            align: "left",
            render: (text, record) => (

                <Row justify={"center"} style={{ display: 'flex', alignItems: "center" }}>
                    <Col span={24} className='flex justify-start items-center' >
                        {
                            record?.propertyImg ?
                                <Avatar className='mr-5' size={40} src={record?.propertyImg[0]?.url} />
                                :
                                <Avatar className='mr-5' size={40} icon={<UserOutlined />} />
                        }
                        <span>{record?.company[0]?.name}</span>
                    </Col>
                </Row >

            )
        },
        {
            title: 'Property Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Property Code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            align: 'left',
            render: (text, record) => {
                return (
                    <Col span={24} className='flex justify-start align-center text-center'>
                        <ActionDropdown
                            items={items}
                            placement={'bottom'}
                            labelStyle={{ color: Colors.Yellow, fontSize: '1.2rem', marginBottom: '7px' }}
                            onClick={(e) => ActionHandler(e, record)}
                            isRole={isRole}
                        />
                    </Col>
                )
            }
        },
    ];


    return (
        <>
            <FilterBar
                SelectFilterHandler={SelectFilterHandler}
                SelectFilterValues={optionsSelect}
                AddUserHandler={AddProperty}
                handleInputChange={(e) => handleInputChange(e)}
                SearchValue={state?.searchVal}
                defaultValue={"asc"}
                title='Site Management'
                btnText="Add New Property"
                isRole={isRole}
                classname={'mb-12'}
            />


            <Row gutter={[8, 32]} style={{ padding: '16px' }} className='bg-white rounded-lg w-fit sm:w-fit md:w-full lg:w-full xl:w-full'>

                <Col span={24} className='w-full' >
                    <TableWrapper
                        className="rounded-lg w-full"
                        headerbg={"red"}
                        tableColumns={columns}
                        tableData={properties && properties?.data?.data}
                        total={properties?.data?.paginate?.total}
                        pageSize={state.limit}
                        currentPage={state.current}
                        handlePaginationChange={handlePaginationChange}
                        loading={loader && <Spin />}
                        rowClassName={'row-antd'}
                    />
                </Col>
            </Row >
        </>
    )
}

export default SiteManagement




var optionsSelect = [
    {
        value: 'asc',
        label: 'Ascending',
    },
    {
        value: 'desc',
        label: 'Descending',
    },
]


