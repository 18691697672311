import { GET_COMPANIES, GET_COMPANIES_SUCCESS, GET_COMPANIES_FAILURE, GET_COMPANIES_DETAIL, GET_COMPANIES_DETAIL_SUCCESS, GET_COMPANIES_DETAIL_FAILURE, GET_INVITED_CLIENTS, GET_INVITED_CLIENTS_SUCCESS, GET_INVITED_CLIENTS_FAILURE, GET_INVITED_CLIENT_DETAIL, GET_INVITED_CLIENT_DETAIL_SUCCESS, GET_INVITED_CLIENT_DETAIL_FAILURE, COMPANIES_STATUS_UPDATE, COMPANIES_STATUS_UPDATE_SUCCESS, COMPANIES_STATUS_UPDATE_FAILURE } from "../constants";

export default class ClientManagementAction {
    static GetInvitedClients(payload){
        return {
            type: GET_INVITED_CLIENTS,
            payload
        }
    }

    static GetInvitedClientsSuccess(payload){
        return {
            type: GET_INVITED_CLIENTS_SUCCESS,
            payload
        }
    }

    static GetInvitedClientsFailure(){
        return {
            type: GET_INVITED_CLIENTS_FAILURE,
        }
    }

    static GetInvitedClientDetail(id){
        return {
            type: GET_INVITED_CLIENT_DETAIL,
            id
        }
    }

    static GetInvitedClientDetailSuccess(payload){
        return {
            type: GET_INVITED_CLIENT_DETAIL_SUCCESS,
            payload
        }
    }

    static GetInvitedClientDetailFailure(){
        return {
            type: GET_INVITED_CLIENT_DETAIL_FAILURE,
        }
    }

    static GetCompanies(payload) {
        return {
            type: GET_COMPANIES,
            payload
        }
    }

    static GetCompaniesSuccess(payload) {
        return {
            type: GET_COMPANIES_SUCCESS,
            payload
        }
    }

    static GetCompaniesFailure() {
        return {
            type: GET_COMPANIES_FAILURE
        }
    }

    static GetCompanyDetails(id,cb) {
        return {
            type: GET_COMPANIES_DETAIL,
            id,
            cb
        }
    }

    static GetCompanyDetailsSuccess(payload) {
        return {
            type: GET_COMPANIES_DETAIL_SUCCESS,
            payload
        }
    }

    static GetCompanyDetailsFailure() {
        return {
            type: GET_COMPANIES_DETAIL_FAILURE
        }
    }

    static CompanyStatusUpdate(payload,cb){
        return {
            type: COMPANIES_STATUS_UPDATE,
            payload,
            cb
        }
    }

    static CompanyStatusUpdateSuccess(payload){
        return {
            type: COMPANIES_STATUS_UPDATE_SUCCESS,
            payload
        }
    }

    static CompanyStatusUpdateFailure(){
        return {
            type: COMPANIES_STATUS_UPDATE_FAILURE
            
        }
    }





}
