import React, { useState, useEffect, Children } from 'react';
import ApiCaller from '../../config/api';
import InfiniteScroll from "react-infinite-scroll-component";

// Here is how you can used it
// In your parent component where you want to use it, like this.
// hasMore and scrollItems both are the state defined in parent component where you are using this comp.
// FOR EXAMPLE ===

// const scrollDataHandler = (data) => {
//   let newData = data?.data?.data;
//   setScrollItems(prevItems => [...prevItems, ...newData]);
//   return newData
// }

{/* <LoadOnScroll
            endPoint={`/users?sortBy=firstName&sortOrder=asc&page=`}
            hasMore={hasMore}
            setHasMore={setHasMore}
            scrollItems={scrollItems}
            scrollDataHandler={scrollDataHandler}
            loading={
                <Circular
                    size={20}
                    color={Colors.Yellow}
                />
            }
            children={
                scrollItems?.map((i, index) => (
                    <div key={index}>
                        div - #{index}
                    </div>
                ))
}
/> */}

const style = {
  height: 30,
  // border: "1px solid green",
  margin: 6,
  padding: 8
};

const LoadOnScroll = ({
  endPoint,
  loading,
  scrollDataHandler,
  scrollItems,
  hasMore,
  setHasMore,
  height,
  children,
  endMessage,
  style

}) => {

  const [page, setPage] = useState(1);

  const fetchMoreData = async () => {
    try {
      const res = await ApiCaller.Get(endPoint ? `${endPoint}?page=${page}` : '');
      const newData = await scrollDataHandler(res) || [];
      if (newData?.length === 0) {
        setHasMore(false);
      } else {
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchMoreData();
  }, []);

  return (
    <div className='w-full' style={style}>
      <InfiniteScroll
        dataLength={scrollItems?.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        height={height}
        endMessage={
          endMessage ? endMessage :
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
        }
      >

        {children}

      </InfiniteScroll>
    </div>
  );
};

export default React.memo(LoadOnScroll);

