import React from 'react'
import { Select, Space, Col, ConfigProvider } from 'antd';
import { Colors } from "../../config";


const LabelWithSelect = ({ labelClass, inputClass, labeltext,labelColor, field, fieldState, options, mode, value, onChange, loading }) => {
    return (
        <div className="flex flex-col py-4 w-full">
            <label className={labelClass} style={{ color: labelColor ? labelColor :  Colors.LabelColor }}>{labeltext}</label>
            <ConfigProvider
                theme={{
                    token: {
                        /* here is your global tokens */
                        // colorPrimary: Colors,
                    },
                    components: {
                        Select: {
                            /* here is your component tokens */
                            optionSelectedBg: Colors.Yellow,
                            optionActiveBg: Colors.LightGray,
                            optionSelectedColor: Colors.White
                        },
                    },
                }}

            >
                <Select
                    mode={mode && 'multiple'}
                    {...field}
                    className={inputClass}
                    style={{ background: Colors.LightGray }}
                    options={options}
                    showSearch={false}
                    loading={loading}
                />
            </ConfigProvider>
        </div>
    )
}

export default LabelWithSelect
