import { Row, Col, Avatar, Form, Checkbox } from "antd";
import React, { useState, useEffect } from "react";
import BasicDrawer from "../../components/Drawer";
import { Button, Text, TextField } from "../../components/index";
import { useForm, Controller } from "react-hook-form";
import { Textarea, SearchTextField } from "../../components/TextField/index";
import CheckBoxField from "../../components/Select/Checkbox";
import LoadOnScroll from "../../components/LoadOnScroll";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { ApiCaller, Colors, Utils } from "../../config";
import { SettingAction } from "../../store/actions";

const AddScheduleDrawer = ({ visible, onClose, setState }) => {

  // for user scroll items
  const [userScrollItems, setUserScrollItems] = useState([])
  const [userHasMore, setUserHasMore] = useState(true)

  let [checkedValues, setCheckedValues] = useState([]);
  const [scheduleName, setScheduleName] = useState('');
  const [errors, setErrors] = useState({
    schedule: ''
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (userScrollItems.length == 0) {
      userScrollItems.push({
        name: 'All',
        id: 'All'
      })
    }
  }, [])

  const addScheduleMutate = useMutation(async (data) => {
    let payload = {
      name: data
    }
    let res = await ApiCaller.Post('/schedule', payload);
    return res;
  },
    {
      onSuccess: (res) => {
        Utils.showMessage('success', res?.data?.message)
        onClose()
        setScheduleName('')
        setErrors('')
        setState({ current: 1, searchVal: '', sortBy: 'id', sortOrder: 'asc', limit: 10, tabName: "schedule", tabKey: 1 })
        dispatch(SettingAction.GetSchedules({ current: 1, searchVal: '', sortBy: 'id', sortOrder: 'asc', limit: 10 }))
      },
      onError: (err) => {
        Utils.showMessage('error', err?.response?.data?.message)
      }
    }

  )


  // const handledChange = (e) => {
  //   let item = e.target.value.toString(); // Convert item to string explicitly
  //   let checkedItemsList = document.querySelectorAll('.checked--Item');
  //   let testArray = [...checkedValues]; // Use spread operator to clone array

  //   if (testArray.includes(item)) {
  //     if (item === 'All') {
  //       checkedItemsList.forEach((checkItem) => {
  //         checkItem.checked = false;
  //       });
  //       testArray = [];
  //       console.log("item already in but All", testArray);
  //       setCheckedValues([]);
  //     } else {
  //       testArray.splice(testArray.indexOf(item), 1);
  //       console.log("item already in but not All", testArray);
  //       setCheckedValues(testArray);
  //     }
  //   } else {
  //     if (item === 'All') {
  //       setCheckedValues([]);
  //       testArray = [];
  //       checkedItemsList.forEach((checkItem) => {
  //         checkItem.checked = true;
  //       });
  //       userScrollItems?.map((opt) => testArray.push(opt?.id.toString())); // Convert IDs to strings
  //       console.log("item clicked All", testArray);
  //       setCheckedValues(testArray);
  //     } else {
  //       console.log(testArray);
  //       testArray.push(item);
  //       console.log("item clicked", testArray);
  //       setCheckedValues(testArray);
  //     }
  //   }
  // };


  // const userScrollDataHandler = (data) => {
  //   console.log(data)
  //   let newData = data?.data?.data;
  //   setUserScrollItems(prevItems => [...prevItems, ...newData]);
  //   return newData
  // }

  const scheduleSaveHandler = () => {
    if (scheduleName?.length == 0) {
      setErrors({
        schedule: 'Schedule Name is required'
      })
      
      return
    }

    else if (scheduleName?.length > 50){
      setErrors({
        schedule: 'Length should be maximum of 50 characters'
      })
    }

    else {
      addScheduleMutate.mutate(scheduleName)
    }

    // else {
    //   let data = {
    //     scheduleName,
    //     checkedValues
    //   }
    //   addScheduleMutate.mutate(data)
    // }
  }

  const scheduleValueHandler = (value) => {
    if (!value) {
      setErrors({
        schedule: 'Schedule Name is required'
      })
    }
    else if(value.length > 50) {
      setErrors({
        schedule: 'Length should be maximum of 50 characters'
      })
    }
    else if(value.length < 50) {
      setErrors({
        schedule: ''
      })
    }
    setScheduleName(value)
  }

  const afterDrawerCloseHandler = () => {
    if(!visible){
    setScheduleName('');
    setErrors({
      schedule: ''
    })
  }
}


  return (
    <>
      <BasicDrawer
        title="Add Schedule"
        openDrawer={visible}
        onClose={onClose}
        width={400}
        afterOpenChange={afterDrawerCloseHandler}
      >
        <>
          <Row gutter={16} className="mb-4">
            <Col lg={24} xs={24} className="">
              <TextField.Basic value={scheduleName} onChange={(e) => scheduleValueHandler(e.target.value)} className={'custom--input'} placeholder={'Schedule Name'} style={{ width: '100%', borderRadius: '4px', padding: '7px 10px', background: '#F9F9FA' }} />
              {errors.schedule && <p style={{ color: Colors.Error }}>{errors.schedule}</p>}
            </Col>
          </Row>


          {/* <Row gutter={16}>
            <Col span={24} style={{ height: "50vh" }} className="overflow-auto scroll-smooth">
              <LoadOnScroll
                endPoint={`/properties`}
                hasMore={userHasMore}
                height={230}
                setHasMore={setUserHasMore}
                scrollItems={userScrollItems}
                scrollDataHandler={userScrollDataHandler}
                children={
                  userScrollItems?.map((opt) => {
                    return (
                      <div className="flex items-center gap-2 mb-2" key={opt?.id}>
                        <input className="checked--Item" type="checkbox" id={opt?.name} name={opt?.name} value={opt?.id} onChange={handledChange} />
                        <label for={opt?.name} name={opt?.name}>{opt?.name}</label>
                      </div>
                    )
                  })

                }
              />

            </Col>
          </Row> */}
          <br />
          <Row gutter={16} className="justify-end">
            <Col lg={24} className="flex justify-end">
              <Button.Basic
                onClick={scheduleSaveHandler}
                style={{ width: "7vw" }}
                className="h-12 yellow-bg border-none text-white border-transparent"
                type={"primary"}
                text={"Save"}
                loader={addScheduleMutate.isLoading}
              >
              </Button.Basic>
            </Col>
          </Row>
        </>
      </BasicDrawer>
    </>
  );
};

export default React.memo(AddScheduleDrawer);

