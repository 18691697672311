import React from "react";
import { Col } from "antd";
import { Colors } from "../../config";

const LabelWithField = ({ labelClass, inputClass, type, placeholder, labeltext, field, fieldState, prop,disabled, onBlur, bgColor}) => {
    return (
        <div className="flex flex-col py-4 w-full">
            <label
                className={labelClass}
                style={{ color: Colors.LabelColor }}
            >
                {labeltext}
            </label>
            <input type={type}
                {...field}
                {...prop}
                onBlur={onBlur}
                className={`pl-2.5 outline-none ${inputClass}`}
                style={{ background: bgColor ? bgColor : Colors.LightGray }}
                placeholder={placeholder}
                disabled={disabled}
            />
        </div>
    )
}

export default LabelWithField

