import axios from 'axios';
import React, { useState, useEffect } from 'react';

const SelectBox = () => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const response = await axios.get(`https://uat.sts.5stardesigners.net/api/companies?search=&page=${1}`);
    console.log(response)
    let opt = []
    const data = response?.data?.data.map((item) => {
        opt.push({
            label: item?.name,
            value: item?.id
        })
    })
    console.log(opt)
    setOptions(prevOptions => [...prevOptions, ...opt]); // Append new data to existing options
    setPage(prevPage => prevPage + 1);
    setLoading(false);
  };

  const handleScroll = e => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    console.log(bottom)
    if (bottom && !loading) {
      fetchData();
    }
  };

  return (
    <select onScroll={handleScroll} style={{ height: '50px', overflowY: 'scroll' }}>
      {options.map((option, index) => (
        <option key={index}>{option?.label}</option>
      ))}
      {loading && <option>Loading...</option>}
    </select>
  );
};

export default SelectBox;
