import {
    GET_PROPERTY, GET_PROPERTY_FAILURE, GET_PROPERTY_SUCCESS,
    GET_PROPERTY_DETAIL, GET_PROPERTY_DETAIL_SUCCESS, GET_PROPERTY_DETAIL_FAILURE,
    ADD_PROPERTY, ADD_PROPERTY_SUCCESS, ADD_PROPERTY_FAILURE,
    EDIT_PROPERTY, EDIT_PROPERTY_SUCCESS, EDIT_PROPERTY_FAILURE,
    ADD_ADDRESS,
    EDIT_ADDRESS,
    LOADER_TRUE,
    LOADER_FALSE,
    ADD_DOCUMENT,
    EDIT_DOCUMENT,
    ADD_LOCATION,
    EDIT_LOCATION,
    ADD_INCIDENT,
    EDIT_INCIDENT,
    ADD_CHECKPOINT,
    EDIT_CHECKPOINT,
    ADD_GEOFENCE,
    EDIT_GEOFENCE,
    ADD_PHONE,
    EDIT_PHONE,
    UPDATE_PROPERTY_STATUS,
    GET_ADDRESS,
    GET_ADDRESS_SUCCESS,
    GET_ADDRESS_FAILURE,
    ADDRESS_CLEAR,
    GET_PROPERTIES,
    GET_PROPERTIES_SUCCESS,
    GET_PROPERTIES_FAILURE,
    GET_DOCUMENT,
    GET_DOCUMENT_SUCCESS,
    GET_DOCUMENT_FAILURE,
    DATA_CLEAR,
    GET_LOCATIONS,
    GET_LOCATIONS_SUCCESS,
    GET_LOCATIONS_FAILURE,
    GET_INCIDENT_TYPES,
    GET_INCIDENT_TYPES_SUCCESS,
    GET_INCIDENT_TYPES_FAILURE,
    GET_CHECKPOINTS,
    GET_CHECKPOINTS_FAILURE,
    GET_CHECKPOINTS_SUCCESS,
    LOCATION_DATA_CLEANER,
    GET_ADDRESS_DATA_CLEANER,
    GET_DOCUMENT_DATA_CLEANER,
    GET_CHECKPOINTS_CLEANER,
    GET_GEOFENCE,
    GET_GEOFENCE_SUCCESS,
    GET_GEOFENCE_FAILURE,
    GET_GEOFENCE_DATA_CLEANER,
    ADD_PROPERTY_INCIDENT,
    ADD_PROPERTY_INCIDENT_SUCCESS,
    ADD_PROPERTY_INCIDENT_FAILURE,
    ADD_ADDRESS_TYPE,
    ADD_ADDRESS_TYPE_SUCCESS,

}
    from '../constants'

const initialState = {
    properties: [],
    propertyDetail: [],
    // propertyLoader: false,
    // UserDetailData: [],
    // AddUserData: [],
    loader: false,
    detailLoader: false,
    propertyIncidentLoader: false,
    editPropertyLoader: false,
    formAddressData: [],
    addPropertyData: [],
    getProperties: [],
    documentsData: [],
    incidentTypes: [],
    checkPointData: [],
    locationData: [],
    geofenceData: [],
    addressTypeData: []
    // AddUserLoader: false,
    // DetailUserLoader: false,
}

export default function SiteManagementReducer(state = initialState, action) {
    switch (action.type) {

        case GET_PROPERTY:
            state = {
                ...state,
                loader: true
            }
            break;

        case GET_PROPERTY_SUCCESS:
            state = {
                ...state,
                loader: false,
                properties: action.payload
            }
            break;

        case GET_PROPERTY_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;


        case UPDATE_PROPERTY_STATUS:
            state = {
                ...state,
                loader: true
            }
            break;



        case GET_PROPERTY_DETAIL:
            state = {
                ...state,
                detailLoader: true
            }
            break;

        case GET_PROPERTY_DETAIL_SUCCESS:
            state = {
                ...state,
                detailLoader: false,
                propertyDetail: action.payload
            }
            break;

        case GET_PROPERTY_DETAIL_FAILURE:
            state = {
                ...state,
                detailLoader: false
            }
            break;

        case GET_PROPERTIES:
            state = {
                ...state,
                loader: true
            }
            break;

        case GET_PROPERTIES_SUCCESS:
            state = {
                ...state,
                getProperties: action.payload,
                loader: false
            }
            break;

        case GET_PROPERTIES_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;

        case ADD_PROPERTY:
            state = {
                ...state,
                loader: true
            }
            break;

        case ADD_PROPERTY_SUCCESS:
            state = {
                ...state,
                addPropertyData: action.payload,
                loader: false
            }
            break;

        case ADD_PROPERTY_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;

        case EDIT_PROPERTY:
            state = {
                ...state,
                editPropertyLoader: true
            }
            break;

        case EDIT_PROPERTY_SUCCESS:
            state = {
                ...state,
                editPropertyLoader: false,
            }
            break;

        case EDIT_PROPERTY_FAILURE:
            state = {
                ...state,
                editPropertyLoader: false
            }
            break;



        case ADD_ADDRESS:
            state = {
                ...state,
                loader: true,
            }
            break;

        case EDIT_ADDRESS:
            state = {
                ...state,
                loader: true
            }
            break;

        case GET_ADDRESS:
            state = {
                ...state,
                loader: true
            }
            break;
        case GET_ADDRESS_SUCCESS:
            state = {
                ...state,
                formAddressData: action.payload,
                loader: false
            }
            break;
        case GET_ADDRESS_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;
        case GET_ADDRESS_DATA_CLEANER:
            state = {
                ...state,
                loader: false,
                formAddressData: [],
            }
            break;

        case GET_DOCUMENT:
            state = {
                ...state,
                loader: true,
            }
            break;

        case GET_DOCUMENT_SUCCESS:
            state = {
                ...state,
                loader: false,
                documentsData: action.payload
            }

            break;

        case GET_DOCUMENT_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;

        case GET_DOCUMENT_DATA_CLEANER:
            state = {
                ...state,
                loader: false,
                documentsData: []
            }
            break;

        case ADD_DOCUMENT:
            state = {
                ...state,
                loader: true,
            }
            break;

        case EDIT_DOCUMENT:
            state = {
                ...state,
                loader: true
            }
            break;

        case GET_LOCATIONS:
            state = {
                ...state,
                loader: true,
            }
            break;

        case GET_LOCATIONS_SUCCESS:
            state = {
                ...state,
                loader: false,
                locationData: action.payload
            }
            break;

        case LOCATION_DATA_CLEANER:
            state = {
                ...state,
                loader: false,
                locationData: []
            }
            break;

        case GET_LOCATIONS_FAILURE:
            state = {
                ...state,
                loader: false,
            }
            break;

        case ADD_LOCATION:
            state = {
                ...state,
                loader: true,
            }
            break;

        case EDIT_LOCATION:
            state = {
                ...state,
                loader: true
            }
            break;

        case GET_INCIDENT_TYPES:
            state = {
                ...state,
                loader: true
            }
            break

        case GET_INCIDENT_TYPES_SUCCESS:
            state = {
                ...state,
                incidentTypes: action.payload,
                loader: false
            }
            break;

        case GET_INCIDENT_TYPES_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;

        case ADD_INCIDENT:
            state = {
                ...state,
                loader: true,
            }
            break;

        case EDIT_INCIDENT:
            state = {
                ...state,
                loader: true
            }
            break;

        case ADD_PROPERTY_INCIDENT:
            state = {
                ...state,
                propertyIncidentLoader: true,
            }
            break;

        case ADD_PROPERTY_INCIDENT_SUCCESS:
            state = {
                ...state,
                propertyIncidentLoader: false
            }
            break;

        case ADD_PROPERTY_INCIDENT_FAILURE:
            state = {
                ...state,
                propertyIncidentLoader: false
            }
            break;

        case GET_CHECKPOINTS:
            state = {
                ...state,
                loader: true,
            }
            break;

        case GET_CHECKPOINTS_SUCCESS:
            state = {
                ...state,
                loader: false,
                checkPointData: action.payload
            }
            break;

        case GET_CHECKPOINTS_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;

        case GET_CHECKPOINTS_CLEANER:
            state = {
                ...state,
                loader: false,
                checkPointData: []
            }
            break;

        case ADD_CHECKPOINT:
            state = {
                ...state,
                loader: true,
            }
            break;

        case EDIT_CHECKPOINT:
            state = {
                ...state,
                loader: true
            }
            break;

        case GET_GEOFENCE:
            state = {
                ...state,
                loader: true
            }
            break;

        case GET_GEOFENCE_SUCCESS:
            state = {
                ...state,
                loader: false,
                geofenceData: action.payload
            }
            break;

        case GET_GEOFENCE_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;

        case GET_GEOFENCE_DATA_CLEANER:
            state = {
                ...state,
                loader: false,
                geofenceData: []
            }
            break;

        case ADD_GEOFENCE:
            state = {
                ...state,
                loader: true,
            }
            break;

        case EDIT_GEOFENCE:
            state = {
                ...state,
                loader: true
            }
            break;


        case ADD_PHONE:
            state = {
                ...state,
                loader: true,
            }
            break;

        case EDIT_PHONE:
            state = {
                ...state,
                loader: true
            }
            break;

        case ADD_ADDRESS_TYPE:
            state = {
                ...state,
            }
            break;

        case ADD_ADDRESS_TYPE_SUCCESS:
            state = {
                ...state,
                addressTypeData: action.payload
            }
            break;

        case LOADER_TRUE:
            state = {
                ...state,
                loader: true,
            }
            break;

        case LOADER_FALSE:
            state = {
                ...state,
                loader: false
            }
            break;

        default:
            state = { ...state }

    }
    return state
}