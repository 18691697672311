import React, { useEffect } from 'react'
import BasicDrawer from '../../components/Drawer'
import { AddUserSchema } from '../../config/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { Avatar, Col, Row } from 'antd';
import LabelWithField from '../../components/LabelWithField';
import { Colors } from '../../config';
import LabelWithDate from '../../components/LabelWithDate';
import LabelWithSelect from '../../components/LabelWithSelect';
import moment from 'moment';
import ButtonLoader from '../../components/Button/ButtonLoader';
import dayjs from 'dayjs';
import { FaCamera } from 'react-icons/fa';
import camera from '../../assets/images/camera.png'
import Text from '../../components/Text';
import { getEligibiity } from '../../config/getApis';
import { useQuery } from 'react-query';


const UserAddnEditDrawer = ({
    drawerStates,
    drawerKeyHandler,
    onCloseDrawer,
    userDetailData,
    phoneNumberFormat,
    FormDataHandler,
    userAddLoader,
    eligibilityData,
    scheduleData,
    rolesData,
    readFile,
    setReadFile,
    img,
    setimg,
    imgValue,
    setImageValue
}) => {


    
    // Form related Working
    let schema = AddUserSchema;
    const { handleSubmit, control, reset, clearErrors, setValue } = useForm(
        {
            resolver: yupResolver(schema),
            mode: 'onChange'
        }
    )

    const { data: eligibility } = useQuery('eligibility', getEligibiity);

    useEffect(() => {
        // usage for this useEffect: whenver userdetail drawer open the action dispatched and we got the
        // data here in userDetailData, now on click of edit we are appending that data. so this effect
        // will run and set those values as whenver the userDetailData changes it will run this effect

        // I am not setting the complete userDetailData object into a reset() whenver the user click on
        // the edit button because after getting out and user decide to click on add user button above
        // it still shows that data even though i called a action to clear it because after clearing it 
        // as well the userDetailData wil become an empty array so if now i set reset(userDetailData)
        // it will stop the validations to occur that is why have to use this useEffect.

        let eligibilities = [];
        let schedules = [];
        let roles;

        userDetailData?.eligibilities?.map((item) => {
            eligibilities?.push(item.id)
        })


        userDetailData?.schedules?.map((item) => {
            schedules.push(item?.id)
            // schedules = item?.id
        })

        userDetailData?.userRole?.map((item) => {
            // roles?.push(item.id)
            roles = item?.id
        })

        reset({
            userimg: userDetailData?.profileImg && userDetailData?.profileImg[0]?.id,
            firstName: userDetailData.firstName || '',
            lastName: userDetailData.lastName || '',
            phoneNo: userDetailData.phoneNo || '',
            email: userDetailData.email || '',
            userName: userDetailData.userName || '',
            eligibilityIds: eligibilities || [],
            scheduleIds: schedules || [],
            roleId: roles || '',
            unarmedExpiration: dayjs(userDetailData?.certificateInfo?.unarmedExpiration) || dayjs(),
            armedExpiration: dayjs(userDetailData?.certificateInfo?.armedExpiration === null ? dayjs() : userDetailData?.certificateInfo?.armedExpiration) || dayjs(),
            // ojt: dayjs(userDetailData?.certificateInfo?.ojt) || dayjs(),
            annualOne: dayjs(userDetailData?.certificateInfo?.annualOne) || dayjs(),
            annualTwo: dayjs(userDetailData?.certificateInfo?.annualTwo) || dayjs()
        })

    }, [userDetailData])

    useEffect(() => {
        let fileReader,
            isCancel = false;

        fileReader = new FileReader();
        if (img) {
            fileReader.onload = (e) => {
                setReadFile(e?.target?.result);
            };
            fileReader.readAsDataURL(img);
        }

        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        };
    }, [img]);



    const fileHandler = (e) => {
        console.log(e)
        const file = e?.target.files[0];
        console.log('file img data', file)

        if (file?.size <= 307200 && ['image/png', 'image/jpeg'].includes(file?.type)) {
            setimg(file);
            setValue('userimg', file)
            setImageValue({ imgSizeValidation: '', imgTypeValidation: '', isRequired: '' })
            return
        }

        else {
            if (file?.size > 307200) {
                setImageValue({ ...imgValue, imgSizeValidation: 'Size should not exceed' })
                setimg('')
                setValue('userimg', null)
                setReadFile('')
                return
            }
            else if (!['image/png', 'image/jpeg'].includes(file?.type)) {
                setImageValue({ ...imgValue, imgTypeValidation: 'Type should be match' })
                setimg('')
                setValue('userimg', null)
                setReadFile('')
                return
            }
        }
    };


    return (
        <BasicDrawer
            openDrawer={drawerStates?.openDrawer}
            afterOpenChange={(isOpen) => drawerKeyHandler(isOpen,reset)}
            title={drawerStates?.drawerkey == 1 ? 'Edit User' : 'Add New User'}
            onClose={onCloseDrawer}
            width={'600'}
        >
            <Row className='w-full' style={{}}>

                <form className='w-full'>
                    <Row className='w-full flex justify-between' >

                        <Row
                            className="py-5 w-full items-center justify-center"
                            // justify={'center'}
                            // align={'middle'}

                            style={{
                                padding: "10px",
                                height: "100px",
                                position: "relative",
                            }}
                        >
                            <Col style={{ width: '70px', height: '70px', borderRadius: '50%', border: '1px solid #F9F9FA', background: '#F9F9FA' }} className='flex items-center justify-center relative '>
                                <Controller
                                    control={control}
                                    name='userimg'
                                    defaultValue={''}
                                    render={(field, fieldState) => (

                                        <>
                                            <>
                                                <input
                                                    type="file"
                                                    className='z-0 cursor-pointer'
                                                    style={{
                                                        borderRadius: "50%",
                                                        width: '100%',
                                                        height: '100%',
                                                        position: "absolute",
                                                        zIndex: 999,
                                                        opacity: 0,
                                                    }}

                                                    onChange={(e) => {
                                                        fileHandler(e)
                                                    }}
                                                    accept=".png, .jpg, .jpeg"
                                                />
                                            </>
                                        </>
                                    )}
                                />
                                {
                                    readFile ?
                                        <>
                                            <Avatar className='z-10 absolute w-full h-full' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} src={readFile} />
                                            <img src={camera} className='absolute z-20 right-0 bottom-0' width={'20px'} height={'20px'} />
                                        </>
                                        : <Avatar className='z-0 absolute w-full h-full flex items-center justify-center' icon={<FaCamera />} />}
                            </Col>

                            {/* user image validation  */}
                            <p style={{ color: Colors.Error, width: '100%' }} className='text-center'>{imgValue && imgValue.imgSizeValidation ? <p>{imgValue.imgSizeValidation}</p> : imgValue && imgValue.imgTypeValidation ? <p>{imgValue.imgTypeValidation}</p> : imgValue.isRequired ? <p>{imgValue.isRequired}</p> : ''}</p>

                        </Row>

                        <Row className='w-6/12 pr-2'>
                            <Controller
                                control={control}
                                name='firstName'
                                defaultValue={''}
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithField labeltext={'First Name'} field={field} type={'text'} labelClass={'font-normal'} inputClass={'outline-none h-10 rounded'} />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}
                            />
                        </Row>

                        <Row className='w-6/12'>
                            <>
                                <Controller
                                    control={control}
                                    name='lastName'
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField labeltext={'Last Name'} field={field} type={'text'} labelClass={'font-normal'} inputClass={'outline-none h-10 rounded'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />

                            </>
                        </Row>

                        <Row className='w-2/4 pr-2'>
                            <>
                                <Controller
                                    control={control}
                                    name='phoneNo'
                                    render={({ field: { value, onChange }, fieldState }) => (
                                        <div className="flex flex-col py-4 w-full">
                                            <label
                                                className={'font-normal'}
                                                style={{ color: Colors.LabelColor }}
                                            >
                                                {'Phone Number'}
                                            </label>
                                            <input type={'string'}
                                                value={value}
                                                onChange={onChange}
                                                onBlur={(e) => {
                                                    const formattedNumber = phoneNumberFormat(e.target.value);
                                                    if (formattedNumber?.length === 14) {
                                                        clearErrors('phoneNo')
                                                        onChange(formattedNumber)
                                                    }
                                                }}
                                                className={`pl-2.5 outline-none h-10 rounded`}
                                                style={{ background: Colors.LightGray }}
                                            />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </div>
                                    )}
                                />

                            </>
                        </Row>

                        <Row className='w-2/4'>
                            <>
                                <Controller
                                    control={control}
                                    name='email'
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField disabled={userDetailData?.email ? true : false} labeltext={'Email'} field={field} type={'email'} labelClass={'font-normal'} inputClass={'outline-none h-10 rounded'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />

                            </>
                        </Row>

                        <Row className='w-2/4 pr-2'>
                            <>
                                <Controller
                                    control={control}
                                    name='userName'
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField disabled={userDetailData?.userName ? true : false} labeltext={'Username'} field={field} type={'text'} labelClass={'font-normal'} inputClass={'outline-none h-10 rounded'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />

                            </>
                        </Row>

                        <Row className='w-2/4'>
                            <>
                                <Controller
                                    control={control}
                                    name='eligibilityIds'
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithSelect mode={'multiple'} options={eligibilityData ? eligibilityData : []} labeltext={'Eligibility'} field={field} labelClass={'font-normal'} inputClass={'outline-none py-1 rounded'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />

                            </>
                        </Row>

                        <Row className='w-2/4 pr-2'>
                            <>
                                <Controller
                                    control={control}
                                    name='scheduleIds'
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithSelect mode={'multiple'} options={scheduleData ? scheduleData : []} labeltext={'Schedules'} field={field} labelClass={'font-normal'} inputClass={'outline-none py-1 rounded'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />

                            </>
                        </Row>

                        <Row className='w-2/4'>
                            <>
                                <Controller
                                    control={control}
                                    name='roleId'
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithSelect options={rolesData ? rolesData : []} labeltext={'Role'} field={field} labelClass={'font-normal'} inputClass={'outline-none h-10 rounded'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />

                            </>
                        </Row>

                        <Text.Basic className='w-full my-3' style={{ textAlign: 'left', fontSize: '1rem', fontWeight: '500', paddingLeft: '2px' }} text={'Certification info:'} />

                        <Row className='w-2/4 pr-2'>
                            <>
                                <Controller
                                    control={control}
                                    // defaultValue={(userDetailData?.certificateInfo?.unarmedExpiration && drawerStates?.drawerkey == 1) ? dayjs(userDetailData?.certificateInfo?.unarmedExpiration) : dayjs()}
                                    name='unarmedExpiration'
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithDate
                                                labeltext={'Unarmed expiration'}
                                                // defaultValue={
                                                //     (userDetailData?.certificateInfo?.unarmedExpiration && drawerStates?.drawerkey == 1)
                                                //         ? dayjs(userDetailData?.certificateInfo?.unarmedExpiration, 'DD-MM-YYYY')
                                                //         : dayjs()}
                                                // defaultValue={(userDetailData?.certificateInfo?.unarmedExpiration && drawerStates?.drawerkey == 1) ? dayjs(userDetailData?.certificateInfo?.unarmedExpiration) : dayjs()}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'outline-none border-none h-10 rounded'}
                                                disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))}
                                            />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />

                            </>
                        </Row>

                        <Row className='w-2/4'>
                            <>
                                <Controller
                                    control={control}
                                    // defaultValue={(userDetailData?.certificateInfo?.armedExpiration && drawerStates?.drawerkey == 1) ? dayjs(userDetailData?.certificateInfo?.armedExpiration) : dayjs()}
                                    name='armedExpiration'
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithDate
                                                field={field}
                                                labeltext={'Armed expiration'}
                                                // defaultValue={userDetailData?.certificateInfo?.unarmedExpiration ? dayjs(userDetailData?.certificateInfo?.unarmedExpiration) : dayjs()}
                                                labelClass={'font-normal'}
                                                inputClass={'outline-none border-none h-10 rounded'}
                                                disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />

                            </>
                        </Row>

                        {/* <Row className='w-2/4 pr-2'>
                            <>
                                <Controller
                                    control={control}
                                    // defaultValue={(userDetailData?.certificateInfo?.ojt && drawerStates?.drawerkey == 1) ? dayjs(userDetailData?.certificateInfo?.ojt) : dayjs()}
                                    name='ojt'
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithDate
                                                field={field}
                                                labeltext={'OJT'}
                                                // defaultValue={userDetailData?.certificateInfo?.unarmedExpiration ? dayjs(userDetailData?.certificateInfo?.unarmedExpiration) : dayjs()}
                                                labelClass={'font-normal'}
                                                inputClass={'outline-none border-none	h-10 rounded'}
                                                disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />

                            </>
                        </Row> */}

                        <Row className='w-2/4 pr-2'>
                            <>
                                <Controller
                                    control={control}
                                    // defaultValue={(userDetailData?.certificateInfo?.annualOne && drawerStates?.drawerkey == 1) ? dayjs(userDetailData?.certificateInfo?.annualOne) : dayjs()}
                                    name='annualOne'
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithDate
                                                field={field}
                                                // defaultValue={userDetailData?.certificateInfo?.unarmedExpiration ? dayjs(userDetailData?.certificateInfo?.unarmedExpiration) : dayjs()}
                                                labeltext={'OJT-Annual-1'}
                                                labelClass={'font-normal'}
                                                inputClass={'outline-none border-none h-10 rounded'}
                                                disabledDate={(current) => current && current > moment().endOf('day')} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}

                                        </>
                                    )}
                                />

                            </>
                        </Row>

                        <Row className='w-2/4'>
                            <>
                                <Controller
                                    control={control}
                                    // defaultValue={(userDetailData?.certificateInfo?.annualTwo && drawerStates?.drawerkey == 1) ? dayjs(userDetailData?.certificateInfo?.annualTwo) : dayjs()}
                                    name='annualTwo'
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithDate
                                                field={field}
                                                // defaultValue={userDetailData?.certificateInfo?.unarmedExpiration ? dayjs(userDetailData?.certificateInfo?.unarmedExpiration) : dayjs()}
                                                labeltext={'OJT-Annual-2'}
                                                labelClass={'font-normal'}
                                                inputClass={'outline-none border-none h-10 rounded'}
                                                disabledDate={(current) => current && current > moment().endOf('day')} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}

                                        </>
                                    )}
                                />

                            </>
                        </Row>


                    </Row>

                    <Row className='w-full flex justify-end'>
                        <ButtonLoader btnHandler={handleSubmit(FormDataHandler)} loader={userAddLoader} style={{ background: Colors.Yellow, color: Colors.White }} className={'cursor-pointer my-2 mx-1 h-10 rounded-none px-8'} text={drawerStates?.drawerkey == 1 ? 'Update User' : 'Add New User'} />
                        {/* <input type='submit' style={{ background: Colors.Yellow, color: Colors.White }} className={'cursor-pointer my-2 mx-1 h-10 rounded-none px-8'} value={drawerStates.drawerkey == 1 ? 'Update User' : 'Add New User'} /> */}
                    </Row>
                </form>

            </Row>

        </BasicDrawer>

    )
}

export default UserAddnEditDrawer