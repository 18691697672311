import React, { useMemo } from 'react';
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';

const libraries = ['places'];
const mapContainerStyle = {
    width: '100%',
    height: '100%',
};

// const defaultCenter = {
//     lat: 24.860966, // default latitude
//     lng: 66.990501, // default longitude
// };

// NEW YORK DEFAULT CENTER
const defaultCenter = {
    lat: 40.730610, // default latitude
    lng: -73.935242, // default longitude
  };

const GoogleMapComp = ({ pointerPosition, mapClickHandler }) => {

    const center = useMemo(() => defaultCenter, []); // Memoize the center


    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
        libraries,
    });


    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps</div>;
    }

    return (
        <>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={10}
                center={center}
                onClick={mapClickHandler}
            >
                {pointerPosition && pointerPosition?.map((pos, index) => (
                    <MarkerF key={index}
                        position={pos}
                    />
                ))}
            </GoogleMap>
        </>
    );
};

export default GoogleMapComp;

