import axios from 'axios'

// base url from the env.
const BASE_URL = process.env.REACT_APP_BASE_URL;

// axios will set the baseurl before any api call so e.g baseurl/endpoint
axios.defaults.baseURL = BASE_URL;


console.log('authtoken', JSON.parse(localStorage.getItem('authToken')))
// it will attach the auth token before making the request any.
axios.interceptors.request.use(
    (config) => {
        config.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('authToken'))}`;
        return config;
    },
    (error) => {
        console.log('wrror', error)
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {

        if (error.response && error.response.status === 401) {
            // Use router.push() to navigate to the login screen
            localStorage.removeItem('user')
            localStorage.removeItem('authToken')
            window.location.replace('/login')

            return Promise.reject('Unauthorized');
        }
        // Handle other errors here
        return Promise.reject(error);
    }
);

// api calls
export default class ApiCaller {

    static async Get(endpoint, params) {
        const resp = await axios.get(`${endpoint}` , {params});
        return resp
    }

    static async Post(endpoint, body) {
        const resp = await axios.post(`${endpoint}`, body);
        return resp
    }

    static async Patch(endpoint, body) {
        const resp = await axios.patch(`${endpoint}`, body);
        return resp
    }

    static async Put(endpoint, body) {
        const resp = await axios.put(`${endpoint}`, body);
        return resp
    }

    static async Delete(endpoint) {
        const resp = await axios.delete(`${endpoint}`);
        return resp
    }

}