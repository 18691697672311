import React, { useEffect, useState } from 'react'
import { Row, Col, Avatar, Form } from "antd";
import { CameraOutlined } from '@ant-design/icons';
import { Button, TextField, Text } from '..';
import { FaArrowLeftLong } from "react-icons/fa6";
import LabelWithField from '../LabelWithField';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { AddUserSchema } from '../../config/Schema';
import LabelWithSelect from '../LabelWithSelect';
import { ApiCaller, Colors, Utils } from '../../config';
import { FaPlus } from "react-icons/fa6";
import fileUpload from '../../assets/images/fileUpload.png'
import { AddDocuments } from '../../config/Schema';
import { useNavigate } from 'react-router-dom';
import Documents from '../DrawerForms/document';
import BasicDrawer from '../Drawer';
import { useMutation } from 'react-query';
import { UseDispatch, useDispatch, useSelector } from 'react-redux';
import { SiteManagementAction } from '../../store/actions';
import Circular from '../Loader/Circular';

const DocumentForm = ({

    nextStep,
    previousStep,
    currenStep

}) => {
    const [DrawerState, setDrawerState] = useState(false)
    const [editdocument, setEditDocument] = useState({})

    // # CASE  
    // have to call the cleaner function because whenever the component render it gets the data from
    // which was previously stored in reducer. Case because if user is in the middle of the form and 
    // clicks to navigate to somewhere else the cleaner function will not going to call on that situation
    // it was getting called only on save btn and continue btn, So thats why it will get the data whenever
    // the component get render so to avoid that we called this function in useEffect whenever the 
    // component get first render on mounting stage this will trigger and by chance if any state is there
    // in reducer it will clear it.
    useEffect(() => {
        dispatch(SiteManagementAction.GetDocumentDataCleaner())
    }, [])

    const [btnDisabled, setBtnDisabled] = useState({
        next: false,
        save: false,
        addMore: false
    })
    const [documentValidate, setDocumentValidate] = useState('');

    const schema = AddDocuments;

    const navigate = useNavigate()

    const dispatch = useDispatch();

    const documentData = useSelector(state => state?.SiteManagementReducer?.documentsData || []);
    const documentLoader = useSelector(state => state?.SiteManagementReducer.loader);

    const defaultValues = {
        name: '',
        accessibility: '',
        description: ''
    }

    const { handleSubmit, control, reset, setValue, watch, getValues } = useForm(
        {
            resolver: yupResolver(schema),
            mode: 'onChange',
            defaultValues: defaultValues

        }
    )

    const documentMutation = useMutation(async (data) => {

        const { buttonName, ...restData } = data;
        console.log(buttonName, restData)

        try {
            const id = localStorage.getItem('PropertyKey')
            let res = await ApiCaller.Post(`/property/${id}/document`, restData);

            if (buttonName == 'next') {
                dispatch(SiteManagementAction.GetDocumentDataCleaner());
                setValue('mediaId', null)
                const file = document.querySelector('.file');
                file.value = '';
                setBtnDisabled({
                    save: false,
                    next: false,
                    addMore: false

                })
                reset(defaultValues)
                setDocumentValidate('')
                nextStep();
                return
            }

            else if (buttonName == 'save') {
                dispatch(SiteManagementAction.GetDocumentDataCleaner());
                setValue('mediaId', null)
                localStorage.removeItem('PropertyKey')
                const file = document.querySelector('.file');
                file.value = '';
                setBtnDisabled({
                    save: false,
                    next: false,
                    addMore: false

                })
                reset(defaultValues)
                setDocumentValidate('')
                navigate('/site-management')
                return
            }

            else if (buttonName == 'more') {
                dispatch(SiteManagementAction.GetDocument())
                setValue('mediaId', null)
                const file = document.querySelector('.file');
                file.value = '';
                setBtnDisabled({
                    save: false,
                    next: false,
                    addMore: false

                })
                reset(defaultValues)
                setDocumentValidate('')
                return
            }
        }

        catch (err) {
            Utils.showMessage('error', err?.response?.data?.message);
            setBtnDisabled({
                save: false,
                next: false,
                addMore: false
            })
            return
        }
    })


    const userProfileMutation = useMutation(async (data) => {
        const { mediaId, ...restData } = data;

        if (!mediaId) {
            console.log(restData)
            documentMutation.mutate(restData);
            return
        }

        else {
            const formData = new FormData();
            formData.append('file', mediaId);
            formData.append('type', 'document')

            try {
                const res = await ApiCaller.Post('/media/upload', formData);
                console.log(res)
                const id = res.data?.data?.id;
                restData.mediaId = id;
                documentMutation.mutate(restData)
                return;
            }

            catch (err) {
                Utils.showMessage('error', err?.response?.data?.message);
                return
            }

        }
    },
    );


    const SaveAndAddMoreHandler = (data, name) => {
        // api hit for post of a address
        if (!data?.mediaId) {
            setDocumentValidate('Document is required')
            return
        }

        else {
            setBtnDisabled({
                next: true,
                save: true,
                addMore: true
            })
            data.buttonName = name;
            userProfileMutation.mutate(data)
        }
    }

    const nextHandler = (data, name) => {
        // post api hit and continue
        if (!data?.mediaId) {
            setDocumentValidate('Document is required')
            return
        }

        else {
            setBtnDisabled({
                save: false,
                addMore: false,
                next: true
            })
            data.buttonName = name;
            userProfileMutation.mutate(data);
        }
    }

    const skipHandler = () => {
        setBtnDisabled({
            next: false,
            save: false,
            addMore: false
        })
        reset(defaultValues)
        setValue('mediaId', null)
        const file = document.querySelector('.file');
        file.value = '';
        nextStep()
        setDocumentValidate('')
    }

    const saveHandler = (data, name) => {
        // post api hit and back to listing.
        if (!data?.mediaId) {
            setDocumentValidate('Document is required')
            return
        }

        else {
            setBtnDisabled({
                addMore: false,
                next: false,
                save: true
            })

            data.buttonName = name;
            userProfileMutation.mutate(data);
        }
    }

    const onCloseDrawer = () => {
        setDrawerState(false)
    }

    const EditDocumentHandler = (document) => {
        setEditDocument(document)
        setDrawerState(true);
    }

    const fileHandler = (e) => {
        const file = e?.target.files[0];
        if (file !== undefined) {
            setValue('mediaId', file)
            setDocumentValidate('')
        }
        else {
            setDocumentValidate('Document is required')
            setValue('mediaId', null)
            const fileVal = document.querySelector('.file');
            fileVal.value = '';
        }

    };


    return (
        <div className='bg-white'>

            {/* //Edit document form */}
            {
                !documentLoader ?
                    documentData?.map((document) => {
                        return (
                            <Row gutter={16} className='px-4 md:px-10 mb-2' key={document?.id}>
                                <Col span={24} className='gry-bg p-6 rounded-lg'>
                                    <Row className='px-2 flex items-center justify-between'>
                                        <Col lg={8} md={8} sm={24} xs={24} className='mb-2'>
                                            <Text.Label
                                                text={'File'}
                                            />
                                            <a href={document.media && document.media[0]?.url} target='_blank'>
                                                <p className='text-xs' style={{ color: '#8DB1FD' }}>media</p>
                                            </a>
                                        </Col>
                                        <Col lg={8} md={8} sm={24} xs={24} className='mb-2'>
                                            <Text.Label
                                                text={'Document Name'}
                                            />
                                            <p className='text-xs'>{document?.name}</p>
                                        </Col>
                                        <Col lg={5} md={5} sm={24} xs={24} className='mb-2'>
                                            <Text.Label
                                                text={'Acessibility'}
                                            />
                                            <p className='text-xs'>{document?.accessibility}</p>
                                        </Col>

                                        <Col lg={3} md={3} sm={24} xs={24}>
                                            <Button.Basic
                                                // onClick={() => }
                                                className="w-full h-10 yellow-bg border-none text-white border-transparent"
                                                text={"Edit"}
                                                onClick={() => EditDocumentHandler(document)}
                                                disabled={currenStep === 7}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='px-2 my-3'>
                                        <Col span={24}>
                                            <Text.Label
                                                text={'Description'}
                                            />
                                            <p className='text-xs'>{document?.description}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    })
                    :
                    <Circular
                        spinning={documentLoader}
                        size={40}
                        color={Colors.Yellow}
                        containerStyle={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    />
            }



            <Row className='px-2 md:px-10'>
                <form layout="vertical" className='w-full'>
                    <Row gutter={16} className='flex items-center justify-between gap-1 px-2 mb-2'>
                        <Col lg={11} xs={24} className={`relative h-16 rounded outline-none flex flex-col justify-start mt-0 lg:mt-2 ${documentValidate && 'mb-3' }`} >

                            <label className='font-normal p-0 m-0' style={{ color: Colors.LabelColor, marginTop: '4px', marginLeft: '-6px' }}>Upload File</label>
                            <input
                                type="file"
                                className='z-0 cursor-pointer h-10 absolute outline-none border-0 rounded left-0 bottom-0 py-2 px-2 file'
                                style={{
                                    width: '100%',
                                    zIndex: 1,
                                    background: Colors.LightGray
                                }}

                                onChange={(e) => {
                                    fileHandler(e)
                                }}

                                accept=".pdf, .docx"
                            />
                            {documentValidate &&
                                <p className='mt-10' style={{ padding: '0', color: Colors.Error}}>{documentValidate}</p>}
                            <img src={fileUpload} className='absolute bottom-0 right-0 h-10' style={{ zIndex: 2 }} width={40} />
                            
                        </Col>
                        <Col lg={12} xs={24} className='px-0' style={{ paddingLeft: 0, paddingRight: 0}}>
                            <Controller
                                control={control}
                                name='name'
                                defaultValue=''
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithField
                                            labeltext={'Document Name'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'outline-none h-10 rounded'} />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}

                            />
                        </Col>
                    </Row>

                    <Row className='mb-2'>
                        <Col lg={11} xs={24}>
                            <Controller
                                control={control}
                                name='accessibility'
                                defaultValue=''
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithSelect
                                            options={optionsSelect}
                                            labeltext={'Acessibility'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'outline-none h-10 rounded'}
                                        />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16} className=''>
                        <Col lg={24} xs={24}>
                            <Controller
                                control={control}
                                name='description'
                                defaultValue=''
                                render={({ field: { value, onChange }, fieldState }) => (
                                    <>
                                        <TextField.Textarea
                                            style={{ color: Colors.LightGray }}
                                            placeholder={''}
                                            value={value}
                                            onChange={onChange}
                                            labeltext={'Description'}
                                            className={'outline-none h-10 rounded'}
                                        />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24} className='flex align-middle justify-end items-center'>
                            <span className={`cursor-pointer hover:underline font-bold ${documentMutation?.isLoading && 'pointer-events-none'}`} onClick={handleSubmit((data) => SaveAndAddMoreHandler(data, 'more'))}><span className='font-bold text-base'> + </span>
                                Save And Add More
                            </span>
                        </Col>
                    </Row>
                </form>

            </Row>
            <br />

            <Row gutter={[16, 16]} className='px-2 md:px-10' >
                <Col xs={24} lg={3} className='flex align-middle items-center'
                >
                    <FaArrowLeftLong className='mr-2' />
                    <span onClick={() => previousStep()} className={`cursor-pointer ${documentMutation?.isLoading && 'pointer-events-none'}`}
                    >Back</span>
                </Col>

                <Col xs={24} lg={{ span: 4, offset: 9 }} >
                    <Button.Basic
                        onClick={handleSubmit((data) => saveHandler(data, 'save'))}
                        className="w-full h-12 yellow-bg border-none text-white border-transparent"
                        text={"Save"}
                        disabled={btnDisabled.next || btnDisabled.addMore ? true : false}
                        loader={btnDisabled.next || btnDisabled.addMore ? false : userProfileMutation.isLoading || documentMutation.isLoading}
                    />
                </Col>
                <Col xs={24} lg={4} >
                    <Button.Basic
                        className="w-full h-12  orange-bg border-none text-white border-transparent"
                        onClick={handleSubmit((data) => nextHandler(data, 'next'))}
                        text={"Continue"}
                        disabled={btnDisabled.save || btnDisabled.addMore ? true : false}
                        loader={btnDisabled.save || btnDisabled.addMore ? false : userProfileMutation.isLoading || documentMutation.isLoading}
                    />
                </Col>
                <Col xs={24} lg={4} >
                    <Button.Basic
                        className="w-full h-12 black-bg border-none text-white border-transparent"
                        onClick={skipHandler}
                        text={"Next"}
                        disabled={btnDisabled.next || btnDisabled.save || btnDisabled.addMore ? true : false}
                    />
                </Col>
            </Row>
            <br />

            <BasicDrawer
                openDrawer={DrawerState}
                title={'Edit Document'}
                onClose={() => onCloseDrawer()}
                width={'800'}
            >
                <Documents
                    documentData={editdocument}
                    onCloseDrawer={onCloseDrawer}
                />

            </BasicDrawer>

        </div>
    )
}

export default DocumentForm


var optionsSelect = [
    {
        value: 'admin',
        label: 'Admin',
    },
    {
        value: 'all',
        label: 'All',
    },

]