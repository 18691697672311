import React, { useState, useEffect,useRef } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload, ConfigProvider, Avatar, Row, Col } from 'antd';
// import Avatar from '../Avatar';
import { FaCamera } from 'react-icons/fa';
import camera from '../../assets/images/camera.png'
import { useForm, Controller } from 'react-hook-form';
import { Colors } from '../../config';
import { FaTrash } from 'react-icons/fa6';

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};


const UploadAvatar = ({

    drawerStates,
    drawerKeyHandler,
    onCloseDrawer,
    userDetailData,
    phoneNumberFormat,
    FormDataHandler,
    userAddLoader,
    eligibilityData,
    scheduleData,
    rolesData,
    readFile,
    setReadFile,
    img,
    setimg,
    imgValue,
    setImageValue,
    setValue
}) => {

    useEffect(() => {
        let fileReader,
            isCancel = false;

        fileReader = new FileReader();
        if (img) {
            fileReader.onload = (e) => {
                setReadFile(e?.target?.result);
            };
            fileReader.readAsDataURL(img);
        }


        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        };
    }, [img]);

    const deleteBtnRef = useRef(null);

    const fileHandler = (e) => {
        console.log(e)
        const file = e?.target.files[0];
        console.log('file img data', file)

        if (file?.size <= 307200 && ['image/png', 'image/jpeg', 'image/jpg'].includes(file?.type)) {
            setimg(file);
            setValue('propertyImgId', file)
            setImageValue({ imgSizeValidation: '', imgTypeValidation: '' })
            return
        }

        else {
            if (file?.size > 307200) {
                setImageValue({ ...imgValue, imgSizeValidation: 'Size should not exceed' })
                setimg(null)
                setValue('propertyImgId', '')
                setReadFile(null)
                return
            }
            else if (!['image/png', 'image/jpeg'].includes(file?.type) && img) {
                setImageValue({ ...imgValue, imgTypeValidation: 'Type should be match' })
                setimg(null)
                setValue('propertyImgId', '')
                setReadFile(null)
                return
            }
        }
    };

    const deleteImage = () => {
        setimg(null)
        setValue('propertyImgId', '')
        setReadFile(null)
        if (deleteBtnRef.current.value) {
            deleteBtnRef.current.value = ''
        }
    }

    return (
        <Row
            className="py-5 w-full items-center justify-center"
            // justify={'center'}
            // align={'middle'}

            style={{
                padding: "10px",
                height: "100px",
                position: "relative",

            }}
        >
            <Col style={{ width: '70px', height: '70px', borderRadius: '50%', border: '1px solid #F9F9FA', background: '#F9F9FA' }} className='flex items-center justify-center relative '>
                <>
                    <>
                        <input
                            type="file"
                            className='z-0 cursor-pointer'
                            ref={deleteBtnRef}
                            style={{
                                borderRadius: "50%",
                                width: '100%',
                                height: '100%',
                                position: "absolute",
                                zIndex: 999,
                                opacity: 0,
                            }}

                            onChange={(e) => {
                                fileHandler(e)
                            }}
                            accept=".png, .jpg, .jpeg"
                        />
                    </>
                </>
                {/* // )}
            // /> */}
                {
                    readFile ?
                        <>
                            <Avatar className='z-10 absolute w-full h-full' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} src={readFile} />
                            <FaTrash className='absolute z-20 right-[-2px] bottom-[-10px] cursor-pointer' onClick={() => deleteImage()} size={20} color='#eeb600' />
                        </>
                        :
                        <Avatar className='z-0 absolute w-full h-full flex items-center justify-center' icon={<FaCamera />} />}
            </Col>

            {/* user image validation  */}
            <p style={{ color: Colors.Error, width: '100%' }} className='text-center'>{imgValue && imgValue.imgSizeValidation ? <p>{imgValue.imgSizeValidation}</p> : imgValue && imgValue.imgTypeValidation ? <p>{imgValue.imgTypeValidation}</p> : imgValue.isRequired ? <p>{imgValue.isRequired}</p> : ''}</p>

        </Row>


    );
};

export default UploadAvatar;
