import React, { useState } from 'react';
import { Collapse, Select, Row, Col, Space } from 'antd';
import { Colors } from '../../config';
import Button from '../Button';
import RoleCheck from '../RoleCheck/RoleCheck';

import Text from '../Text';
const { Panel } = Collapse;

const CollapseBar = ({
    onChange,
    genExtra,
    text,
    items,
    label,
    data,
    ...props

}) => {


    return (
        <>
            <Row className='w-full'>
                {data && data?.map(item => (
                    <Collapse
                        accordion
                        className='mb-4 w-full'
                        expandIconPosition='end'
                        size="large"
                        key={item?.id}
                    >
                        <Panel
                            className=''
                            key={item.id}
                            header={
                                <Row gutter={[16, 16]} className='rounded-lg w-full'  >
                                    <Col span={4} lg={4} md={8} sm={24} xs={24}>
                                        <Text.Label
                                            style={{ color: Colors.LabelColor }}
                                            text={'Checkpoint Name'}
                                        />
                                        <p className='text-xs'>{item?.name}</p>
                                    </Col>
                                    <Col span={5} lg={5} md={8} sm={24} xs={24}>
                                        <Text.Label
                                            style={{ color: Colors.LabelColor }}
                                            text={'Checkpoint Location'}
                                        />
                                        <p className='text-xs break-all'>{item?.location}</p>
                                    </Col>
                                    <Col span={3} lg={3} md={8} sm={24} xs={24}>
                                        <Text.Label
                                            style={{ color: Colors.LabelColor }}
                                            text={'QR/NFC Code Value'}
                                        />
                                        <p className='text-xs'>{item?.code}</p>
                                    </Col>

                                    <Col span={8} lg={8} md={24} sm={24} xs={24}>
                                        <Text.Label
                                            style={{ color: Colors.LabelColor }}
                                            text={'Property Address'}
                                        />
                                        <p className='text-xs break-all'>{`${item?.address?.streetNumber}, ${item?.address?.streetName}, ${item?.address?.city} ${item?.address?.zip}`}</p>
                                    </Col>

                                    <RoleCheck
                                        children={
                                            <Col span={4} lg={4} md={24} sm={24} xs={24} className='flex justify-start md:justify-end'>
                                                <Button.Basic
                                                    className="w-full px-6 h-10 yellow-bg border-none text-white border-transparent"
                                                    text={"Edit"}
                                                    onClick={() => onChange(item)}
                                                />
                                            </Col>
                                        }
                                        task={'Edit'}
                                    />

                                    <br />

                                </Row>
                            }
                        >
                            <Row gutter={[16, 16]} className='mb-6 mx-2 bg-white rounded-lg'>
                                <Col span={24} className='rounded-lg'>
                                    <Row className='' gutter={[16, 16]}>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'Checkpoint Unit'}
                                            />
                                            <p className='text-xs'>{item?.unit}</p>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'Checkpoint Incident Type'}
                                            />
                                            <p className='text-xs break-all'>{item?.incidentType?.name}</p>
                                        </Col>
                                        {/* <Col lg={8} md={24} sm={24} xs={24}>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'Auto Create Issue Type'}
                                            />
                                            <p className='text-xs'>{'-'}</p>
                                        </Col> */}

                                        <Col lg={24} md={24} sm={24} xs={24}>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'Report Notes'}
                                            />
                                            <p className='text-xs break-all'>{item?.reportNotes}</p>
                                        </Col>

                                        <Col lg={24} md={24} sm={24} xs={24}>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'Officer Notes'}
                                            />
                                            <p className='text-xs break-all'>{item?.officerNotes}</p>
                                        </Col>
                                    </Row>
                                    <br />
                                </Col>
                            </Row>
                        </Panel>

                    </Collapse>
                ))}
            </Row>
        </>
    );
};

export default CollapseBar;



{/* <Col lg={5}>
<Text.Label
    style={{ color: Colors.LabelColor }}
    text={'Property Address'}
/>
<p className='text-xs'>{`${item?.address?.streetNumber} - ${item?.address?.streetName} - ${item?.address?.streetType} - ${item?.address?.addressType} - ${item?.address?.city}`}</p>
</Col> */}