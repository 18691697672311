import React, { useEffect } from 'react'
import BasicDrawer from '../../components/Drawer'
import { InviteClient } from '../../config/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { Col, Row } from 'antd';
import LabelWithField from '../../components/LabelWithField';
import { ApiCaller, Colors, Utils } from '../../config';
import LabelWithSelect from '../../components/LabelWithSelect';
import { Button } from '../../components';
import { useSelector, useDispatch } from 'react-redux'
import { ClientManagementAction, SiteManagementAction } from '../../store/actions';
import { useMutation } from 'react-query'
import { phoneNumberFormat } from '../../config/util/PhoneNumberFormatter';

const InviteClients = ({
    openDrawer,
    onCloseDrawer,
}) => {

    const dispatch = useDispatch();

    // properties/companies listing action.
    useEffect(() => {
        dispatch(SiteManagementAction.GetProperties())
    }, [])

    const companiesOption = useSelector(state => state?.SiteManagementReducer?.getProperties || []);

    // Form related Working
    let schema = InviteClient;
    const { handleSubmit, control, reset } = useForm(
        {
            resolver: yupResolver(schema),
            mode: 'onChange'
        }
    )

    const inviteClientMutation = useMutation(async (data) => {
        let res = await ApiCaller.Post('/client', data);
        return res
    },
        {
            onSuccess: (res) => {
                Utils.showMessage('success', res?.data?.message)
                dispatch(ClientManagementAction.GetInvitedClients({ current: 1, limit: 10, searchVal: '', filterStatus: '' }));
                onCloseDrawer()
            },
            onError: (err) => {
                Utils.showMessage('error',err?.response?.data?.message)
            }
        }
    )

    const FormDataHandler = (data) => {
        inviteClientMutation.mutate(data)
    }

    // just reseting the fields to their default values after closing of drawer
    const afterCloseDrawer = () => {
        reset()
    }

    return (
        <BasicDrawer
            openDrawer={openDrawer}
            title={'Invite Clients'}
            onClose={onCloseDrawer}
            afterOpenChange={afterCloseDrawer}
            width={'600'}
        >
            <Row className='w-full' style={{}}>

                <form className='w-full'>
                    <Row className='w-full flex justify-between' >
                        <Row className='w-full flex items-center justify-between'>
                            <Col span={12}>
                                <Controller
                                    control={control}
                                    name='firstName'
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField labeltext={'First Name'} field={field} type={'text'} labelClass={'font-normal'} inputClass={'outline-none h-10 rounded'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>

                            <Col span={11}>
                                <Controller
                                    control={control}
                                    name='lastName'
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField labeltext={'Last Name'} field={field} type={'email'} labelClass={'font-normal'} inputClass={'outline-none h-10 rounded'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>

                        </Row>


                        <Row className='w-full flex items-center justify-between'>
                            <Col span={12}>
                                <Controller
                                    control={control}
                                    name='userName'
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField labeltext={'User Name'} field={field} type={'text'} labelClass={'font-normal'} inputClass={'outline-none h-10 rounded'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>

                            <Col span={11}>
                                <Controller
                                    control={control}
                                    name='email'
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField labeltext={'Email'} field={field} type={'email'} labelClass={'font-normal'} inputClass={'outline-none h-10 rounded'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>

                        </Row>



                        <Row className='w-full flex items-center justify-between mb-10'>
                            <Col span={12}>
                                <Controller
                                    control={control}
                                    name='phoneNo'
                                    defaultValue={''}
                                    render={({ field: { value, onChange }, fieldState }) => (
                                        <>
                                            <div className="flex flex-col py-4 w-full">
                                                <label
                                                    className={'font-normal'}
                                                    style={{ color: Colors.LabelColor }}
                                                >
                                                    {'Phone Number'}
                                                </label>
                                                <input type={'string'}
                                                    value={value}
                                                    onChange={onChange}
                                                    onBlur={(e) => {
                                                        const formattedNumber = phoneNumberFormat(e.target.value);
                                                        if (formattedNumber?.length === 14) {
                                                            onChange(formattedNumber)
                                                        }
                                                    }}
                                                    className={`pl-2.5 outline-none h-10 rounded`}
                                                    style={{ background: Colors.LightGray }}
                                                />
                                                {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                            </div>
                                        </>
                                    )}
                                />
                            </Col>
                            <Col span={11}>
                                <Controller
                                    control={control}
                                    name='companyId'
                                    defaultValue=""
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithSelect
                                                options={companiesOption && companiesOption}
                                                labeltext={'Management Company'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'outline-none h-10 rounded'}
                                            />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}

                                        </>
                                    )}
                                />
                            </Col>
                        </Row>
                    </Row>

                    <Row className='w-full flex justify-end'>
                        <Button.Basic
                            type={'submit'}
                            onClick={handleSubmit((data) => FormDataHandler(data))}
                            className="h-12 yellow-bg border-none text-white border-transparent"
                            text={"Send Invite"}
                            loader={inviteClientMutation?.isLoading}
                        >
                        </Button.Basic>
                        {/* <input type='submit' style={{ background: Colors.Yellow, color: Colors.White }} className={'cursor-pointer my-2 mx-1 h-10 rounded-none px-8'} value={drawerStates.drawerkey == 1 ? 'Update User' : 'Invite Clients'} /> */}
                    </Row>

                </form>

            </Row>

        </BasicDrawer>

    )
}

export default InviteClients