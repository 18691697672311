import React from 'react'
import { Dropdown, message, Space, ConfigProvider } from 'antd';


// items should be array of obj with {key,label}.
// dropdown by default open on hover or you can set the trigger as well.

const DropDown = ({ items, onClick, trigger, placement, labelStyle,children }) => (
    <ConfigProvider
        theme={{
            token: {
                /* here is your global tokens */
                // colorText:'red'
                colorTextLightSolid: "black",
            },
        }}
    >
        <Dropdown
            menu={{
                items: items,
                onClick: onClick,
            }}
            trigger={trigger && ['click']}
            placement={placement}

        >
            <a onClick={(e) => e.preventDefault()}>
                <Space style={labelStyle}>
                    {children ? children : '...' } 
                </Space>
            </a>
        </Dropdown>
    </ConfigProvider>
);
export default DropDown;