import { Image } from 'antd'
import React,{useRef} from 'react'

const VideoPlayer = ({videoSrc, posterSrc}) => {
    const videoRef = useRef();

    const videoPlayHandler = (visible) => {
        if(!visible){
            videoRef.current.pause();
        }
    }

    return (
        <Image
            height='100%'
            width='100%'
            preview={{
                imageRender: () => (
                    <video
                        className='w-5/6 md:w-1/2'
                        controls
                        ref={videoRef}
                        src={videoSrc}
                        disablePictureInPicture={true} />
                ),
                onVisibleChange: (visible) => videoPlayHandler(visible),
                toolbarRender: () => null,
            }}
            src={posterSrc}
        />
    )
}

export default VideoPlayer