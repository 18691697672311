
import { GET_POTENTIAL_TAKERS,GET_POTENTIAL_TAKERS_FAILURE, GET_POTENTIAL_TAKERS_SUCCESS, GET_SHIFTS, GET_SHIFTS_FAILURE, GET_SHIFTS_SUCCESS } from "../constants";

export default class ShiftSchedulerAction{
    static GetPotentialTakers(payload,cb){
        return{
            type: GET_POTENTIAL_TAKERS,
            payload,
            cb
        }
    }

    static GetPotentialTakersSuccess(payload){
        return{
            type: GET_POTENTIAL_TAKERS_SUCCESS,
            payload,
        }
    }

    static GetPotentialTakersFailure(){
        return{
            type: GET_POTENTIAL_TAKERS_FAILURE,
        }
    }

    static GetShifts(payload,cb) {
        return {
            type: GET_SHIFTS,
            payload,
            cb
        }
    }

    static GetShiftsSuccess(payload) {
        return {
            type: GET_SHIFTS_SUCCESS,
            payload,
            
        }
    }

    static GetShiftsFailure() {
        return {
            type: GET_SHIFTS_FAILURE,
         
        }
    }
}