import { Col, Row } from 'antd'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import Button from '../Button'
import LabelWithField from '../LabelWithField'
import LabelWithSelect from '../LabelWithSelect'
import { UseDispatch, useDispatch, useSelector } from 'react-redux'
import { SiteManagementAction } from '../../store/actions'
import { yupResolver } from '@hookform/resolvers/yup'
import { AddIncident } from '../../config/Schema'
import { Colors } from '../../config'


const IncidentAdd = ({ closeDrawerHandler,searchVal }) => {

    const schema = AddIncident;
    const { handleSubmit, control, reset } = useForm({
        resolver: yupResolver(schema)
    })

    const dispatch = useDispatch();
    const incidentAddLoader = useSelector(state => state?.SiteManagementReducer?.loader);

    const handleSubmitHandler = (data) => {
        dispatch(SiteManagementAction.AddIncident(data, () => {
            dispatch(SiteManagementAction.GetIncidentTypes(searchVal));
            reset()
            closeDrawerHandler()
        }))
        console.log(data);
    }

    return (
        <Row className=''>
            <form layout="vertical" className='w-full'>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            name='name'
                            defaultValue={''}
                            render={({ field, fieldState }) => (
                                <>
                                    <LabelWithField
                                        labeltext={'Incident Type Name'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'h-10 rounded outline-none'} />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>
                            )}
                        />
                    </Col>
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            name='description'
                            defaultValue={''}
                            render={({ field, fieldState }) => (
                                <LabelWithField
                                    labeltext={'Description'}
                                    field={field}
                                    labelClass={'font-normal'}
                                    inputClass={'h-10 rounded outline-none'} />
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    {/* <Col lg={12} xs={24}>

                        <Controller
                            control={control}
                            name='streetType'
                            defaultValue={''}
                            render={({ field, fieldState }) => (
                                <LabelWithSelect
                                    options={'optionsSelect'}
                                    labeltext={'Add To'}
                                    field={field}
                                    labelClass={'font-normal'}
                                    inputClass={'outline-none h-10 rounded'}
                                />

                            )}
                        />
                    </Col> */}
                    <Col lg={24} xs={24}>
                        <Controller
                            control={control}
                            name='level'
                            defaultValue={''}
                            render={({ field, fieldState }) => (
                                <>
                                    <LabelWithSelect
                                        options={levelOptions}
                                        labeltext={'Level'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'outline-none h-10 rounded'}
                                    />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>
                            )}

                        />
                    </Col>
                </Row>

                <Row gutter={16} className='justify-end my-4'>
                    <Col lg={24} className='flex justify-end'  >
                        <Button.Basic
                            onClick={handleSubmit(handleSubmitHandler)}
                            style={{ width: "10vw" }}
                            htmlType="submit"
                            className=" h-12 yellow-bg border-none text-white border-transparent"
                            text={"Submit"}
                            loader={incidentAddLoader}
                        />
                    </Col>
                </Row>


            </form>
        </Row>
    )
}

const levelOptions = [
    {
        label: 'High',
        value: 'high'
    },
    {
        label: 'Medium',
        value: 'medium'
    },
    {
        label: 'Low',
        value: 'low'
    },
]

export default IncidentAdd