import React from 'react';
import { Badge, Calendar } from 'antd';


const Calender = ({cellRender,headerRender}) => {

    // const getListData = (value) => {
    //     let listData;
    //     console.log(value)
    //     console.log(value.date())
    //     if(value.$D == 8 && value.$M == 0){
    //         listData = [
    //             {
    //                 type: 'warning',
    //                 content: 'This is warning event.',
    //             },
    //             {
    //                 type: 'success',
    //                 content: 'This is usual event.',
    //             },
    //         ];
    //     }
    //     // switch (value.date()) {
    //     //     case 8:
    //     //         listData = [
    //     //             {
    //     //                 type: 'warning',
    //     //                 content: 'This is warning event.',
    //     //             },
    //     //             {
    //     //                 type: 'success',
    //     //                 content: 'This is usual event.',
    //     //             },
    //     //         ];
    //     //         break;
         
    //     //     default:
    //     // }
    //     return listData || [];
    // };

    // const daterender = (value) => {
    //     const listData = getListData(value);
    //     return (
    //         <ul className="events">
    //             {listData.map((item) => (
    //                 <li key={item.content}>
    //                     <Badge status={item.type} text={item.content} />
    //                 </li>
    //             ))}
    //         </ul>
    //     );
    // };

    // const customizeHeader = (e) => {
    //     console.log(e)
    //     return(
    //         <div>
    //         </div>
    //     )
    // }

    // const cellRender = (current, info) => {
    //     console.log('current info')
    //     console.log(info.originNode)
    //     console.log(current.$D)
    //     if (info.type === 'date') return daterender(current);
    //     // return info.originNode;
    // };

    return <Calendar cellRender={cellRender} headerRender={headerRender}  />;
};

export default Calender