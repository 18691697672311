import React from "react";
import { Colors } from "../../config";
import { Input } from 'antd';


const LabelWithFieldIcon = ({ labelClass, inputClass, type, placeholder, labeltext, value, onChange, prop, suffix, prefix }) => {
    return (
        <div className="flex flex-col py-4 w-full">
            <label
                className={labelClass}
                style={{ color: Colors.LabelColor }}
            >
                {labeltext}
            </label>
            <Input type={type}
                suffix={suffix}
                prefix={prefix}
                value={value}
                onChange={onChange}
                {...prop}
                className={`outline-none ${inputClass}`}
                style={{ background: Colors.LightGray }}
                placeholder={placeholder}
            />
        </div>
    )
}

export default LabelWithFieldIcon

