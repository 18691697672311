import { Popover, Steps, Col, Row, ConfigProvider } from 'antd';
import React, { useState,useEffect } from 'react'
import AddressForm from './address';
import ClientForm from './client';
import DocumentForm from './document';
import Location from './location';
import IncidentType from './incident';
import CheckPoint from './checkpoint';
import MapLocation from './maplocation';
import PhoneNumber from './phoneNumber';
import { useForm } from 'react-hook-form';
import { SiteManagementAction } from '../../store/actions';

const customDot = (dot, { status, index }) => (
    <Popover
        content={
            <span>
            </span>
        }
    >
    </Popover>
);
const description = '';


const StepForm = () => {

    const [state, setState] = useState(1)
    const previousStep = () => {
        setState((previousstate) => previousstate - 1)

    }
    const nextStep = () => {
        setState((previousstate) => previousstate + 1)
    }

    return (
        <div>
            <Row className='m-2' >
                <Col span={24}>
                    <h1 className='text-xl font-semibold'>Site Management</h1>
                </Col>

            </Row >
            <Row className='bg-white pt-6 rounded-lg '>
                <Col span={24}>
                    <Row className='mb-6 px-2 md:px-10'>
                        <Col span={24} className='flex flex-wrap items-start gap-1 sm:gap-2'>
                            <span className=' mb-4 font-bold text-xl'>
                                Add New Property
                            </span>
                            <span className='font-bold mt-1' style={{ color: '#4E5D78' }} >
                                {
                                    state == 1 ? '' :
                                        state == 2 ? ' - Add Address (es)' :
                                            state == 3 ? ' - Add Documents' :
                                                state == 4 ? ' - Add Location' :
                                                    state == 5 ? ' - Incident Types' :
                                                        state == 6 ? ' - Add Check Points' :
                                                            state == 7 ? ' - Add Geo Fence' :
                                                                state == 8 ? ' - Phone Numbers' :
                                                                    ''
                                }
                            </span>
                        </Col>
                    </Row>

                    <Col lg={24} className='w-full mx-auto flex items-center justify-center'>
                        <ConfigProvider theme={{
                            token: {
                                colorPrimary: "#EEB600"
                            },

                        }}>
                            <Steps
                                className='mb-6 justify-center custom-step'
                                size='small'
                                current={state}
                                progressDot={customDot}
                                responsive='false'
                                style={{ width: '100%' }}
                                items={[
                                    {
                                        // title: 'Finished',
                                        description,
                                    },
                                    {
                                        // title: 'Finished',
                                        description,
                                    },
                                    {
                                        // title: 'In Progress',
                                        description,
                                    },
                                    {
                                        // title: 'Waiting',
                                        description,
                                    },
                                    {
                                        // title: 'Waiting',
                                        description,
                                    },
                                    {
                                        // title: 'Finished',
                                        description,
                                    },
                                    {
                                        // title: 'In Progress',
                                        description,
                                    },
                                    {
                                        // title: 'Waiting',
                                        description,
                                    },
                                    {
                                        // title: 'Waiting',
                                        description,
                                    },

                                ]}
                            />
                        </ConfigProvider>

                    </Col>
                    <div className={state === 1 ? 'show' : 'hideshow'}>
                        <ClientForm nextStep={nextStep} previousStep={previousStep} currentStep={state} />
                    </div>

                    <div className={state === 2 ? 'show' : 'hideshow'}>
                        <AddressForm nextStep={nextStep} previousStep={previousStep} currentStep={state} />
                    </div>

                    <div className={state === 3 ? 'show' : 'hideshow'}>
                        <DocumentForm nextStep={nextStep} previousStep={previousStep} currenStep={state} />
                    </div>
                    <div className={state === 4 ? 'show' : 'hideshow'}>
                        <Location nextStep={nextStep} previousStep={previousStep} currenStep={state} />
                    </div>

                    <div className={state === 5 ? 'show' : 'hideshow'}>
                        <IncidentType nextStep={nextStep} previousStep={previousStep} currenStep={state} />
                    </div>

                    <div className={state === 6 ? 'show' : 'hideshow'}>
                        <CheckPoint nextStep={nextStep} previousStep={previousStep} currenStep={state} />
                    </div>
                    <div className={state === 7 ? 'show' : 'hideshow'}>
                        <MapLocation nextStep={nextStep} previousStep={previousStep} currenStep={state} />
                    </div>
                    <div className={state === 8 ? 'show' : 'hideshow'}>
                        <PhoneNumber nextStep={nextStep} previousStep={previousStep} currenStep={state} />
                    </div>
                </Col>
            </Row>
        </div >
    )
}

export default StepForm;