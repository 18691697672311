import { Col, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { Text, TextField } from "../../components";
import { Colors, Utils } from "../../config";
import styles from "./styles";
import { UserSignin } from "../../config/Schema";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { AppAction } from "../../store/actions";
import ButtonLoader from "../../components/Button/ButtonLoader";


import login from '../../assets/images/login.png'
import siteLogo from '../../assets/images/siteLogo.png'
import { IoIosEyeOff } from "react-icons/io";
import { IoIosEye } from "react-icons/io";
import LabelWithFieldIcon from "../../components/LabelWithFieldIcon";

const SignIn = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector(state => state?.AppReducer.loader);

  const [showPassword, setShowPassword] = useState(false)

  let schema = UserSignin;
  const { handleSubmit, control, reset } = useForm(
    {
      resolver: yupResolver(schema),
    }
  )


  const signinHandler = (data) => {
    console.log(data)
    dispatch(AppAction.SignIn(data, () => {
      navigate('/')
    }))
  };


  return (

    <Row>
      <Col lg={12} md={12} sm={24} xs={24} className="h-screen" >

        <section className="h-full flex">
          <div className="flex flex-col items-center justify-center px-6 mx-auto md:h-screen lg:py-0">
            <a href="#" className="mt-3" >
              <img className="xl:w-80 md:w-80 xl:h-32 md:h-32 h-24 w-72 mr-2" src={siteLogo} alt="logo" />
            </a>
            <div className="w-full md:mt-0 sm:max-w-md xl:p-0">
              <div className="pt-3 mx-auto flex items-center flex-col justify-center" style={{}}>
                {/* <h1 className="mb-3 text-center text-2xl font-bold">
                  Southern Tier Security
                </h1> */}
                <p className="text-center text-sm mb-5" style={{ color: Colors.Gray }}>
                  Sentinel is Located in the heart of the Echanted Mountains, We are your local Guard.
                </p>

                <form className="space-y-4 md:space-y-6 w-10/12" style={{}}>
                  <div>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field, fieldState }) => {
                        return (
                          <>
                            <input
                              type={"email"}
                              placeholder={"Email Address"}
                              {...field}
                              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-3 "
                            />
                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                          </>
                        )
                      }}
                    />

                  </div>
                  <div>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field: { value, onChange }, fieldState }) => {
                        return (
                          <div className="relative">
                            <input
                              type={showPassword ? 'text': 'password'}
                              placeholder={"Password"}
                              value={value}
                              onChange={onChange}
                              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-3 pr-8"
                            />
                            {
                              showPassword ? <IoIosEye size={18} className='cursor-pointer absolute right-2.5 top-3.5' color={Colors.LabelColor} onClick={() => setShowPassword(false)} /> : <IoIosEyeOff size={18} className='cursor-pointer absolute right-2.5 top-3.5' color={Colors.LabelColor} onClick={() => setShowPassword(true)} />
                            }


                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                          </div>

                        )
                      }}
                    />
                  </div>

                  <div className="flex items-center justify-end" >
                    <a href="#" className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500" style={{ color: Colors.Yellow }} onClick={() => navigate('/forgot-password')}>Forgot password?</a>
                  </div>
                  <ButtonLoader loader={loader} btnHandler={handleSubmit(signinHandler)} text={'LOGIN'} style={{ background: "#2f3032" }} className="cursor-pointer w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 h-12 text-base tracking-wider" />

                </form>

                <p className="text-xs text-center mt-6">
                  Copyright 2024 <span style={{ color: "#f0bf1f" }}>Sentinel</span>
                </p>

              </div>
            </div>
          </div>
        </section>

      </Col>

      <Col lg={12} md={12} sm={0} xs={0} className="h-screen rounded-none md:rounded-3xl">
        <div className=" w-full h-full rounded-none md:rounded-l-3xl">
          <img src={login} className="h-full max-w-full aspect-[4/3] object-top object-cover rounded-none md:rounded-l-3xl" />
        </div>
      </Col>


    </Row>

  );
};

export default SignIn;
