import React, { useState } from 'react'
import { Layout, Menu, ConfigProvider } from 'antd'
import { Link } from 'react-router-dom';
// import logo from '../../assets/images/logo.png'
// import icon from '../../assets/images/icon.png'
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppAction } from '../../store/actions'
import ButtonLoader from "../../components/Button/ButtonLoader";

// import BasicModal from '../../components/Modal/BasicModal'

// ICONS IMPORT
import { RxDashboard } from "react-icons/rx";
import { AiFillAlert } from "react-icons/ai";
import { MdOutlineManageAccounts } from "react-icons/md";
import { FaSitemap } from "react-icons/fa6";
import { FaUserAlt } from "react-icons/fa";
import { PiUsersThreeDuotone } from "react-icons/pi";
import { FaCalendarAlt } from "react-icons/fa";
import { FaFacebookMessenger } from "react-icons/fa";
import { FaUsersRays } from "react-icons/fa6";
import { IoSettingsSharp } from "react-icons/io5";







import siteLogo from '../../assets/images/siteLogo.png'
import { IoLogOutSharp } from "react-icons/io5";
import Colors from '../../config/colors';


const GuardSideBar = () => {

    const { Sider } = Layout;
    const [collapsed, setCollapsed] = useState(false);
    const [logoutModal, setLogoutModal] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const loader = useSelector((state) => state?.AppReducer.loader);
    const currentLocation = location?.pathname;

    // console.log("Location",location.pathname)

    // console.log("Location",window.location.pathname);
    console.log("current location", currentLocation)


    const logoutModalViewer = () => {
        setLogoutModal(true);
    }


    const logOut = () => {
        const payload = {
            from_everywhere: true
        }
        dispatch(AppAction.Logout(payload, () => {
            navigate('/logout')
        }))

    }


    return (
        <>
            <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                className=''
                style={{
                    overflow: 'auto',
                    // height: '100vh',
                    // position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    zIndex: 999
                }}

                theme='light'
                breakpoint='lg'
                onCollapse={() => setCollapsed(!collapsed)}
                width={260}

            >

                <div className="demo-logo-vertical" />
                <ConfigProvider
                    theme={{
                        components: {
                            Menu: {
                                // Background color of menu item when selected
                                itemSelectedBg: '',
                                // Color of selected menu item text	
                                itemSelectedColor: '',
                                fontSize: collapsed ? 14 : 13
                            },
                        },
                    }}
                >

                    <Menu
                        theme="light"
                        mode="inline"
                        defaultSelectedKeys='1'
                        className='h-full'
                        style={{ height: '90vh', padding: '0px 3px', }}

                    >
                        {/* have to perform this inline stylng for bg and color , as on refresh of a page it will make the key = 1 and set the bg and color for dashboard whereas our current location is still pointing to where we are previously standing e.g user management */}
                        <>
                            {collapsed ?
                                <Link to="/">
                                    <img className='mt-4 mx-auto py-0 px-2 mb-2' style={{ width: '65px', height: '35px' }} src={siteLogo} />
                                </Link>
                                :
                                <Link to="/">
                                    <img style={{ width: '160px', height: '70px' }} className='mt-4 mx-auto py-0 px-2 mb-2' src={siteLogo} />
                                </Link>

                            }

                            <Menu.Item style={{ background: currentLocation === '/' ? Colors.Yellow : '', color: currentLocation === '/' ? Colors.White : Colors.MenuItem }} className="menu-item" key="1"
                                icon={
                                    <div style={{ padding: '3px', background: currentLocation === '/' ? '#2F3032' : '#EEF4FF', borderRadius: '5px' }}>
                                        <RxDashboard style={{ paddingRight: collapsed ? '0px' : '', paddingTop: collapsed ? '0px' : '' }} size={15} color={currentLocation === '/' ? Colors.White : Colors.Yellow} />
                                    </div>
                                }>
                                <Link to="/">Dashboard</Link>
                            </Menu.Item>


                        </>

                    </Menu>

                </ConfigProvider>



                <div className='flex items-center justify-center mx-5  gap-1.5 py-3 rounded-md' style={{ background: Colors.LightBlack }}>
                    <ButtonLoader icon={<IoLogOutSharp size={25} color={Colors.White} />} loader={loader} text={collapsed ? '' : 'Log Out'} btnHandler={logOut} style={{ background: "#2f3032", color: Colors.White, outline: 'none', border: 'none' }} className="flex items-center justify-center cursor-pointer text-center w-full text-base tracking-wider" />
                </div>

            </Sider>

            {/* {

                <BasicModal
                    centered={'centered'}
                    width={'300px'}
                    modalType={'basicModal'}
                    text='Are you sure, you want to logout ?'
                    descriptionStyle={{ textAlign: 'center', fontSize: '1.3rem', fontWeight: '500', margin: '10px' }}
                    isModalOpen={logoutModal}
                    isFooter={true}
                    leftButtonText='yes'
                    rightButtonText="no"
                    rightButtonStyle={{ backgroundColor: 'black', color: 'white' }}
                    leftButtonStyle={{ backgroundColor: 'green', color: 'white' }}
                    onClickLeft={logOut} onClickRight={() => setLogoutModal(false)}
                    onCancel={() => setLogoutModal(false)} />
            } */}

        </>
    )
}

export default GuardSideBar