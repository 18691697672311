import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { Button, Text, TextField } from '../../components'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Colors } from '../../config'
import BasicDrawer from '../../components/Drawer'
// import PropertyForm from '../../components/DrawerForms/property'
import AddressForm from '../../components/DrawerForms/address'
import Document from '../../components/DrawerForms/document'
import Location from '../../components/DrawerForms/location'
import Incidenttype from '../../components/DrawerForms/incidenttype'
import CheckPoint from '../../components/DrawerForms/checkpoint'
import Geofence from '../../components/DrawerForms/geofence'
import PhoneNumber from '../../components/DrawerForms/phone'
import Client from '../../components/DrawerForms/property'
import CollapseBar from '../../components/Collapse'
import { SiteManagementAction } from '../../store/actions'
import { useDispatch, useSelector } from 'react-redux';
import EmptyData from '../../components/Empty'
import { Spin } from 'antd';
import { useParams } from 'react-router-dom'
import Circular from '../../components/Loader/Circular'
import RoleCheck from '../../components/RoleCheck/RoleCheck'
import PageNotFound from '../../components/PageNotFound'

const Sitemanagementdetail = () => {

    const dispatch = useDispatch()
    const { id } = useParams();
    console.log(id)


    localStorage.setItem('PropertyKey', id)
    const propertyDetails = useSelector(state => state?.SiteManagementReducer?.propertyDetail);
    const loadingState = useSelector(state => state?.SiteManagementReducer?.detailLoader);

    const user = useSelector((state) => state.AppReducer?.user)
    const isRole = user?.data?.userRole[0]

    const [openDrawer, setOpenDrawer] = useState(false)
    const [formtype, setFormType] = useState("")
    const [title, setFormTitle] = useState("")
    const [detailData, setDetailData] = useState({})

    useEffect(() => {
        dispatch(SiteManagementAction.GetPropertyDetail(id))

    }, [id])


    const onCloseDrawer = () => {
        setOpenDrawer(false)
        setFormType("")
        setFormTitle("")
    }

    const handleInputChange = () => { }

    const propertyEditDetail = (property) => {
        setOpenDrawer(true);
        setFormType('Property');
        setFormTitle("Edit")
        setDetailData(property)
    }

    const addAddress = () => {
        setOpenDrawer(true);
        setFormType('Address');
        setFormTitle('Add')
        setDetailData({})
    }

    const addressEditDetail = (address) => {
        setOpenDrawer(true);
        setFormType('Address');
        setFormTitle('Edit');
        setDetailData(address)
    }

    const addDocument = () => {
        setOpenDrawer(true);
        setFormType('Document');
        setFormTitle('Add');
        setDetailData({})
    }

    const documentEditDetail = (document) => {
        setOpenDrawer(true);
        setFormType('Document');
        setFormTitle('Edit')
        setDetailData(document)
    }

    const addLocation = () => {
        setOpenDrawer(true);
        setFormType('Location');
        setFormTitle('Add')
        setDetailData({})
    }

    const locationEditDetail = (location) => {
        setOpenDrawer(true);
        setFormType('Location');
        setFormTitle('Edit');
        setDetailData(location)
    }

    // const addIncident = () => {
    //     setOpenDrawer(true);
    //     setFormType('Add Incident Types');
    //     setFormTitle('Add')
    // }

    const incidentEditDetail = (incident) => {
        setOpenDrawer(true);
        setFormType('Incident Types');
        setFormTitle('Edit')
        setDetailData(incident)
    }

    const addCheckpoints = () => {
        setOpenDrawer(true);
        setFormType('CheckPoints');
        setFormTitle('Add')
        setDetailData({})
    }

    const checkpointEditDetail = (checkpoint) => {
        console.log(checkpoint)
        setOpenDrawer(true)
        setFormType('CheckPoints');
        setFormTitle('Edit')
        setDetailData(checkpoint)
    }

    const addGeofence = () => {
        setOpenDrawer(true);
        setFormType('Property Geofence');
        setFormTitle('Add')
        setDetailData({})
    }

    const editGeofenceDetail = (geofence) => {
        setOpenDrawer(true);
        setFormType('Property Geofence');
        setFormTitle('Edit')
        setDetailData(geofence)
    }

    const editPhoneNumber = (contact) => {
        setOpenDrawer(true);
        setFormType('Phone Number');
        setFormTitle('Edit')
        setDetailData(contact)
    }


    return (
        <>
            {
                !loadingState && propertyDetails?.length !== 0 ?
                    <>
                        <Row gutter={[16, 32]} className='mb-10'>

                            <Col span={24}>
                                <h3 className='text-xl font-bold'>Site Management</h3>
                            </Col>

                        </Row>

                        {/* // property Edit Details */}
                        <Row gutter={[16, 16]} className='mb-6 p-4 mx-2 bg-white rounded-lg flex items-center'>

                            <Col lg={18} md={18} sm={18} xs={24} className=''>
                                <h3 className='font-semibold text-base'>Property Details</h3>
                            </Col>


                            <RoleCheck
                                children={
                                    <Col lg={6} md={6} sm={6} xs={24} className='flex justify-end'>
                                        <Button.Basic
                                            buttonStyle={{ backgroundColor: "#eeb600" }}
                                            className="w-full px-6 h-10 yellow-bg border-none text-white border-transparent"
                                            text={"Edit"}
                                            onClick={() => propertyEditDetail(propertyDetails)}
                                        />
                                    </Col>
                                }
                                task={'Edit'}
                            />

                            <Col span={24}>
                                <hr />
                            </Col>


                            <Col span={24} className='rounded-lg'>
                                <Row className='' gutter={[16, 16]}>
                                    <Col lg={8} md={8} sm={24} xs={24}>
                                        <Text.Label
                                            style={{ color: Colors.LabelColor }}
                                            text={'Property Name'}
                                        />
                                        <p className='text-xs'>{propertyDetails?.name}</p>
                                    </Col>
                                    <Col lg={9} md={9} sm={24} xs={24}>
                                        <Text.Label
                                            style={{ color: Colors.LabelColor }}
                                            text={'Client'}
                                        />
                                        <p className='text-xs'>{propertyDetails?.company && propertyDetails?.company[0]?.name}</p>
                                    </Col>
                                    <Col lg={5} md={5} sm={24} xs={24}>
                                        <Text.Label
                                            style={{ color: Colors.LabelColor }}
                                            text={'Property Code'}
                                        />
                                        <p className='text-xs'>{propertyDetails?.code}</p>
                                    </Col>

                                    <Col lg={24} md={24} sm={24} xs={24}>
                                        <Text.Label
                                            style={{ color: Colors.LabelColor }}
                                            text={'Schedule'}
                                        />
                                        <p className='text-xs break-all'>{propertyDetails?.schedules.length > 0 ? propertyDetails?.schedules[0]?.name : '-'}</p>
                                    </Col>

                                    {/* <Col lg={24} md={24} sm={24} xs={24}>
                                        <Text.Label
                                            style={{ color: Colors.LabelColor }}
                                            text={'Property Notes'}
                                        />
                                        <p className='text-xs break-all'>{propertyDetails?.note}</p>
                                    </Col> */}

                                    <br />
                                </Row>
                            </Col>

                        </Row>

                        {/* Address Edit Detail */}
                        <Row gutter={[16, 16]} className='mb-6 p-4 mx-2 bg-white rounded-lg flex items-center'>

                            <Col lg={18} md={18} sm={18} xs={24} className=''>
                                <h3 className='text-lg font-semibold text-base'>Address</h3>
                            </Col>

                            <RoleCheck
                                children={
                                    <Col lg={6} md={6} sm={6} xs={24} className='text-end flex justify-end'>
                                        <h3 style={{ color: Colors.AddMore }}
                                            onClick={() => addAddress()}
                                            className='hover:underline cursor-pointer font-semibold text-lg w-fit self-end'>
                                            + Add More
                                        </h3>
                                    </Col>
                                }
                                task={'Add'}
                            />

                            <Col span={24}>
                                <hr />
                            </Col>

                            {propertyDetails && propertyDetails?.addresses?.map((item) => {
                                return <Col span={24} className='  rounded-lg'>
                                    <Row className='' gutter={[16, 16]}>
                                        <Col lg={10} md={11} sm={24} xs={24}>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'Address'}
                                            />
                                            <p className='text-xs break-all'>{`${item?.streetNumber}, ${item?.streetName}, ${item?.city} ${item?.zip}`}</p>
                                        </Col>
                                        <Col lg={4} md={3} sm={24} xs={24}>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'Building Number'}
                                            />
                                            <p className='text-xs'>{item?.buildingNumber}</p>
                                        </Col>
                                        <Col lg={3} md={3} sm={24} xs={24}>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'GPS Longitude'}
                                            />
                                            <p className='text-xs break-all'>{item?.longitude}</p>
                                        </Col>
                                        <Col lg={3} md={3} sm={24} xs={24}>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'GPS Latittude'}
                                            />
                                            <p className='text-xs break-all'>{item?.latitude}</p>
                                        </Col>

                                        <RoleCheck
                                            children={
                                                <Col lg={4} md={4} sm={24} xs={24} className='flex justify-end'>
                                                    <Button.Basic
                                                        className="w-full px-6 h-10 yellow-bg border-none text-white border-transparent"
                                                        text={"Edit"}
                                                        onClick={() => addressEditDetail(item)}
                                                    />
                                                </Col>
                                            }
                                            task={'Edit'}
                                        />
                                    </Row>
                                    <br />

                                    <Row className='px-2' gutter={[16, 16]}>
                                    </Row>
                                    <br />
                                    <Row className='px-2' gutter={16}>
                                        <Col span={24}>
                                            <hr />
                                        </Col>
                                    </Row>

                                </Col>
                            })}
                            {
                                propertyDetails && propertyDetails?.addresses?.length == 0 ?
                                    <Col span={24} className='flex justify-center'>
                                        <EmptyData />
                                    </Col>
                                    : ''
                            }
                        </Row>

                        {/* Document Edit Detail */}
                        <Row gutter={[16, 16]} className='mb-6 p-4 mx-2 bg-white rounded-lg flex items-center'>
                            <Col lg={18} md={18} sm={18} xs={24} className=''>
                                <h3 className='text-lg font-semibold text-base'>Documents</h3>
                            </Col>

                            <RoleCheck
                                children={
                                    <Col lg={6} md={6} sm={6} xs={24} className='text-end flex justify-end'>
                                        <h3 style={{ color: Colors.AddMore }}
                                            onClick={() => addDocument()}
                                            className='hover:underline cursor-pointer font-semibold text-lg w-fit self-end'>
                                            + Add More
                                        </h3>
                                    </Col>
                                }
                                task={'Add'}
                            />
                            <Col span={24}>
                                <hr />

                            </Col>
                            {propertyDetails && propertyDetails?.documents?.map((item) => {
                                return <Col span={24} className='rounded-lg'>
                                    <Row gutter={16} className=''>
                                        <Col lg={8} md={10} sm={24} xs={24} className='mb-2 md:mb-0'>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'File'}
                                            />
                                            <a href={item.media && item.media[0]?.url} target='_blank'>
                                                <p className='text-xs break-all' style={{ color: '#8DB1FD' }}>media</p>
                                            </a>
                                        </Col>
                                        <Col lg={8} md={6} sm={24} xs={24} className='mb-2 md:mb-0'>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'Document Name'}
                                            />
                                            <p className='text-xs'>{item?.name}</p>
                                        </Col>
                                        <Col lg={4} md={4} sm={24} xs={24} className='mb-2 md:mb-0'>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'Acessibility'}
                                            />
                                            <p className='text-xs'>{item?.accessibility}</p>
                                        </Col>
                                        <RoleCheck
                                            children={
                                                <Col lg={4} md={4} sm={24} xs={24} className='flex justify-end'>
                                                    <Button.Basic
                                                        className="w-full px-6 h-10 yellow-bg border-none text-white border-transparent "
                                                        text={"Edit"}
                                                        onClick={() => documentEditDetail(item)}
                                                    />
                                                </Col>
                                            }
                                            task={'Edit'}
                                        />
                                    </Row>
                                    <br />
                                    <Row className=''>
                                        <Col lg={24}>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'Description'}
                                            />
                                            <p className='text-xs break-all'>{item?.description}</p>
                                        </Col>

                                    </Row>

                                    <Row className='px-2 my-4' gutter={16}>
                                        <Col span={24}>
                                            <hr />
                                        </Col>
                                    </Row>
                                </Col>


                            })}
                            <br />

                            {
                                propertyDetails && propertyDetails?.documents?.length == 0 ?
                                    <Col span={24} className='flex justify-center'>
                                        <EmptyData />
                                    </Col>
                                    : ''
                            }


                        </Row>

                        {/* Location Edit Detail */}
                        <Row gutter={[16, 16]} className='mb-6 p-4 mx-2 bg-white rounded-lg flex items-center'>
                            <Col lg={18} md={18} sm={18} xs={24} className=''>
                                <h3 className='text-lg font-semibold text-base'>Locations</h3>
                            </Col>
                            <RoleCheck
                                children={
                                    <Col lg={6} md={6} sm={6} xs={24} className='text-end flex justify-end'>
                                        <h3 style={{ color: Colors.AddMore }}
                                            onClick={() => addLocation()}
                                            className='hover:underline cursor-pointer font-semibold text-lg w-fit self-end'>
                                            + Add More
                                        </h3>
                                    </Col>
                                }
                                task={'Add'}
                            />
                            <Col span={24}>
                                <hr />

                            </Col>
                            {propertyDetails && propertyDetails?.locations?.map((item) => {
                                return <Col span={24} className='  rounded-lg'>
                                    <Row className=''>
                                        <Col lg={8} md={8} sm={24} xs={24} className='mb-2 md:mb-0'>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'Location Name'}
                                            />
                                            <p className='text-xs'>{item?.name}</p>
                                        </Col>
                                        <Col lg={12} md={12} sm={24} xs={24} className='mb-2 md:mb-0'>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'Assign to'}
                                            />
                                            <p className='text-xs break-all'>{
                                                item?.address?.map(address => {
                                                    return <>{`${address?.addressType} - ${address?.streetType} - ${address?.buildingNumber}`} <br /></>
                                                })
                                            }
                                            </p>
                                        </Col>

                                        <RoleCheck
                                            children={
                                                <Col lg={4} md={4} sm={24} xs={24} className='flex justify-end'>
                                                    <Button.Basic
                                                        // onClick={() => }
                                                        className="w-full px-6 h-10 yellow-bg border-none text-white border-transparent"
                                                        text={"Edit"}
                                                        onClick={() => locationEditDetail(item)}
                                                    />
                                                </Col>
                                            }
                                            task={'Edit'}
                                        />
                                    </Row>
                                    <Row className='my-2'>
                                        <Col lg={24} md={24} sm={24} xs={24} className='mb-2 md:mb-0'>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'Description'}
                                            />
                                            <p className='text-xs break-all'>{item?.description}</p>
                                        </Col>
                                    </Row>
                                    <Col span={24} className='mt-4'>
                                        <hr />
                                    </Col>
                                    <br />
                                </Col>

                            })}

                            {
                                propertyDetails && propertyDetails?.locations?.length == 0 ?
                                    <Col span={24} className='flex justify-center'>
                                        <EmptyData />
                                    </Col>
                                    : ''
                            }


                        </Row>

                        {/* Incident Edit Detail */}
                        <Row gutter={[16, 16]} className='mb-6 p-4 mx-2 bg-white rounded-lg flex items-center'>
                            <Col lg={18} md={18} sm={18} xs={24} className=''>
                                <h3 className='text-lg font-semibold text-base'>Incident Types</h3>
                            </Col>


                            <RoleCheck
                                children={
                                    <Col lg={6} md={6} sm={6} xs={24} className='flex items-center justify-end'>
                                        <Button.Basic
                                            className="h-10 px-6 yellow-bg border-none text-white border-transparent px-6"
                                            text={propertyDetails?.incidentTypes?.length > 0 ? "Edit" : 'Add'}
                                            onClick={() => incidentEditDetail(propertyDetails?.incidentTypes)}
                                        />
                                    </Col>
                                }
                                task={'Edit'}
                            />


                            <Col span={24}>
                                <hr />

                            </Col>

                            <Col span={24} className='p-6 rounded-lg '>
                                <Row className=''>
                                    {propertyDetails && propertyDetails?.incidentTypes?.map((item, i) => {
                                        return <>
                                            <Col lg={4} md={6} sm={8} xs={24} className='mr-2 mb-2'>
                                                <p className='text-xs'>{`${i + 1} - ${item?.name}`}</p>
                                            </Col>
                                        </>
                                    })}

                                </Row>
                            </Col>
                            {
                                propertyDetails && propertyDetails?.incidentTypes?.length == 0 ?
                                    <Col span={24} className='flex justify-center'>
                                        <EmptyData />
                                    </Col>
                                    : ''
                            }
                        </Row>

                        {/* Checkpoints Edit Detail */}
                        <Row gutter={[16, 16]} className='mb-6 p-4 mx-2 bg-white rounded-lg flex items-center'>
                            <Col lg={18} md={18} sm={18} xs={24} className=''>
                                <h3 className='text-lg font-semibold text-base'>Check Points</h3>
                            </Col>
                            <RoleCheck
                                children={
                                    <Col lg={6} md={6} sm={6} xs={24} className='flex items-center justify-end'>
                                        <h3 style={{ color: Colors.AddMore }}
                                            onClick={() => addCheckpoints()}
                                            className='hover:underline cursor-pointer font-semibold text-lg w-fit self-end'>
                                            + Add More
                                        </h3>
                                    </Col>
                                }
                                task={'Add'}
                            />

                            <Col span={24}>
                                <hr />
                            </Col>

                            <Col lg={24} className=''>
                                <CollapseBar
                                    data={propertyDetails?.checkPoints}
                                    onChange={checkpointEditDetail}
                                />
                            </Col>

                            {
                                propertyDetails && propertyDetails?.checkPoints?.length == 0 ?
                                    <Col span={24} className='flex justify-center'>
                                        <EmptyData />
                                    </Col>
                                    : ''
                            }

                        </Row>

                        {/* Geofence Edit Detail */}
                        <Row gutter={[16, 16]} className='mb-6 p-4 mx-2 bg-white rounded-lg flex items-center'>
                            <Col lg={18} md={18} sm={18} xs={24} className=''>
                                <h3 className='text-lg font-semibold text-base'>Property Geofence</h3>
                            </Col>
                            <RoleCheck
                                children={
                                    <Col lg={6} md={6} sm={6} xs={24} className='text-end flex justify-end'>
                                        <h3 style={{ color: Colors.AddMore }}
                                            onClick={() => addGeofence()}
                                            className='hover:underline cursor-pointer font-semibold text-lg w-fit self-end'>
                                            + Add More
                                        </h3>
                                    </Col>
                                }
                                task={'Add'}
                            />
                            <Col span={24}>
                                <hr />

                            </Col>

                            {propertyDetails && propertyDetails?.geofences?.map((item) => {
                                return <Col span={24} className='rounded-lg gry-bg p-4'>
                                    <Row className='px-2'>
                                        <Col lg={8} md={8} sm={8} xs={24} className='mb-2 md:mb-0'>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'Geofence Name'}
                                            />
                                            <p className='text-xs break-all'>{item?.name}</p>
                                        </Col>
                                        <Col lg={4} md={4} sm={8} xs={24} className='mb-2 md:mb-0'>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'Longitude'}
                                            />
                                            <p className='text-xs'>{item?.longitude}</p>
                                        </Col>
                                        <Col lg={4} md={4} sm={8} xs={24} className='mb-2 md:mb-0'>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'Longitude'}
                                            />
                                            <p className='text-xs'>{item?.latitude}</p>
                                        </Col>
                                        {/* <Col lg={4} md={4} sm={8} xs={24} className='mb-2 md:mb-0'>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'Radius'}
                                            />
                                            <p className='text-xs'>{'-'}</p>
                                        </Col> */}
                                        {/* <IoIosArrowForward /> */}

                                        <RoleCheck
                                            children={
                                                <Col lg={8} md={8} sm={24} xs={24} className='flex justify-end'>
                                                    <Button.Basic
                                                        // onClick={() => }
                                                        className="w-full px-6 h-10 yellow-bg border-none text-white border-transparent"
                                                        text={"Edit"}
                                                        onClick={() => editGeofenceDetail(item)}
                                                    />
                                                </Col>
                                            }
                                            task={'Edit'}

                                        />
                                    </Row>
                                </Col>
                            })}

                            {
                                propertyDetails && propertyDetails?.geofences?.length == 0 ?
                                    <Col span={24} className='flex justify-center'>
                                        <EmptyData />
                                    </Col>
                                    : ''
                            }
                        </Row>

                        {/* Contact Edit Detail */}
                        <Row gutter={[16, 16]} className='mb-6 p-4 mx-2 bg-white rounded-lg flex items-center'>
                            <Col lg={18} md={18} sm={18} xs={24} className=''>
                                <h3 className='text-lg font-semibold text-base'>Phone Number</h3>
                            </Col>

                            <RoleCheck
                                children={
                                    <Col lg={6} md={6} sm={6} xs={24} className='text-end flex justify-end'>
                                        <Button.Basic
                                            // onClick={() => }
                                            className="w-full px-6 h-10 yellow-bg border-none text-white border-transparent w-fit self-end"
                                            text={propertyDetails?.contacts?.length > 0 ? 'Edit' : 'Add'}
                                            onClick={() => editPhoneNumber(propertyDetails?.contacts)}
                                        />
                                    </Col>
                                }
                                task={'Edit'}
                            />


                            <Col span={24}>
                                <hr />
                            </Col>

                            <Col span={24} className='rounded-lg pb-2'>
                                <Row className='w-full'>
                                    {propertyDetails && propertyDetails?.contacts?.map((item) => {
                                        return <Col lg={6} md={6} sm={12} xs={24} className='mr-2 mb-2'>
                                            <Text.Label
                                                style={{ color: Colors.LabelColor }}
                                                text={'Phone Number'}
                                            />
                                            <p className='text-xs break-all'>{item.number} - {`(${item?.type})`}</p>
                                        </Col>
                                    })}

                                </Row>
                            </Col>

                            {
                                propertyDetails && propertyDetails?.contacts?.length == 0 ?
                                    <Col span={24} className='flex justify-center'>
                                        <EmptyData />
                                    </Col>
                                    : ''
                            }
                        </Row>


                        <BasicDrawer
                            openDrawer={openDrawer}
                            title={title == "Add" ? `Add ${formtype}` : `Edit ${formtype}`}
                            onClose={() => onCloseDrawer()}
                            width={'700'}
                        >
                            {(() => {
                                switch (formtype) {
                                    case "Property":
                                        return <Client clientData={detailData} onCloseDrawer={onCloseDrawer} isCompanyDetail={true} />;
                                    case "Address":
                                        return <AddressForm addressData={detailData} title={title} onCloseDrawer={onCloseDrawer} isAddressDetail={true} />;
                                    case "Document":
                                        return <Document documentData={detailData} title={title} onCloseDrawer={onCloseDrawer} isDocumentDetail={true} />;
                                    case "Location":
                                        return <Location editLocationData={detailData} title={title} onCloseDrawer={onCloseDrawer} isLocationDetail={true} />;
                                    case "Incident Types":
                                        return <Incidenttype incidentData={detailData} onCloseDrawer={onCloseDrawer} />;
                                    case "CheckPoints":
                                        return <CheckPoint checkPointEditData={detailData} title={title} onCloseDrawer={onCloseDrawer} isCheckpointDetail={true} />;
                                    case "Property Geofence":
                                        return <Geofence mapEditData={detailData} title={title} closeDrawerHandler={onCloseDrawer} isGeofenceDetail={true} />;
                                    case "Phone Number":
                                        return <PhoneNumber phoneEditData={detailData} closeDrawerHandler={onCloseDrawer} isPhoneDetail={true} />;
                                    default:
                                        return null;
                                }
                            })()}
                        </BasicDrawer>

                    </>

                    :

                    !loadingState && propertyDetails?.length == 0 ?
                        <PageNotFound desc={'Property Detail Not Found'} />

                        :

                        <Circular
                            spinning={loadingState}
                            size={40}
                            color={Colors.Yellow}
                            containerStyle={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        />
            }

        </>

    )
}

export default Sitemanagementdetail