import { combineReducers } from 'redux';
import AppReducer from './AppReducer';
import UserManagementReducer from './UserManagementReducer';
import SiteManagementReducer from './SiteManagementReducer';
import ClientManagementReducer from './ClientManagementReducer';
import SettingReducer from './SettingReducer';
import ManageIncidentReducer from './ManageIncidentsReducer';
import ShiftSchedulerReducer from './ShiftSchedulerReducer';

const RootReducer = combineReducers({
    AppReducer,
    UserManagementReducer,
    SiteManagementReducer,
    ClientManagementReducer,
    SettingReducer,
    ManageIncidentReducer,
    ShiftSchedulerReducer

});

export default RootReducer;