import React, { useState, useEffect } from 'react'
import { Row, Col, Avatar, Form, Checkbox } from "antd";
import { CameraOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, TextField } from '..';
import { FaArrowLeftLong } from "react-icons/fa6";
import LabelWithField from '../LabelWithField';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import LabelWithSelect from '../LabelWithSelect';
import { ApiCaller, Colors, Utils } from '../../config';
import { FaPlus } from "react-icons/fa6";
// import CheckBoxField from '../Select/Checkbox';
import { useNavigate } from 'react-router-dom';
import BasicDrawer from '../Drawer';
import IncidentAdd from '../DrawerForms/IncidentAdd';
import { useQuery, useMutation, Query } from 'react-query';
import { getIncidents } from '../../config/getApis';
import { SiteManagementAction } from '../../store/actions';
import { UseDispatch, useDispatch, useSelector } from 'react-redux';
import Circular from '../Loader/Circular';

const IncidentType = ({

    nextStep,
    previousStep,
    currenStep

}) => {

    console.log(currenStep)

    const dispatch = useDispatch()

    const incidentTypesOptions = useSelector(state => state?.SiteManagementReducer?.incidentTypes || []);
    const incidentTypesLoader = useSelector(state => state?.SiteManagementReducer?.loader);

    useEffect(() => {
        dispatch(SiteManagementAction.GetIncidentTypes(searchVal))
    }, [])


    const [drawerState, setDrawerState] = useState(false);
    const [incidentTypeId, setIncidentTypeId] = useState([]);
    const [searchVal,setSearchVal] = useState('')

    const [btnDisabled, setBtnDisabled] = useState({
        next: false,
        save: false,
    })

    const navigate = useNavigate();
    const CheckBoxField = Checkbox.Group;

    const incidentMutation = useMutation(async (data) => {
        console.log(data)
        try {
            const id = localStorage.getItem('PropertyKey')
            const payload = {
                incidentTypeId
            }
            let res = await ApiCaller.Post(`/property/${id}/incident-type`, payload);
            console.log(res)

            if (data == 'next') {
                nextStep();
                // setIncidentTypeId([]);
                setBtnDisabled({
                    save: false,
                    next: false,
                });

            }
            else if (data == 'save') {
                localStorage.removeItem('PropertyKey')
                navigate('/site-management')
                nextStep();
                setIncidentTypeId([]);
                setBtnDisabled({
                    save: false,
                    next: false,
                });
            }
        }
        catch (err) {
            Utils.showMessage('error', err?.response?.data?.message);
            setBtnDisabled({
                save: false,
                next: false,
            });
            return
        }
    })

    const incidentCheckedHandler = (checkedValues) => {
        setIncidentTypeId(checkedValues)
    }

    const AddMoreHandler = () => {
        setDrawerState(true)
    }

    const closeDrawerHandler = () => {
        setDrawerState(false)
    }

    const nextHandler = (name) => {
        // post api hit and continue
        if (incidentTypeId.length == 0) {
            return
        }
        else {
            setBtnDisabled({
                save: false,
                next: true,
            });
            incidentMutation.mutate(name)
        }
    }

    const skipHandler = () => {
        setBtnDisabled({
            save: false,
            next: false,
        });
        nextStep()
    }

    const saveHandler = (name) => {
        // post api hit and back to listing.
        if (incidentTypeId.length == 0) {
            return
        }
        else {
            setBtnDisabled({
                save: true,
                next: false,
            });
            incidentMutation.mutate(name)
        }

    }

    const debounce = (query, delay) => {
        let timeout;
        clearTimeout(timeout);
        setTimeout(() => {
            return(
                dispatch(SiteManagementAction.GetIncidentTypes(query))
            )
        }, [delay])
    }



    const handleInputChange = (e) => {
        const query = e.target.value;
        console.log('test quiery',query)
        setSearchVal(query)
        debounce(query,2000)
    }

    return (
        <>
            <div className='bg-white'>

                <Row className='px-2 md:px-10 flex flex-col'>
                    <Row gutter={16} className='mb-5 md:mb-4'>
                        <Col lg={8} xs={24} className='my-2'>
                            <label style={{ color: Colors.LabelColor }}>search</label>
                            <TextField.Basic
                                style={{
                                    background: "#eff2f5",
                                    width: "100%",

                                }}
                                suffix={<SearchOutlined size={48} />}
                                placeholder="Search"
                                onChange={(e) => handleInputChange(e)}
                                id="search"
                                name="search"
                                value={searchVal}
                                className={'h-10 rounded outline-none w-full'}
                            />
                        </Col>
                        <Col span={12} className='flex align-middle justify-start items-center sm:my-0 my-2' onClick={AddMoreHandler}>
                            <span className=' font-bold hover:underline cursor-pointer'><span> + </span>Add New</span>
                        </Col>
                    </Row>


                    {
                        !incidentTypesLoader ?
                            <form>
                                <Row gutter={16}>
                                    <Col span={24} style={{ height: "50vh" }} className='overflow-auto scroll-smooth'>
                                        <CheckBoxField
                                            options={incidentTypesOptions}
                                            value={incidentTypeId}
                                            onChange={incidentCheckedHandler}
                                        />
                                    </Col>
                                </Row>
                            </form>
                            :
                            <Circular
                                spinning={incidentTypesLoader}
                                size={40}
                                color={Colors.Yellow}
                                containerStyle={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            />
                    }
                </Row>

                <br />

                <Row gutter={[16, 16]} className='px-2 md:px-10' >
                    <Col xs={24} lg={3} className='flex align-middle items-center'
                    >
                        <FaArrowLeftLong className='mr-2' />
                        <span onClick={() => previousStep()} className={`cursor-pointer ${incidentMutation?.isLoading && 'pointer-events-none'}`}
                        >Back</span>
                    </Col>

                    <Col xs={24} lg={{ span: 4, offset: 9 }} >
                        <Button.Basic
                            onClick={() => saveHandler('save')}
                            className="w-full h-12 yellow-bg border-none text-white border-transparent"
                            text={"Save"}
                            disabled={btnDisabled.next || incidentTypesLoader ? true : false}
                            loader={btnDisabled.next ? false : incidentMutation.isLoading}
                        // disabled={currenStep === 7}
                        />
                    </Col>
                    <Col xs={24} lg={4} >
                        <Button.Basic
                            className="w-full h-12  orange-bg border-none text-white border-transparent"
                            onClick={() => nextHandler('next')}
                            text={"Continue"}
                            disabled={btnDisabled.save || incidentTypesLoader ? true : false}
                            loader={btnDisabled.save ? false : incidentMutation.isLoading}
                        // disabled={currenStep === 7}
                        />
                    </Col>
                    <Col xs={24} lg={4} >
                        <Button.Basic
                            className="w-full h-12 black-bg border-none text-white border-transparent"
                            onClick={skipHandler}
                            text={"Next"}
                            disabled={btnDisabled.next || btnDisabled.save ? true : false}
                        />
                    </Col>
                </Row>
                <br />

            </div>
            <BasicDrawer
                openDrawer={drawerState}
                title={'Add New Incident Type'}
                onClose={closeDrawerHandler}
                width={'800'}
            >

                <IncidentAdd
                    closeDrawerHandler={closeDrawerHandler}
                    searchVal={searchVal}
                />

            </BasicDrawer>
        </>
    )
}

export default IncidentType


var optionsSelect = [
    {
        label: 'Alarm response',
        value: '1',

    },
    {
        label: 'Assisting security officer',
        value: '2',
    },

    {
        label: 'Theft',
        value: '3',
    },
    {
        label: 'Custody Of weapon',
        value: '4',
    }

]