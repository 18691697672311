import { UserManagementAction } from '../actions';
import { ApiCaller } from '../../config';
import { put, delay } from 'redux-saga/effects';
import { Utils } from "../../config"

export default class UserManagementMiddleware {

    static *GetUser(params) {
        const { payload } = params;
        console.log(payload)
        const { current, limit, searchVal, tabName, roleType } = payload;
        try {
            const res = yield ApiCaller.Get(`/users?status=${tabName}&page=${current}&perPage=${limit}&search=${searchVal}&roleType=${roleType}`);
            if (res.status == 200) {
                yield put(UserManagementAction.GetUsersSuccess(res));
            } else {
                yield put(UserManagementAction.GetUsersFailure());
            }
        }
        catch (err) {
            yield put(UserManagementAction.GetUsersFailure());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }

    static *GetUserDetail(params) {
        const { payload, cb } = params;
        const { id } = payload;
        try {
            let res = yield ApiCaller.Get(`/user/${id}`);
            if (res.status == 200) {
                yield put(UserManagementAction.GetUserDetailSuccess(res?.data?.data));
                if (cb) {
                    cb()
                }
            }
            else {
                yield put(UserManagementAction.GetUserDetailFailure());
            }
        }
        catch (err) {
            yield put(UserManagementAction.GetUserDetailFailure());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }

    static *AddUser(params) {
        const { payload, cb } = params;
        const { userimg, ...userData } = payload;
        try {
            if (!userimg) {
                let res = yield ApiCaller.Post('/user/signup', userData);
                if (res.status == 200 || res.status == 201) {
                    yield put(UserManagementAction.AddUserSuccess());
                    if (cb) {
                        cb()
                    }
                    Utils.showMessage('success', res?.data?.message)
                }
                else {
                    yield put(UserManagementAction.AddUserFailure());
                    Utils.showMessage('error', 'error')
                }
            }
            else {
                const formData = new FormData();
                formData.append('file', userimg);
                formData.append('type', 'profile')
                let res = yield ApiCaller.Post('/media/upload', formData);
                if (res.status == 200 || res.status == 201) {
                    let profileImgId = res?.data?.data?.id;
                    userData.profileImgId = profileImgId;
                    try {
                        let res = yield ApiCaller.Post('/user/signup', userData);
                        if (res.status == 200 || res.status == 201) {
                            yield put(UserManagementAction.AddUserSuccess());
                            if (cb) {
                                cb()
                            }
                            Utils.showMessage('success', res?.data?.message)
                        }
                        else {
                            yield put(UserManagementAction.AddUserFailure());
                            Utils.showMessage('error', 'error')
                        }
                    }
                    catch (err) {
                        yield put(UserManagementAction.AddUserFailure());
                        console.log(err)
                        Utils.showMessage('error', err?.response?.data?.message)
                    }

                }
            }
        }

        catch (err) {
            yield put(UserManagementAction.AddUserFailure());
            console.log(err)
            Utils.showMessage('error', err?.response?.data?.message)
        }

    }

    static *UpdateUser(params) {
        try {
            const { payload, id, cb } = params;
            const { userimg, ...userData } = payload;

            if (Array.isArray(userData?.roleId)) {
                userData.roleId = parseInt(userData.roleId[0], 10);
            }

            if (!userimg) {
                const updateRes = yield ApiCaller.Put(`/user/${id}`, userData);
                if (updateRes.status === 200 || updateRes.status === 201) {
                    Utils.showMessage('success', updateRes.data.message);
                    if (cb) {
                        cb();
                    }
                    return yield put(UserManagementAction.UpdateUserSuccess());
                } else {

                    Utils.showMessage('error', 'Error');
                    return yield put(UserManagementAction.UpdateUserFailure());
                }
            }

            else {
                if (typeof userimg === 'number') {
                    userData.profileImgId = userimg;
                } 
                else {
                    const formData = new FormData();
                    formData.append('file', userimg);
                    formData.append('type', 'profile');

                    const uploadRes = yield ApiCaller.Post('/media/upload', formData);
                    userData.profileImgId = uploadRes.data.data.id;
                }
                const updateRes = yield ApiCaller.Put(`/user/${id}`, userData);
                if (updateRes.status === 200 || updateRes.status === 201) {

                    Utils.showMessage('success', updateRes.data.message);
                    if (cb) {
                        cb();
                    }
                    return yield put(UserManagementAction.UpdateUserSuccess());
                } else {

                    Utils.showMessage('error', 'Error');
                    return yield put(UserManagementAction.UpdateUserFailure());
                }

            }
        }

        catch (err) {

            Utils.showMessage('error', err?.response?.data?.message);
            return yield put(UserManagementAction.UpdateUserFailure());
        }
    }

    static *UpdateUserStatus(params) {
        const { payload, cb } = params;
        const { id } = payload;
        try {
            let res = yield ApiCaller.Patch(`/user/${id}/status`, { status: payload?.status });
            console.log(res)
            if (res.status == 200) {
                yield put(UserManagementAction.UpdateUserStatusSuccess());
                if (cb) {
                    cb()
                }
                Utils.showMessage('success', res?.data?.message)
            }
            else {
                yield put(UserManagementAction.UpdateUserStatusFailure());
            }
        }
        catch (err) {
            yield put(UserManagementAction.UpdateUserStatusFailure());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }
}