import React, { useState, useEffect } from 'react'
import { Row, Col, Avatar } from "antd";
import { Colors, Utils } from "../../../config";
import TextField from "../../../components/TextField";
import DropDown from "../../../components/DropDown";
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import { UserDetail } from '../../../config/Schema';
import LabelWithField from '../../../components/LabelWithField';
import { useNavigate } from 'react-router-dom';

import { HiOutlineBellAlert } from "react-icons/hi2";
import { IoIosSearch } from "react-icons/io";
import { UserOutlined } from "@ant-design/icons";
import { IoIosArrowForward } from "react-icons/io";
import camera from '../../../assets/images/camera.png'
import { FaCamera } from "react-icons/fa";



const EditProfile = () => {

    const navigate = useNavigate();

    const schema = UserDetail;
    const { control, handleSubmit, reset, setValue, watch } = useForm({
        resolver: yupResolver(schema)
    })

    const [img, setimg] = useState(null);
    const [readFile, setReadFile] = useState(null);

    const [imgValue, setImageValue] = useState({
        imgSizeValidation: '',
        imgTypeValidation: '',
        isRequired: '',
    });

    useEffect(() => {
        let fileReader,
            isCancel = false;

        fileReader = new FileReader();
        if (img) {
            fileReader.onload = (e) => {
                setReadFile(e?.target?.result);
            };
            fileReader.readAsDataURL(img);
        }

        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        };
    }, [img]);


    const options = [
        {
            label: 'My Availability',
            value: '1'
        },
        {
            label: 'Edit Profile',
            value: '2'
        },
        {
            label: 'Change Password',
            value: '3'
        },
    ]

    const fileHandler = (e) => {

        const file = e?.target.files[0];

        if (file?.size <= 307200 && ['image/png', 'image/jpeg'].includes(file?.type)) {
            setimg(file);
            setValue('userimg', file)
            setImageValue({ imgSizeValidation: '', imgTypeValidation: '', isRequired: '' })
            return
        }

        else {
            if (file?.size > 307200) {
                setImageValue({ ...imgValue, imgSizeValidation: 'Size should not exceed' })
                setimg('')
                setValue('userimg', null)
                setReadFile('')
                return
            }
            else if (!['image/png', 'image/jpeg'].includes(file?.type)) {
                setImageValue({ ...imgValue, imgTypeValidation: 'Type should be match' })
                setimg('')
                setValue('userimg', null)
                setReadFile('')
                return
            }
        }
    };

    const FormDataHandler = (data) => {
        const file = watch('userimg')
        if (!file) {
            console.log(file)
            setImageValue({ ...imgValue, isRequired: 'Image is required' })
            return
        }
        else {
            console.log("edit user form", data)
            reset()
            return
        }
    }

    const dropDownSelector = (e) => {
        if (e.key === "tmp-0") {
            navigate('/MyAvailability')
            return
        }
        else if (e.key === "tmp-1") {
            navigate('/EditProfile')
            return
        }
        else if (e.key === 'tmp-2') {
            navigate('/ChangePassword')
            return
        }
    }

    return (
        <>
            <Row className="w-full flex items-center flex-wrap mb-8">
                <Col lg={6} md={4} style={{}} className="my-2">
                    <h1 className='text-xl font-semibold'>Edit Profile</h1>
                </Col>

                <Col lg={18} md={18} className="flex justify-end items-center py-3 px-2 gap-4 flex-wrap" style={{}}>
                    <TextField.Basic placeholder={'Search here'} suffix={<IoIosSearch />} style={{ background: '#EFF2F5', border: '1px solid red' }} className='w-8/12 py-3' />
                    <div className="py-3 px-3 rounded-md" style={{
                        background: Colors.White,
                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'
                    }}>
                        <HiOutlineBellAlert size={20} />
                    </div>

                    <div className="py-2 px-4 rounded-md" style={{
                        background: Colors.White,
                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'
                    }}>

                        <DropDown placement={'bottom'} onClick={(e) => dropDownSelector(e)} items={options} children={
                            <div className="flex items-center gap-2 justify-between">
                                <div>
                                    <Avatar icon={<UserOutlined />} />
                                </div>
                                <div className="flex">
                                    <span>Mark Smith <IoIosArrowForward className="inline" /></span>
                                </div>
                            </div>
                        } />
                    </div>
                </Col>
            </Row>

            <Row className='w-full flex justify-between  rounded-lg py-6 px-2' style={{ background: Colors.White }}>
                <form onSubmit={handleSubmit(FormDataHandler)} className='w-full' style={{}}>
                    <Row
                        className="py-5 w-full items-center justify-center"
                        style={{
                            padding: "10px",
                            height: "100px",
                            position: "relative",
                        }}
                    >
                        <Col style={{ width: '70px', height: '70px', borderRadius: '50%', border: '1px solid #F9F9FA', background: '#F9F9FA' }} className='flex items-center justify-center relative '>
                            <Controller
                                control={control}
                                name='userimg'
                                defaultValue={''}
                                render={(field, fieldState) => (

                                    <>
                                        <>
                                            <input
                                                type="file"
                                                className='z-0 cursor-pointer'
                                                style={{
                                                    borderRadius: "50%",
                                                    width: '100%',
                                                    height: '100%',
                                                    position: "absolute",
                                                    zIndex: 999,
                                                    opacity: 0,
                                                }}

                                                onChange={(e) => {
                                                    fileHandler(e)
                                                }}
                                            // accept=".png, .jpg, .jpeg"
                                            />
                                        </>
                                    </>
                                )}
                            />
                            {
                                readFile ?
                                    <>
                                        <Avatar className='z-10 absolute w-full h-full' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} src={readFile} />
                                        <img src={camera} className='absolute z-20 right-0 bottom-0' width={'20px'} height={'20px'} />
                                    </>
                                    : <Avatar className='z-0 absolute w-full h-full flex items-center justify-center' icon={<FaCamera />} />}
                        </Col>

                        {/* user image validation  */}
                        <p style={{ color: Colors.Error, width: '100%' }} className='text-center'>{imgValue && imgValue.imgSizeValidation ? <p>{imgValue.imgSizeValidation}</p> : imgValue && imgValue.imgTypeValidation ? <p>{imgValue.imgTypeValidation}</p> : imgValue.isRequired ? <p>{imgValue.isRequired}</p> : ''}</p>

                    </Row>

                    <Row className='w-full flex gap-2 items-center justify-center'>
                        <Col lg={10}>
                            <Controller
                                name='firstName'
                                control={control}
                                render={({ field, fieldState }) => {
                                    return (
                                        <>
                                            <LabelWithField labeltext={'First Name'} field={field} inputClass={'py-3 px-1 rounded-md'} />
                                            {fieldState?.error ? <p style={{ color: Colors.Error }}>{fieldState.error.message}</p> : ''}
                                        </>
                                    )
                                }}
                            />
                        </Col>

                        <Col lg={10}>
                            <Controller
                                name='lastName'
                                control={control}
                                render={({ field, fieldState }) => {
                                    return (
                                        <>
                                            <LabelWithField labeltext={'Last Name'} field={field} inputClass={'py-3 px-1 rounded-md'} />
                                            {fieldState?.error ? <p style={{ color: Colors.Error }}>{fieldState.error.message}</p> : ''}
                                        </>
                                    )

                                }}
                            />
                        </Col>
                    </Row>

                    <Row className='w-full flex gap-2 items-center justify-center'>
                        <Col lg={10}>
                            <Controller
                                name='email'
                                control={control}
                                render={({ field, fieldState }) => {
                                    return (
                                        <>
                                            <LabelWithField labeltext={'Email Address'} field={field} inputClass={'py-3 px-1 rounded-md'} />
                                            {fieldState?.error ? <p style={{ color: Colors.Error }}>{fieldState.error.message}</p> : ''}
                                        </>
                                    )

                                }}
                            />
                        </Col>

                        <Col lg={10}>
                            <Controller
                                name='phone'
                                control={control}
                                render={({ field, fieldState }) => {
                                    return (
                                        <>
                                            <LabelWithField labeltext={'Phone Number'} field={field} type={'number'} inputClass={'py-3 px-1 rounded-md'} />
                                            {fieldState?.error ? <p style={{ color: Colors.Error }}>{fieldState.error.message}</p> : ''}
                                        </>
                                    )

                                }}
                            />
                        </Col>
                    </Row>

                    <Row className='w-full flex justify-start items-center' >
                        <Col lg={22} md={6} sm={24} xs={24} className='flex justify-center md:justify-end' style={{}}>
                            <input type='submit' style={{ background: Colors.Yellow, color: Colors.White }} className={'cursor-pointer my-4 h-10 rounded-md px-12'} value={'Save'} />
                        </Col>
                    </Row>


                </form>
            </Row>

        </>
    )
}

export default EditProfile