import React from 'react'
import BasicText from '../../components/Text/BasicText'
import { Col, Row } from 'antd'
import { Colors } from '../../config'
import shift from '../../assets/images/shift.png'
import report from '../../assets/images/report.png'
import tor_icon from '../../assets/images/tor_icon.png'
import swap_icon from '../../assets/images/swap_icon.png'
import myshifts_icon from '../../assets/images/myshifts_icon.png'
import { useNavigate } from 'react-router-dom'

const WorkForceManagement = () => {
    const navigate = useNavigate();
    return (
        <>
            <Row align={"middle"} justify={"start"} className='mt-2'>
                <BasicText text={'Workforce Management'} className='text-xl font-semibold' />
            </Row>

            <Row className='w-full mt-10 flex gap-5 flex-wrap'>
                <Col xl={7} lg={7} md={8} sm={10} xs={24}
                    onClick={() => navigate('/workforce-management/shift-scheduler')}
                    style={{ background: Colors.White, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} className='flex items-center gap-3 px-4 py-6 rounded-md cursor-pointer hover:scale-x-105 hover:scale-y-105 duration-500 ease-in-out delay-75'>
                    <img src={shift} height={50} width={50} />
                    <div>
                        <BasicText text={'Shift Scheduler'} className='text-lg font-semibold'
                        />
                    </div>
                </Col>

                <Col xl={7} lg={7} md={8} sm={10} xs={24}
                    style={{ background: Colors.White, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} className='flex items-center gap-3 px-4 py-6 rounded-md cursor-pointer hover:scale-x-105 hover:scale-y-105 duration-500 ease-in-out delay-75'>
                    <img src={tor_icon} height={50} width={50} />
                    <div>
                        <BasicText text={'Manage Time'} className='text-lg font-semibold'
                        />
                        <span className='' style={{ fontSize: '12px' }}>of Requests</span>
                    </div>
                </Col>

                <Col xl={7} lg={7} md={8} sm={10} xs={24}
                    style={{ background: Colors.White, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} className='flex items-center gap-3 px-4 py-6 rounded-md cursor-pointer hover:scale-x-105 hover:scale-y-105 duration-500 ease-in-out delay-75'>
                    <img src={swap_icon} height={50} width={50} />
                    <div>
                        <BasicText text={'Manage Shift'} className='text-lg font-semibold'
                        />
                        <span className='' style={{ fontSize: '12px' }}>Drop/Swap Requests</span>
                    </div>
                </Col>
            </Row>

            <Row className='w-full mt-4 flex flex-wrap'>
                <Col xl={7} lg={7} md={8} sm={10} xs={24}
                    style={{ background: Colors.White, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} className='flex items-center gap-3 px-4 py-6 rounded-md cursor-pointer hover:scale-x-105 hover:scale-y-105 duration-500 ease-in-out delay-75'>
                    <img src={myshifts_icon} height={50} width={50} />
                    <div>
                        <BasicText text={'My Shifts'} className='text-lg font-semibold'
                        />
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default WorkForceManagement