import React from 'react'
import { useSelector } from 'react-redux'

const RoleCheck = ({ children, task }) => {

    const user = useSelector((state) => state.AppReducer?.user)
    const isRole = user?.data?.userRole[0]

    return(
        <>
            {
                (isRole == 'admin') ? children : 
                (isRole == 'manager') ? children :
                (isRole == 'supervisor' && task == 'Add' || task == 'Edit') ? '' :
                (isRole == 'guard' && task == 'Add' || task == 'Edit') ? '' : ''
            }
        </>
    )
}

export default RoleCheck