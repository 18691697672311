import { SiteManagementAction } from '../actions';
import { ApiCaller } from '../../config';
import { put, delay } from 'redux-saga/effects';
import { Utils } from "../../config"


export default class SiteManagementMiddleware {

    static *GetProperty(params) {

        const { payload } = params;
        console.log(payload)
        const { current, limit, searchVal, sortBy, sortOrder } = payload;
        try {
            const res = yield ApiCaller.Get(`/properties?search=${searchVal}&page=${current}&perPage=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder ? sortOrder : 'asc'}`);
            if (res.status == 200) {
                console.log(res);
                yield put(SiteManagementAction.GetPropertySuccess(res));
            } else {
                yield put(SiteManagementAction.GetPropertyFailure());
            }
        }
        catch (err) {
            yield put(SiteManagementAction.GetPropertyFailure());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }

    static *GetPropertyDetail(payload, cb) {
        console.log(payload)
        const id = Number(payload?.payload);

        try {
            const res = yield ApiCaller.Get(`/property/${id}`);
            yield put(SiteManagementAction.GetPropertyDetailSuccess(res?.data?.data));
            console.log(res)
        }
        catch (err) {
            yield put(SiteManagementAction.GetPropertyDetailFailure());
        }
    }
    static *GetProperties() {
        try {
            const res = yield ApiCaller.Get('/all-companies?status=active');
            console.log(res)
            const resData = res?.data?.data;
            let companiesOption = [];
            const role = resData?.map((item) => {
                companiesOption?.push({
                    label: item.name,
                    value: item.id
                })
            })
            console.log(companiesOption)
            yield put(SiteManagementAction.GetPropertiesSuccess(companiesOption))
        }
        catch (err) {
            yield put(SiteManagementAction.GetPropertiesFailure())
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }

    static *UpdatePropertyStatus(params) {
        const { payload, cb } = params;
        const { id } = payload;
        try {
            let res = yield ApiCaller.Delete(`/property/${id}`, { status: payload?.status });
            console.log(res)
            if (res.status == 200) {
                yield put(SiteManagementAction.LoaderFalse());
                cb && cb()
                Utils.showMessage('success', res?.data?.message)
            }
            else {
                yield put(SiteManagementAction.LoaderFalse());
            }
        }
        catch (err) {
            yield put(SiteManagementAction.LoaderFalse());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }



    static *AddProperty(params) {

        const { payload, cb } = params;
        console.log(payload)

        try {
            const res = yield ApiCaller.Post('/company', payload);
            console.log(res)
            if (res.status == 200 || res.status == 201) {
                Utils.showMessage('success', res?.data?.message)
                cb && cb()
            }
        }
        catch (err) {
            yield put(SiteManagementAction.AddPropertyFailure());
            console.log(err)
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }


    static *EditProperty(params) {

        const { payload, id, cb } = params;

        if (typeof (payload?.propertyImgId) == 'object') {

            let { propertyImgId, ...restData } = payload;
            const formData = new FormData();
            formData.append('file', propertyImgId);
            formData.append('type', 'profile')

            try {
                let res = yield ApiCaller.Post('/media/upload', formData);
                if (res.status == 200 || res.status == 201) {
                    restData.propertyImgId = res.data?.data?.id;
                    try {
                        const res = yield ApiCaller.Put(`/property/${id}`, restData);
                        if (res.status == 200 || res.status == 201) {
                            yield put(SiteManagementAction.EditPropertySuccess());
                            Utils.showMessage('success', res?.data?.message)
                            cb && cb()
                        } else {
                            yield put(SiteManagementAction.EditPropertyFailure());
                        }
                    }
                    catch (err) {
                        yield put(SiteManagementAction.EditPropertyFailure());
                        Utils.showMessage('error', err?.response?.data?.message)
                    }
                }
            }

            catch (err) {
                yield put(SiteManagementAction.EditPropertyFailure());
                Utils.showMessage('error', err?.response?.data?.message)
            }

        }
        else {
            try {
                const res = yield ApiCaller.Put(`/property/${id}`, payload);
                if (res.status == 200 || res.status == 201) {
                    yield put(SiteManagementAction.EditPropertySuccess());
                    Utils.showMessage('success', res?.data?.message)
                    cb && cb()
                } else {
                    yield put(SiteManagementAction.EditPropertyFailure());
                }
            }
            catch (err) {
                yield put(SiteManagementAction.EditPropertyFailure());
                Utils.showMessage('error', err?.response?.data?.message)
            }
        }

    }



    static *AddAddress(params) {

        const { payload, id, cb } = params;
        console.log(payload);

        try {
            yield put(SiteManagementAction.LoaderTrue());
            const res = yield ApiCaller.Post(`/property/${id}/address`, payload);
            console.log(res)
            if (res.status == 200) {
                yield put(SiteManagementAction.LoaderFalse());
                Utils.showMessage('success', res?.data?.message)
                cb && cb()
            } else {
                yield put(SiteManagementAction.LoaderFalse());
            }
        }
        catch (err) {
            yield put(SiteManagementAction.LoaderFalse());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }


    static *EditAddress(params) {

        const { data, id, cb } = params;

        try {
            yield put(SiteManagementAction.LoaderTrue());
            let propertyId = localStorage.getItem('PropertyKey')
            const res = yield ApiCaller.Put(`/property/${propertyId}/address/${id}`, data);
            console.log(res)
            if (res.status == 200) {
                yield put(SiteManagementAction.LoaderFalse());
                Utils.showMessage('success', res?.data?.message)
                if (cb) {

                    cb()

                }
            } else {
                yield put(SiteManagementAction.LoaderFalse());
            }
        }
        catch (err) {
            yield put(SiteManagementAction.LoaderFalse());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }

    static *GetAddress(params) {
        const { cb } = params;
        console.log(cb)
        try {
            let id = localStorage.getItem('PropertyKey')
            let res = yield ApiCaller.Get(`/property/${id}/addresses`);
            if (res.status == 200) {
                yield put(SiteManagementAction.GetAddressSuccess(res?.data?.data?.addresses))
                if (cb) {
                    cb()
                }
            }
        }
        catch (err) {
            Utils.showMessage('error', err?.response?.data?.message);
            yield put(SiteManagementAction.GetAddressFailure())
        }
    }

    static *GetDocuments() {

        try {
            let id = localStorage.getItem('PropertyKey')
            let res = yield ApiCaller.Get(`/property/${id}/documents`);
            console.log(res?.data?.data?.documents)
            yield put(SiteManagementAction.GetDocumentSuccess(res?.data?.data?.documents))
        }
        catch (err) {
            yield put(SiteManagementAction.GetDocumentFailure());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }



    static *AddDocument(params) {

        const { payload, id, cb } = params;
        let { mediaId, ...restData } = payload;

        const formData = new FormData();
        formData.append('file', mediaId);
        formData.append('type', 'document')

        try {
            yield put(SiteManagementAction.LoaderTrue());
            let res = yield ApiCaller.Post('/media/upload', formData);
            if (res.status == 200 || res.status == 201) {
                restData.mediaId = res.data?.data?.id;

                try {
                    const res = yield ApiCaller.Post(`/property/${id}/document`, restData);
                    if (res.status == 200 || res.status == 201) {
                        yield put(SiteManagementAction.LoaderFalse());
                        Utils.showMessage('success', 'document added successfully')
                        cb && cb()
                    } else {
                        yield put(SiteManagementAction.LoaderFalse());
                    }
                }
                catch (err) {
                    yield put(SiteManagementAction.LoaderFalse());
                    Utils.showMessage('error', err?.response?.data?.message)
                }
            }
        }

        catch (err) {
            yield put(SiteManagementAction.LoaderFalse());
            Utils.showMessage('error', err?.response?.data?.message)
        }


    }


    static *EditDcoument(params) {

        const { payload, cb, docId } = params;

        yield put(SiteManagementAction.LoaderTrue());
        let id = localStorage.getItem('PropertyKey')

        if (typeof (payload?.mediaId) == 'object') {
            const formData = new FormData()
            formData.append('file', payload?.mediaId);
            formData.append('type', 'document');
            try {
                const docUploadRes = yield ApiCaller.Post('/media/upload', formData);
                let uploadedDocId = docUploadRes?.data?.data?.id;
                payload.mediaId = uploadedDocId;
                if (docUploadRes.status == 200 || docUploadRes.status == 201) {
                    const res = yield ApiCaller.Put(`/property/${id}/document/${docId}`, payload);
                    if (res.status == 200 || res.status == 201) {
                        yield put(SiteManagementAction.LoaderFalse());
                        Utils.showMessage('success', res?.data?.message)
                        cb && cb()

                    } else {
                        yield put(SiteManagementAction.LoaderFalse());
                    }
                }
            }
            catch (err) {
                yield put(SiteManagementAction.LoaderFalse());
                Utils.showMessage('error', err?.response?.data?.message)
                return
            }
        }
        else {
            try {
                const res = yield ApiCaller.Put(`/property/${id}/document/${docId}`, payload);
                if (res.status == 200 || res.status == 201) {
                    yield put(SiteManagementAction.LoaderFalse());
                    Utils.showMessage('success', res?.data?.message)
                    cb && cb()

                } else {
                    yield put(SiteManagementAction.LoaderFalse());
                }
            }
            catch (err) {
                yield put(SiteManagementAction.LoaderFalse());
                Utils.showMessage('error', err?.response?.data?.message)
                return
            }
        }

    }


    static *GetLocations() {
        try {
            let id = localStorage.getItem('PropertyKey')
            let res = yield ApiCaller.Get(`/property/${id}/locations`);
            console.log('api response', res?.data.locations)

            if (res.status == 200 || res.status == 201) {
                yield put(SiteManagementAction.GetLocationSuccess(res?.data?.data?.locations))
            }
        }
        catch (err) {
            yield put(SiteManagementAction.GetLocationFailure());
            Utils.showMessage('error', err?.response?.data?.message)
            return
        }
    }

    static *AddLocation(params) {

        const { payload, id, cb } = params;
        console.log(payload)
        try {
            yield put(SiteManagementAction.LoaderTrue());
            const res = yield ApiCaller.Post(`/property/${id}/location`, payload);
            if (res.status == 200) {
                yield put(SiteManagementAction.LoaderFalse());
                Utils.showMessage('success', res?.data?.message)
                cb && cb()
            } else {
                yield put(SiteManagementAction.LoaderFalse());
            }
        }
        catch (err) {
            yield put(SiteManagementAction.LoaderFalse());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }


    static *EditLocation(params) {

        const { payload, locationId, cb } = params;
        console.log('location Payload', payload)
        console.log('locationId', locationId)

        try {
            yield put(SiteManagementAction.LoaderTrue());
            let id = localStorage.getItem('PropertyKey')
            const res = yield ApiCaller.Put(`/property/${id}/location/${locationId}`, payload);
            if (res.status == 200) {
                Utils.showMessage('success', res?.data?.message)
                yield put(SiteManagementAction.LoaderFalse());
                cb && cb()
            } else {
                yield put(SiteManagementAction.LoaderFalse());
            }
        }
        catch (err) {
            yield put(SiteManagementAction.LoaderFalse());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }


    static *GetIncidentTypes(params) {
        const { payload, cb } = params;
        try {
            let res = yield ApiCaller.Get(`/all-incident-type?search=${payload}`);
            console.log(res)
            if (res.status == 200) {
                const resData = res?.data?.data;
                let incidentOptions = [];
                const role = resData?.map((item) => {
                    incidentOptions?.push({
                        label: item.name,
                        value: item.id
                    })
                })
                console.log(incidentOptions)
                yield put(SiteManagementAction.GetIncidentTypesSuccess(incidentOptions))
                return
            }
        }
        catch (err) {
            yield put(SiteManagementAction.GetIncidentTypesFailure());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }



    static *AddIncident(params) {

        const { payload, cb } = params;
        console.log(payload, cb)

        try {
            yield put(SiteManagementAction.LoaderTrue());
            const res = yield ApiCaller.Post('/incident-type', payload);
            console.log(res)
            if (res.status == 200 || res.status == 201) {
                Utils.showMessage('success', res?.data?.message)
                yield put(SiteManagementAction.LoaderFalse());
                cb && cb()
            } else {
                yield put(SiteManagementAction.LoaderFalse());
            }
        }
        catch (err) {
            yield put(SiteManagementAction.LoaderFalse());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }

    static *AddPropertyIncident(params) {
        const { payload, id, cb } = params;
        console.log(payload, id)

        try {
            const res = yield ApiCaller.Post(`/property/${id}/incident-type`, payload);
            if (res.status == 200 || res.status == 201) {
                yield put(SiteManagementAction.AddPropertyIncidentSuccess());
                Utils.showMessage('success', res?.data?.message)
                cb && cb()
            }
            else {
                yield put(SiteManagementAction.AddPropertyIncidentFailure());
            }
        }
        catch (err) {
            yield put(SiteManagementAction.AddPropertyIncidentFailure());
            Utils.showMessage('error', err?.response?.data?.message)
        }

    }



    static *EditIncident(params) {

        const { payload } = params;
        console.log(payload)
        const { current, limit, searchVal, tabName } = payload;
        try {
            const res = yield ApiCaller.Get(`/user`);
            if (res.status == 200) {
                yield put(SiteManagementAction.LoaderFalse());
            } else {
                yield put(SiteManagementAction.LoaderFalse());
            }
        }
        catch (err) {
            yield put(SiteManagementAction.LoaderFalse());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }


    static * GetCheckPoints(params) {
        const { payload } = params;
        console.log(payload)
        yield delay(2000)
        yield put(SiteManagementAction.GetCheckPointsSuccess(payload))
    }


    static * AddCheckpoint(params) {
        const { payload, id, cb } = params;
        console.log(payload)
        try {
            yield put(SiteManagementAction.LoaderTrue());
            const res = yield ApiCaller.Post(`/property/${id}/check-point`, payload);
            if (res.status == 200 || res.status == 201) {
                yield put(SiteManagementAction.LoaderFalse());
                Utils.showMessage('success', res?.data?.message);
                cb && cb()
            } else {
                yield put(SiteManagementAction.LoaderFalse());
            }
        }
        catch (err) {
            yield put(SiteManagementAction.LoaderFalse());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }


    static * EditCheckpoint(params) {

        const { payload, checkPointId, cb } = params;
        console.log(payload)
        console.log(checkPointId)

        try {
            yield put(SiteManagementAction.LoaderTrue());
            let id = localStorage.getItem('PropertyKey')
            const res = yield ApiCaller.Put(`/property/${id}/check-point/${checkPointId}`, payload);
            console.log(res)
            if (res.status == 200 || res.status == 201) {
                yield put(SiteManagementAction.LoaderFalse());
                Utils.showMessage('success', 'CheckPoint updated successfully')
                cb && cb(res?.data?.data?.checkPoints)
            } else {
                yield put(SiteManagementAction.LoaderFalse());
            }
        }
        catch (err) {
            yield put(SiteManagementAction.LoaderFalse());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }


    static * GetGeofence() {
        try {
            let id = localStorage.getItem('PropertyKey');
            let res = yield ApiCaller.Get(`/property/${id}/geofences`);
            if (res.status == 200 || res.status == 201) {
                yield put(SiteManagementAction.GetGeofenceSuccess(res?.data?.data?.geofences));
            }
        }
        catch (err) {
            yield put(SiteManagementAction.GetGeofenceFailure());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }

    static * AddGeofence(params) {
        const { payload, id, cb } = params;
        console.log(payload)
        try {
            yield put(SiteManagementAction.LoaderTrue());
            const res = yield ApiCaller.Post(`/property/${id}/geofence`, payload);
            if (res.status == 200) {
                yield put(SiteManagementAction.LoaderFalse());
                Utils.showMessage('success', res?.data?.message);
                cb && cb()
            } else {
                yield put(SiteManagementAction.LoaderFalse());
            }
        }
        catch (err) {
            yield put(SiteManagementAction.LoaderFalse());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }


    static * EditGeofence(params) {

        const { payload, geofenceId, cb } = params;
        console.log(payload)
        try {
            yield put(SiteManagementAction.LoaderTrue());
            let id = localStorage.getItem('PropertyKey');
            const res = yield ApiCaller.Put(`/property/${id}/geofence/${geofenceId}`, payload);
            if (res.status == 200 || res.status == 201) {
                yield put(SiteManagementAction.LoaderFalse());
                Utils.showMessage('success', res?.data?.message)
                cb && cb()
            } else {
                yield put(SiteManagementAction.LoaderFalse());
            }
        }
        catch (err) {
            yield put(SiteManagementAction.LoaderFalse());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }



    static * AddPhone(params) {

        const { payload, id, cb } = params;
        console.log(payload)
        try {
            yield put(SiteManagementAction.LoaderTrue());
            const res = yield ApiCaller.Post(`/property/${id}/contact`, payload);
            if (res.status == 200) {
                yield put(SiteManagementAction.LoaderFalse());
                Utils.showMessage('success', res?.data?.message)
                cb && cb()
            } else {
                yield put(SiteManagementAction.LoaderFalse());
            }
        }
        catch (err) {
            yield put(SiteManagementAction.LoaderFalse());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }


    static * EditPhone(params) {


        const { payload, id, cb } = params;
        console.log(payload)
        try {
            yield put(SiteManagementAction.LoaderTrue());
            const res = yield ApiCaller.Put(`/property/${id}/contact`, payload);
            if (res.status == 200) {
                yield put(SiteManagementAction.LoaderFalse());
                Utils.showMessage('success', res?.data?.message)
                cb && cb()
            } else {
                yield put(SiteManagementAction.LoaderFalse());
            }
        }
        catch (err) {
            yield put(SiteManagementAction.LoaderFalse());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }

    // for address types which is using in address types in form and in add client as well. 
    static *GetAddressTypes(params) {
        const { payload } = params;
        console.log(payload)
        localStorage.setItem('addressType', JSON.stringify(payload));
        let addressTypes = JSON.parse(localStorage.getItem('addressType'));
        yield put(SiteManagementAction.AddAddresTypeSuccess(addressTypes))
    }

}