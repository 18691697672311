import React, { useState } from 'react'
import { TimePicker, Space, Col } from 'antd';
import { Colors } from '../../config';
import moment from 'moment';


const LabelWithTimePicker = ({ labelClass, inputClass, labeltext,field }) => {

    const [selectedTime, setSelectedTime] = useState([]);


    return (
        <div className="flex flex-col py-2 w-full">
            <label className={labelClass} style={{ color: Colors.LabelColor }}>{labeltext}</label>
            <TimePicker.RangePicker
                allowClear={true}
                format="hh:mm a"
                {...field}
                className={`${inputClass}`}
                style={{ background: Colors.LightGray }}
            />
        </div>
    )
}

export default LabelWithTimePicker