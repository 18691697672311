import React, { useEffect, useState } from 'react'
import { Row, Col, Avatar, Form } from "antd";
import { CameraOutlined } from '@ant-design/icons';
import { FaArrowLeftLong } from "react-icons/fa6";
import LabelWithField from '../LabelWithField';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { AddProperty } from '../../config/Schema';
import LabelWithSelect from '../LabelWithSelect';
import { Colors } from '../../config';
import { FaPlus } from "react-icons/fa6";
import BasicDrawer from '../Drawer';
import TextField from '../TextField';
import Button from '../Button';
import UploadAvatar from '../UploadAvatar';
import { UseDispatch, useDispatch, useSelector } from 'react-redux';
import { SiteManagementAction } from '../../store/actions';
import { useParams } from 'react-router-dom';
import { getSchedules } from '../../config/getApis';
import { useQuery } from 'react-query';

const PropertyForm = ({
    clientData,
    onCloseDrawer,
    isCompanyDetail = false

}) => {

    const dispatch = useDispatch()
    const { id } = useParams()

    const { data: scheduleData } = useQuery('schedule', getSchedules);

    useEffect(() => {
        reset({
            name: clientData?.name,
            code: clientData?.code,
            companyId: clientData.company ? clientData.company[0]?.id : '',
            propertyImgId: clientData?.propertyImg ? clientData?.propertyImg[0]?.id : null,
            scheduleId: clientData?.schedules ? clientData?.schedules[0]?.id : null
        })
    }, [clientData])

    useEffect(() => {
        dispatch(SiteManagementAction.GetProperties())
    }, [])

    const [readFile, setReadFile] = useState(clientData?.propertyImg ? clientData.propertyImg[0]?.url : null);
    const [img, setimg] = useState(null);
    const [imgValue, setImageValue] = useState({
        imgSizeValidation: '',
        imgTypeValidation: '',
        isRequired: '',
    });

    const companiesOption = useSelector(state => state?.SiteManagementReducer?.getProperties || []);
    const propertyLoader = useSelector(state => state?.SiteManagementReducer?.editPropertyLoader);

    const schema = AddProperty;

    const { handleSubmit, control, reset, setValue, watch } = useForm(
        {
            resolver: yupResolver(schema),
            mode: "onChange"
        }
    )


    const handleChange = (data) => {
        console.log('form data', data)
        dispatch(SiteManagementAction.EditProperty(data, id, () => {
            dispatch(SiteManagementAction.GetPropertyDetail(id));
            onCloseDrawer()
        }))
    };

    return (
        <div className='bg-white'>
            <Row className='px-10 mb-6' justify={'center'}>
                <Col>
                    <div>
                        <UploadAvatar
                            img={img}
                            setimg={setimg}
                            imgValue={imgValue}
                            setImageValue={setImageValue}
                            readFile={readFile}
                            setReadFile={setReadFile}
                            setValue={setValue}
                        />
                    </div>
                </Col>

            </Row>

            <Row className=''>
                <form layout="vertical" className='w-full' >
                    <Row gutter={16}>
                        <Col lg={12} xs={24}>
                            <Controller
                                control={control}
                                name='name'
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithField
                                            labeltext={'Property Name'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'h-10 rounded outline-none'} />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}

                            />

                        </Col>

                        <Col lg={12} xs={24}>
                            <Controller
                                control={control}
                                name='code'
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithField
                                            labeltext={'Property Code'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'outline-none h-10 rounded'} />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col lg={12} xs={24} className='mb-2'>
                            <Controller
                                control={control}
                                name='companyId'
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithSelect
                                            options={companiesOption}
                                            labeltext={'Client'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'outline-none h-10 rounded'}
                                        />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}
                            />
                        </Col>
                        <Col lg={12} xs={24} className='mb-2'>
                            <Controller
                                control={control}
                                name='scheduleId'
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithSelect
                                            options={scheduleData}
                                            labeltext={'Schedule'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'outline-none h-10 rounded'}
                                        />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}
                            />
                        </Col>
                    </Row>

                    {/* <Row gutter={16}>
                        <Col span={24} className='flex align-middle justify-end items-center underline cursor-pointer'>
                            <FaPlus className='' /><span className=' font-bold'
                                onClick={() => setOpenDrawer(true)}
                            >Add New Client</span>
                        </Col>
                    </Row> */}

                    {/* <Row gutter={16}>
                        <Col span={24}>
                            <Controller
                                control={control}
                                name='note'
                                render={({ field: { value, onChange }, fieldState }) => (
                                    <>
                                        <TextField.Textarea
                                            style={{ color: Colors.LightGray }}
                                            placeholder={''}
                                            name="propertyNote"
                                            value={value}
                                            onChange={onChange}
                                            labeltext={'Property Notes'}
                                            className={'outline-none h-10 rounded'}
                                        />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}
                            />
                        </Col>
                    </Row> */}
                    <br />
                    <Row gutter={16} className='justify-end'>
                        <Col lg={24} className='flex justify-end'  >
                            <Button.Basic
                                onClick={handleSubmit(handleChange)}
                                htmlType="submit"
                                className="h-12 px-6 yellow-bg border-none text-white border-transparent"
                                text={"Save"}
                                loader={propertyLoader}
                            />
                        </Col>
                    </Row>
                </form>

            </Row>
            {/* <br />

            <Row gutter={[16, 16]} className='px-10' >
                <Col xs={24} lg={3} className='flex align-middle items-center cursor-pointer'

                    // onClick={() => previousStep()}
                    text={"Back"}
                    disabled={currenStep === 1}
                >
                    <FaArrowLeftLong className='mr-2' onClick={() => previousStep()} /><span onClick={() => previousStep()}>Back</span>
                </Col>
                <Col xs={24} lg={{ span: 4, offset: 9 }} >
                    <Button.Basic
                        // onClick={() => }
                        className="w-full h-12 yellow-bg border-none text-white border-transparent"
                        text={"Save"}
                        disabled={currenStep === 7}
                    />
                </Col>
                <Col xs={24} lg={4} >
                    <Button.Basic
                        className="w-full h-12  orange-bg border-none text-white border-transparent"
                        onClick={() => nextStep()}
                        text={"Continue"}
                        disabled={currenStep === 7}
                    />
                </Col>
                <Col xs={24} lg={4} >
                    <Button.Basic
                        className="w-full h-12 black-bg border-none text-white border-transparent"
                        onClick={() => nextStep()}
                        text={"Skip"}
                        disabled={currenStep === 7}
                    />
                </Col>
            </Row> */}
            <br />
        </div >
    )
}

export default PropertyForm

