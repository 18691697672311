import React from 'react'
import { Row, Col } from 'antd'
import TextField from '../TextField'
import SelectField from '../../components/TextField/SelectField';

import Button from '../Button'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Colors } from '../../config'
// import './style.css'

const FilterBar = ({ AddUserHandler, SelectFilterHandler, handleInputChange, SelectFilterValues, isRole, defaultValue, SearchValue, isFilter = true, ...props }) => {
    return (
        <Row gutter={[8, 16]} align={'middle'} justify={'space-between'} className={`${props.classname} w-full py-4`}>
            <Col
                lg={isRole == "admin" || isRole == "manager" ? 24 : 10}
                xl={isRole == "admin" || isRole == "manager" ? 5 : 10}
                md={isFilter ? 24 : 10} sm={24} xs={24}
            >
                <Row align={"middle"} justify={"start"}>
                    <h1 className='text-xl font-semibold'>{props.title}</h1>
                </Row>

            </Col >

            <Col lg={isFilter ? 8 : 12} xl={isFilter ? 8 : 12} md={7} sm={12} xs={24}>
                <TextField.Basic
                    style={{
                        background: "#eff2f5",
                        width: "100%"
                    }}
                    suffix={<SearchOutlined size={48} />}
                    placeholder="Search"
                    onChange={(e) => handleInputChange(e)}
                    id="search"
                    name="search"
                    value={SearchValue}

                    className='placeholder-red outline-none w-full py-2 px-4'

                />

            </Col>

            {
                isFilter ?
                    <Col lg={8} xl={6} md={7} sm={12} xs={24}>
                        <SelectField
                            defaultValue={defaultValue}
                            style={{ backgroundColor: "#eff2f5" }}
                            className='w-full h-10'
                            onChange={(val) => SelectFilterHandler(val)}
                            options={SelectFilterValues}
                            placeholder={'View All'}
                        />
                    </Col> : ''
            }

            {
                isRole == "admin" || isRole == "manager" ?

                    <Col lg={8} xl={isFilter ? 4 : 5} md={7} sm={isFilter ? 24 : 12} xs={24} >
                        <Button.Basic
                            className="text-white outline-none border-none w-full cursor-pointer px-2"
                            style={{ height: "40px", background: Colors.Yellow, color: Colors.White }}
                            text={props.btnText}
                            icon={<PlusOutlined />}
                            onClick={() => AddUserHandler()}
                        />

                    </Col>

                    : ""
            }

        </Row >
    )
}

export default FilterBar