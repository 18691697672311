import React from 'react'
import { DatePicker, Space, Col } from 'antd';
import { Colors } from '../../config';
import moment from 'moment';


const LabelWithDate = ({ labelClass, inputClass, labeltext,defaultValue,field,format, value, onChange, props,options, disabledDate, maxDate }) => {
  return (
    <div className="flex flex-col py-2 w-full">
      <label className={labelClass} style={{ color: Colors.LabelColor }}>{labeltext}</label>
      <DatePicker
        {...props}
        format={format ? format : 'DD-MM-YYYY'}
        defaultValue={defaultValue}
        // defaultValue={defaultValue ? moment(defaultValue, 'DD-MM-YYYY') : null}  // Ensure defaultValue is a moment object
        onChange={onChange}
        {...field}
        className={`${inputClass}`}
        style={{ background: Colors.LightGray }}
        options={options}
        disabledDate={disabledDate}
        maxDate={maxDate}
      />
    </div>
  );
};

export default LabelWithDate;
