const Colors = {
    PlaceHolder: (opacity = "0.5") => `rgba(235, 235, 235, ${opacity})`,
    BlackOpacity: (opacity = "0.5") => `rgba(0, 0, 0, ${opacity})`,
    WhiteOpacity: (opacity = "0.5") => `rgba(255, 255, 255, ${opacity})`,
    Black: "#000000",
    LightBlack: "#2F3032",
    Transparent: "transparent",
    Primary: "#d94826",
    Secondary: "#000000",
    DarkGray: "#1E1E22",
    LightGray: "#F5F5F5",
    White: "#ffffff",
    Text: "#ffffff",
    Danger: "#FF494C",
    Yellow: "#EEB600",
    LabelColor: '#4E5D78',
    Gray: "#9A99A9",
    Error: 'red',
    MenuItem: '#A2A9B8'

}

export default Colors;