import React, { useState } from 'react'
import { Row, Col, Form } from "antd";
import { CameraOutlined } from '@ant-design/icons';
import LabelWithField from '../LabelWithField';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { AddClient } from '../../config/Schema';
import LabelWithSelect from '../LabelWithSelect';
import { Colors } from '../../config';
import { FaPlus } from "react-icons/fa6";
import BasicDrawer from '../Drawer';
import TextField from '../TextField';
import Button from '../Button';
import Avatar from '../Avatar';
import { phoneNumberFormat } from '../../config/util/PhoneNumberFormatter';
import { UseDispatch, useDispatch, useSelector } from 'react-redux';
import { ClientManagementAction, SiteManagementAction } from '../../store/actions';
import LabelWithCustomSelect from '../LabelWithCustomSelect';


const ClientForm = ({ onCloseDrawer, isCompaniesListing = false }) => {

    const schema = AddClient;
    const { handleSubmit, control, reset, setValue, watch, clearErrors } = useForm(
        {
            resolver: yupResolver(schema),
            mode: "onChange"

        }
    )

    const companiesLoader = useSelector(state => state?.SiteManagementReducer?.loader);
    const streetType = useSelector(state => state?.SiteManagementReducer?.addressTypeData || []);

    const dispatch = useDispatch()


    const ClientFormHandler = (data) => {

        const { name, url, phoneNo, fax, note, ...address } = data;
        const payload = {
            name,
            url,
            phoneNo,
            fax,
            note,
            address
        }
        dispatch(SiteManagementAction.AddProperty(payload, () => {
            if (isCompaniesListing) {
                dispatch(ClientManagementAction.GetCompanies({ current: 1, limit: 10, searchVal: '', filterStatus: '' }));
                onCloseDrawer()
                reset()
            }
            else {
                dispatch(SiteManagementAction.GetProperties())
                onCloseDrawer()
                reset()
            }

        }))
    }

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    return (
        <>
            <Row>
                <Form layout="vertical" className='w-full'>
                    <Row gutter={16}>
                        <Col lg={12} xs={24}>
                            <Controller
                                control={control}
                                defaultValue={''}
                                name='name'
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithField
                                            labeltext={'Company Name'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'h-10 rounded outline-none'} />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}

                                    </>
                                )}
                            />
                        </Col>
                        <Col lg={12} xs={24}>
                            <Controller
                                control={control}
                                name='url'
                                defaultValue={''}
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithField
                                            labeltext={'URL'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'h-10 rounded outline-none'} />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col lg={12} xs={24}>
                            <Controller
                                control={control}
                                name='streetNumber'
                                defaultValue={''}
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithField
                                            labeltext={'Street Number'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'h-10 rounded outline-none'} />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}
                            />
                        </Col>
                        <Col lg={12} xs={24}>
                            <Controller
                                control={control}
                                name='streetName'
                                defaultValue={''}
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithField
                                            labeltext={'Street Name'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'h-10 rounded outline-none'} />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col lg={6} xs={24}>

                            <Controller
                                control={control}
                                name='streetType'
                                defaultValue={''}
                                render={({ field, fieldState }) => (
                                    <>
                                        {/* <LabelWithSelect
                                            options={streetType}
                                            labeltext={'Street Type'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'outline-none h-10 rounded'}
                                        /> */}
                                        <LabelWithCustomSelect
                                            // options={[...streetType, {
                                            //     label: addressData?.streetType,
                                            //     value: addressData?.streetType
                                            // }]}
                                            options={streetType}
                                            labeltext={'Street Type'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'outline-none h-10 rounded'}
                                        />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>

                                )}
                            />
                        </Col>
                        <Col lg={6} xs={24}>
                            <Controller
                                control={control}
                                name='city'
                                defaultValue={''}
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithField
                                            labeltext={'City'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'h-10 rounded outline-none'} />

                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}
                            />
                        </Col>
                        <Col lg={6} xs={24}>
                            <Controller
                                control={control}
                                name='state'
                                defaultValue={''}
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithField
                                            labeltext={'State'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'h-10 rounded outline-none'} />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}
                            />
                        </Col>
                        <Col lg={6} xs={24}>
                            <Controller
                                control={control}
                                name='zip'
                                defaultValue={''}
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithField
                                            labeltext={'Zip'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'h-10 rounded outline-none'} />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col lg={12} xs={24}>
                            <Controller
                                control={control}
                                name='buildingNumber'
                                defaultValue={''}
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithField
                                            labeltext={'Building Number'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'h-10 rounded outline-none'} />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>

                                )}
                            />
                        </Col>

                        <Col lg={12} xs={24}>
                            <Controller
                                control={control}
                                name='addressType'
                                defaultValue={''}
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithSelect
                                            options={addressType}
                                            labeltext={'Address Type'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'outline-none h-10 rounded'}
                                        />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>

                                )}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16} className='mb-2'>
                        <Col lg={12} xs={24}>
                            <Controller
                                control={control}
                                name='phoneNo'
                                defaultValue={''}
                                render={({ field: { value, onChange }, fieldState }) => (
                                    <>
                                        <div className="flex flex-col py-4 w-full">
                                            <label
                                                className={'font-normal'}
                                                style={{ color: Colors.LabelColor }}
                                            >
                                                {'Phone Number'}
                                            </label>
                                            <input type={'string'}
                                                value={value}
                                                onChange={onChange}
                                                onBlur={(e) => {
                                                    const formattedNumber = phoneNumberFormat(e.target.value);
                                                    if (formattedNumber?.length === 14) {
                                                        clearErrors('Phone Number2')
                                                        onChange(formattedNumber)
                                                    }
                                                }}
                                                className={`pl-2.5 outline-none h-10 rounded`}
                                                style={{ background: Colors.LightGray }}
                                            />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </div>
                                    </>
                                )}
                            />

                        </Col>
                        <Col lg={12} xs={24}>
                            <Controller
                                control={control}
                                name='fax'
                                defaultValue={''}
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithField
                                            labeltext={'Fax'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'h-10 rounded outline-none'} />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Controller
                                control={control}
                                name='note'
                                defaultValue={''}
                                render={({ field: { value, onChange }, fieldState }) => (
                                    <>
                                        <TextField.Textarea
                                            style={{ color: Colors.LightGray }}
                                            placeholder={''}
                                            labeltext={'Property Notes'}
                                            value={value}
                                            onChange={onChange}
                                            className={'outline-none h-10 rounded'}
                                        />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={16}>
                        <Col lg={24} className='flex justify-end'  >
                            <Button.Basic
                                onClick={handleSubmit(ClientFormHandler)}
                                style={{ width: "10vw" }}
                                className=" h-12 yellow-bg border-none text-white border-transparent"
                                text={"Save"}
                                loader={companiesLoader && companiesLoader}
                            />
                        </Col>
                    </Row>
                </Form>

            </Row>
        </>
    )
}

export default ClientForm



// let streetType = [
//     {
//         value: 'Avenue',
//         label: 'Avenue',
//     },
//     {
//         value: 'Block',
//         label: 'Block',
//     },
//     {
//         value: 'Street',
//         label: 'Street',
//     },
//     {
//         value: 'Other',
//         label: 'Other',
//     },
// ]

let addressType = [
    {
        value: 'Commercial Property',
        label: 'Commercial Property',
    },
    {
        value: 'Residential',
        label: 'Residential',
    },
    {
        value: 'Other',
        label: 'Other',
    }
]