import React, { useEffect, useState } from 'react'
import RoleCheck from '../../components/RoleCheck/RoleCheck'
import { Button } from '../../components'
import LabelWithSelect from '../../components/LabelWithSelect'
import LabelWithField from '../../components/LabelWithField'
import BasicDrawer from '../../components/Drawer'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from 'react-query'
import { Col, Row } from 'antd'
import { ApiCaller, Colors, Utils } from '../../config'
import { Controller, useForm } from 'react-hook-form'
import { phoneNumberFormat } from '../../config/util/PhoneNumberFormatter'
import { ClientManagementAction, SiteManagementAction } from '../../store/actions'
import { yupResolver } from '@hookform/resolvers/yup'
import { EditInviteClient } from '../../config/Schema'
import Circular from '../../components/Loader/Circular'

const InviteClientEditDrawer = ({ openDrawer, onCloseDrawer, width, invitedClientDetailData, detailLoader, state, setState }) => {

  const dispatch = useDispatch();
  const [companyState, setCompanyState] = useState('')

  // properties/companies listing action.
  useEffect(() => {
    dispatch(SiteManagementAction.GetProperties())
  }, [])

  const companiesOption = useSelector(state => state?.SiteManagementReducer?.getProperties || []);

  // Form related Working
  let schema = EditInviteClient;
  const { handleSubmit, control, reset } = useForm(
    {
      resolver: yupResolver(schema),
      mode: 'onChange'
    }
  )

  useEffect(() => {
    invitedClientDetailData?.company ? invitedClientDetailData?.company[0]?.status == 'inActive' ? setCompanyState('Company is in-active, kindly select the new one.') : setCompanyState('') : setCompanyState('');
  }, [invitedClientDetailData])


  useEffect(() => {
    reset({
      firstName: invitedClientDetailData?.firstName ? invitedClientDetailData?.firstName : '-',
      lastName: invitedClientDetailData?.lastName ? invitedClientDetailData?.lastName : '-',
      phoneNo: invitedClientDetailData?.phoneNo ? invitedClientDetailData?.phoneNo : '-',
      companyId: invitedClientDetailData?.company ? invitedClientDetailData?.company[0]?.status == 'active' ? invitedClientDetailData?.company[0]?.id : '' : ''
    })
  }, [invitedClientDetailData])

  const inviteClientEditMutation = useMutation(async (data) => {
    let res = await ApiCaller.Put(`/client/${invitedClientDetailData?.id}`, data);
    return res
  },
    {
      onSuccess: (res) => {
        Utils.showMessage('success', res?.data?.message)
        dispatch(ClientManagementAction.GetInvitedClients(state));
        setState(state)
        onCloseDrawer()
      },
      onError: (err) => {
        Utils.showMessage('error', err?.response?.data?.message)
      }
    }
  )

  const FormDataHandler = (data) => {
    inviteClientEditMutation.mutate(data)
  }

  // just reseting the fields to their default values after closing of drawer
  const afterCloseDrawer = () => {
    reset()
  }



  return (
    <BasicDrawer
      openDrawer={openDrawer}
      onClose={onCloseDrawer}
      width={width}
      afterOpenChange={afterCloseDrawer}
      title={'Edit Invite Details'}
      children={
        <>
          {
            detailLoader ?
              <Circular
                spinning={detailLoader}
                size={40}
                color={Colors.Yellow}
                containerStyle={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              /> :
              <form className='w-full'>
                <Row className='w-full flex justify-between' >
                  <Row className='w-full flex items-center justify-between'>
                    {
                      companyState ?
                        <Col span={24}>
                          <p style={{color:Colors.Error}}>{companyState}</p>
                        </Col>
                        : ''
                    }
                    <Col span={12}>
                      <Controller
                        control={control}
                        name='firstName'
                        render={({ field, fieldState }) => (
                          <>
                            <LabelWithField labeltext={'First Name'} field={field} type={'text'} labelClass={'font-normal'} inputClass={'outline-none h-10 rounded'} />
                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                          </>
                        )}
                      />
                    </Col>

                    <Col span={11}>
                      <Controller
                        control={control}
                        name='lastName'
                        render={({ field, fieldState }) => (
                          <>
                            <LabelWithField labeltext={'Last Name'} field={field} type={'email'} labelClass={'font-normal'} inputClass={'outline-none h-10 rounded'} />
                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                          </>
                        )}
                      />
                    </Col>

                  </Row>


                  {/* <Row className='w-full flex items-center justify-between'>
          <Col span={12}>
            <Controller
              control={control}
              name='userName'
              render={({ field, fieldState }) => (
                <>
                  <LabelWithField labeltext={'User Name'} field={field} type={'text'} labelClass={'font-normal'} inputClass={'outline-none h-10 rounded'} />
                  {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                </>
              )}
            />
          </Col>

          <Col span={11}>
            <Controller
              control={control}
              name='email'
              render={({ field, fieldState }) => (
                <>
                  <LabelWithField labeltext={'Email'} field={field} type={'email'} labelClass={'font-normal'} inputClass={'outline-none h-10 rounded'} />
                  {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                </>
              )}
            />
          </Col>

        </Row> */}



                  <Row className='w-full flex items-center justify-between mb-10'>
                    <Col span={12}>
                      <Controller
                        control={control}
                        name='phoneNo'
                        defaultValue={''}
                        render={({ field: { value, onChange }, fieldState }) => (
                          <>
                            <div className="flex flex-col py-4 w-full">
                              <label
                                className={'font-normal'}
                                style={{ color: Colors.LabelColor }}
                              >
                                {'Phone Number'}
                              </label>
                              <input type={'string'}
                                value={value}
                                onChange={onChange}
                                onBlur={(e) => {
                                  const formattedNumber = phoneNumberFormat(e.target.value);
                                  if (formattedNumber?.length === 14) {
                                    onChange(formattedNumber)
                                  }
                                }}
                                className={`pl-2.5 outline-none h-10 rounded`}
                                style={{ background: Colors.LightGray }}
                              />
                              {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                            </div>
                          </>
                        )}
                      />
                    </Col>
                    <Col span={11}>
                      <Controller
                        control={control}
                        name='companyId'
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <>
                            <LabelWithSelect
                              options={companiesOption && companiesOption}
                              labeltext={'Management Company'}
                              field={field}
                              labelClass={'font-normal'}
                              inputClass={'outline-none h-10 rounded'}
                            />
                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}

                          </>
                        )}
                      />
                    </Col>
                  </Row>
                </Row>

                <Row className='w-full flex justify-end'>
                  <RoleCheck
                    children={
                      <Button.Basic
                        type={'submit'}
                        onClick={handleSubmit((data) => FormDataHandler(data))}
                        className="h-12 yellow-bg border-none text-white border-transparent"
                        text={"Edit Invite"}
                        loader={inviteClientEditMutation?.isLoading}
                      >
                      </Button.Basic>
                    }
                    task={'Edit'}

                  />
                </Row>

              </form>
          }


        </>
      }
    />
  )
}

export default InviteClientEditDrawer