import React, { useEffect, useState } from 'react'
import { Row, Col, Avatar, Form } from "antd";
import { CameraOutlined } from '@ant-design/icons';
import { Button, TextField, Text } from '..';
import { FaArrowLeftLong } from "react-icons/fa6";
import LabelWithField from '../LabelWithField';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { AddUserSchema } from '../../config/Schema';
import LabelWithSelect from '../LabelWithSelect';
import { ApiCaller, Colors, Utils } from '../../config';
import { FaPlus } from "react-icons/fa6";
import { AddLocation } from '../../config/Schema';
import { useNavigate } from 'react-router-dom';
import BasicDrawer from '../Drawer';
import Location from '../DrawerForms/location';
import { useQuery, useMutation } from 'react-query';
import { getAddresses, getAddressesLoc } from '../../config/getApis';
import { useDispatch, useSelector } from 'react-redux';
import { SiteManagementAction } from '../../store/actions';
import Circular from '../Loader/Circular';
import LabelWithMultipleSelect from '../LabelWithMultipleSelect';

const Document = ({

    nextStep,
    previousStep,
    currenStep

}) => {
    console.log(currenStep)
    const [state, setState] = useState(1)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [editLocationData, setEditLocationData] = useState({})

    // # CASE  
    // have to call the cleaner function because whenever the component render it gets the data from
    // which was previously stored in reducer. Case because if user is in the middle of the form and 
    // clicks to navigate to somewhere else the cleaner function will not going to call on that situation
    // it was getting called only on save btn and continue btn, So thats why it will get the data whenever
    // the component get render so to avoid that we called this function in useEffect whenever the 
    // component get first render on mounting stage this will trigger and by chance if any state is there
    // in reducer it will clear it.
    useEffect(() => {
        dispatch(SiteManagementAction.GetLocationDataCleaner())
    }, [])

    const [btnDisabled, setBtnDisabled] = useState({
        next: false,
        save: false,
        addMore: false
    })

    const [optValues, setOptValues] = useState([]);
    const [customValidation, setCustomValidation] = useState(false)

    const { data: addressesOptions } = useQuery('addressesOption', getAddressesLoc, {
        enabled: currenStep == 4
    })

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const locationData = useSelector(state => state?.SiteManagementReducer?.locationData || []);
    const locationLoader = useSelector(state => state?.SiteManagementReducer?.loader);
    console.log('locationData', locationData)

    const schema = AddLocation;

    const defaultValues = {
        name: '',
        description: ''
    }

    const { handleSubmit, control, reset, setValue, watch } = useForm(
        {
            resolver: yupResolver(schema),
            mode: 'onChange',
            defaultValues: defaultValues
        }
    )

    const onCloseDrawer = () => {
        setOpenDrawer(false)
    }


    const propertyMutation = useMutation(async (data) => {
        const { btnName, ...restData } = data;
        console.log(restData)
        try {
            const id = localStorage.getItem('PropertyKey');
            let res = await ApiCaller.Post(`/property/${id}/location`, restData);
            console.log(res)

            if (btnName == 'next') {
                dispatch(SiteManagementAction.GetLocationDataCleaner())
                setBtnDisabled({
                    save: false,
                    next: false,
                    addMore: false
                });
                reset(defaultValues)
                setOptValues([])
                nextStep();

            }
            else if (btnName == 'save') {
                dispatch(SiteManagementAction.GetLocationDataCleaner())
                setBtnDisabled({
                    save: false,
                    next: false,
                    addMore: false
                });
                localStorage.removeItem('PropertyKey')
                setOptValues([])
                reset(defaultValues)
                navigate('/site-management')
            }
            else if (btnName == 'more') {
                setBtnDisabled({
                    save: false,
                    next: false,
                    addMore: false
                });
                dispatch(SiteManagementAction.GetLocation())
                reset(defaultValues)
                setOptValues([])
            }

        }
        catch (err) {
            Utils.showMessage('error', err?.response?.data?.message);
            setBtnDisabled({
                save: false,
                next: false,
                addMore: false
            });
            return
        }

    })


    const SaveAndAddMoreHandler = (data, name) => {
        console.log(data)
        // api hit for post of a address
        if (optValues.length == 0) {
            setCustomValidation(true)
            return
        }
        else {
            setBtnDisabled({
                next: true,
                save: true,
                addMore: true
            })
            data.btnName = name;
            data.addressIds = optValues
            propertyMutation.mutate(data)
        }
    }

    const nextHandler = (data, name) => {
        if (optValues.length == 0) {
            setCustomValidation(true)
            return
        }
        else {
            console.log(data)
            setBtnDisabled({
                save: false,
                addMore: false,
                next: true
            })
            data.btnName = name;
            data.addressIds = optValues
            propertyMutation.mutate(data)
            // post api hit and continue
        }

    }

    const skipHandler = () => {
        setBtnDisabled({
            next: false,
            save: false,
            addMore: false
        })
        reset(defaultValues)
        setOptValues([])
        nextStep()
    }

    const saveHandler = (data, name) => {
        // post api hit and back to listing.
        if (optValues.length == 0) {
            setCustomValidation(true)
            return
        }
        else {
            setBtnDisabled({
                addMore: false,
                next: false,
                save: true
            })
            data.btnName = name;
            data.addressIds = optValues
            propertyMutation.mutate(data)
        }

    }


    const locationEditHandler = (location) => {
        setOpenDrawer(true)
        setEditLocationData(location)
        console.log(location);
    };


    const handleChange = (value) => {
        let AllValues = [];
        for (let opt of value) {
            if (opt == 'All') {
                console.log('All', value)
                for (let val of addressesOptions) {
                    if (val.value == 'All') continue
                    AllValues.push(val.value)
                }
                console.log(AllValues)
                setOptValues(AllValues)
                setCustomValidation(false)

            }
            else if (opt !== 'All') {
                setCustomValidation(false)
                setOptValues(value)
            }

        }
    }

    console.log(optValues)


    return (
        <div className='bg-white'>

            {/* edit bar/*/}
            {
                !locationLoader ?
                    locationData.map((location) => {
                        return (
                            <Row gutter={16} className='px-4 md:px-10 mb-2'>
                                <Col span={24} className='gry-bg p-6 rounded-lg'>
                                    <Row className='px-2'>
                                        <Col lg={12} md={12} xs={24} sm={24} className='mb-2'>
                                            <Text.Label
                                                text={'Property Name'}
                                            />
                                            <p className='text-xs'>{location?.name}</p>
                                        </Col>
                                        <Col lg={9} md={9} xs={24} sm={24} className='mb-2'>
                                            <Text.Label
                                                text={'Assign To'}
                                            />
                                            <p className='text-xs'>{
                                                location?.address?.map(address => {
                                                    return <>{`${address?.addressType} - ${address?.streetType} - ${address?.buildingNumber}`} <br /></>
                                                })
                                            }
                                            </p>
                                        </Col>

                                        <Col lg={3} md={3} xs={24} sm={24}>
                                            <Button.Basic
                                                // onClick={() => }
                                                className="w-full h-10 yellow-bg border-none text-white border-transparent"
                                                text={"Edit"}
                                                onClick={() => locationEditHandler(location)}
                                                disabled={currenStep === 7}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='px-2 my-3'>
                                        <Col span={24}>
                                            <Text.Label
                                                text={'Description'}
                                            />
                                            <p className='text-xs'>{location?.description}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    })

                    :
                    <Circular
                        spinning={locationLoader}
                        size={40}
                        color={Colors.Yellow}
                        containerStyle={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    />
            }

            <Row className='px-2 md:px-10'>
                <form layout="vertical" className='w-full'>
                    <Row gutter={16}>
                        <Col lg={12} xs={24}>
                            <Controller
                                control={control}
                                name='name'
                                defaultValue={''}
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithField
                                            labeltext={'Property Name'}
                                            field={field}
                                            type={'Location Name'}
                                            labelClass={'font-normal'}
                                            inputClass={'h-10 rounded outline-none'} />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}
                            />
                        </Col>
                        <Col lg={12} xs={24}>
                            <Controller
                                control={control}
                                name=''
                                defaultValue={''}
                                render={({ field, fieldState }) => (
                                    <>
                                        {/* <LabelWithSelect
                                            options={addressesOptions && addressesOptions}
                                            labeltext={'Assign to'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'outline-none h-10 rounded'}
                                        /> */}
                                        <LabelWithMultipleSelect
                                            inputClass={`outline-none ${optValues.length == 0 ? 'h-10' : 'h-fit'}`}
                                            labeltext={'Assign to'}
                                            labelClass={'font-normal'}
                                            onChange={handleChange}
                                            value={optValues && optValues}
                                            options={addressesOptions && addressesOptions}
                                        />
                                        {
                                            customValidation ? <p style={{ color: Colors.Error }}>Address is required</p> : ''
                                        }
                                        {/* {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>} */}
                                    </>
                                )}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Controller
                                control={control}
                                defaultValue={''}
                                name='description'
                                render={({ field: { value, onChange }, fieldState }) => (
                                    <>
                                        <TextField.Textarea
                                            style={{ color: Colors.LightGray }}
                                            placeholder={''}
                                            value={value}
                                            onChange={onChange}
                                            labeltext={'Description'}
                                            className={'outline-none h-10 rounded'}
                                        />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>

                                )}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24} className='flex align-middle justify-end items-center'>
                            <span className={`cursor-pointer hover:underline font-bold ${propertyMutation?.isLoading && 'pointer-events-none'}`} onClick={handleSubmit((data) => SaveAndAddMoreHandler(data, 'more'))}><span className='font-bold text-base'> + </span>Save And Add More</span>
                        </Col>
                    </Row>
                </form>

            </Row>
            <br />

            <Row gutter={[16, 16]} className='px-2 md:px-10' >
                <Col xs={24} lg={3} className='flex align-middle items-center'>
                    <FaArrowLeftLong className='mr-2' />
                    <span onClick={() => previousStep()} className={`cursor-pointer ${propertyMutation?.isLoading && 'pointer-events-none'}`}
                    >Back</span>
                </Col>
                <Col xs={24} lg={{ span: 4, offset: 9 }} >
                    <Button.Basic
                        onClick={handleSubmit((data) => saveHandler(data, 'save'))}
                        className="w-full h-12 yellow-bg border-none text-white border-transparent"
                        text={"Save"}
                        disabled={btnDisabled.next || btnDisabled.addMore ? true : false}
                        loader={btnDisabled.next || btnDisabled.addMore ? false : propertyMutation?.isLoading}
                    />
                </Col>
                <Col xs={24} lg={4} >
                    <Button.Basic
                        className="w-full h-12  orange-bg border-none text-white border-transparent"
                        onClick={handleSubmit((data) => nextHandler(data, 'next'))}
                        text={"Continue"}
                        disabled={btnDisabled.save || btnDisabled.addMore ? true : false}
                        loader={btnDisabled.save || btnDisabled.addMore ? false : propertyMutation?.isLoading}

                    />
                </Col>
                <Col xs={24} lg={4} >
                    <Button.Basic
                        className="w-full h-12 black-bg border-none text-white border-transparent"
                        onClick={skipHandler}
                        text={"Next"}
                        disabled={btnDisabled.next || btnDisabled.save || btnDisabled.addMore ? true : false}

                    />
                </Col>
            </Row>
            <br />



            <BasicDrawer
                openDrawer={openDrawer}
                title={'Edit Location'}
                onClose={() => onCloseDrawer()}
                width={'800'}
            >
                <Location
                    editLocationData={editLocationData}
                    onCloseDrawer={onCloseDrawer}
                />

            </BasicDrawer>

        </div>
    )
}

export default Document


var optionsSelect = [
    {
        value: 'jack',
        label: 'Jack',
    },
    {
        value: 'lucy',
        label: 'Lucy',
    },
    {
        value: 'Yiminghe',
        label: 'yiminghe',
    },
    {
        value: 'disabled',
        label: 'Disabled',
    },
]