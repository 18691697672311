import { Select, ConfigProvider } from "antd";
import React from "react";
import { Colors } from "../../config";

const SelectField = ({
  containerStyle,
  className,
  defaultValue,
  value,
  options,
  inputStyle,
  onSelect,
  onSearch,
  onChange,
  placeholder,
  allowClear,
  notFoundContent = true,
  size,
  mode,
  ...props
}) => {
  return (
      <ConfigProvider
        theme={{
          components: {
            Select: {
              /* here is your component tokens */
              optionSelectedBg: Colors.Yellow,
              optionActiveBg: Colors.LightGray,
              optionSelectedColor: Colors.White
            },
          },
        }}

      >
        <Select
          allowClear={false}
          className={className}
          defaultValue={defaultValue}
          value={value}
          notFoundContent={notFoundContent}
          options={options}
          style={inputStyle}
          onSelect={onSelect}
          onChange={onChange}
          placeholder={placeholder}
          size={size}
          mode={mode}
          {...props}
        />
      </ConfigProvider>
  );
};

export default SelectField;
