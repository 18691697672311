import React, { useEffect, useState } from 'react'
import { Row, Col, Avatar, Form } from "antd";
import { CameraOutlined } from '@ant-design/icons';
import { Button, Text, TextField } from '..';
import { FaArrowLeftLong } from "react-icons/fa6";
import LabelWithField from '../LabelWithField';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { AddLocation } from '../../config/Schema';
import LabelWithSelect from '../LabelWithSelect';
import { FaPlus } from "react-icons/fa6";
import { Colors } from '../../config';
import { useQuery } from 'react-query';
import { getAddresses, getAddressesLoc } from '../../config/getApis';
import { UseDispatch, useDispatch, useSelector } from 'react-redux';
import { SiteManagementAction } from '../../store/actions';
import { useParams } from 'react-router-dom';
import LabelWithMultipleSelect from '../LabelWithMultipleSelect';

const Location = ({ editLocationData, onCloseDrawer, title, isLocationDetail }) => {

    console.log('edit location data', editLocationData)
    const schema = AddLocation;
    const dispatch = useDispatch()

    const { id } = useParams();

    const [optValues, setOptValues] = useState([]);
    const [customValidation, setCustomValidation] = useState(false)

    useEffect(() => {
        let temp = [];
        reset({
            name: editLocationData?.name,
            // addressId: editLocationData?.address ? editLocationData?.address[0]?.id : '',
            description: editLocationData?.description
        })

        editLocationData?.address && editLocationData?.address?.map((add) => {
            temp.push({
                label: `${add?.addressType} - ${add?.streetType} - ${add?.buildingNumber}`,
                value: add.id
            })
        })
        setOptValues(temp)

    }, [editLocationData])

    const { data: addressesOptions } = useQuery('addressesOption', getAddressesLoc)

    const locationLoader = useSelector(state => state?.SiteManagementReducer?.loader);


    console.log(locationLoader)

    const { handleSubmit, control, reset, setValue, watch } = useForm(
        {
            resolver: yupResolver(schema),
            mode: "onChange"
        }
    )


    const handleChange = (value) => {
        let AllValues = [];
        for (let opt of value) {
            if (opt == 'All') {
                console.log('All', value)
                for (let val of addressesOptions) {
                    if (val.value == 'All') continue
                    AllValues.push(val.value)
                }
                console.log(AllValues)
                setOptValues(AllValues)
                setCustomValidation(false)

            }
            else if (opt !== 'All') {
                setCustomValidation(false)
                setOptValues(value)
            }

        }
    }

    const locationFormHandler = (data) => {
        if (title == 'Add') {
            if (optValues.length == 0) {
                setCustomValidation(true)
                return
            }
            else {
                data.addressIds = optValues;
                dispatch(SiteManagementAction.AddLocation(data, id, () => {
                    dispatch(SiteManagementAction.GetPropertyDetail(id))
                    onCloseDrawer()
                    reset()
                    setOptValues([])
                }))
            }
        }

        else {
            if (optValues.length == 0) {
                setCustomValidation(true)
                return
            }
            else {
                data.addressIds = optValues;
                dispatch(SiteManagementAction.EditLocation(data, editLocationData?.id, () => {
                    if (!isLocationDetail) {
                        dispatch(SiteManagementAction?.GetLocation());
                        reset()
                        setOptValues([])
                    }
                    else {
                        dispatch(SiteManagementAction.GetPropertyDetail(id))
                        reset()
                        setOptValues([])
                    }
                    onCloseDrawer()
                }))
            }
        }

        console.log("location edit data", data)
    };


    return (
        <Row className=''>
            <form layout="vertical" className='w-full'>
                <Row gutter={16} className='p-0'>
                    <Col lg={12} xs={24} >
                        <Controller
                            control={control}
                            name='name'
                            defaultValue={''}

                            render={({ field, fieldState }) => (
                                <>
                                    <LabelWithField
                                        labeltext={'Property Name'}
                                        field={field}
                                        type={'Location Name'}
                                        labelClass={'font-normal'}
                                        inputClass={'h-10 rounded outline-none'} />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>
                            )}
                        />
                    </Col>
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            name=''
                            defaultValue={''}

                            render={({ field, fieldState }) => (
                                <>
                                    {/* <LabelWithSelect
                                        options={addressesOptions}
                                        labeltext={'Assign to'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'outline-none h-10 rounded'}
                                    />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>} */}
                                    <LabelWithMultipleSelect
                                        defaultValue={optValues && optValues}
                                        inputClass={`outline-none ${optValues.length == 0 ? 'h-10' : 'h-fit'}`}
                                        labeltext={'Assign to'}
                                        labelClass={'font-normal'}
                                        onChange={handleChange}
                                        value={optValues && optValues}
                                        options={addressesOptions && addressesOptions}
                                    />
                                    {
                                        customValidation ? <p style={{ color: Colors.Error }}>Address is required</p> : ''
                                    }
                                </>

                            )}
                        />
                    </Col>
                </Row>

                <Row gutter={16} className='py-4'>
                    <Col span={24}>
                        <Controller
                            control={control}
                            name='description'
                            defaultValue={''}
                            render={({ field: { value, onChange }, fieldState }) => (
                                <>
                                    <TextField.Textarea
                                        style={{ color: Colors.LightGray }}
                                        placeholder={''}
                                        value={value}
                                        onChange={onChange}
                                        labeltext={'Description'}
                                        className={'outline-none h-10 rounded'}
                                    />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>
                            )}
                        />
                    </Col>
                </Row>
                <br />
                <Row gutter={16} className='justify-end'>
                    <Col lg={24} className='flex justify-end'>
                        <Button.Basic
                            onClick={handleSubmit(locationFormHandler)}
                            htmlType="submit"
                            className="h-12 px-6 yellow-bg border-none text-white border-transparent"
                            text={title == 'Edit' ? 'Edit' : "Save"}
                            type={"submit"}
                            loader={locationLoader}
                        />
                    </Col>
                </Row>
                {/* <Row gutter={16}>
                    <Col span={24} className='flex align-middle justify-end items-center underline'>
                        <FaPlus className='' /><span className=' font-bold'>Add More</span>
                    </Col>
                </Row> */}
            </form>

        </Row>
    )
}

export default Location