import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { IoMdClose } from "react-icons/io";
import { IoArrowBackOutline } from "react-icons/io5";
import TextField from '../TextField';
import { Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space } from 'antd';

const { Option } = Select;

const BasicDrawer = ({
    children,
    openDrawer,
    onClose,
    width,
    size,
    title,
    afterOpenChange

}) => {
    return (
        <Drawer
            title={title}
            placement="right"
            size={size}
            width={width}
            destroyOnClose={true}
            afterOpenChange={afterOpenChange}
            // onClose={() => onClose()}
            closeIcon={false}
            open={openDrawer}
            maskClosable={false}
            extra={
                <span className='flex item-center justify-center p-1' style={{ borderRadius: '50%', background: '#FEF3F5' }}>
                    
                    <IoMdClose style={{ color: '#F07080' }} className='cursor-pointer' size={25} onClick={() => onClose()} />
                </span>

            }
        >
            {children}
        </Drawer>

    );
};
export default BasicDrawer;


