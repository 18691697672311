import React, { useState, useEffect, useCallback } from 'react'
import TableWrapper from '../../components/Grid'
import { Avatar, Col, Row, Tabs, Tooltip } from 'antd'
import { LoadingOutlined, UserOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import NewFilterBar from '../../components/NewFilterBar';
import moment from 'moment';
import { FaTrash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { ApiCaller, Colors, Utils } from '../../config';
import { getEligibiity, getSchedules, getUserRoles } from '../../config/getApis';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ClientManagementAction, SiteManagementAction, UserManagementAction } from '../../store/actions';
import { Spin, Tag } from 'antd';
import ActionDropdown from '../../components/DropDown/actiondropdown';
import UserAddnEditDrawer from '../UserManagement/UserAddnEditDrawer';
import { phoneNumberFormat } from '../../config/util/PhoneNumberFormatter';
import UserDetailDrawer from '../UserManagement/UserDetailDrawer';
import InviteClients from './InviteClients'
import ClientForm from '../../components/DrawerForms/client';
import BasicDrawer from '../../components/Drawer';
import CompanyDetailDrawer from './CompanyDetailDrawer';
import CompanyEditDrawer from './CompanyEditDrawer';
import InviteClientEditDrawer from './InviteClientEditDrawer';
import { useMutation } from 'react-query';
import { Switch } from '../../components';
import { GrUpdate } from "react-icons/gr";
import { icons } from 'antd/es/image/PreviewGroup';


const { TabPane } = Tabs

const ClientManagement = () => {

    const dispatch = useDispatch();

    const user = useSelector((state) => state.AppReducer?.user)
    const isRole = user?.data?.userRole[0]

    const updateUserStatusLoader = useSelector(state => state?.UserManagementReducer?.loader);

    const { companiesData, invitedClientsData, loader, companiesDetailData, detailLoader, invitedClientDetailData } = useSelector(state => state?.ClientManagementReducer);
    let managementCompaniesData = companiesData?.data?.data;
    let companiesPagination = companiesData?.data?.paginate;
    let clientsData = invitedClientsData?.data?.data;
    let clientsPagination = invitedClientsData?.data?.paginate;

    const [state, setState] = useState({
        current: 1,
        limit: 10,
        searchVal: "",
        tabName: "profile",
        tabKey: 1,
        // sortBy: 'id',
        // sortOrder: "asc"
        filterStatus: ''
    });

    useEffect(() => {
        dispatch(ClientManagementAction.GetInvitedClients(state));
    }, [])

    // Drawers maintaining states.
    const [drawerStates, setDrawerStates] = useState({
        // openDrawer: false,
        userDetailDrawer: false,
        companiesDetailDrawer: false,
        companiesEditDrawer: false,
        inviteClientDrawer: false,
        addClientDrawer: false,
        drawerkey: null,
        userid: null,
    })


    const onCloseDrawer = () => {
        // for users
        if (drawerStates?.drawerkey == 1 && drawerStates.userDetailDrawer) {
            console.log(drawerStates)
            setDrawerStates({ ...drawerStates, userDetailDrawer: false, drawerkey: null, userid: null })
        }

        // else if (drawerStates.drawerkey == 1 && drawerStates.openDrawer) {
        //     setDrawerStates({ ...drawerStates, openDrawer: false, userDetailDrawer: false, userid: null, drawerkey: null })
        //     dispatch(UserManagementAction.UserDetailReset())
        // }

        else if (drawerStates.drawerkey !== 1 && drawerStates.inviteClientDrawer) {
            setDrawerStates({ ...drawerStates, inviteClientDrawer: false })
            console.log(drawerStates)
            return
        }

        // for companies
        else if (drawerStates.drawerkey !== 1 && drawerStates.addClientDrawer) {
            console.log(drawerStates)
            setDrawerStates({ ...drawerStates, addClientDrawer: false })
            return
        }

        else if (drawerStates.drawerkey == 1 && drawerStates.companiesDetailDrawer) {
            console.log(drawerStates)
            setDrawerStates({ ...drawerStates, companiesDetailDrawer: false, userid: null, drawerkey: null })
            return
        }

        else if (drawerStates.drawerkey == 1 && drawerStates.companiesEditDrawer) {
            console.log(drawerStates)
            setDrawerStates({ ...drawerStates, companiesDetailDrawer: false, companiesEditDrawer: false, userid: null, drawerkey: null })
        }
    }

    // user Select values filter handler
    const SelectFilterHandler = (value) => {
        setState({ ...state, current: 1, filterStatus: value })
        if (state?.tabKey == 1) {
            dispatch(ClientManagementAction.GetInvitedClients({ ...state, current: 1, filterStatus: value }))
        }
        else {
            dispatch(ClientManagementAction.GetCompanies({ ...state, current: 1, filterStatus: value }))
        }
    };

    // filter basis of values
    const SelectFilterValues = [
        {
            value: "active",
            label: "Active",
        },
        {
            value: "inActive",
            label: "In-active",
        },
        {
            label: "View All",
            value: ''
        }

    ];

    const debouceHandler = (query, delay) => {
        let timeout;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            if (state?.tabKey == 1) {
                return dispatch(ClientManagementAction.GetInvitedClients({ ...state, current: 1, searchVal: query }))
            }
            else {
                return dispatch(ClientManagementAction.GetCompanies({ ...state, current: 1, searchVal: query }))
            }
        }, delay);
    }

    // filter basis of search
    const handleInputChange = (e) => {
        const query = e?.target.value;
        setState({ ...state, current: 1, searchVal: e.target.value })
        debouceHandler(query, 2000)
    }

    // items for action
    const items = [
        {
            label: state?.tabKey == 1 ? "Edit Details" : "View Details",
            key: 1,
            icon: <FaEye color="#6A9BFF" />,
            backgroundColor: "#eef4ff",
            color: "#4582ff",
        },
        // {
        //     label: "Status Update",
        //     key: 2,
        //     // icon: <FaTrash color="#E71D36" />,
        //     icon: <GrUpdate color="#E71D36" />,
        //     backgroundColor: "#fef3f5",
        //     color: "#e93148",
        // },
    ];

    // Profile action handler
    const inviteActionHandler =
        (e, record) => {

            const { current, limit, searchVal } = state;
            let id = Number(record?.id);

            if (e.key == '1') {
                setDrawerStates({ ...drawerStates, userDetailDrawer: true, drawerkey: 1, userid: id });
                dispatch(ClientManagementAction.GetInvitedClientDetail(id))
            }

            // else {
            //     if (record?.status == 'active') {
            //         let payload = {
            //             id,
            //             status: 'inActive'
            //         }
            //         dispatch(UserManagementAction.UpdateUserStatus(payload, () => {
            //             dispatch(ClientManagementAction.GetInvitedClients({ ...state, current: current, limit: limit, searchVal: searchVal }))
            //         }))
            //         setState({ ...state, current: current, limit: limit, searchVal: searchVal })
            //     }
            //     else {
            //         let payload = {
            //             id,
            //             status: 'active'
            //         }
            //         dispatch(UserManagementAction.UpdateUserStatus(payload, () => {
            //             dispatch(ClientManagementAction.GetInvitedClients({ ...state, current: current, limit: limit, searchVal: searchVal }))
            //         }))
            //         setState({ ...state, current: current, limit: limit, searchVal: searchVal })
            //     }
            //     return
            // }
        }

    // companies action handler
    const companiesActionHandler = (e, record) => {

        let id = Number(record?.id);
        const { current, limit, searchVal } = state;

        if (e.key == '1') {
            setDrawerStates({ ...drawerStates, companiesDetailDrawer: true, drawerkey: 1, userid: id });
            dispatch(ClientManagementAction.GetCompanyDetails(id))
        }
        // else {
        //     if (record?.status == 'active') {
        //         let payload = {
        //             id,
        //             status: 'inActive'
        //         }
        //         setState({ ...state, current: current, limit: limit, searchVal: searchVal })
        //     }
        //     else {
        //         let payload = {
        //             id,
        //             status: 'active'
        //         }
        //         setState({ ...state, current: current, limit: limit, searchVal: searchVal })
        //     }
        //     return
        // }
    }

    const handlePaginationChange = (page, pageSize) => {
        setState({ ...state, current: page, limit: pageSize })
        if (state?.tabKey == 1) {
            dispatch(ClientManagementAction.GetInvitedClients({ ...state, current: page }))
            return
        }
        else {
            dispatch(ClientManagementAction.GetCompanies({ ...state, current: page }))
            return
        }
    }

    // Function to handle tab change
    const handleTabChange = (key) => {
        if (key == 1) {
            setState({ ...state, tabKey: key, tabName: 'profile', current: 1, searchVal: '', filterStatus: '' })
            const duplicateState = { current: 1, limit: state.limit, searchVal: '', filterStatus: '' }
            dispatch(ClientManagementAction.GetInvitedClients(duplicateState))

        }
        else {
            setState({ ...state, tabKey: key, tabName: 'companies', current: 1, searchVal: '', filterStatus: '' })
            const duplicateState = { current: 1, limit: state.limit, searchVal: '', filterStatus: '' }
            dispatch(ClientManagementAction.GetCompanies(duplicateState))
        }
    };


    // handle invite client
    const handleInviteClient = () => {
        setDrawerStates({ ...drawerStates, inviteClientDrawer: true })
    }

    // handle Add New Company 
    const handleAddClient = () => {
        setDrawerStates({ ...drawerStates, addClientDrawer: true })
    }

    // Company edit drawer handler
    const CompanyeditHandler = () => {
        setDrawerStates({ ...drawerStates, companiesDetailDrawer: false, companiesEditDrawer: true })
    }

    // profile status active and inactive handler
    const profileStatusHandler = (status, event, record) => {
        const { current, limit, searchVal } = state;
        let id = Number(record?.id);

        if (!status) {
            let payload = {
                id,
                status: 'inActive'
            }
            dispatch(UserManagementAction.UpdateUserStatus(payload, () => {
                dispatch(ClientManagementAction.GetInvitedClients({ ...state, current: current, limit: limit, searchVal: searchVal }))
            }))
            setState({ ...state, current: current, limit: limit, searchVal: searchVal })
        }
        else {
            let payload = {
                id,
                status: 'active'
            }
            dispatch(UserManagementAction.UpdateUserStatus(payload, () => {
                dispatch(ClientManagementAction.GetInvitedClients({ ...state, current: current, limit: limit, searchVal: searchVal }))
            }))
            setState({ ...state, current: current, limit: limit, searchVal: searchVal })
        }
    }

    // company status active and inactive handler
    const companyStatusHandler = (status, event, record) => {
        const { current, limit, searchVal } = state;
        let id = Number(record?.id);

        if (!status) {
            let payload = {
                id,
                status: 'inActive'
            }
            dispatch(ClientManagementAction.CompanyStatusUpdate(payload, () => {
                dispatch(ClientManagementAction.GetCompanies({ ...state, current: current, limit: limit, searchVal: searchVal }))
                dispatch(SiteManagementAction.GetProperties())

            }))
            setState({ ...state, current: current, limit: limit, searchVal: searchVal })
        }
        else {
            let payload = {
                id,
                status: 'active'
            }

            dispatch(ClientManagementAction.CompanyStatusUpdate(payload, () => {
                dispatch(ClientManagementAction.GetCompanies({ ...state, current: current, limit: limit, searchVal: searchVal }))
                dispatch(SiteManagementAction.GetProperties())

            }))
            setState({ ...state, current: current, limit: limit, searchVal: searchVal })
        }
    }


    // PROFILE USER COLUMNS
    const ClientsColumns = [
        {
            title: "Name",
            dataIndex: "userName",
            align: "left",
            render: (text, record) => (
                <Col span={24} className='flex justify-start items-center' >
                    {/* {
                        record?.profileImg ?
                            <Avatar className='mr-5' size={40} src={record?.profileImg[0]?.url} />
                            :
                            <Avatar className='mr-5' size={40} icon={<UserOutlined />} />
                    } */}
                    <span>{record?.firstName + ' ' + record?.lastName}</span>
                </Col>
            )
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text, record) => {
                return (
                    <Col span={24} className='w-full flex justify-start align-center flex-wrap'>
                        <span className='break-all'>{record?.email}</span>
                    </Col>

                )
            }

        },
        {
            title: 'Phone#',
            dataIndex: 'phoneNo',
            key: 'phone',
            render: (text, record) => {
                return (
                    <Col span={24} className='flex justify-start align-center'>
                        <span>{record?.phoneNo}</span>
                    </Col>
                )
            }
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            render: (text, record) => {
                return (

                    <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start' }}>
                        {
                            record?.company?.length > 0 &&
                            <Tag color={record?.company.length > 0 ? record?.company[0]?.status == 'active' ? "success" : "error" : ''}>{record?.company?.length > 0 ? record?.company[0]?.status : ''}</Tag>

                        }
                        <span>{record?.company?.length > 0 ? record?.company[0]?.name : '-'}</span>
                    </Col>
                )
            }
        },


        {
            title: 'Last Access',
            dataIndex: 'eligibilities',
            key: 'elegibility',
            render: (text, record) => {
                return (
                    <Col span={24} className='flex justify-start align-center'>
                        <span className=''>{record?.lastLogin ? record?.lastLogin : '-'}</span>
                    </Col>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                return (
                    <Col span={24} className='flex justify-start align-center gap-2'>
                        <span className={`rounded`} style={{ background: record?.status == 'active' ? Colors.Yellow : '#FF474D', color: Colors.White, padding: record?.status == 'active' ? '6px 16px' : '6px 10px' }}>{record?.status ? record?.status : '-'}</span>
                        <Switch.Basic className='cursor-pointer' checked={record?.status == 'active' ? true : false} onChange={(checked, event) => profileStatusHandler(checked, event, record)} containerStyle={{ paddingTop: '5px' }} />
                    </Col>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => {
                return (
                    <Col span={24} className='flex justify-center align-center' style={{}}>
                        <ActionDropdown
                            items={items}
                            placement={'bottom'}
                            labelStyle={{ color: Colors.Yellow, fontSize: '1.2rem', marginBottom: '7px' }}
                            onClick={(e) => inviteActionHandler(e, record)}
                            isRole={isRole}
                        />
                    </Col>
                )
            }
        },
    ];

    const managementColumns = [
        {
            title: "Company Name",
            dataIndex: "name",
            align: "left",
            render: (text, record) => (
                <Col span={24}>
                    <span className='break-all'>{record?.name}</span>
                </Col>
            )
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            align: "left",
            render: (text, record) => {
                return (
                    <Col span={24} className='flex justify-start align-center flex-wrap'>
                        {
                            record?.address?.map((item) => {
                                return <span key={item?.id} className='break-all'>{item?.streetNumber + ' ' + item?.streetName}</span>
                            })
                        }
                    </Col>
                )
            }
        },
        {
            title: 'Phone#',
            dataIndex: 'phoneNumber',
            key: 'phone',
            render: (text, record) => {
                return (
                    <Col span={24} className='flex justify-start align-center'>
                        <span>{record?.phoneNumber}</span>
                    </Col>
                )
            }
        },

        // {
        //     title: 'Email',
        //     dataIndex: 'email',
        //     key: 'email',
        //     render: (text, record) => {
        //         return (
        //             <Col span={24} className='w-full'>
        //                 <span className='break-all'>{record?.email || '-'}</span>
        //             </Col>

        //         )
        //     }

        // },

        {
            title: 'Properties',
            dataIndex: 'propertiesCount',
            key: 'propertiesCount',
            render: (text, record) => {
                return (
                    <Col span={24} className='flex justify-start align-center'>
                        <span>{record?.propertiesCount}</span>
                    </Col>
                )
            }
        },
        {
            title: 'Incidents',
            dataIndex: 'incidentsCount',
            key: 'incidentsCount',
            render: (text, record) => {
                return (
                    <Col span={24} className='flex justify-start align-center'>
                        <span>{record?.incidentsCount}</span>
                    </Col>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                return (
                    <Col span={24} className='flex justify-start align-center gap-2'>
                        <span className={`rounded`} style={{ background: record?.status == 'active' ? Colors.Yellow : '#FF474D', color: Colors.White, padding: record?.status == 'active' ? '6px 16px' : '6px 10px' }}>{record?.status ? record?.status : '-'}</span>
                        <Switch.Basic className='cursor-pointer' checked={record?.status == 'active' ? true : false} onChange={(checked, event) => companyStatusHandler(checked, event, record)} containerStyle={{ paddingTop: '5px' }} />
                    </Col>
                )
            }
        },


        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => {
                return (
                    <Col span={24} className='flex justify-center align-center' style={{}}>
                        <ActionDropdown
                            items={items}
                            placement={'bottom'}
                            labelStyle={{ color: Colors.Yellow, fontSize: '1.2rem', marginBottom: '7px' }}
                            onClick={(e) => companiesActionHandler(e, record)}
                            isRole={isRole}
                        />
                    </Col>
                )
            }
        },
    ];

    return (
        <>

            {/* // Profile tab working of Invite Client, user Details and Edit drawers  */}

            <InviteClients
                openDrawer={drawerStates?.inviteClientDrawer}
                onCloseDrawer={onCloseDrawer}
            />

            <InviteClientEditDrawer
                openDrawer={drawerStates?.userDetailDrawer}
                onCloseDrawer={onCloseDrawer}
                invitedClientDetailData={invitedClientDetailData}
                detailLoader={drawerStates?.userDetailDrawer && detailLoader}
                state={state}
                setState={setState}
                width={500}
            />

            {/* NewFilterBar is a component created which is on the top displaying different buttons based on the conditions
            and also rendering search and filter bar with the title as well. */}

            <NewFilterBar
                SelectFilterHandler={SelectFilterHandler}
                SelectFilterValues={SelectFilterValues}
                value={state?.filterStatus}
                // AddUserHandler={AddUserHandler}
                handleInputChange={handleInputChange}
                SearchValue={state?.searchVal}
                title='Client Management'
                isRole={isRole}
                style={{ fontSize: '14px', padding: '0px 4px' }}
                isManageSchedule={state?.tabKey}
                btnText1={'Invite Clients'}
                btnText2={'Add New Company'}
                btnText1Handler={handleInviteClient}
                btnText2Handler={handleAddClient}
            />

            {/* Add Client or Company working, Detail of Company along with the Edit drawer */}

            <BasicDrawer
                openDrawer={drawerStates?.addClientDrawer}
                title={'Add New Client/Management Company'}
                onClose={onCloseDrawer}
                width={'800'}
            >
                <ClientForm onCloseDrawer={onCloseDrawer} isCompaniesListing={true} />
            </BasicDrawer>

            <CompanyDetailDrawer
                openDrawer={drawerStates?.companiesDetailDrawer}
                onCloseDrawer={onCloseDrawer}
                isRole={isRole}
                companyDetailData={companiesDetailData}
                width={500}
                CompanyeditHandler={CompanyeditHandler}
                isLoading={detailLoader}
            />

            <CompanyEditDrawer
                openDrawer={drawerStates?.companiesEditDrawer}
                onCloseDrawer={onCloseDrawer}
                companyDetailData={companiesDetailData && companiesDetailData}
                setState={setState}
                state={state}
                width={600}
            />

            <Row gutter={[8, 32]} className='w-fit sm:w-fit lg:w-fit xl:w-full' style={{ background: "white", padding: '16px', borderRadius: "10px" }}>
                <Col lg={24}>
                    <Row className='w-full'>
                        <Col lg={24}>


                            <Tabs
                                defaultActiveKey="1"
                                itemSelectedColor="black"
                                style={{ marginTop: '-4rem', fontWeight: "500" }}
                                onChange={handleTabChange}
                                items={[
                                    {
                                        label: 'Profile',
                                        key: '1',
                                        children: <>


                                            <TableWrapper
                                                tableStyle={{ borderRadius: "40px", width: '100%' }}
                                                headerbg={"red"}
                                                tableColumns={ClientsColumns}
                                                tableData={clientsData}
                                                total={clientsPagination?.total}
                                                pageSize={state.limit}
                                                currentPage={state.current}
                                                handlePaginationChange={handlePaginationChange}
                                                loading={loader || updateUserStatusLoader ? <Spin /> : ''}
                                                rowClassName={'row-antd'}
                                            />

                                        </>,
                                    },

                                    {
                                        label: 'Management Companies',
                                        key: '2',
                                        children: <>

                                            <TableWrapper
                                                tableStyle={{ borderRadius: "40px", }}
                                                headerbg={"red"}
                                                tableColumns={managementColumns}
                                                tableData={managementCompaniesData}
                                                total={companiesPagination?.total}
                                                pageSize={state.limit}
                                                currentPage={state.current}
                                                handlePaginationChange={handlePaginationChange}
                                                loading={loader || updateUserStatusLoader ? <Spin /> : ''}
                                                rowClassName={'row-antd'}
                                            />

                                        </>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row >


        </>
    )

}





export default ClientManagement



