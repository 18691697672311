import { yupResolver } from '@hookform/resolvers/yup'
import { Row, Col } from 'antd'
import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import LabelWithField from '../LabelWithField'
import Button from '../Button'
import { FaArrowLeftLong } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import { AddPhoneNumber } from '../../config/Schema'
import { ApiCaller, Colors, Utils } from '../../config'
import { phoneNumberFormat } from '../../config/util/PhoneNumberFormatter'
import { useMutation } from 'react-query'


const PhoneNumber = (
    {
        previousStep,
        nextStep,
        currenStep
    }
) => {

    const schema = AddPhoneNumber;
    const { handleSubmit, control, reset, setValue, watch, clearErrors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    }
    )

    const navigate = useNavigate()

    const phoneNumberMutation = useMutation(async (data) => {
        console.log(data)
        const id = localStorage.getItem('PropertyKey');
        const res = await ApiCaller.Post(`/property/${id}/contact`, data);
        console.log(res)
        return res
    },

        {
            onSuccess: () => {
                reset()
                navigate('/site-management')
            },

            onError: (error) => {
                Utils.showMessage('error', error?.response?.data?.message);
                return
            }

        }

    )

    const saveHandler = (data) => {
        // post api hit and back to listing.

        const result = []
        for (let i = 1; i <= 5; i++) {
            if (data[`Phone Number${i}`] !== '' && data[`Phone Type${i}`] !== '') {
                result.push({
                    'number': data[`Phone Number${i}`],
                    'type': data[`Phone Type${i}`]
                })
            }
        }

        if (result.length !== 0) {
            phoneNumberMutation.mutate(result)
        } else {
            return
        }
    }

    return (
        <div className='bg-white'>
            <Row className='px-2 md:px-6'>
                <form className='w-full'>

                    <Row gutter={16} className='' align={'middle'} justify={'center'}>
                        <Col lg={11} xs={24}>
                            <Controller
                                control={control}
                                name='Phone Number1'
                                defaultValue={''}
                                render={({ field: { value, onChange }, fieldState }) => (
                                    <>
                                        <div className="flex flex-col py-4 w-full">
                                            <label
                                                className={'font-normal'}
                                                style={{ color: Colors.LabelColor }}
                                            >
                                                {'Phone Number'}
                                            </label>
                                            <input type={'string'}
                                                value={value}
                                                onChange={onChange}
                                                onBlur={(e) => {
                                                    const formattedNumber = phoneNumberFormat(e.target.value);
                                                    if (formattedNumber?.length === 14) {
                                                        clearErrors('Phone Number1')
                                                        onChange(formattedNumber)
                                                    }
                                                }}
                                                className={`pl-2.5 outline-none h-10 rounded`}
                                                style={{ background: Colors.LightGray }}
                                            />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </div>
                                    </>
                                )}
                            />
                        </Col>
                        <Col lg={11} xs={24}>
                            <Controller
                                control={control}
                                name='Phone Type1'
                                defaultValue={''}
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithField
                                            labeltext={'Phone Type'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'outline-none h-10 rounded'} />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}
                            />
                        </Col>

                    </Row>

                    <Row gutter={16} className='' align={'middle'} justify={'center'}>
                        <Col lg={11} xs={24}>
                            <Controller
                                control={control}
                                name='Phone Number2'
                                defaultValue={''}
                                render={({ field: { value, onChange }, fieldState }) => (
                                    <>
                                        <div className="flex flex-col py-4 w-full">
                                            <label
                                                className={'font-normal'}
                                                style={{ color: Colors.LabelColor }}
                                            >
                                                {'Phone Number'}
                                            </label>
                                            <input type={'string'}
                                                value={value}
                                                onChange={onChange}
                                                onBlur={(e) => {
                                                    const formattedNumber = phoneNumberFormat(e.target.value);
                                                    if (formattedNumber?.length === 14) {
                                                        clearErrors('Phone Number2')
                                                        onChange(formattedNumber)
                                                    }
                                                }}
                                                className={`pl-2.5 outline-none h-10 rounded`}
                                                style={{ background: Colors.LightGray }}
                                            />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </div>
                                    </>
                                )}
                            />
                        </Col>
                        <Col lg={11} xs={24}>
                            <Controller
                                control={control}
                                name='Phone Type2'
                                defaultValue={''}
                                render={({ field, fieldState }) => (
                                    <LabelWithField
                                        labeltext={'Phone Type'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'outline-none h-10 rounded'} />
                                )}
                            />
                        </Col>

                    </Row>

                    <Row gutter={16} className='' align={'middle'} justify={'center'}>
                        <Col lg={11} xs={24}>
                            <Controller
                                control={control}
                                defaultValue={''}
                                name='Phone Number3'
                                render={({ field: { value, onChange }, fieldState }) => (
                                    <>
                                        <div className="flex flex-col py-4 w-full">
                                            <label
                                                className={'font-normal'}
                                                style={{ color: Colors.LabelColor }}
                                            >
                                                {'Phone Number'}
                                            </label>
                                            <input type={'string'}
                                                value={value}
                                                onChange={onChange}
                                                onBlur={(e) => {
                                                    const formattedNumber = phoneNumberFormat(e.target.value);
                                                    if (formattedNumber?.length === 14) {
                                                        clearErrors('Phone Number3')
                                                        onChange(formattedNumber)
                                                    }
                                                }}
                                                className={`pl-2.5 outline-none h-10 rounded`}
                                                style={{ background: Colors.LightGray }}
                                            />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </div>
                                    </>
                                )}
                            />
                        </Col>
                        <Col lg={11} xs={24}>
                            <Controller
                                control={control}
                                defaultValue={''}
                                name='Phone Type3'
                                render={({ field, fieldState }) => (
                                    <LabelWithField
                                        labeltext={'Phone Type'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'outline-none h-10 rounded'} />
                                )}
                            />
                        </Col>

                    </Row>


                    <Row gutter={16} className='' align={'middle'} justify={'center'}>
                        <Col lg={11} xs={24}>
                            <Controller
                                control={control}
                                name='Phone Number4'
                                defaultValue={''}
                                render={({ field: { value, onChange }, fieldState }) => (
                                    <>
                                        <div className="flex flex-col py-4 w-full">
                                            <label
                                                className={'font-normal'}
                                                style={{ color: Colors.LabelColor }}
                                            >
                                                {'Phone Number'}
                                            </label>
                                            <input type={'string'}
                                                value={value}
                                                onChange={onChange}
                                                onBlur={(e) => {
                                                    const formattedNumber = phoneNumberFormat(e.target.value);
                                                    if (formattedNumber?.length === 14) {
                                                        clearErrors('Phone Number4')
                                                        onChange(formattedNumber)
                                                    }
                                                }}
                                                className={`pl-2.5 outline-none h-10 rounded`}
                                                style={{ background: Colors.LightGray }}
                                            />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </div>
                                    </>


                                )}
                            />
                        </Col>
                        <Col lg={11} xs={24}>
                            <Controller
                                control={control}
                                name='Phone Type4'
                                defaultValue={''}
                                render={({ field, fieldState }) => (
                                    <LabelWithField
                                        labeltext={'Phone Type'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'outline-none h-10 rounded'} />
                                )}
                            />
                        </Col>

                    </Row>
                    <Row gutter={16} className='' align={'middle'} justify={'center'}>
                        <Col lg={11} xs={24}>
                            <Controller
                                control={control}
                                defaultValue={''}
                                name='Phone Number5'
                                render={({ field: { value, onChange }, fieldState }) => (
                                    <>
                                        <div className="flex flex-col py-4 w-full">
                                            <label
                                                className={'font-normal'}
                                                style={{ color: Colors.LabelColor }}
                                            >
                                                {'Phone Number'}
                                            </label>
                                            <input type={'string'}
                                                value={value}
                                                onChange={onChange}
                                                onBlur={(e) => {
                                                    const formattedNumber = phoneNumberFormat(e.target.value);
                                                    if (formattedNumber?.length === 14) {
                                                        clearErrors('Phone Number5')
                                                        onChange(formattedNumber)
                                                    }
                                                }}
                                                className={`pl-2.5 outline-none h-10 rounded`}
                                                style={{ background: Colors.LightGray }}
                                            />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </div>

                                    </>

                                )}
                            />
                        </Col>
                        <Col lg={11} xs={24}>
                            <Controller
                                control={control}
                                defaultValue={''}
                                name='Phone Type5'
                                render={({ field, fieldState }) => (
                                    <LabelWithField
                                        labeltext={'Phone Type'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'outline-none h-10 rounded'} />
                                )}
                            />
                        </Col>

                    </Row>
                </form>
            </Row>

            <Row className='px-2 md:px-16 my-5 flex items-center justify-between' >
                <Col xs={24} lg={3} className='flex align-middle items-center'
                >
                    <FaArrowLeftLong className='mr-2' />
                    <span onClick={() => previousStep()} className={`cursor-pointer ${phoneNumberMutation?.isLoading && 'pointer-events-none'}`}
                    >Back</span>
                </Col>

                <Col xs={24} lg={{ span: 4, offset: 9 }} >
                    <Button.Basic
                        onClick={handleSubmit(saveHandler)}
                        className="w-full h-12 yellow-bg border-none text-white border-transparent"
                        text={"Save"}
                        type={'submit'}
                        loader={phoneNumberMutation?.isLoading}
                    // disabled={currenStep === 7}
                    />
                </Col>
                {/* <Col xs={24} lg={4} >
                    <Button.Basic
                        className="w-full h-12  orange-bg border-none text-white border-transparent"
                        onClick={() => nextStep()}
                        text={"Continue"}
                        disabled={currenStep === 7}
                    />
                </Col> */}
                {/* <Col xs={24} lg={4} >
                    <Button.Basic
                        className="w-full h-12 black-bg border-none text-white border-transparent"
                        onClick={() => nextStep()}
                        text={"Skip"}
                        disabled={currenStep === 7}
                    />
                </Col> */}
            </Row>
        </div>
    )
}

export default PhoneNumber