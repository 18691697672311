import React, { useEffect, useState } from 'react'
import { Row, Col, Avatar, Form } from "antd";
import { CameraOutlined } from '@ant-design/icons';
import { Button, TextField } from '..';
import { FaArrowLeftLong } from "react-icons/fa6";
import LabelWithField from '../LabelWithField';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { AddCheckPoints, UserSignin } from '../../config/Schema';
import LabelWithSelect from '../LabelWithSelect';
import { FaPlus } from "react-icons/fa6";
import { Colors } from '../../config';
import CheckBoxField from '../Select/Checkbox';
import QRCode from "react-qr-code";
import { useQuery } from 'react-query';
import { getAddresses, getIncidents } from '../../config/getApis';
import { UseDispatch, useDispatch, useSelector } from 'react-redux';
import { SiteManagementAction } from '../../store/actions';
import { useParams } from 'react-router-dom';


const CheckPoint = ({ checkPointEditData, onCloseDrawer, isCheckpointDetail = false, title }) => {


    const { id } = useParams();

    const { data: incidentOptions } = useQuery('incidentsOpt', getIncidents);

    const { data: addressOptions } = useQuery('addressOpt', getAddresses);

    const schema = AddCheckPoints;
    const { handleSubmit, control, reset, setValue, watch, getValues } = useForm(
        {
            resolver: yupResolver(schema),
            mode: 'onChange'
        }
    )

    // const val = getValues('code');
    const [codeValue, setCodeValue] = useState(checkPointEditData?.code ? checkPointEditData?.code : '')
    // const [qrValue, setQrValue] = useState(checkPointEditData?.code ? checkPointEditData?.code : '');

    const dispatch = useDispatch()
    const checkPointLoader = useSelector(state => state?.SiteManagementReducer?.loader);

    useEffect(() => {
        reset({
            name: checkPointEditData?.name,
            location: checkPointEditData?.location,
            code: checkPointEditData?.code,
            unit: checkPointEditData?.unit,
            reportNotes: checkPointEditData?.reportNotes,
            officerNotes: checkPointEditData?.officerNotes,
            addressId: checkPointEditData?.address?.id,
            propertiesIncidentTypesId: checkPointEditData?.incidentType?.id
        })
    }, [checkPointEditData])

    const codeHandler = (e) => {
        let query = e?.target.value;
        setCodeValue(query)
        // setting qr value
        // setQrValue(query)
    }

    const handleSubmitHandler = (data) => {
        console.log(data)
        data.code = codeValue;
        console.log(data)
        if (title == 'Add') {
            dispatch(SiteManagementAction.AddCheckpoint(data, id, () => {
                dispatch(SiteManagementAction.GetPropertyDetail(id))
                reset()
                setCodeValue('')
                // setQrValue('')
                onCloseDrawer()
            }))
        }
        else {
            dispatch(SiteManagementAction.EditCheckpoint(data, checkPointEditData?.id, (payload) => {
                if (!isCheckpointDetail) {
                    dispatch(SiteManagementAction.GetCheckPoints(payload))
                    setCodeValue('')
                    // setQrValue('')
                    reset()
                }
                else {
                    dispatch(SiteManagementAction.GetPropertyDetail(id));
                    setCodeValue('')
                    // setQrValue('')
                }

                onCloseDrawer()
            }))
        }

    };

    return (
        <Row className='px-2'>
            <form layout="vertical" className='w-full'>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            name='name'
                            defaultValue={''}
                            render={({ field, fieldState }) => (
                                <>
                                    <LabelWithField
                                        labeltext={'Checkpoint Name'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'h-10 rounded outline-none'} />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>
                            )}
                        />
                    </Col>
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            name='location'
                            defaultValue={''}
                            render={({ field, fieldState }) => (
                                <>
                                    <LabelWithField
                                        labeltext={'Checkpoint Location'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'h-10 rounded outline-none'} />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16} className='my-2'>
                    <Col lg={7} xs={24}>
                        <Controller
                            control={control}
                            name='code'
                            defaultValue={''}
                            render={({ field, fieldState }) => (
                                <>
                                    <div className="flex flex-col py-4 w-full">
                                        <label
                                            className={'font-normal'}
                                            style={{ color: Colors.LabelColor }}
                                        >
                                            QR/NFC Code Value
                                        </label>
                                        <input type={'text'}
                                            value={codeValue}
                                            onChange={codeHandler}
                                            className={`pl-2.5 outline-none h-10 rounded outline-none`}
                                            style={{ background: Colors.LightGray }}
                                        />
                                    </div>
                                </>
                            )}
                        />

                    </Col>

                    <Col lg={5} xs={0} sm={0}>
                        <QRCode value={codeValue} size={80} className='ml-6' />
                    </Col>

                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            name='addressId'
                            render={({ field, fieldState }) => (
                                <>
                                    <LabelWithSelect
                                        options={addressOptions}
                                        labeltext={'Property Address'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'outline-none h-10 rounded'}
                                    />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16} className='mb-2'>
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            name='unit'
                            render={({ field, fieldState }) => (
                                <>
                                    <LabelWithField
                                        labeltext={'Checkpoint Units'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'h-10 rounded outline-none'} />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>
                                // <>
                                //     <LabelWithSelect
                                //         options={checkPointsUnitOptions}
                                //         labeltext={'Checkpoint Units'}
                                //         field={field}
                                //         labelClass={'font-normal'}
                                //         inputClass={'outline-none h-10 rounded'}
                                //     />

                                //     {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                // </>
                            )}
                        />
                    </Col>

                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            name='propertiesIncidentTypesId'
                            render={({ field, fieldState }) => (
                                <>
                                    <LabelWithSelect
                                        options={incidentOptions}
                                        labeltext={'Checkpoint Incident Type'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'outline-none h-10 rounded'}
                                    />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>

                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            name='reportNotes'
                            render={({ field: { value, onChange }, fieldState }) => (
                                <>
                                    <TextField.Textarea
                                        style={{ color: Colors.LightGray }}
                                        placeholder={''}
                                        value={value}
                                        onChange={onChange}
                                        labeltext={'Report Notes'}
                                        className={'outline-none h-10 rounded'}
                                    />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>
                            )}
                        />
                    </Col>

                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            name='officerNotes'
                            render={({ field: { value, onChange }, fieldState }) => (
                                <>
                                    <TextField.Textarea
                                        style={{ color: Colors.LightGray }}
                                        placeholder={''}
                                        value={value}
                                        onChange={onChange}
                                        labeltext={'Officer Notes'}
                                        className={'outline-none h-10 rounded'}
                                    />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>
                            )}
                        />
                    </Col>
                </Row>
                <br />
                <Row gutter={16}>
                    <Col span={12} className='invisible'>
                        <CheckBoxField
                            label={'Auto Create Issue Type'}
                        />
                    </Col>
                </Row>
                <Row gutter={16} className='justify-end'>
                    <Col lg={24} className='flex justify-end'>
                        <Button.Basic
                            onClick={handleSubmit(handleSubmitHandler)}
                            htmlType="submit"
                            className="h-12 px-6 yellow-bg border-none text-white border-transparent"
                            text={"Save"}
                            type={"submit"}
                            loader={checkPointLoader}
                        />
                    </Col>

                </Row>
            </form>

        </Row>
    )
}

export default CheckPoint


var checkPointsUnitOptions = [
    {
        value: 'floor 1',
        label: 'Floor 1',
    },
    {
        value: 'floor 2',
        label: 'Floor 2',
    },
    {
        value: 'floor 3',
        label: 'Floor 3',
    },

]
