import { yupResolver } from '@hookform/resolvers/yup'
import { Row, Col } from 'antd'
import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import LabelWithField from '../LabelWithField'
import Button from '../Button'
import { FaArrowLeftLong } from 'react-icons/fa6'
import { AddPhoneNumber } from '../../config/Schema'
import { phoneNumberFormat } from '../../config/util/PhoneNumberFormatter'
import { Colors } from '../../config'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SiteManagementAction } from '../../store/actions'

const PhoneNumber = (
    {
        phoneEditData,
        closeDrawerHandler,
        isPhoneDetail = false
    }
) => {

    useEffect(() => {
        reset({
            'Phone Number1': phoneEditData ? phoneEditData[0]?.number : '',
            'Phone Type1': phoneEditData ? phoneEditData[0]?.type : '',
            'Phone Number2': phoneEditData ? phoneEditData[1]?.number : '',
            'Phone Type2': phoneEditData ? phoneEditData[1]?.type : '',
            'Phone Number3': phoneEditData ? phoneEditData[2]?.number : '',
            'Phone Type3': phoneEditData ? phoneEditData[2]?.type : '',
            'Phone Number4': phoneEditData ? phoneEditData[3]?.number : '',
            'Phone Type4': phoneEditData ? phoneEditData[3]?.type : '',
            'Phone Number5': phoneEditData ? phoneEditData[4]?.number : '',
            'Phone Type5': phoneEditData ? phoneEditData[4]?.type : '',
        })

    }, [phoneEditData])

    const dispatch = useDispatch();
    const { id } = useParams();

    const phoneLoader = useSelector(state => state?.SiteManagementReducer?.loader);

    const schema = AddPhoneNumber;
    const { handleSubmit, control, reset, setValue, watch, clearErrors } = useForm(
        {
            resolver: yupResolver(schema),
            mode: "onChange"
        }
    )

    const handleChange = (data) => {
        // i have to modify the data here from object to array of object with same key value pair
        // as per the req of api.
        const result = []
        for (let i = 1; i <= 5; i++) {
            if (data[`Phone Number${i}`] !== '' && data[`Phone Type${i}`] !== '') {
                result.push({
                    'number': data[`Phone Number${i}`],
                    'type': data[`Phone Type${i}`]
                })
            }
        }
        if (result.length !== 0) {

            if (phoneEditData.length == 0) {
                dispatch(SiteManagementAction.AddPhone(result, id, () => {
                    dispatch(SiteManagementAction.GetPropertyDetail(id));
                    reset()
                    closeDrawerHandler()
                }))
            }

            else {
                dispatch(SiteManagementAction.EditPhone(result, id, () => {
                    dispatch(SiteManagementAction.GetPropertyDetail(id));
                    reset()
                    closeDrawerHandler()
                }))
            }

        } else {
            return
        }
    };

    return (
        <div className='bg-white'>

            <form className='w-full'>

                <Row gutter={16} className='w-full' >
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            name='Phone Number1'
                            defaultValue={''}
                            render={({ field: { value, onChange }, fieldState }) => (
                                <>
                                    <div className="flex flex-col py-4 w-full">
                                        <label
                                            className={'font-normal'}
                                            style={{ color: Colors.LabelColor }}
                                        >
                                            {'Phone Number'}
                                        </label>
                                        <input type={'string'}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={(e) => {
                                                const formattedNumber = phoneNumberFormat(e.target.value);
                                                if (formattedNumber?.length === 14) {
                                                    clearErrors('Phone Number1')
                                                    onChange(formattedNumber)
                                                }
                                            }}
                                            className={`pl-2.5 outline-none h-10 rounded`}
                                            style={{ background: Colors.LightGray }}
                                        />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </div>
                                </>
                            )}
                        />
                    </Col>
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            name='Phone Type1'
                            defaultValue={''}
                            render={({ field, fieldState }) => (
                                <>
                                    <LabelWithField
                                        labeltext={'Phone Type'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'outline-none h-10 rounded'} />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>
                            )}
                        />
                    </Col>

                </Row>

                <Row gutter={16} className='w-full'>
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            name='Phone Number2'
                            defaultValue={''}
                            render={({ field: { value, onChange }, fieldState }) => (
                                <>
                                    <div className="flex flex-col py-4 w-full">
                                        <label
                                            className={'font-normal'}
                                            style={{ color: Colors.LabelColor }}
                                        >
                                            {'Phone Number'}
                                        </label>
                                        <input type={'string'}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={(e) => {
                                                const formattedNumber = phoneNumberFormat(e.target.value);
                                                if (formattedNumber?.length === 14) {
                                                    clearErrors('Phone Number2')
                                                    onChange(formattedNumber)
                                                }
                                            }}
                                            className={`pl-2.5 outline-none h-10 rounded`}
                                            style={{ background: Colors.LightGray }}
                                        />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </div>
                                </>
                            )}
                        />
                    </Col>
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            name='Phone Type2'
                            defaultValue={''}
                            render={({ field, fieldState }) => (
                                <LabelWithField
                                    labeltext={'Phone Type'}
                                    field={field}
                                    labelClass={'font-normal'}
                                    inputClass={'outline-none h-10 rounded'} />
                            )}
                        />
                    </Col>

                </Row>

                <Row gutter={16} className='w-full'>
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            defaultValue={''}
                            name='Phone Number3'
                            render={({ field: { value, onChange }, fieldState }) => (
                                <>
                                    <div className="flex flex-col py-4 w-full">
                                        <label
                                            className={'font-normal'}
                                            style={{ color: Colors.LabelColor }}
                                        >
                                            {'Phone Number'}
                                        </label>
                                        <input type={'string'}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={(e) => {
                                                const formattedNumber = phoneNumberFormat(e.target.value);
                                                if (formattedNumber?.length === 14) {
                                                    clearErrors('Phone Number3')
                                                    onChange(formattedNumber)
                                                }
                                            }}
                                            className={`pl-2.5 outline-none h-10 rounded`}
                                            style={{ background: Colors.LightGray }}
                                        />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </div>
                                </>
                            )}
                        />
                    </Col>
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            defaultValue={''}
                            name='Phone Type3'
                            render={({ field, fieldState }) => (
                                <LabelWithField
                                    labeltext={'Phone Type'}
                                    field={field}
                                    labelClass={'font-normal'}
                                    inputClass={'outline-none h-10 rounded'} />
                            )}
                        />
                    </Col>

                </Row>


                <Row gutter={16} className='w-full' >
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            name='Phone Number4'
                            defaultValue={''}
                            render={({ field: { value, onChange }, fieldState }) => (
                                <>
                                    <div className="flex flex-col py-4 w-full">
                                        <label
                                            className={'font-normal'}
                                            style={{ color: Colors.LabelColor }}
                                        >
                                            {'Phone Number'}
                                        </label>
                                        <input type={'string'}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={(e) => {
                                                const formattedNumber = phoneNumberFormat(e.target.value);
                                                if (formattedNumber?.length === 14) {
                                                    clearErrors('Phone Number4')
                                                    onChange(formattedNumber)
                                                }
                                            }}
                                            className={`pl-2.5 outline-none h-10 rounded`}
                                            style={{ background: Colors.LightGray }}
                                        />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </div>
                                </>


                            )}
                        />
                    </Col>
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            name='Phone Type4'
                            defaultValue={''}
                            render={({ field, fieldState }) => (
                                <LabelWithField
                                    labeltext={'Phone Type'}
                                    field={field}
                                    labelClass={'font-normal'}
                                    inputClass={'outline-none h-10 rounded'} />
                            )}
                        />
                    </Col>

                </Row>
                <Row gutter={16} className='w-full'>
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            defaultValue={''}
                            name='Phone Number5'
                            render={({ field: { value, onChange }, fieldState }) => (
                                <>
                                    <div className="flex flex-col py-4 w-full">
                                        <label
                                            className={'font-normal'}
                                            style={{ color: Colors.LabelColor }}
                                        >
                                            {'Phone Number'}
                                        </label>
                                        <input type={'string'}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={(e) => {
                                                const formattedNumber = phoneNumberFormat(e.target.value);
                                                if (formattedNumber?.length === 14) {
                                                    clearErrors('Phone Number5')
                                                    onChange(formattedNumber)
                                                }
                                            }}
                                            className={`pl-2.5 outline-none h-10 rounded`}
                                            style={{ background: Colors.LightGray }}
                                        />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </div>

                                </>

                            )}
                        />
                    </Col>
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            defaultValue={''}
                            name='Phone Type5'
                            render={({ field, fieldState }) => (
                                <LabelWithField
                                    labeltext={'Phone Type'}
                                    field={field}
                                    labelClass={'font-normal'}
                                    inputClass={'outline-none h-10 rounded'} />
                            )}
                        />
                    </Col>

                </Row>
            </form>

            <br />
            <Row gutter={16} className='justify-end pr-4'>
                <Col lg={24} className='flex justify-end'  >
                    <Button.Basic
                        onClick={handleSubmit(handleChange)}
                        htmlType="submit"
                        className=" h-12 px-6 yellow-bg border-none text-white border-transparent"
                        text={"Save"}
                        loader={phoneLoader}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default PhoneNumber