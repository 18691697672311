import {
    SIGNIN, SIGNIN_SUCCESS, SIGNIN_FAILURE,
    EMAIL_VERIFICATION, EMAIL_VERIFICATION_SUCCESS, EMAIL_VERIFICATION_FAILURE,
    OTP_VERIFICATION, OTP_VERIFICATION_SUCCESS, OTP_VERIFICATION_FAILURE,
    PASSWORD_RESET, PASSWORD_RESET_SUCCESS, PASSWORD_RESET_FAILURE,
    LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAILURE
} from '../constants'


export default class AppAction {

    static SignIn(payload, cb) {
        console.log("action signin payload",payload)
        return {
            type: SIGNIN,
            payload,
            cb
        }
    }
    static SignInSuccess(payload) {
        return {
            type: SIGNIN_SUCCESS,
            payload
        }
    }
    static SignInFailure() {
        return {
            type: SIGNIN_FAILURE
        }
    }
    static EmailVerification(payload,cb) {
        console.log(payload)
        return {
            type: EMAIL_VERIFICATION,
            payload,
            cb
        }
    }
    static EmailVerificationSuccess() {
        return {
            type: EMAIL_VERIFICATION_SUCCESS
        }
    }
    static EmailVerificationFailure() {
        return {
            type: EMAIL_VERIFICATION_FAILURE
        }
    }
    static OTPVerification(payload,cb) {
        console.log(cb)
        return {
            type: OTP_VERIFICATION,
            payload,
            cb
        }
    }
    static OTPVerificationSuccess() {
        return {
            type: OTP_VERIFICATION_SUCCESS
        }
    }
    static OTPVerificationFailure() {
        return {
            type: OTP_VERIFICATION_FAILURE
        }
    }
    static PasswordReset(payload,cb) {
        console.log(payload)
        return {
            type: PASSWORD_RESET,
            payload,
            cb
        }
    }
    static PasswordResetSuccess() {
        return {
            type: PASSWORD_RESET_SUCCESS
        }
    }
    static PasswordResetFaiure() {
        return {
            type: PASSWORD_RESET_FAILURE
        }
    }


    static Logout(payload,cb) {
        console.log(payload)
        return {
            type: LOGOUT,
            payload,
            cb
        }
    }
    static LogoutSuccess() {
        return {
            type: LOGOUT_SUCCESS
        }
    }
    static LogoutFailure() {
        return {
            type: LOGOUT_FAILURE
        }
    }

}