import { GET_ASSIGN_INCIDENT_DETAIL, GET_ASSIGN_INCIDENT_DETAIL_CLEANER, GET_ASSIGN_INCIDENT_DETAIL_FAILURE, GET_ASSIGN_INCIDENT_DETAIL_SUCCESS, GET_MANAGE_INCIDENTS, GET_MANAGE_INCIDENTS_DETAIL, GET_MANAGE_INCIDENTS_DETAIL_FAILURE, GET_MANAGE_INCIDENTS_DETAIL_SUCCESS, GET_MANAGE_INCIDENTS_FAILURE, GET_MANAGE_INCIDENTS_SUCCESS } from '../constants'


const initialState = {
    incidentData: [],
    incidentDetailData: [],
    assignIncidentDetail: [],
    loader: false,
    detailLoader: false,
    assignLoader:false
}

export default function ManageIncidentReducer(state = initialState, action) {
    switch (action.type) {
        case GET_MANAGE_INCIDENTS:
            state = {
                ...state,
                loader: true
            }
            break;

        case GET_MANAGE_INCIDENTS_SUCCESS:
            state = {
                ...state,
                loader: false,
                incidentData: action.payload
            }
            break;

        case GET_MANAGE_INCIDENTS_FAILURE:
            state = {
                ...state,
                loader: false,
            }
            break;

        case GET_MANAGE_INCIDENTS_DETAIL:
            state = {
                ...state,
                detailLoader: true,
            }
            break;


        case GET_MANAGE_INCIDENTS_DETAIL_SUCCESS:
            state = {
                ...state,
                detailLoader: false,
                incidentDetailData: action.payload
            }
            break;


        case GET_MANAGE_INCIDENTS_DETAIL_FAILURE:
            state = {
                ...state,
                detailLoader: false,
            }
            break;

        case GET_ASSIGN_INCIDENT_DETAIL:
            state = {
                ...state,
                assignLoader: true
            }
            break;

        case GET_ASSIGN_INCIDENT_DETAIL_SUCCESS:
            state = {
                ...state,
                assignLoader: false,
                assignIncidentDetail: action.payload
            }
            break;

        case GET_ASSIGN_INCIDENT_DETAIL_FAILURE:
            state = {
                ...state,
                assignLoader: false
            }
            break;

        case GET_ASSIGN_INCIDENT_DETAIL_CLEANER:
            state = {
                ...state,
                assignIncidentDetail: []
            }
            break;

        default:
            break;
    }

    return state
}