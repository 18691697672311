import React from 'react'
import {Row,Col} from 'antd'

const CommingSoon = () => {
  return (
    <Row>
        <Col span={24}>
            <h2 className='text-xl font-semibold'>Coming Soon</h2>
        </Col>
    </Row>
  )
}

export default CommingSoon