import React, { useState, useEffect, useCallback } from 'react'
import { Row, Col, Avatar, Form } from "antd";
import { CameraOutlined } from '@ant-design/icons';
import { Button, TextField } from '..';
import { FaArrowLeftLong } from "react-icons/fa6";
import LabelWithField from '../LabelWithField';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { AddGeoFence, UserSignin } from '../../config/Schema';
import LabelWithSelect from '../LabelWithSelect';
import { FaPlus } from "react-icons/fa6";
import { Colors } from '../../config';
import CheckBoxField from '../Select/Checkbox';
import QRCode from "react-qr-code";
import GoogleMapReact from 'google-map-react';
import { UseDispatch, useDispatch, useSelector } from 'react-redux';
import { SiteManagementAction } from '../../store/actions';
import { useParams } from 'react-router-dom';
import GoogleMapComp from '../GoogleMap';



const mapOptions = {
    zoomControl: false, // Disable zoom control
    fullscreenControl: false, // Disable fullscreen control
    streetViewControl: false, // Disable street view control
    mapTypeControl: false, // Disable map type control
    draggable: false, // Disable dragging
    scrollwheel: false // Disable scrollwheel zoom
};


const Geofence = ({ mapEditData, closeDrawerHandler, isGeofenceDetail = false, title }) => {

    const [currLocationJs, setCurrLocationJs] = useState({});
    const [position, setPosition] = useState([])
    const schema = AddGeoFence;

    const { id } = useParams()

    const dispatch = useDispatch();
    const mapLoader = useSelector(state => state?.SiteManagementReducer?.loader);

    const { handleSubmit, control, reset, setValue, watch } = useForm(
        {
            resolver: yupResolver(schema),
            mode: "onChange"
        }
    )

    useEffect(() => {
        reset({
            name: mapEditData?.name,
            latitude: mapEditData?.latitude,
            longitude: mapEditData?.longitude
        })
        setPosition([{
            lat: Number(mapEditData?.latitude),
            lng: Number(mapEditData?.longitude)
        }])
    }, [mapEditData])

    const geoFenceFormHandler = (data) => {
        console.log(data)
        if (title == 'Add') {
            dispatch(SiteManagementAction.AddGeofence(data, id, () => {
                dispatch(SiteManagementAction.GetPropertyDetail(id));
                reset()
                closeDrawerHandler()
            }))
        }
        else {
            dispatch(SiteManagementAction.EditGeofence(data, mapEditData?.id, () => {
                if (!isGeofenceDetail) {
                    dispatch(SiteManagementAction?.GetGeofence());
                    reset()
                }
                else {
                    dispatch(SiteManagementAction.GetPropertyDetail(id));
                    reset()
                }
                closeDrawerHandler()
            }))
        }

    };


    useEffect(() => {
        if(title == 'Add'){
            getLocationJs();
        }
        else{
            return
        }
    }, [title]);

    const getLocationJs = () => {
        navigator.geolocation.getCurrentPosition(position => {
            const { latitude, longitude } = position.coords;
            setPosition([{ lat: latitude, lng: longitude }]);
            setValue('latitude', latitude);
            setValue('longitude', longitude);
        });
    };

    const mapClickHandler = useCallback(
        (e) => {
            setPosition([e.latLng.toJSON()]);
            setValue('latitude', e?.latLng.toJSON()?.lat);
            setValue('longitude', e?.latLng.toJSON()?.lng);
        },
        [setPosition]
    );

    return (
        <Row className=''>
            <form layout="vertical" className='w-full'>
                <Row gutter={16}>
                    <Col lg={16} xs={24}>

                        <GoogleMapComp
                            pointerPosition={position}
                            mapClickHandler={mapClickHandler}
                        >
                        </GoogleMapComp>

                    </Col>
                    <Col lg={8} xs={24}>
                        <Row>
                            <Col span={24} className='h-28'>
                                <GoogleMapComp />
                            </Col>

                            <Col span={24}>
                                <Controller
                                    control={control}
                                    name='name'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField
                                                labeltext={'Geofence Name'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'h-10 rounded outline-none'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>

                            <Col span={24}>
                                <Controller
                                    control={control}
                                    name='latitude'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField
                                                labeltext={'Latitude'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'h-10 rounded outline-none'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>

                            <Col span={24}>
                                <Controller
                                    control={control}
                                    defaultValue={''}
                                    name='longitude'
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField
                                                labeltext={'Longitude'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'h-10 rounded outline-none'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>

                            <Col span={24}>
                                <p className='text-center mt-4 text-gray-100 px-10' style={{ color: "#8A94A6" }}>
                                    Click and Drag the red map marker to move the GeoFence
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <br />
                <Row gutter={16} className='justify-end'>
                    <Col lg={24} className='flex justify-end'>
                        <Button.Basic
                            onClick={handleSubmit(geoFenceFormHandler)}
                            htmlType="submit"
                            className="h-12 px-6 yellow-bg border-none text-white border-transparent"
                            text={"Save"}
                            type={"submit"}
                            loader={mapLoader}
                        />
                    </Col>
                </Row>
                {/* <Row gutter={16}>
                    <Col span={24} className='flex align-middle justify-end items-center underline'>

                        <FaPlus className='' /><span className=' font-bold'>Add More</span>
                    </Col>
                </Row> */}
            </form>

        </Row>
    )
}

export default Geofence

var optionsSelect = [
    {
        value: 'jack',
        label: 'Jack',
    },
    {
        value: 'lucy',
        label: 'Lucy',
    },
    {
        value: 'Yiminghe',
        label: 'yiminghe',
    },
    {
        value: 'disabled',
        label: 'Disabled',
    },
]
