import { Button, ConfigProvider } from "antd";
import React from "react";

const Basic = ({
  disabled,
  icon,
  loader,
  shape,
  type,
  size,
  color,
  text,
  onClick,
  containerStyle,
  buttonStyle,
  className,
  sx,
  ...props
}) => (
  <div style={containerStyle}>
    <ConfigProvider theme={{
      components: {
        Button: {
          colorPrimaryBorderHover: 'white',
          colorPrimaryHover: 'white',
          colorPrimary: 'white',
          colorPrimaryActive: 'white',
          colorPrimaryTextHover: 'white',
          defaultHoverBg: "",
          defaultActiveBg: ""
        }
      }
    }}>
      <Button
        disabled={disabled}
        icon={icon}
        loading={loader}
        prefix="t"
        shape={shape}
        size={size} // 'small'| 'medium'| 'large'
        htmlType={type}
        color={color}
        onClick={onClick}
        style={buttonStyle}
        className={className}
        {...props}
      >
        {text}
      </Button>
    </ConfigProvider>
  </div>
);

export default Basic;


