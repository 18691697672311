import { GET_ASSIGN_INCIDENT_DETAIL, GET_ASSIGN_INCIDENT_DETAIL_CLEANER, GET_ASSIGN_INCIDENT_DETAIL_FAILURE, GET_ASSIGN_INCIDENT_DETAIL_SUCCESS, GET_MANAGE_INCIDENTS, GET_MANAGE_INCIDENTS_DETAIL, GET_MANAGE_INCIDENTS_DETAIL_FAILURE, GET_MANAGE_INCIDENTS_DETAIL_SUCCESS, GET_MANAGE_INCIDENTS_FAILURE, GET_MANAGE_INCIDENTS_SUCCESS } from '../constants'

export default class ManageIncidentsAction {
    static GetManageIncidents(payload) {
        console.log(payload)
        return {
            type: GET_MANAGE_INCIDENTS,
            payload
        }
    }

    static GetManageIncidentsSuccess(payload) {
        console.log(payload)
        return {
            type: GET_MANAGE_INCIDENTS_SUCCESS,
            payload
        }
    }

    static GetManageIncidentsFailure() {
        return {
            type: GET_MANAGE_INCIDENTS_FAILURE
        }
    }

    static GetManageIncidentDetail(payload, cb) {
        console.log(payload)
        return {
            type: GET_MANAGE_INCIDENTS_DETAIL,
            payload,
            cb
        }
    }

    static GetManageIncidentDetailSuccess(payload) {
        return {
            type: GET_MANAGE_INCIDENTS_DETAIL_SUCCESS,
            payload
        }
    }

    static GetManageIncidentDetailfailure() {
        return {
            type: GET_MANAGE_INCIDENTS_DETAIL_FAILURE,
        }
    }

    static GetAssignIncidentDetail(payload,cb){
        return {
            type: GET_ASSIGN_INCIDENT_DETAIL,
            payload,
            cb
        }
    }

    static GetAssignIncidentDetailSuccess(payload){
        return {
            type: GET_ASSIGN_INCIDENT_DETAIL_SUCCESS,
            payload
        }
    }

    static GetAssignIncidentDetailFailure(){
        return {
            type: GET_ASSIGN_INCIDENT_DETAIL_FAILURE,
        }
    }

    static GetAssignIncidentDetailCleaner(){
        return {
            type: GET_ASSIGN_INCIDENT_DETAIL_CLEANER
        }
    }
}