import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

const DoughnutChart = ({ data,centerText }) => {
    const options = {
        plugins: {
            legend: {
                display: true, // Set to false to hide legend
                position:'bottom',
            },
        },
        cutout: '70%' // inner space of a chart
    };
    const plugins = [{
        beforeDraw: function(chart) {
         var width = chart.width,
             height = chart.height,
             ctx = chart.ctx;
             ctx.restore();
             var fontSize = (height / 160).toFixed(2);
             ctx.font = fontSize + "em sans-serif";
             ctx.textBaseline = "center";
             var text = centerText,
             textX = Math.round((width - ctx.measureText(text).width) / 2),
             textY = height / 2.2;
             ctx.fillText(text, textX, textY);
             ctx.save();
        } 
      }]

    return (
        <Chart type='doughnut' data={data} options={options} className='w-11/12 h-full'  />
    );
};

export default DoughnutChart;

