import React from "react";
import { Row, Col, Table, Empty, Button,ConfigProvider } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

// import LoaderGif from "../../assets/images/ie_loader.gif";

const TableWrapper = ({
    tableData,
    tableColumns,
    onHandleChange,
    previousPage,
    metaData,
    isLoader,
    allowedAccountLoading,
    fromOrder,
    sorter,
    headerBg,
    loading,
    total,
    pageSize,
    currentPage,
    handlePaginationChange,
    rowClassName,
    tableStyle,
    title,
    position,
    size,

}) => {
    let locale = {
        emptyText: 'No User',
    };
    return (
        <>
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            /* here is your component tokens */
                            cellFontSize: 14
                        },
                    },
                }}
            >
                <Table
                    style={tableStyle}
                    dataSource={tableData}
                    columns={tableColumns}
                    loading={loading}
                    rowClassName={rowClassName}
                    title={title}
                    size={size}
                    pagination={{
                        total: total,
                        pageSize: pageSize,
                        current: currentPage,
                        onChange: handlePaginationChange,
                        showSizeChanger: false,
                        position: "bottom center"
                    }}
                    locale={locale}

                />
            </ConfigProvider>

        </>
    )
}

export default TableWrapper;