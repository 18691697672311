import { ADD_USER, ADD_USER_SUCCESS, ADD_USER_FAILURE, GET_USERS, GET_USERS_SUCCESS, GET_USERS_FAILURE, UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE, UPDATE_USER_STATUS, UPDATE_USER_STATUS_SUCCESS, UPDATE_USER_STATUS_FAILURE, GET_USER_DETAIL, GET_USER_DETAIL_SUCCESS, GET_USER_DETAIL_FAILURE, USER_DETAIL_RESET } from '../constants'

const initialState = {
    UserData: [],
    UserDetailData: [],
    AddUserData: [],
    loader: false,
    AddUserLoader: false,
    DetailUserLoader: false,
}

export default function UserManagementReducer(state = initialState, action) {
    switch (action.type) {
        case GET_USERS:
            state = {
                ...state,
                loader: true
            }
            break;
        case GET_USERS_SUCCESS:
            state = {
                ...state,
                loader: false,
                UserData: action.payload
            }
            break;
        case GET_USERS_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;
        case GET_USER_DETAIL:
            state = {
                ...state,
                DetailUserLoader: true,
            }
            break;
        case GET_USER_DETAIL_SUCCESS:
            state = {
                ...state,
                DetailUserLoader: false,
                UserDetailData: action.payload
            }
            break;
        case GET_USER_DETAIL_FAILURE:
            state = {
                ...state,
                DetailUserLoader: false
            }
            break;
        case UPDATE_USER_STATUS:
            state = {
                ...state,
                loader: true
            }
            break;

        case UPDATE_USER_STATUS_SUCCESS:
            state = {
                ...state,
                loader: false
            }
            break;

        case UPDATE_USER_STATUS_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;

        case ADD_USER:
            state = {
                ...state,
                AddUserLoader: true
            }
            break;

        case ADD_USER_SUCCESS:
            state = {
                ...state,
                AddUserData: action.payload,
                AddUserLoader:false,
            }
            break;

        case ADD_USER_FAILURE:
            state = {
                ...state,
                AddUserLoader: false
            }
            break;

        case UPDATE_USER:
            state = {
                ...state,
                AddUserLoader: true
            }
            break;

        case UPDATE_USER_SUCCESS:
            state={
                ...state,
                AddUserLoader: false
            }
            break;

        case UPDATE_USER_FAILURE:
            state = {
                ...state,
                AddUserLoader: false
            }
            break;

        case USER_DETAIL_RESET:
            state = {
                ...state,
                UserDetailData: []
            }
            break;

        default:
            state = { ...state }

    }
    return state
}