import React, { useState } from 'react'
import BasicDrawer from '../../components/Drawer'
import Circular from '../../components/Loader/Circular'
import { Row, Col, Avatar } from 'antd'
import BasicText from '../../components/Text/BasicText'
import { Colors } from '../../config'
import moment from 'moment'
import { AddUserSchema } from '../../config/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import LabelWithSelect from '../../components/LabelWithSelect';
import { Typography } from 'antd';
import LoadOnScroll from '../../components/LoadOnScroll'
import { useNavigate } from 'react-router-dom'
import UserDetailDrawer from '../UserManagement/UserDetailDrawer'
import { useDispatch, useSelector } from 'react-redux'
import { UserManagementAction } from '../../store/actions'
import UserAddnEditDrawer from '../UserManagement/UserAddnEditDrawer'
import { phoneNumberFormat } from '../../config/util/PhoneNumberFormatter'
import { UserOutlined } from '@ant-design/icons'

const { Title, Text } = Typography;

const ScheduleDetailDrawer = ({
    setDrawerStates,
    drawerStates,
    onCloseDrawer,
    detailDrawerReset,
    ScheduleDetailDrawer,
    clientDetailData,
    eventName,
    eligibilityData,
    scheduleData,
    rolesData

}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();


    // const userData = useSelector(state => state?.UserManagementReducer?.UserData);
    // const loader = useSelector(state => state?.UserManagementReducer?.loader);
    const userDetailData = useSelector(state => state?.UserManagementReducer?.UserDetailData);
    const userDetailLoader = useSelector(state => state?.UserManagementReducer?.DetailUserLoader);
    const userAddLoader = useSelector(state => state?.UserManagementReducer?.AddUserLoader);

    const user = useSelector((state) => state.AppReducer?.user)
    const isRole = user?.data?.userRole[0]


    if (rolesData && isRole === 'manager') {
        for (let i = 0; i < rolesData.length; i++) {
            if (rolesData[i]?.label === 'admin') {
                rolesData.splice(i, 1);
                i--;
            }
        }

    }

    // for property scroll items
    const [propertyScrollItems, setPropertyScrollItems] = useState([])
    const [propertyHasMore, setPropertyHasMore] = useState(true)

    // for user scroll items
    const [userScrollItems, setUserScrollItems] = useState([])
    const [userHasMore, setUserHasMore] = useState(true)


    const [img, setimg] = useState(null);
    const [readFile, setReadFile] = useState(null);
    // Image Validation handling
    const [imgValue, setImageValue] = useState({
        imgSizeValidation: '',
        imgTypeValidation: '',
        isRequired: '',
    });

    const propertyScrollDataHandler = (data) => {
        let newData = data?.data?.data;
        setPropertyScrollItems(prevItems => [...prevItems, ...newData]);
        return newData
    }

    const userScrollDataHandler = (data) => {
        let newData = data?.data?.data;
        setUserScrollItems(prevItems => [...prevItems, ...newData]);
        return newData
    }

    const propertyDetailHandler = (record) => {
        navigate(`/property-details/${record}`)
    }

    const userDetailHandler = (record) => {
        if (drawerStates?.drawerkey == 1) {
            setDrawerStates({ ...drawerStates, userDetailDrawer: true, userid: record })
            let payload = {
                id: Number(record),
            }
            dispatch(UserManagementAction.GetUserDetail(payload))
        }
    }

    // user detail drawer close handler
    const userDetailDrawerClose = () => {
        setDrawerStates({ ...drawerStates, userDetailDrawer: false })
    }

    // user edit drawer close handler
    const userEditDrawerClose = () => {
        if (drawerStates?.drawerkey == 1) {
            setDrawerStates({ ...drawerStates, openDrawer: false, userDetailDrawer: false, userid: null })
            setImageValue({ imgSizeValidation: '', imgTypeValidation: '', isRequired: '' })
        }

    }

    // user edit drawer open handler
    const UserEditHandler = () => {
        setDrawerStates({ ...drawerStates, userDetailDrawer: false, openDrawer: true })
        if (userDetailData?.profileImg[0]?.url) {
            setReadFile(userDetailData?.profileImg[0]?.url)
        }
    }

    // form data
    const FormDataHandler = (data) => {
        if (imgValue?.imgSizeValidation || imgValue.imgTypeValidation) {
            return
        }
        //setting the key null is important as when the buttons inside the drawers clicked the key is already 
        //select to 1 from the dropdown selection and for the future actions it will remain 1 if we didn't -
        //handle it.
        else {
            if (drawerStates.drawerkey == 1) {
                // for dispatching form action about UPDATE USER CASE.
                let certificationInfo = {};
                for (const key in data) {
                    if (data[key] instanceof Date) {
                        certificationInfo[key] = moment(data[key]).format('DD-MM-YYYY');
                        delete data[key]
                    }
                }

                data['certificationInfo'] = certificationInfo;
                const id = Number(drawerStates?.userid);

                certificationInfo &&
                    dispatch(UserManagementAction.UpdateUser(data, id, () => {
                        dispatch(UserManagementAction.UserDetailReset());
                        setDrawerStates({ ...drawerStates, openDrawer: false, userDetailDrawer: false, userid: null })
                        setReadFile(null)
                    }))
                return
            }
            return
        }
    }

    const afterDrawerCloseHandler = () => {
        if (!drawerStates?.ScheduleDetailDrawer) {
            setUserScrollItems([])
            setUserHasMore(true)
            setPropertyScrollItems([])
            setPropertyHasMore(true)
        }
    }

    const drawerKeyHandler = (isOpen,reset) => {
        if (!isOpen) {
            setReadFile(null)
            reset()
        }
    }


    return (
        <>
            <BasicDrawer
                openDrawer={drawerStates?.ScheduleDetailDrawer}
                title={'Schedule Details'}
                onClose={() => onCloseDrawer()}
                width={'400'}
                afterOpenChange={afterDrawerCloseHandler}
            >

                {
                    ScheduleDetailDrawer ?

                        <Circular
                            spinning={ScheduleDetailDrawer}
                            size={40}
                            color={"#FF9900"}
                            containerStyle={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        />

                        :

                        <Row className='w-full'>


                            <Row gutter={16} className="mb-4 w-full">
                                <Col lg={24} xs={24} className="">
                                    <span
                                        className={"h-10 rounded w-full font-normal flex items-center px-2 cursor-pointer"}
                                        style={{ background: '#F9F9FA', color: '#C0C5CE' }}
                                    >{eventName && eventName}</span>
                                </Col>
                            </Row>


                            <Row className='w-full mt-4 break-normal mb-4'>
                                <Title level={5}>Properties</Title>

                                <LoadOnScroll
                                    endPoint={`/schedule/${drawerStates?.actionid}/properties`}
                                    hasMore={propertyHasMore}
                                    height={170}
                                    setHasMore={setPropertyHasMore}
                                    scrollItems={propertyScrollItems}
                                    scrollDataHandler={propertyScrollDataHandler}
                                    children={
                                        propertyScrollItems?.map((property, index) => (
                                            <div key={index} onClick={() => propertyDetailHandler(property?.id)}>
                                                <span className='underline cursor-pointer'>{property?.name}</span>
                                            </div>
                                        ))
                                    }
                                />
                            </Row>

                            <Row className='w-full mt-4 break-normal mb-4'>
                                <Title level={5} >Assigned Users</Title>

                                <LoadOnScroll
                                    endPoint={`/schedule/${drawerStates?.actionid}/users`}
                                    hasMore={userHasMore}
                                    height={200}
                                    style={{ marginTop: '5px' }}
                                    setHasMore={setUserHasMore}
                                    scrollItems={userScrollItems}
                                    scrollDataHandler={userScrollDataHandler}
                                    children={
                                        userScrollItems?.map((user, index) => (
                                            <div key={index} onClick={() => userDetailHandler(user?.id)} className='flex gap-2 items-center mb-3 cursor-pointer'>
                                                {
                                                    user?.profileImg ?
                                                        <Avatar src={user?.profileImg && user?.profileImg[0]?.url} />
                                                        :
                                                        <Avatar icon={<UserOutlined />} />
                                                }
                                                <span>{user?.firstName + ' ' + user?.lastName}</span>
                                            </div>
                                        ))
                                    }
                                />
                            </Row>
                        </Row>
                }


            </BasicDrawer>


            <UserDetailDrawer
                drawerStates={drawerStates?.ScheduleDetailDrawer && drawerStates}
                onCloseDrawer={userDetailDrawerClose}
                userDetailLoader={userDetailLoader}
                userDetailData={userDetailData}
                UserEditHandler={UserEditHandler}
            />


            <UserAddnEditDrawer
                drawerStates={drawerStates?.ScheduleDetailDrawer && drawerStates}
                onCloseDrawer={userEditDrawerClose}
                userDetailData={userDetailData}
                phoneNumberFormat={phoneNumberFormat}
                FormDataHandler={FormDataHandler}
                userAddLoader={userAddLoader}
                eligibilityData={eligibilityData}
                scheduleData={scheduleData}
                rolesData={rolesData}
                img={img}
                setimg={setimg}
                imgValue={imgValue}
                setImageValue={setImageValue}
                readFile={readFile}
                setReadFile={setReadFile}
                drawerKeyHandler={drawerKeyHandler}
            />


        </>
    )
}

export default ScheduleDetailDrawer
