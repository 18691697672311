
import { GET_POTENTIAL_TAKERS, GET_POTENTIAL_TAKERS_FAILURE, GET_POTENTIAL_TAKERS_SUCCESS, GET_SHIFTS, GET_SHIFTS_FAILURE, GET_SHIFTS_SUCCESS } from "../constants";


const initialState = {
    potentialTakersLoader: false,
    potentialTakersData: [],
    loader: false,
    shiftsData: []
}

export default function ShiftSchedulerReducer(state = initialState, action) {
    switch (action.type) {
        case GET_POTENTIAL_TAKERS:
            state = {
                ...state,
                potentialTakersLoader: true
            }
            break;
        case GET_POTENTIAL_TAKERS_SUCCESS:
            state = {
                ...state,
                potentialTakersLoader: false,
                potentialTakersData: action.payload
            }
            break;
        case GET_POTENTIAL_TAKERS_FAILURE:
            state = {
                ...state,
                potentialTakersLoader: false
            }
            break;
        case GET_SHIFTS:
            state = {
                ...state,
                loader: true
            }
            break;
        case GET_SHIFTS_SUCCESS:
            state = {
                ...state,
                loader: false,
                shiftsData: action.payload
            }
            break;
        case GET_SHIFTS_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;
        default:
            break;
    }
    return state;
}