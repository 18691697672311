import React, { useEffect } from 'react'
import BasicDrawer from '../../components/Drawer'
import { Row, Col } from 'antd'
import LabelWithField from '../../components/LabelWithField'
import { useForm, Controller } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
import { ApiCaller, Colors, Utils } from '../../config';
import { Button, TextField } from '../../components';
import RoleCheck from '../../components/RoleCheck/RoleCheck';
import { CiEdit } from 'react-icons/ci';
import LabelWithSelect from '../../components/LabelWithSelect';
import { useMutation } from "react-query";
import { phoneNumberFormat } from '../../config/util/PhoneNumberFormatter';
import LabelWithCustomSelect from '../../components/LabelWithCustomSelect';
import { useSelector, useDispatch } from 'react-redux';
import { ClientManagementAction } from '../../store/actions';
import { AddClient } from '../../config/Schema';
import { yupResolver } from '@hookform/resolvers/yup';


const CompanyEditDrawer = ({ openDrawer, onCloseDrawer, width, companyDetailData, setState, state }) => {

    const schema = AddClient;
    const { handleSubmit, control, reset } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
    })

    const dispatch = useDispatch()


    const streetType = useSelector(state => state?.SiteManagementReducer?.addressTypeData || []);


    const companyMutate = useMutation(async (data) => {
        const { name, url, phoneNo, fax, note, ...address } = data;
        console.log(data)
        let payload = {
            name,
            url,
            phoneNo,
            fax,
            note,
            address: {
                ...address,
                id: companyDetailData?.address ? companyDetailData?.address[0]?.id : '-',
            }
        }
        let id = Number(companyDetailData?.id);
        try {
            const res = await ApiCaller.Put(`/company/${id}`, payload);
            if (res.status == 200 || res.status == 201) {
                Utils.showMessage('success', res?.data?.message);
                onCloseDrawer()
                dispatch(ClientManagementAction.GetCompanies(state))
                setState(state)
                return
            }
        }
        catch (err) {
            Utils.showMessage('error', err?.response?.data?.message);
        }
    })

    useEffect(() => {
        reset({
            name: companyDetailData?.name,
            url: companyDetailData?.url,
            phoneNo: companyDetailData?.phoneNumber,
            fax: companyDetailData?.fax && companyDetailData?.fax,
            streetNumber: companyDetailData?.address ? companyDetailData?.address[0]?.streetNumber : '-',
            streetName: companyDetailData?.address ? companyDetailData?.address[0]?.streetName : '-',
            city: companyDetailData?.address ? companyDetailData?.address[0]?.city : '-',
            zip: companyDetailData?.address ? companyDetailData?.address[0]?.zip : '-',
            addressType: companyDetailData?.address ? companyDetailData?.address[0]?.addressType : '-',
            buildingNumber: companyDetailData?.address ? companyDetailData?.address[0]?.buildingNumber : '-',
            streetType: companyDetailData?.address ? companyDetailData?.address[0]?.streetType : '-',
            state: companyDetailData?.address ? companyDetailData?.address[0]?.state : '-',
            note: companyDetailData?.note && companyDetailData?.note
        })
    }, [companyDetailData])


    const companyEditDataHandler = (data) => {
        companyMutate.mutate(data)
    }

    return (
        <BasicDrawer
            openDrawer={openDrawer}
            onClose={onCloseDrawer}
            width={width}
            title={'Edit Details'}
            children={
                <>
                    <form layout="vertical" className='w-full'>
                        <Row gutter={16}>
                            <Col lg={12} xs={24}>
                                <Controller
                                    control={control}
                                    defaultValue={''}
                                    name='name'
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField
                                                labeltext={'Company Name'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'h-10 rounded outline-none'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}

                                        </>
                                    )}
                                />
                            </Col>
                            <Col lg={12} xs={24}>
                                <Controller
                                    control={control}
                                    name='url'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField
                                                labeltext={'URL'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'h-10 rounded outline-none'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col lg={12} xs={24}>
                                <Controller
                                    control={control}
                                    name='streetNumber'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField
                                                labeltext={'Street Number'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'h-10 rounded outline-none'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>
                            <Col lg={12} xs={24}>
                                <Controller
                                    control={control}
                                    name='streetName'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField
                                                labeltext={'Street Name'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'h-10 rounded outline-none'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col lg={6} xs={24}>

                                <Controller
                                    control={control}
                                    name='streetType'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            {/* <LabelWithSelect
                                            options={streetType}
                                            labeltext={'Street Type'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'outline-none h-10 rounded'}
                                        /> */}
                                            <LabelWithCustomSelect
                                                // options={[...streetType, {
                                                //     label: addressData?.streetType,
                                                //     value: addressData?.streetType
                                                // }]}
                                                options={streetType}
                                                labeltext={'Street Type'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'outline-none h-10 rounded'}
                                            />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>

                                    )}
                                />
                            </Col>
                            <Col lg={6} xs={24}>
                                <Controller
                                    control={control}
                                    name='city'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField
                                                labeltext={'City'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'h-10 rounded outline-none'} />

                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>
                            <Col lg={6} xs={24}>
                                <Controller
                                    control={control}
                                    name='state'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField
                                                labeltext={'State'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'h-10 rounded outline-none'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>
                            <Col lg={6} xs={24}>
                                <Controller
                                    control={control}
                                    name='zip'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField
                                                labeltext={'Zip'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'h-10 rounded outline-none'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col lg={12} xs={24}>
                                <Controller
                                    control={control}
                                    name='buildingNumber'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField
                                                labeltext={'Building Number'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'h-10 rounded outline-none'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>

                                    )}
                                />
                            </Col>

                            <Col lg={12} xs={24}>
                                <Controller
                                    control={control}
                                    name='addressType'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithSelect
                                                options={addressType}
                                                labeltext={'Address Type'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'outline-none h-10 rounded'}
                                            />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>

                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16} className='mb-2'>
                            <Col lg={12} xs={24}>
                                <Controller
                                    control={control}
                                    name='phoneNo'
                                    defaultValue={''}
                                    render={({ field: { value, onChange }, fieldState }) => (
                                        <>
                                            <div className="flex flex-col py-4 w-full">
                                                <label
                                                    className={'font-normal'}
                                                    style={{ color: Colors.LabelColor }}
                                                >
                                                    {'Phone Number'}
                                                </label>
                                                <input type={'string'}
                                                    value={value}
                                                    onChange={onChange}
                                                    onBlur={(e) => {
                                                        const formattedNumber = phoneNumberFormat(e.target.value);
                                                        if (formattedNumber?.length === 14) {
                                                            onChange(formattedNumber)
                                                        }
                                                    }}
                                                    className={`pl-2.5 outline-none h-10 rounded`}
                                                    style={{ background: Colors.LightGray }}
                                                />
                                                {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                            </div>
                                        </>
                                    )}
                                />

                            </Col>
                            <Col lg={12} xs={24}>
                                <Controller
                                    control={control}
                                    name='fax'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField
                                                labeltext={'Fax'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'h-10 rounded outline-none'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Controller
                                    control={control}
                                    name='note'
                                    defaultValue={''}
                                    render={({ field: { value, onChange }, fieldState }) => (
                                        <>
                                            <TextField.Textarea
                                                style={{ color: Colors.LightGray }}
                                                placeholder={''}
                                                labeltext={'Property Notes'}
                                                value={value}
                                                onChange={onChange}
                                                className={'outline-none h-10 rounded'}
                                            />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row gutter={16}>
                            <Col lg={24} className='flex justify-end'  >
                                <Button.Basic
                                    onClick={handleSubmit(companyEditDataHandler)}
                                    style={{ width: "10vw" }}
                                    className=" h-12 yellow-bg border-none text-white border-transparent"
                                    text={"Save"}
                                    loader={companyMutate.isLoading}
                                />
                            </Col>
                        </Row>
                    </form>

                </>
            }

        />
    )
}

let addressType = [
    {
        value: 'Commercial Property',
        label: 'Commercial Property',
    },
    {
        value: 'Residential Property',
        label: 'Residential Property',
    },
    {
        value: 'Other',
        label: 'Other',
    }
]

export default React.memo(CompanyEditDrawer)