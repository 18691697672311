"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default2 = require("./default");
var _default = exports["default"] = {
  schedulerWidth: 'fit-content',
  besidesWidth: 20,
  schedulerMaxHeight: 0,
  tableHeaderHeight: 50,
  schedulerContentHeight: 'fit-content',
  responsiveByParent: true,
  agendaResourceTableWidth: 160,
  agendaMaxEventWidth: 100,
  dayResourceTableWidth: 200,
  weekResourceTableWidth: 230,
  monthResourceTableWidth: 160,
  quarterResourceTableWidth: 160,
  yearResourceTableWidth: 160,
  customResourceTableWidth: 180,
  dayCellWidth: 31,
  weekCellWidth: 208,
  monthCellWidth: 80,
  quarterCellWidth: 80,
  yearCellWidth: 80,
  customCellWidth: 100,
  dayMaxEvents: 99,
  weekMaxEvents: 99,
  monthMaxEvents: 99,
  quarterMaxEvents: 99,
  yearMaxEvents: 99,
  customMaxEvents: 99,
  eventItemPopoverTrigger: 'hover',
  eventItemPopoverPlacement: 'bottomLeft',
  eventItemPopoverWidth: 300,
  eventItemHeight: 25,
  eventItemLineHeight: 20,
  nonAgendaSlotMinHeight: 0,
  dayStartFrom: 0,
  dayStopTo: 23,
  defaultEventBgColor: '#80C5F6',
  selectedAreaColor: '#7EC2F3',
  nonWorkingTimeHeadColor: '#999999',
  // nonWorkingTimeHeadBgColor: '#fff0f6',
  // nonWorkingTimeBodyBgColor: '#fff0f6',
  summaryColor: '#666',
  summaryPos: _default2.SummaryPos.TopRight,
  groupOnlySlotColor: '#F8F8F8',
  startResizable: false,
  endResizable: false,
  movable: true,
  creatable: true,
  crossResourceMove: true,
  checkConflict: false,
  scrollToSpecialDayjsEnabled: true,
  eventItemPopoverEnabled: true,
  eventItemPopoverShowColor: true,
  calendarPopoverEnabled: true,
  recurringEventsEnabled: true,
  viewChangeSpinEnabled: true,
  dateChangeSpinEnabled: true,
  headerEnabled: true,
  resourceViewEnabled: true,
  displayWeekend: true,
  relativeMove: true,
  defaultExpanded: true,
  dragAndDropEnabled: true,
  schedulerHeaderEventsFuncsTimeoutMs: 100,
  resourceName: 'Name',
  taskName: 'Task Name',
  agendaViewHeader: 'Agenda',
  addMorePopoverHeaderFormat: 'MMM D, YYYY dddd',
  eventItemPopoverDateFormat: 'MMM D',
  nonAgendaDayCellHeaderFormat: 'ha',
  nonAgendaWeekCellHeaderFormat: 'ww/YYYY',
  nonAgendaMonthCellHeaderFormat: 'MMM YYYY',
  nonAgendaYearCellHeaderFormat: 'YYYY',
  nonAgendaOtherCellHeaderFormat: 'ddd M/D',
  minuteStep: 30,
  views: [{
    viewName: 'Day',
    viewType: _default2.ViewType.Day,
    showAgenda: false,
    isEventPerspective: false
  }, 
  {
    viewName: 'Week',
    viewType: _default2.ViewType.Week,
    showAgenda: false,
    isEventPerspective: false
  },
  //  {
  //   viewName: 'Month',
  //   viewType: _default2.ViewType.Month,
  //   showAgenda: false,
  //   isEventPerspective: false
  // }, 
  // {
  //   viewName: 'Quarter',
  //   viewType: _default2.ViewType.Quarter,
  //   showAgenda: false,
  //   isEventPerspective: false
  // }, {
  //   viewName: 'Year',
  //   viewType: _default2.ViewType.Year,
  //   showAgenda: false,
  //   isEventPerspective: false
  // }
]
};