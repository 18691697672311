import { AppAction } from '../actions';
import { ApiCaller } from '../../config';
import { put, delay } from 'redux-saga/effects';
import { Utils } from "../../config"

export default class AppMiddleware {

    static *SignIn(params) {
        console.log('andar', params)
        const { payload, cb } = params;
        console.log(payload, cb)
        try {
            let res = yield ApiCaller.Post('/user/login', payload)
            console.log(res)
            if (res.status == 200) {
                yield put(AppAction.SignInSuccess(res?.data))

                if (cb) {
                    console.log('andar')
                    cb()
                }

                Utils.showMessage('success', res?.data?.message)
                console.log('SignIn Response', res)
            }

            else {
                yield put(AppAction.SignInFailure())
                console.log('here')
                Utils.showMessage('error', "error")
            }
            console.log(res)
            localStorage.setItem("user", JSON.stringify(res?.data))
            // authtoken seperately for the interceptor only.
            localStorage.setItem('authToken', JSON.stringify(res?.data?.data.token))

        }
        catch (err) {
            yield put(AppAction.SignInFailure())
            Utils.showMessage('error', err?.response?.data?.message)
            console.log("signin error")
        }
    }

    static *EmailVerify(params) {
        const { payload, cb } = params;
        console.log(payload)
        const email = payload?.email;
        const verification_purpose = 'Account Recovery';
        try {
            let res = yield ApiCaller.Post('/password/otp/send', { email, verification_purpose });
            console.log(res)
            if (res.status == 200) {
                yield put(AppAction.EmailVerificationSuccess());
                if (cb) {
                    cb()
                }
                Utils.showMessage('success', res?.data?.message)
                localStorage.setItem('email', email);

            }
            else {
                yield put(AppAction.EmailVerificationFailure());
                Utils.showMessage('error', 'error')
            }

        }
        catch (err) {
            yield put(AppAction.SignInFailure())
            Utils.showMessage('error', err?.response?.data?.message)
            console.log(err)
        }
    }

    static *OtpVerification(params) {
        const { payload, cb } = params;
        console.log(payload, cb)

        const verification_code = payload;
        const email = localStorage.getItem('email');

        try {
            let res = yield ApiCaller.Post('/password/otp/verify', { verification_code, email });
            console.log(res)
            if (res.status == 200) {
                yield put(AppAction.OTPVerificationSuccess());
                if (cb) {
                    cb()
                }
                Utils.showMessage('success', res?.data?.message);
            }
            else {
                yield put(AppAction.OTPVerificationFailure());
                // Utils.showMessage('error', err?.response?.data?.message)
            }

            localStorage.setItem('otp', verification_code);
        }

        catch (err) {
            yield put(AppAction.OTPVerificationFailure());
            Utils.showMessage('error', err?.response?.data?.message);
            console.log(err)
        }
    }

    static *ResetPassword(params) {
        const { payload, cb } = params;
        const data = {
            email: localStorage.getItem('email'),
            verification_code: localStorage.getItem('otp'),
            password: payload?.newpassword,
        }
        try {
            let res = yield ApiCaller.Post('/password/otp/reset', data);
            if (res.status == 200) {
                yield put(AppAction.PasswordResetSuccess());
                if (cb) {
                    cb()
                }
                Utils.showMessage('success', res?.data?.message);
            }
            else {
                yield put(AppAction.PasswordResetFaiure());
                Utils.showMessage('error', res?.message);
            }
        }
        catch (err) {
            yield put(AppAction.PasswordResetFaiure());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }

    static *Logout(params) {
        const { payload, cb } = params;
        console.log(payload)
        try {
            let res = yield ApiCaller.Post(
                "/user/logout",
                payload
            );
            if (res.status == 200) {
                yield put(AppAction.LogoutSuccess())
                if (cb) {
                    cb()
                }
                Utils.showMessage('success', res?.data?.message);
                console.log(res)
            }
            else {
                yield put(AppAction.LogoutFailure())
                // Utils.showMessage('error', err?.response?.data?.message)
            }

            localStorage.removeItem("user")
            localStorage.removeItem('authToken');
            localStorage.removeItem('email');

        }
        catch (err) {
            yield put(AppAction.LogoutFailure())
            Utils.showMessage('error', err?.response?.data?.message)
        }

    }

}