import React, { useState, useEffect, useCallback } from "react";
import TableWrapper from "../../components/Grid";
import AddEligibilityDrawer from "./AddEligibilityDrawer";
import AddScheduleDrawer from "./AddScheduleDrawer";
import { Avatar, Col, Row, Tabs } from "antd";
import { UserOutlined } from "@ant-design/icons";
import NewFilterBar from "../../components/NewFilterBar";
import { FaEye } from "react-icons/fa";
import { Button } from "antd";
import { ApiCaller, Colors, Utils } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { SettingAction, UserManagementAction } from "../../store/actions";
import { Spin } from "antd";
import ActionDropdown from "../../components/DropDown/actiondropdown";
import ScheduleDetailDrawer from "./ScheduleDetailDrawer"
import EligibilityDetailDrawer from "./EligibilityDetailDrawer";
import { FaTrash } from "react-icons/fa";
import CustomModal from "../../components/Modal/CustomModal";
import { useMutation, useQuery } from "react-query";
import { getEligibiity, getSchedules, getUserRoles } from "../../config/getApis";

const { TabPane } = Tabs;

const Settings = () => {

  const dispatch = useDispatch();
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);

  const user = useSelector((state) => state.AppReducer?.user);
  const isRole = user?.data?.userRole[0];

  const { scheduleData, loader, elegibilityData } = useSelector(state => state?.SettingReducer);
  let schedules = scheduleData?.data?.data;
  let schedulesPagination = scheduleData?.data?.paginate;

  let elegibilities = elegibilityData?.data?.data;
  let elegibilityPagination = elegibilityData?.data?.paginate;

  const { data: eligibilitiesData } = useQuery('eligibility', getEligibiity);
  const { data: schedulesData } = useQuery('schedules', getSchedules);
  const { data: rolesData } = useQuery('roles', getUserRoles);

  // State to manage table related working pagination, search and filter etc
  const [state, setState] = useState({
    current: 1,
    limit: 10,
    searchVal: "",
    tabName: "schedule",
    tabKey: 1,
    sortBy: 'id',
    sortOrder: 'asc'
  });

  // Drawers maintaining states.
  const [drawerStates, setDrawerStates] = useState({
    openDrawer: false,
    ScheduleDetailDrawer: false,
    EligibilityDetailDrawer: false,
    ScheduleAddDrawer: false,
    EligibilityAddDrawer: false,
    drawerkey: null,
    userid: null,
    actionid: null,
    userDetailDrawer: false,
  });

  const [eventName, setEventName] = useState('')

  // initial render
  useEffect(() => {
    dispatch(SettingAction.GetSchedules(state))
  }, [])

  // Function to handle tab change
  const handleTabChange = (key) => {
    if (key == 1) {
      setState({ ...state, tabKey: key, tabName: 'schedule', current: 1, searchVal: '', sortOrder:'asc' })
      setDrawerStates({ ...state, drawerkey: null, userid: null, actionid: null })
      dispatch(SettingAction.GetSchedules({ ...state, tabKey: key, tabName: 'schedule', current: 1, searchVal: '', sortOrder:'asc' }))

    }
    else {
      setState({ ...state, tabKey: key, tabName: 'eligibility', current: 1, searchVal: '', sortOrder:'asc' })
      setDrawerStates({ ...state, drawerkey: null, userid: null, actionid: null })
      dispatch(SettingAction.GetEligibilities({ ...state, tabKey: key, tabName: 'eligibility', current: 1, searchVal: '', sortOrder:'asc' }))
    }
  };


  // on drawers closes
  const onCloseDrawer = () => {
    if (drawerStates?.drawerkey == 1 && drawerStates.EligibilityDetailDrawer) {
      setDrawerStates({
        ...drawerStates,
        EligibilityDetailDrawer: false,
        drawerkey: null,
        userid: null,
        actionid: null
      });
    } else if (drawerStates.drawerkey == 1 && drawerStates.ScheduleDetailDrawer) {
      setDrawerStates({
        ...drawerStates,
        ScheduleDetailDrawer: false,
        drawerkey: null,
        userid: null,
        actionid: null
      });
    } else {
      setDrawerStates({ ...drawerStates, ScheduleAddDrawer: false, EligibilityAddDrawer: false })
    }
  }


  // user Select values filter handler
  const SelectFilterHandler = (value) => {
    setState({ ...state, current: 1, sortOrder: value })
    if (state?.tabKey == 1) {
      dispatch(SettingAction.GetSchedules({ ...state, current: 1, sortOrder: value }));
      return
    }
    else {
      dispatch(SettingAction.GetEligibilities({ ...state, current: 1, sortOrder: value }));
      return
    }
  };

  // user Select Values
  const SelectFilterValues = [
    {
      value: "asc",
      label: "Ascending",
    },
    {
      value: "desc",
      label: "Descending",
    },

  ];



  const items = [
    {
      label: "View Details",
      key: 1,
      icon: <FaEye color="#6A9BFF" />,
      backgroundColor: "#eef4ff",
      color: "#4582ff",
    },
    // {
    //   label: "Delete",
    //   key: 2,
    //   icon: <FaTrash color="#E71D36" />,
    //   backgroundColor: "#fef3f5",
    //   color: "#e93148",
    // },
  ];

  // schedule Delete -- created but not using it right now as we not deleting any Schedules
  const scheduleDeleteMutation = useMutation(async (data) => {
    let res = await ApiCaller.Delete(``);
    return res
  },
    {
      onSuccess: (res) => {
        Utils.showMessage('success', res?.data?.message)
      },
      onError: (err) => {
        Utils.showMessage('error', err?.response?.data?.message)
      }
    }
  )


  // Schedule action handler
  const ScheduleActionHandler = (e, record) => {
    let id = Number(record?.id);
    if (e.key == '1') {
      setDrawerStates({ ...drawerStates, ScheduleDetailDrawer: true, drawerkey: 1, actionid: id });
      setEventName(record?.name)
    }
    else {
      // Delete action
      scheduleDeleteMutation.mutate(id);
      return
    }

  };


  // Eligibility Delete -- created but not using it right now as we not deleting any Eligibility
  const elegibilityDeleteMutation = useMutation(async (data) => {
    let res = await ApiCaller.Delete(``);
    return res
  },
    {
      onSuccess: (res) => {
        Utils.showMessage('success', res?.data?.message)
      },
      onError: (err) => {
        Utils.showMessage('error', err?.response?.data?.message)
      }
    }
  )


  // Eligibility action handler
  const EligibilityActionHandler = (e, record) => {
    let id = Number(record?.id);
    if (e.key == '1') {
      console.log(id)
      setDrawerStates({ ...drawerStates, EligibilityDetailDrawer: true, drawerkey: 1, actionid: id });
      setEventName(record?.name)
    }
    else {
      // Delete action
      elegibilityDeleteMutation.mutate(id)
    }

  };

  // Manage Schedules Columns
  const schedulesColumns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      render: (text, record) => (
        <Col span={24} className="flex justify-start items-center break-all">
          <span>{record?.name}</span>
        </Col>
      ),
    },
    // {
    //   title: "Properties",
    //   dataIndex: "Properties",
    //   key: "phone",
    //   render: (text, record) => {
    //     return (
    //       <Col span={24} className="flex justify-start align-center">
    //         <span>{record?.Properties}</span>
    //       </Col>
    //     );
    //   },
    // },
    // {
    //   title: "Assigned Users",
    //   dataIndex: "Assigned Users",
    //   key: "role",
    //   render: (text, record) => {
    //     return (
    //       <Col span={24} className="flex justify-start align-center">
    //         <span>{record['Assigned Users']}</span>
    //       </Col>
    //     );
    //   },
    // },
    {
      title: "Action",
      dataIndex: "id",
      align: 'left',
      render: (text, record) => {
        return (
          <Col
            span={24}
            className="flex items-start justify-start"
          >
            <ActionDropdown
              items={items}
              placement={"bottomLeft"}
              labelStyle={{
                color: Colors.Yellow,
                fontSize: "1.2rem",
                marginBottom: "7px",
              }}
              onClick={(e) => ScheduleActionHandler(e, record)}
              isRole={isRole}
            />
          </Col>
        );
      },
    },
  ];

  // Manage Eligibility COLUMNS
  const eligibilityColumns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      render: (text, record) => (
        <Col span={24} className="flex justify-start items-center">
          <span>{record?.name}</span>
        </Col>
      ),
    },
    // {
    //   title: "Assigned Users",
    //   dataIndex: "Assigned Users",
    //   key: "role",
    //   render: (text, record) => {
    //     return (
    //       <Col span={24} className="flex justify-start align-center">
    //         <span>{record['Assigned Users']}</span>
    //       </Col>
    //     );
    //   },
    // },
    {
      title: "Action",
      dataIndex: "id",
      align: 'left',
      render: (text, record) => {
        return (
          <Col
            span={24}
            className="flex justify-start align-start"
            style={{}}
          >
            <ActionDropdown
              items={items}
              placement={"bottomLeft"}
              labelStyle={{
                color: Colors.Yellow,
                fontSize: "1.2rem",
                marginBottom: "7px",
              }}
              onClick={(e) => EligibilityActionHandler(e, record)}
              isRole={isRole}
            />
          </Col>
        );
      },
    },
  ];

  const handlePaginationChange = (page, pageSize) => {
    console.log(page, pageSize)
    setState({ ...state, current: page, limit: pageSize })
    if (state?.tabKey == 1) {
      dispatch(SettingAction.GetSchedules({ ...state, current: page }))
      return
    }
    else {
      dispatch(SettingAction.GetEligibilities({ ...state, current: page }))
      return
    }
  };

  const handleDeleteConfirmation = (record) => {
    setRecordToDelete(record);
    setConfirmDeleteVisible(true);
  };


  const debouceHandler = (query, delay) => {
    let timeout;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if(state?.tabKey == 1){
        dispatch(
          SettingAction.GetSchedules({
            ...state,
            searchVal: query,
            current: 1,
          })
        );
      }
      else{
        dispatch(
          SettingAction.GetEligibilities({
            ...state,
            searchVal: query,
            current: 1,
          })
        );
      }

    }, delay);
  };

  const handleInputChange = (e) => {
    const query = e?.target.value;
    console.log(query)
    setState({ ...state, current: 1, searchVal: e.target.value });
    debouceHandler(query, 2000);
  };

  const handleDeleteConfirm = () => {
    // Perform delete operation here using recordToDelete
    console.log("Deleting record:", recordToDelete);
    setConfirmDeleteVisible(false);
    setRecordToDelete(null);
  };

  const handleDeleteCancel = () => {
    setConfirmDeleteVisible(false);
    setRecordToDelete(null);
  };


  // buttons to open Add schedule and eligibility drawers
  const handleAddSchedule = () => {
    setDrawerStates({ ...drawerStates, ScheduleAddDrawer: true })
  };

  const handleAddEligibility = () => {
    setDrawerStates({ ...drawerStates, EligibilityAddDrawer: true })
  };

  return (
    <>

      <CustomModal
        title="Eligibility Delete"
        open={confirmDeleteVisible}
        cancelModal={handleDeleteCancel}
        centered={true}
        style={{ fontWeight: "500", textAlign: "center" }}
        Children={
          <>
            <div style={{ fontWeight: "500", textAlign: "center", width: "100%" }}>
              <p style={{ fontWeight: "500", textAlign: "center" }}>Are you sure you want to delete?</p>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <div></div>
                <Button onClick={handleDeleteConfirm} style={{ backgroundColor: "#EEB600", width: "35%", marginRight: "5%" }} type="primary">
                  Yes
                </Button>

                <Button onClick={handleDeleteCancel} style={{ backgroundColor: "#000000", width: "35%" }} type="primary">
                  No
                </Button>
              </div>
            </div>
          </>
        }
      />



      <EligibilityDetailDrawer
        onCloseDrawer={onCloseDrawer}
        drawerStates={drawerStates}
        setDrawerStates={setDrawerStates}
        eventName={eventName}
        isRole={isRole}
        eligibilityData={eligibilitiesData}
        scheduleData={schedulesData}
        rolesData={rolesData}
      />


      <ScheduleDetailDrawer
        onCloseDrawer={onCloseDrawer}
        setDrawerStates={setDrawerStates}
        drawerStates={drawerStates?.ScheduleDetailDrawer && drawerStates}
        eventName={eventName}
        isRole={isRole}
        eligibilityData={eligibilitiesData}
        scheduleData={schedulesData}
        rolesData={rolesData}
      />


      <AddScheduleDrawer
        visible={drawerStates.ScheduleAddDrawer}
        onClose={onCloseDrawer}
        setState={setState}
      />

      <AddEligibilityDrawer
        visible={drawerStates.EligibilityAddDrawer}
        onClose={onCloseDrawer}
        setState={setState}
      />

      <NewFilterBar
        btnText1Handler={handleAddSchedule}
        btnText2Handler={handleAddEligibility}
        SelectFilterHandler={SelectFilterHandler}
        handleInputChange={handleInputChange}
        SelectFilterValues={SelectFilterValues}
        defaultValue={"asc"}
        value={state?.sortOrder}
        isRole={isRole}
        SearchValue={state.searchVal}
        title="Setting"
        isManageSchedule={state?.tabKey}
        btnText1={'Add Schedule'}
        btnText2={'Add Eligibility'}
      />


      <Row
        gutter={[8, 32]}
        className="w-full"
        style={{ background: "white", padding: "16px", borderRadius: "10px" }}
      >
        <Col lg={24} md={24} sm={24} xs={24}>
          <Row className="w-full">
            <Col lg={24} md={24} sm={24} xs={24}>
              <Tabs
                defaultActiveKey="1"
                itemSelectedColor="black"
                style={{ marginTop: "-4rem", fontWeight: "500" }}
                onChange={handleTabChange}
                items={[
                  {
                    label: "Manage Schedule",
                    key: "1",
                    children: (
                      <>
                        <TableWrapper
                          tableStyle={{ borderRadius: "40px", width: "100%" }}
                          headerbg={"red"}
                          tableColumns={schedulesColumns}
                          tableData={schedules}
                          total={schedulesPagination?.total}
                          pageSize={state.limit}
                          currentPage={state.current}
                          handlePaginationChange={handlePaginationChange}
                          loading={loader && <Spin />}
                          rowClassName={"row-antd"}
                        />
                      </>
                    ),
                  },

                  {
                    label: "Manage Eligibility",
                    key: "2",
                    children: (
                      <>
                        <TableWrapper
                          tableStyle={{ borderRadius: "40px", width: '100%' }}
                          headerbg={"red"}
                          tableColumns={eligibilityColumns}
                          tableData={elegibilities}
                          total={elegibilityPagination?.total}
                          pageSize={state.limit}
                          currentPage={state.current}
                          handlePaginationChange={handlePaginationChange}
                          loading={loader && <Spin />}
                          rowClassName={"row-antd"}
                        />
                      </>
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};


// const columns = [
//     {
//       title: 'Name',
//       dataIndex: 'name',
//       key: 'name',
//     },
//     {
//       title: 'Properties',
//       dataIndex: 'Properties',
//       key: 'Properties',
//     },
//     {
//       title: 'AssignedUsers',
//       dataIndex: 'AssignedUsers',
//       key: 'AssignedUsers',
//     },
//     {
//       title: 'Action',
//       dataIndex: 'Action',
//       key: 'Action',
//     },
//   ];


export default Settings;
