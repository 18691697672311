import {
    SIGNIN, SIGNIN_SUCCESS, SIGNIN_FAILURE,
    EMAIL_VERIFICATION, EMAIL_VERIFICATION_SUCCESS, EMAIL_VERIFICATION_FAILURE,
    OTP_VERIFICATION, OTP_VERIFICATION_SUCCESS, OTP_VERIFICATION_FAILURE,
    PASSWORD_RESET, PASSWORD_RESET_SUCCESS, PASSWORD_RESET_FAILURE,
    LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAILURE, ADD_ADDRESS_TYPE,
} from '../constants';

const initialState = {
    user: JSON.parse(localStorage.getItem("user")) || {},
    loader: false,
    posts: []
}
console.log(initialState)

export default function AppReducer(state = initialState, action) {
    switch (action.type) {

        case SIGNIN:
            console.log(action.payload)
            state = {
                ...state,
                loader: true
            }
            break;
        case SIGNIN_SUCCESS:
            state = {
                ...state,
                user: action.payload,
                loader: false
            }
            break;
        case SIGNIN_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;
        case EMAIL_VERIFICATION:
            state = {
                ...state,
                loader: true
            }
            break;
        case EMAIL_VERIFICATION_SUCCESS:
            state = {
                ...state,
                loader: false
            }
            break;
        case EMAIL_VERIFICATION_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;
        case OTP_VERIFICATION:
            state = {
                ...state,
                loader: true
            }
            break;
        case OTP_VERIFICATION_SUCCESS:
            state = {
                ...state,
                loader: false
            }
            break;

        case OTP_VERIFICATION_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;

        case PASSWORD_RESET:
            state = {
                ...state,
                loader: true
            }
            break;
        case PASSWORD_RESET_SUCCESS:
            state = {
                ...state,
                loader: false
            }
            break;
        case PASSWORD_RESET_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;
        case LOGOUT:
            state = {
                ...state,
                loader: true
            }
            break;

        case LOGOUT_SUCCESS:
            state = {
                user: {},
                posts: [],
                loader: false
            }
            break;
        case LOGOUT_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;

        default:
            break;
    }

    return state;
}