import { Col, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { Button, Text, TextField } from "../../components";
import { Colors, Utils } from "../../config";
import { AppAction } from "../../store/actions";
import login from '../../assets/images/login.png'
import siteLogo from '../../assets/images/siteLogo.png'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { UserPasswordReset } from "../../config/Schema";

import { IoIosEyeOff } from "react-icons/io";
import { IoIosEye } from "react-icons/io";
import ButtonLoader from "../../components/Button/ButtonLoader";

const ResetPassword = () => {

    const loader = useSelector(state => state?.AppReducer.loader);
    let schema = UserPasswordReset;
    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema)
    })

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const PasswordChangeHandler = (data) => {
        dispatch(AppAction.PasswordReset(data, () => {
            navigate('/login')
        }))
        console.log(data)
    };

    const [state, setState] = useState({
        oldPassword: false,
        newPassword: false,
    })


    return (

        <Row>
            <Col lg={12} md={12} sm={24} xs={24} className="h-screen" >

                <section className="h-full flex">
                    <div className="flex flex-col items-center justify-center px-6 mx-auto md:h-screen lg:py-0">
                        <a href="#" className="mt-3" >
                            <img className="xl:w-80 md:w-80 xl:h-32 md:h-32 h-24 w-72 mr-2" src={siteLogo} alt="logo" />
                        </a>
                        <div className="w-full md:mt-0 sm:max-w-md xl:p-0">
                            <div className="pt-3 mx-auto flex items-center flex-col justify-center" style={{}}>
                                {/* <h1 className="mb-3 text-center text-2xl font-bold">
                                    Southern Tier Security
                                </h1> */}
                                <p className="text-center text-sm mb-5" style={{ color: Colors.Gray }}>
                                    Sentinel is Located in the heart of the Echanted Mountains, We are your local Guard.
                                </p>
                                <form className="space-y-4 md:space-y-6 w-11/12	" style={{}}>
                                    <div>
                                        <Controller
                                            name="newpassword"
                                            control={control}
                                            render={({ field:{value,onChange}, fieldState }) => {
                                                return (
                                                    <div className="relative">
                                                        <input
                                                            type={state.oldPassword ? 'text' : "password"}
                                                            placeholder={"New Password"}
                                                            value={value}
                                                            onChange={onChange}
                                                            className="bg-gray-50 border border-gray-300 text-gray-900 w-full sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-3 pr-8"
                                                        />
                                                        {
                                                            state?.oldPassword ? <IoIosEye size={18} className='cursor-pointer absolute right-2.5 top-3.5' color={Colors.LabelColor} onClick={() => setState({ ...state, oldPassword: false })} /> : <IoIosEyeOff size={18} className='cursor-pointer absolute right-2.5 top-3.5' color={Colors.LabelColor} onClick={() => setState({ ...state, oldPassword: true })} />
                                                        }
                                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                                    </div>
                                                )
                                            }}
                                        />
                                    </div>

                                    <div>

                                        <Controller
                                            name="reenterpassword"
                                            control={control}
                                            render={({ field: { value, onChange }, fieldState }) => {
                                                return (
                                                    <div className="relative">
                                                        <input
                                                            type={state.newPassword ? 'text' : "password"}
                                                            value={value}
                                                            onChange={onChange}
                                                            placeholder={"Re-enter Password"}

                                                            className="bg-gray-50 border border-gray-300 text-gray-900 w-full sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-3 pr-8"
                                                        />
                                                        {
                                                            state?.newPassword ? <IoIosEye size={18} className='cursor-pointer absolute right-2.5 top-3.5' color={Colors.LabelColor} onClick={() => setState({ ...state, newPassword: false })} /> : <IoIosEyeOff size={18} className='cursor-pointer absolute right-2.5 top-3.5' color={Colors.LabelColor} onClick={() => setState({ ...state, newPassword: true })} />
                                                        }
                                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                                    </div>
                                                )
                                            }}
                                        />
                                    </div>
                                    <ButtonLoader loader={loader} text={'SUBMIT'} btnHandler={handleSubmit(PasswordChangeHandler)} style={{ background: "#2f3032" }} className="cursor-pointer w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 h-12 text-base tracking-wider" />
                                </form>

                                <div className="flex items-center justify-center mt-5">
                                    <a href="#" className="text-sm font-medium text-primary-600 hover:underline" style={{ color: Colors.Yellow }} onClick={() => navigate('/login')}>Back to Login</a>
                                </div>

                                <p className="text-xs text-center mt-5" style={{}}>
                                    Copyright 2024 <span style={{ color: "#f0bf1f" }}>Sentinel</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

            </Col>

            <Col lg={12} md={12} sm={0} xs={0} className="h-screen rounded-none md:rounded-3xl">
                <div className=" w-full h-full rounded-none md:rounded-l-3xl">
                    <img src={login} className="h-full max-w-full aspect-[4/3] object-top object-cover rounded-none md:rounded-l-3xl" />
                </div>
            </Col>


        </Row>
    )
}

export default ResetPassword