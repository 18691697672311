
// make sure you include the timeline stylesheet or the timeline will not be styled
// import 'react-calendar-timeline/lib/Timeline.css'
import Timeline from 'react-calendar-timeline';
import moment from 'moment';

const MyTimeline = () => {
    const groups = [
        { id: 1, title: 'Group 1' },
        { id: 2, title: 'Group 2' },
        { id: 3, title: 'Group 3' },
    ];

    const items = [
        {
            id: 1,
            group: 1,
            title: 'Item 1',
            start_time: moment().hour(7).minute(0).toDate(), // Start from 7 am today
            end_time: moment().add(1, 'day').hour(7).minute(0).toDate(), // End at 7 am next day
        },
    ];

    const defaultTimeStart = moment().hour(7).toDate(); // Start from 7 am today
    const defaultTimeEnd = moment().add(1, 'day').hour(7).toDate(); // End at 7 am next day

    const timeSteps = {
        second: 0,
        minute: 0,
        hour: 1, // Show intervals of 1 hour
        day: 1,
        month: 0,
        year: 0,
    };

    const sidebarContent = (
        <div>
            I want
        </div>
    );
    return (
        <Timeline
            groups={groups}
            items={items}
            defaultTimeStart={defaultTimeStart}
            defaultTimeEnd={defaultTimeEnd}
            timeSteps={timeSteps}
            canMove={false} // Disable moving items
            canResize={false} // Disable resizing items
            canChangeGroup={false} // Disable changing groups
            sidebarContent={sidebarContent}
            

        />
    );
};

export default MyTimeline;
