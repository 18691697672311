import React, { useCallback, useEffect, useState } from 'react'
import { Row, Col, Avatar, DatePicker, Form } from "antd";
import { CameraOutlined } from '@ant-design/icons';
import { Button, Modal, Text, TextField } from '..';
import { FaArrowLeftLong } from "react-icons/fa6";
import LabelWithField from '../LabelWithField';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { AddUserSchema } from '../../config/Schema';
import LabelWithSelect from '../LabelWithSelect';
import { FaPlus } from "react-icons/fa6";
import BasicDrawer from '../Drawer';
import Addressform from '../DrawerForms/address';
import GoogleMapComp from '../GoogleMap';
import dayjs from 'dayjs';
import { AddAddress } from '../../config/Schema';
import { useNavigate } from 'react-router-dom';
import { ApiCaller, Colors, Utils } from '../../config';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { SiteManagementAction } from '../../store/actions';
import Circular from '../Loader/Circular';
import { Route } from 'react-router-dom/dist/umd/react-router-dom.development';
import LabelWithCustomSelect from '../LabelWithCustomSelect';

const AddressForm = ({
    nextStep,
    previousStep,
    currentStep

}) => {

    // # CASE  
    // have to call the cleaner function because whenever the component render it gets the data from
    // which was previously stored in reducer. Case because if user is in the middle of the form and 
    // clicks to navigate to somewhere else the cleaner function will not going to call on that situation
    // it was getting called only on save btn and continue btn, So thats why it will get the data whenever
    // the component get render so to avoid that we called this function in useEffect whenever the 
    // component get first render on mounting stage this will trigger and by chance if any state is there
    // in reducer it will clear it.
    useEffect(() => {
        dispatch(SiteManagementAction.GetAddressDataCleaner())
    }, [])

    const [openDrawer, setOpenDrawer] = useState(false)

    const [btnDisabled, setBtnDisabled] = useState({
        next: false,
        save: false,
        addMore: false
    })

    const [currLocationJs, setCurrLocationJs] = useState({
        lat: '',
        lng: ''
    });

    const [addressData, setAddressData] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [position, setPosition] = useState([])

    console.log(position)

    const addressesData = useSelector(state => state?.SiteManagementReducer.formAddressData || []);
    const getAddressLoader = useSelector(state => state?.SiteManagementReducer.loader);
    


    const onCloseDrawer = () => {
        setOpenDrawer(false)
        setAddressData({})
    }
    const schema = AddAddress;
    const navigate = useNavigate()

    const dispatch = useDispatch();

    const defaultValues = {
        streetNumber: '',
        streetName: '',
        streetType: '',
        city: '',
        state: '',
        zip: '',
        buildingNumber: '',
        addressType: '',
        longitude: position && position[0]?.lng,
        latitude: position && position[0]?.lat,
    }

    const { handleSubmit, control, reset, setValue, watch, getValues } = useForm(
        {
            resolver: yupResolver(schema),
            mode: 'onChange',
            defaultValues: defaultValues

        }
    )


    useEffect(() => {
        getLocationJs();
    }, [currentStep == 2]);

    const renderMarkers = (map, maps, positions) => {
        console.log(positions)
        const markers = positions.map(position => {
            return new maps.Marker({
                position,
                map
            });
        });

        return markers;
    };

    const getLocationJs = () => {
        navigator.geolocation.getCurrentPosition(position => {
            const { latitude, longitude } = position.coords;
            setPosition([{
                lat: latitude,
                lng: longitude
            }])
            setValue('latitude', latitude);
            setValue('longitude', longitude)
        });
    };

    const mapClickHandler = useCallback(
        (e) => {
            setPosition([e.latLng.toJSON()]);
            setValue('latitude', e?.latLng.toJSON()?.lat);
            setValue('longitude', e?.latLng.toJSON()?.lng);
        },
        [setPosition]
    );

    const handleMapClick = (events) => {
        setCurrLocationJs({
            lat: events?.lat,
            lng: events?.lng
        })

        return
    }

    const defaultProps = {
        center: {
            lat: 32.7767,
            lng: 96.7970
        },
        zoom: 11
    };


    const addressMutation = useMutation(async (data) => {
        try {
            const { buttonName, ...restData } = data;
            const id = localStorage.getItem('PropertyKey');
            const res = await ApiCaller.Post(`/property/${id}/address`, restData);

            if (buttonName === 'save') {
                dispatch(SiteManagementAction.GetAddressDataCleaner()); // Clear data
                localStorage.removeItem('PropertyKey');
                setBtnDisabled({
                    save: false,
                    next: false,
                    addMore: false
                });
                reset(defaultValues)
                navigate('/site-management');
                return;
            }

            else if (buttonName === 'next') {
                dispatch(SiteManagementAction.GetAddressDataCleaner()); // Clear data
                setBtnDisabled({
                    save: false,
                    next: false,
                    addMore: false
                });
                reset(defaultValues)
                nextStep();
                return;
            }

            else if (buttonName === 'more') {
                dispatch(SiteManagementAction.GetAddress(() => {
                    reset(defaultValues); // Reset form
                }));
                setBtnDisabled({
                    save: false,
                    next: false,
                    addMore: false
                });
                return;
            }
        }

        catch (err) {
            console.log(err);
            setBtnDisabled({
                save: false,
                next: false,
                addMore: false
            });
            Utils.showMessage('error', err?.response?.data?.message);
            return;
        }
    }

    );

    const SaveAndAddMoreHandler = (data, name) => {
        // api hit for post of a address
        setBtnDisabled({
            next: true,
            save: true,
            addMore: true
        })
        data.buttonName = name;
        addressMutation.mutate(data)
    }

    const nextHandler = (name, data) => {
        // post api hit and continue
        setBtnDisabled({
            save: false,
            addMore: false,
            next: true
        })
        data.buttonName = name;
        addressMutation.mutate(data)
    }

    const skipHandler = () => {
        reset(defaultValues)
        setBtnDisabled({
            next: false,
            save: false,
            addMore: false
        })
        nextStep()
    }

    const saveHandler = (name, data) => {
        // post api hit and back to listing.
        setBtnDisabled({
            addMore: false,
            next: false,
            save: true
        })

        data.buttonName = name;
        addressMutation.mutate(data)
    }

    const editButtonHandler = (address) => {
        console.log(address)
        setAddressData(address)
        setOpenDrawer(true);
    }


    return (
        <div className='bg-white'>

            {
                !getAddressLoader ?
                    addressesData.map((address) => {
                        return (
                            < Row gutter={16} className='px-4 md:px-10 mb-2' key={address?.id}>
                                <Col span={24} className='gry-bg p-6 rounded-lg'>
                                    <Row className='px-2'>
                                        <Col lg={8} md={8} sm={24} xs={24} className='mb-2 px-2'>
                                            <Text.Label
                                                text={'Address'}
                                            />
                                            <p className='text-xs break-all'>{`${address?.streetNumber}, ${address?.streetName}, ${address?.city} ${address?.zip}`}</p>
                                        </Col>
                                        <Col lg={4} md={4} sm={24} xs={24} className='mb-2'>
                                            <Text.Label
                                                text={'Building Number'}
                                            />
                                            <p className='text-xs'>{address?.buildingNumber}</p>
                                        </Col>
                                        <Col lg={5} md={5} xs={24} className='mb-2'>
                                            <Text.Label
                                                text={'GPS Longitude'}
                                            />
                                            <p className='text-xs'>{address?.longitude}</p>
                                        </Col>
                                        <Col lg={5} md={5} sm={24} xs={24} className='mb-2'>
                                            <Text.Label
                                                text={'GPS Latittude'}
                                            />
                                            <p className='text-xs'>{address?.latitude}</p>
                                        </Col>
                                        <Col lg={2} md={4} sm={4} xs={24} className='mb-2'>
                                            <Button.Basic
                                                // onClick={() => }
                                                className="w-full h-10 yellow-bg border-none text-white border-transparent"
                                                text={"Edit"}
                                                onClick={() => editButtonHandler(address)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                        )
                    })
                    :
                    <Circular
                        spinning={getAddressLoader}
                        size={40}
                        color={Colors.Yellow}
                        containerStyle={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    />
            }

            <Row className='px-2 md:px-10'>
                <Col span={24}>
                    <form layout="vertical" className='w-full'>
                        <Row gutter={16}>
                            <Col lg={12} xs={24}>
                                <Controller
                                    control={control}
                                    name='streetNumber'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField
                                                labeltext={'Street Number'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'h-10 rounded outline-none'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>
                            <Col lg={12} xs={24}>
                                <Controller
                                    control={control}
                                    name='streetName'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField
                                                labeltext={'Street Name'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'h-10 rounded outline-none'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col lg={6} xs={24}>

                                <Controller
                                    control={control}
                                    name='streetType'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            {/* <LabelWithSelect
                                                options={streetType}
                                                labeltext={'Street Type'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'outline-none h-10 rounded'}
                                            />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>} */}
                                            <LabelWithCustomSelect
                                                labeltext={'Street Type'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'outline-none h-10 rounded'}
                                            />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>

                                    )}
                                />
                            </Col>
                            <Col lg={6} xs={24}>
                                <Controller
                                    control={control}
                                    name='city'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField
                                                labeltext={'City'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'h-10 rounded outline-none'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>
                            <Col lg={6} xs={24}>
                                <Controller
                                    control={control}
                                    name='state'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField
                                                labeltext={'State'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'h-10 rounded outline-none'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>
                            <Col lg={6} xs={24}>
                                <Controller
                                    control={control}
                                    name='zip'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField
                                                labeltext={'Zip'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'h-10 rounded outline-none'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col lg={12} xs={24}>
                                <Controller
                                    control={control}
                                    name='buildingNumber'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField
                                                labeltext={'Building Number'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'h-10 rounded outline-none'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>

                            <Col lg={12} xs={24}>
                                <Controller
                                    control={control}
                                    name='addressType'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithSelect
                                                options={addressType}
                                                labeltext={'Address Type'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'outline-none h-10 rounded'}
                                            />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}

                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col lg={12} xs={24}>
                                <Controller
                                    control={control}
                                    name='longitude'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField
                                                labeltext={'GPS Longitude'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'h-10 rounded outline-none'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>

                            <Col lg={8} xs={24}>
                                <Controller
                                    control={control}
                                    name='latitude'
                                    defaultValue={''}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <LabelWithField
                                                labeltext={'GPS Latittude'}
                                                field={field}
                                                labelClass={'font-normal'}
                                                inputClass={'h-10 rounded outline-none'} />
                                            {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                        </>
                                    )}
                                />
                            </Col>
                            <Col lg={4} xs={24} className='pt-9  max-[600px]:pt-0' >
                                <Button.Basic
                                    text={'View Map'}
                                    onClick={() => setShowModal(true)}
                                    className="w-full h-10 green-bg border-none text-white border-transparent"
                                />
                            </Col>
                        </Row>
                        <Row gutter={16} className='my-1'>
                            <Col span={24} className='flex align-middle justify-end items-center'>
                                <span className={`cursor-pointer hover:underline font-bold ${addressMutation?.isLoading && 'pointer-events-none'}`} onClick={handleSubmit((data) => SaveAndAddMoreHandler(data, 'more'))}><span className='font-bold text-base'> + </span>Save And Add More</span>
                            </Col>
                        </Row>
                    </form>
                </Col>
            </Row>

            <br />

            <Row gutter={[16, 16]} className='px-2 md:px-10' justify={'end'} >
                {/* <Col xs={24} lg={3} className='flex align-middle items-center'
                >
                    <FaArrowLeftLong className='mr-2' />
                    <span onClick={() => previousStep()} className={`cursor-pointer ${addressMutation?.isLoading && 'pointer-events-none'}`}
                    >Back</span>
                </Col> */}
                <Col xs={24} lg={{ span: 4, offset: 9 }} >
                    <Button.Basic
                        onClick={handleSubmit((data) => saveHandler('save', data))}
                        className="w-full h-12 yellow-bg border-none text-white border-transparent"
                        text={"Save"}
                        disabled={btnDisabled.next || btnDisabled.addMore ? true : false}
                        loader={btnDisabled.next || btnDisabled.addMore ? false : addressMutation?.isLoading}
                    />
                </Col>
                <Col xs={24} lg={4} >
                    <Button.Basic
                        className="w-full h-12  orange-bg border-none text-white border-transparent"
                        onClick={handleSubmit((data) => nextHandler('next', data))}
                        text={"Continue"}
                        disabled={btnDisabled.save || btnDisabled.addMore ? true : false}
                        loader={btnDisabled.save || btnDisabled.addMore ? false : addressMutation?.isLoading}
                    />
                </Col>
                <Col xs={24} lg={4} >
                    <Button.Basic
                        className="w-full h-12 black-bg border-none text-white border-transparent"
                        onClick={skipHandler}
                        text={"Next"}
                        disabled={btnDisabled.next || btnDisabled.save || btnDisabled.addMore ? true : false}
                    />
                </Col>
            </Row>
            <br />

            <BasicDrawer
                openDrawer={openDrawer}
                title={'Edit Address'}
                onClose={() => onCloseDrawer()}
                width={'800'}
            >
                <Addressform
                    addressData={addressData && addressData}
                    onCloseDrawer={onCloseDrawer}
                />
            </BasicDrawer>



            <Modal.Basic
                open={showModal}
                className={""}
                onCancel={() => setShowModal(false)}
                closable={() => setShowModal(false)}
                width={1000}
                title={'Add Address'}
                Children={
                    <Row style={{ height: "60vh" }}>
                        <Col span={24}>
                            <GoogleMapComp
                                pointerPosition={position}
                                mapClickHandler={mapClickHandler}
                            />
                        </Col>
                    </Row>
                }
            />


        </div >
    )
}

export default AddressForm

let addressType = [
    {
        value: 'Commercial',
        label: 'Commercial',
    },
    {
        value: 'Residential',
        label: 'Residential',
    },
    {
        value: 'Other',
        label: 'Other',
    }
]

