export const phoneNumberFormat = (number) => {
    const cleaned = ('', number).replace(/\D/g, '');
    let phoneNumber;

    if (cleaned.length === 10) {
        return phoneNumber = '(' + cleaned.substr(0, 3) + ') ' + cleaned.substr(3, 3) + '-' + cleaned.substr(6, 4);
    }

    else {
        return
    }

}