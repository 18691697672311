import React, { useState, useEffect } from 'react'
import { Row, Col, Avatar, Form } from "antd";
import { CameraOutlined } from '@ant-design/icons';
import { FaArrowLeftLong, FaTrash } from "react-icons/fa6";
import LabelWithField from '../LabelWithField';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { UserSignin } from '../../config/Schema';
import LabelWithSelect from '../LabelWithSelect';
import { Colors } from '../../config';
import { FaPlus } from "react-icons/fa6";
import BasicDrawer from '../Drawer';
import TextField from '../TextField';
import Button from '../Button';
import PropertyForm from '../DrawerForms/client';
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from 'react-query';
import { ApiCaller } from '../../config';
import { FaCamera } from 'react-icons/fa';
import camera from '../../assets/images/camera.png'
import { AddProperty } from '../../config/Schema';
import { Utils } from "../../config"
import { getCompanies, getEligibiity, getSchedules } from '../../config/getApis'
import { useDispatch, useSelector } from 'react-redux';
import { SiteManagementAction } from '../../store/actions';
import SelectBox from '../SelectBoxWithLoading';
import UploadAvatar from '../UploadAvatar';

const ClientForm = ({

    nextStep,
    previousStep,
    currenStep

}) => {
    const onCloseDrawer = () => {
        setOpenDrawer(false)
    }

    const [btnDisabled, setBtnDisabled] = useState({
        next: false,
        save: false,
    })

    console.log(currenStep)
    const dispatch = useDispatch()

    // const { data: companiesOption } = useQuery('getCompanies', getCompanies, {
    //     // enabled: currenStep == 1
    // });

    useEffect(() => {
        dispatch(SiteManagementAction.GetProperties())
    }, [])

    const companiesOption = useSelector(state => state?.SiteManagementReducer?.getProperties || []);

    const defaultValues = {
        name: '',
        code: '',
        companyId: '',
    }

    let schema = AddProperty;
    const { handleSubmit, control, reset, setValue, watch, getValues } = useForm(
        {
            resolver: yupResolver(schema),
            mode: 'onChange',
            defaultValues: defaultValues
        }
    )

    const { data: scheduleData } = useQuery('schedule', getSchedules);


    const navigate = useNavigate();

    const [openDrawer, setOpenDrawer] = useState(false)

    const [buttonName, setButtonName] = useState('');

    const [img, setimg] = useState(null);
    const [readFile, setReadFile] = useState(null);
    const [imgValue, setImageValue] = useState({
        imgSizeValidation: '',
        imgTypeValidation: '',
    });

    const propertyMutation = useMutation(async (restData) => {
        console.log(restData)
        let resData;
        if (readFile) {
            resData = {
                ...restData
            }
        }
        else {
            resData = {
                ...restData
            }
        }

        const res = await ApiCaller.Post('/property', resData);
        return res?.data;
    },
        {
            onSuccess: (data) => {
                console.log('client data', data)
                localStorage.setItem('PropertyKey', data?.data?.id)
                if (buttonName == 'save') {
                    setButtonName('')
                    localStorage.removeItem('PropertyKey')
                    reset(defaultValues)
                    setimg(null)
                    setValue('propertyImgId', null)
                    setReadFile(null)
                    setBtnDisabled({
                        save: false,
                        next: false,
                    })
                    navigate('/site-management')
                }

                else {
                    setButtonName('')
                    reset(defaultValues)
                    setimg(null)
                    setValue('propertyImgId', null)
                    setReadFile(null)
                    setBtnDisabled({
                        save: false,
                        next: false,
                    })
                    nextStep()
                }
            },

            onError: (error) => {
                console.log(error?.response)
                Utils.showMessage('error', error?.response?.data?.message);
                setBtnDisabled({
                    save: false,
                    next: false,
                })
                return
            }
        }

    )

    const userProfileMutation = useMutation(async (data) => {
        const { propertyImgId, ...restData } = data;
        console.log(propertyImgId, restData)

        if (!propertyImgId) {
            console.log(restData)
            propertyMutation.mutate(restData);
            return
        }

        else {
            const formData = new FormData();
            formData.append('file', propertyImgId);
            formData.append('type', 'profile')

            try {
                const res = await ApiCaller.Post('/media/upload', formData);
                console.log(res)
                const id = res.data?.data?.id;
                restData.propertyImgId = id;
                propertyMutation.mutate(restData)
                return;
            }

            catch (err) {
                Utils.showMessage('error', err?.response?.data?.message);
                return
            }

        }
    },
        // {
        //     onSuccess: (id) => {
        //         console.log(id)
        //         alert('userProfile alert')
        //         if (id) {
        //             propertyMutation.mutate(id);
        //         }
        //     }
        //     ,
        //     onError: (error) => {
        //     }
        // }
    );

    const nextHandler = (data) => {
        setBtnDisabled({
            save: false,
            next: true
        })
        console.log(data)
        userProfileMutation.mutate(data)
        setButtonName('next')
    }

    const saveHandler = (data, name) => {
        // here we have to hit the get listing of site data api.
        setBtnDisabled({
            next: false,
            save: true
        })
        console.log(data, name)
        userProfileMutation.mutate(data)
        setButtonName('save')

    }


    return (
        <Row className='bg-white w-full h-full' style={{

        }}>

            <Row className='px-2 md:px-10 w-full'>
                <form className='w-full'>
                    <Row
                        className="py-5 w-full items-center justify-center"
                        // justify={'center'}
                        // align={'middle'}

                        style={{
                            padding: "10px",
                            height: "100px",
                            position: "relative",

                        }}
                    >
                        {/* <Col style={{ width: '70px', height: '70px', borderRadius: '50%', border: '1px solid #F9F9FA', background: '#F9F9FA' }} className='flex items-center justify-center relative '> */}
                        <>
                            <>
                                {/* <input
                                        type="file"
                                        className='z-0 cursor-pointer'
                                        ref={deleteBtnRef}
                                        style={{
                                            borderRadius: "50%",
                                            width: '100%',
                                            height: '100%',
                                            position: "absolute",
                                            zIndex: 999,
                                            opacity: 0,
                                        }}

                                        onChange={(e) => {
                                            fileHandler(e)
                                        }}
                                        accept=".png, .jpg, .jpeg"
                                    /> */}
                                <UploadAvatar
                                    img={img}
                                    setimg={setimg}
                                    imgValue={imgValue}
                                    setImageValue={setImageValue}
                                    readFile={readFile}
                                    setReadFile={setReadFile}
                                    setValue={setValue}
                                />
                            </>
                        </>
                        {/* // )}
                            // /> */}
                        {/* {
                                readFile ?
                                    <>
                                        <Avatar className='z-10 absolute w-full h-full' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} src={readFile} />
                                        <FaTrash className='absolute z-20 right-[-2px] bottom-[-10px] cursor-pointer' onClick={() => deleteImage()} size={20} color='#eeb600' />
                                    </>
                                    :

                                    <Avatar className='z-0 absolute w-full h-full flex items-center justify-center' icon={<FaCamera />} />} */}
                        {/* </Col> */}

                        {/* user image validation  */}
                        {/* <p style={{ color: Colors.Error, width: '100%' }} className='text-center'>{imgValue && imgValue.imgSizeValidation ? <p>{imgValue.imgSizeValidation}</p> : imgValue && imgValue.imgTypeValidation ? <p>{imgValue.imgTypeValidation}</p> : ''}</p> */}

                    </Row>

                    <Row gutter={16}>
                        <Col lg={12} xs={24}>
                            <Controller
                                control={control}
                                defaultValue=""
                                name='name'
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithField labeltext={'Property Name'} field={field} type={'text'} labelClass={'font-normal'} inputClass={'outline-none h-10 rounded'} />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}
                            />
                        </Col>
                        <Col lg={12} xs={24}>
                            <Controller
                                control={control}
                                name='code'
                                defaultValue=""
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithField
                                            labeltext={'Property Code'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'outline-none h-10 rounded'} />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}

                            />
                        </Col>

                    </Row>
                    <Row gutter={16}>
                        <Col lg={12} xs={24}>
                            <Controller
                                control={control}
                                name='companyId'
                                defaultValue=""
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithSelect
                                            options={companiesOption && companiesOption}
                                            labeltext={'Client'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'outline-none h-10 rounded'}
                                        />
                                        {/* <SelectBox /> */}
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}

                                    </>
                                )}
                            />
                        </Col>
                        <Col lg={12} xs={24} className='mb-2'>
                            <Controller
                                control={control}
                                name='scheduleId'
                                render={({ field, fieldState }) => (
                                    <>
                                        <LabelWithSelect
                                            options={scheduleData}
                                            labeltext={'Schedule'}
                                            field={field}
                                            labelClass={'font-normal'}
                                            inputClass={'outline-none h-10 rounded'}
                                        />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24} className='flex align-middle justify-end items-center'>
                            <span className='font-bold hover:underline cursor-pointer'
                                onClick={() => setOpenDrawer(true)}
                            ><span className='text-base'>+</span> Add New Client</span>
                        </Col>
                    </Row>
                    {/* <Row gutter={16}>
                        <Col span={24}>
                            <Controller
                                control={control}
                                name='note'
                                defaultValue=""
                                render={({ field: { value, onChange }, fieldState }) => (
                                    <>
                                        <TextField.Textarea
                                            style={{ color: Colors.LightGray }}
                                            value={value}
                                            onChange={onChange}
                                            placeholder={''}
                                            labeltext={'Property Notes'}
                                            className={'outline-none h-10 rounded'}
                                        />
                                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                    </>
                                )}
                            />
                        </Col>
                    </Row> */}
                </form>

            </Row>
            <br />

            <Row className='px-2 md:px-6 w-full my-3 flex items-center'>
                <Row className='w-full flex items-center justify-end gap-2 px-4'>
                    <Col xs={24} sm={12} lg={{ span: 4, offset: 9 }} >
                        <Button.Basic
                            className="w-full h-12 yellow-bg border-none text-white border-transparent"
                            text={"Save"}
                            onClick={handleSubmit(saveHandler)}
                            disabled={btnDisabled.next ? true : false}
                            loader={btnDisabled.next ? false : propertyMutation.isLoading || userProfileMutation.isLoading}
                        />

                    </Col>
                    <Col xs={24} sm={12} lg={4} >
                        <Button.Basic
                            className="w-full h-12  orange-bg border-none text-white border-transparent"
                            type={"submit"}
                            onClick={handleSubmit(nextHandler)}
                            text={"Continue"}
                            disabled={btnDisabled.save ? true : false}
                            loader={btnDisabled.save ? false : propertyMutation.isLoading || userProfileMutation.isLoading}
                        />
                    </Col>
                </Row>
            </Row>
            <br />

            <BasicDrawer

                openDrawer={openDrawer}
                title={'Add New Client/Management Company'}
                onClose={() => onCloseDrawer()}
                width={'800'}

            >
                <PropertyForm
                    onCloseDrawer={onCloseDrawer}
                />

            </BasicDrawer>
        </Row>
    )
}


export default ClientForm

