import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { SignIn, ForgetPasswordEmail, Dashboard, Users, VerifyOTP, ResetPassword } from "../../containers";
import DashboardLayout from "../../components/Layout/dashboardLayout.jsx";
import AppRoutes from "./AppRoutes";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Chatapp from "../../components/ChatApp/chatapp.js";
import { useNavigate } from "react-router-dom";


const AuthRoutes = () => {
  const navigate = useNavigate();
  console.log(window.location.pathname);
  const user = useSelector((state) => state.AppReducer?.user)
  console.log("user", user)


  useEffect(() => {
    if (window.location.pathname == "/login" && user?.data?.token) {
      navigate("/")
    }

    else if (window.location.pathname == '/' && !user?.data?.token) {
      navigate('/login')
    }

  }, [window?.location.pathname])


  return (
    <div className="App">
      {!user?.data?.token ? (
        <Routes>
          <>
            <Route path="/login" element={<SignIn />} />
            <Route path="/forgot-password" element={<ForgetPasswordEmail />} />
            <Route path="/verify-password" element={<VerifyOTP />} />
            <Route path="/logout" element={<Navigate to="/login" />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        </Routes>
      )

        : (
          <>
            <DashboardLayout expandible={false}>
              <AppRoutes isRole={user?.data?.userRole[0]} />
            </DashboardLayout>

          </>
        )}
    </div >
  )
}

export default AuthRoutes
