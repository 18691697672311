import { Col, Row } from "antd";
import React, { useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Button, Text, TextField, InputOtp } from "../../components";
import { Colors, Utils } from "../../config";
import { AppAction } from "../../store/actions";
// import styles from "./styles";
import login from '../../assets/images/login.png'
import siteLogo from '../../assets/images/siteLogo.png'
import ButtonLoader from "../../components/Button/ButtonLoader";

const VerifyOTP = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const loader = useSelector(state => state?.AppReducer.loader)

    const [otp, setOtp] = useState('')

    const validateEmail = () => { }

    const otpvalues = () => { }

    const otpResendHandler = () => {
        const payload = {
            email: localStorage.getItem('email')
        }
        dispatch(AppAction.EmailVerification(payload))
    }

    const otpHandler = (a) => {
        setOtp(a)
    }

    const otpSubmitHandler = () => {
        dispatch(AppAction.OTPVerification(otp, () => {
            navigate('/reset-password')
        }))
    }

    return (

        <Row>
            <Col lg={12} md={12} sm={24} xs={24} className="h-screen" >

                <section className="h-full flex">
                    <div className="flex flex-col items-center justify-center px-6 mx-auto md:h-screen lg:py-0">
                        <a href="#" className="mt-3" >
                            <img className="xl:w-80 md:w-80 xl:h-32 md:h-32 h-24 w-72 mr-2" src={siteLogo} alt="logo" />
                        </a>
                        <div className="w-full md:mt-0 sm:max-w-md xl:p-0">
                            <div className="pt-3 mx-auto flex items-center flex-col justify-center" style={{}}>
                                <h1 className="mb-3 text-center text-2xl font-bold">
                                    Enter the 4 Digit Code
                                </h1>
                                <p className="text-center text-sm mb-5" style={{ color: Colors.Gray }}>
                                    A one-time password (OTP), also known as a one-time PIN, one-time authorization
                                </p>

                                <div className="space-y-4 md:space-y-6 w-11/12	">
                                    <div>
                                        <InputOtp
                                            value={otp}
                                            onChange={otpHandler}
                                            inputStyle={{ margin: '0 10px', padding: '0.5rem 1.2rem', color: Colors.Black, width: '60px', fontSize: '2rem', border: '1px solid #D7DBE8', backgroundColor: '#F9FAFC', borderRadius: '7px',outline:'none' }}
                                            containerStyle={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                            numInputs={'4'}
                                            inputType={'number'}
                                            skipDefaultStyles={true}
                                        />
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <a href="#" className="text-sm font-medium" onClick={otpResendHandler}>Didn't receive the code?<span style={{ color: Colors.Yellow }} className="hover:underline"> Resend</span></a>
                                    </div>
                                    {/* <button type="submit" disabled={otp?.length == 4 ? false : true} style={{ background: "#2f3032" }} onClick={otpSubmitHandler} className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-3 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">SUBMIT</button> */}
                                    <ButtonLoader isDisabled={otp?.length == 4 ? false : true} loader={loader} text={'SUBMIT'}  btnHandler={otpSubmitHandler} style={{ background: "#2f3032",color:Colors.White }} className="cursor-pointer w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 h-12 text-base tracking-wider" />

                                    <div className="flex items-center justify-center mb-3">
                                        <a href="#" className="text-sm font-medium text-primary-600 hover:underline" style={{ color: Colors.Yellow }} onClick={() => navigate('/login')}>Back to Login</a>
                                    </div>

                                    <p className="text-xs text-center mt-10">
                                        Copyright 2024 <span style={{ color: "#f0bf1f" }}>Sentinel</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </Col>

            <Col lg={12} md={12} sm={0} xs={0} className="h-screen rounded-none md:rounded-3xl">
                <div className=" w-full h-full rounded-none md:rounded-l-3xl">
                    <img src={login} className="h-full max-w-full aspect-[4/3] object-top object-cover rounded-none md:rounded-l-3xl" />
                </div>
            </Col>

        </Row>

    )
}

export default VerifyOTP