import {
    GET_PROPERTY, GET_PROPERTY_FAILURE, GET_PROPERTY_SUCCESS,
    GET_PROPERTY_DETAIL, GET_PROPERTY_DETAIL_FAILURE, GET_PROPERTY_DETAIL_SUCCESS,
    ADD_PROPERTY, ADD_PROPERTY_SUCCESS, GET_PROPERTIES, GET_PROPERTIES_SUCCESS, GET_PROPERTIES_FAILURE, ADD_USER_FAILURE,
    EDIT_PROPERTY, EDIT_PROPERTY_SUCCESS, EDIT_PROPERTY_FAILURE,
    ADD_ADDRESS, EDIT_ADDRESS, GET_ADDRESS, GET_ADDRESS_SUCCESS, GET_ADDRESS_FAILURE, ADDRESS_CLEAR,
    LOADER_TRUE,
    LOADER_FALSE,
    ADD_DOCUMENT,
    EDIT_DOCUMENT,
    GET_DOCUMENT,
    GET_DOCUMENT_SUCCESS,
    GET_DOCUMENT_FAILURE,
    ADD_LOCATION,
    EDIT_LOCATION,
    ADD_INCIDENT,
    EDIT_INCIDENT,
    ADD_CHECKPOINT,
    EDIT_CHECKPOINT,
    ADD_GEOFENCE,
    EDIT_GEOFENCE,
    ADD_PHONE,
    EDIT_PHONE,
    UPDATE_PROPERTY_STATUS,
    ADD_PROPERTY_FAILURE,
    DATA_CLEAR,
    GET_LOCATIONS_SUCCESS,
    GET_LOCATIONS_FAILURE,
    GET_LOCATIONS,
    GET_INCIDENT_TYPES,
    GET_INCIDENT_TYPES_SUCCESS,
    GET_INCIDENT_TYPES_FAILURE,
    GET_CHECKPOINTS,
    GET_CHECKPOINTS_SUCCESS,
    GET_CHECKPOINTS_FAILURE,
    LOCATION_DATA_CLEANER,
    GET_ADDRESS_DATA_CLEANER,
    GET_DOCUMENT_DATA_CLEANER,
    GET_CHECKPOINTS_CLEANER,
    GET_GEOFENCE,
    GET_GEOFENCE_SUCCESS,
    GET_GEOFENCE_FAILURE,
    GET_GEOFENCE_DATA_CLEANER,
    ADD_PROPERTY_INCIDENT,
    ADD_PROPERTY_INCIDENT_SUCCESS,
    ADD_PROPERTY_INCIDENT_FAILURE,
    ADD_ADDRESS_TYPE,
    ADD_ADDRESS_TYPE_SUCCESS

} from '../constants'

export default class SiteManagementAction {

    static GetProperty(payload) {

        return {
            type: GET_PROPERTY,
            payload
        }
    }

    static GetPropertySuccess(payload) {
        return {
            type: GET_PROPERTY_SUCCESS,
            payload
        }
    }


    static GetPropertyFailure() {
        return {
            type: GET_PROPERTY_FAILURE
        }
    }

    static UpdatePropertyStatus(payload, cb) {

        return {
            type: UPDATE_PROPERTY_STATUS,
            payload,
            cb
        }
    }


    static GetPropertyDetail(payload) {
        return {
            type: GET_PROPERTY_DETAIL,
            payload
        }
    }

    static GetPropertyDetailSuccess(payload) {
        return {
            type: GET_PROPERTY_DETAIL_SUCCESS,
            payload
        }
    }

    static  GetPropertyDetailFailure() {
        return {
            type: GET_PROPERTY_DETAIL_FAILURE
        }
    }

    static GetProperties() {
        return {
            type: GET_PROPERTIES
        }
    }

    static GetPropertiesSuccess(payload) {
        return {
            type: GET_PROPERTIES_SUCCESS,
            payload
        }
    }

    static GetPropertiesFailure() {
        return {
            type: GET_PROPERTIES_FAILURE
        }
    }




    static AddProperty(payload, cb) {

        return {
            type: ADD_PROPERTY,
            payload,
            cb
        }
    }

    static AddPropertySuccess(payload) {
        return {
            type: ADD_PROPERTY_SUCCESS,
            payload
        }
    }

    static AddPropertyFailure() {
        return {
            type: ADD_PROPERTY_FAILURE,
        }
    }


    static EditProperty(payload, id, cb) {

        return {
            type: EDIT_PROPERTY,
            payload,
            id,
            cb
        }
    }

    static EditPropertySuccess() {
        return {
            type: EDIT_PROPERTY_SUCCESS,
        }
    }

    static EditPropertyFailure() {
        return {
            type: EDIT_PROPERTY_FAILURE
        }
    }


    static AddAddress(payload, id, cb) {
        return {
            type: ADD_ADDRESS,
            payload,
            id,
            cb
        }
    }
    static EditAddress(data, id, cb) {
        console.log(data, id)
        return {
            type: EDIT_ADDRESS,
            data,
            id,
            cb
        }
    }

    static GetAddress(cb) {
        console.log(cb)
        return {
            type: GET_ADDRESS,
            cb
        }
    }

    static GetAddressSuccess(payload) {
        return {
            type: GET_ADDRESS_SUCCESS,
            payload
        }
    }

    static GetAddressFailure() {
        return {
            type: GET_ADDRESS_FAILURE
        }
    }

    static GetAddressDataCleaner() {
        return {
            type: GET_ADDRESS_DATA_CLEANER,
        }
    }


    static AddDocument(payload, id, cb) {
        return {
            type: ADD_DOCUMENT,
            payload,
            id,
            cb
        }
    }
    static EditDocument(payload, docId, cb) {
        return {
            type: EDIT_DOCUMENT,
            payload,
            docId,
            cb
        }
    }

    static GetDocument() {
        return {
            type: GET_DOCUMENT
        }
    }

    static GetDocumentSuccess(payload) {
        return {
            type: GET_DOCUMENT_SUCCESS,
            payload
        }
    }

    static GetDocumentFailure() {
        return {
            type: GET_DOCUMENT_FAILURE
        }
    }

    static GetDocumentDataCleaner() {
        return {
            type: GET_DOCUMENT_DATA_CLEANER
        }
    }

    static AddLocation(payload, id, cb) {
        return {
            type: ADD_LOCATION,
            payload,
            id,
            cb
        }
    }


    static GetLocation() {
        return {
            type: GET_LOCATIONS
        }
    }

    static GetLocationSuccess(payload) {
        return {
            type: GET_LOCATIONS_SUCCESS,
            payload
        }
    }

    static GetLocationFailure() {
        return {
            type: GET_LOCATIONS_FAILURE,
        }
    }

    static GetLocationDataCleaner() {
        return {
            type: LOCATION_DATA_CLEANER
        }
    }

    static EditLocation(payload, locationId, cb) {
        return {
            type: EDIT_LOCATION,
            payload,
            locationId,
            cb
        }
    }

    static GetIncidentTypes(payload,cb) {
        return {
            type: GET_INCIDENT_TYPES,
            payload,
            cb
        }
    }

    static GetIncidentTypesSuccess(payload) {
        return {
            type: GET_INCIDENT_TYPES_SUCCESS,
            payload
        }
    }

    static GetIncidentTypesFailure() {
        return {
            type: GET_INCIDENT_TYPES_FAILURE
        }
    }

    static AddIncident(payload, cb) {
        return {
            type: ADD_INCIDENT,
            payload,
            cb
        }
    }

    static AddPropertyIncident(payload, id, cb) {
        return {
            type: ADD_PROPERTY_INCIDENT,
            payload,
            id,
            cb
        }
    }

    static AddPropertyIncidentSuccess() {
        return {
            type: ADD_PROPERTY_INCIDENT_SUCCESS,

        }
    }

    static AddPropertyIncidentFailure() {
        return {
            type: ADD_PROPERTY_INCIDENT_FAILURE,
        }
    }


    static EditIncident() {
        return {
            type: EDIT_INCIDENT
        }
    }

    static GetCheckPoints(payload) {
        console.log(payload)
        return {
            type: GET_CHECKPOINTS,
            payload
        }
    }

    static GetCheckPointsSuccess(payload) {
        return {
            type: GET_CHECKPOINTS_SUCCESS,
            payload
        }
    }

    static GetCheckPointsFailure() {
        return {
            type: GET_CHECKPOINTS_FAILURE
        }
    }

    static GetCheckPointDataCleaner() {
        return {
            type: GET_CHECKPOINTS_CLEANER
        }
    }

    static AddCheckpoint(payload, id, cb) {
        return {
            type: ADD_CHECKPOINT,
            payload,
            id,
            cb
        }
    }
    static EditCheckpoint(payload, checkPointId, cb) {
        return {
            type: EDIT_CHECKPOINT,
            payload,
            checkPointId,
            cb
        }
    }

    static GetGeofence() {
        return {
            type: GET_GEOFENCE
        }
    }

    static GetGeofenceSuccess(payload) {
        console.log(payload)
        return {
            type: GET_GEOFENCE_SUCCESS,
            payload
        }
    }


    static GetGeofenceFailure() {
        return {
            type: GET_GEOFENCE_FAILURE
        }
    }

    static GetGeofenceDataCleaner() {
        return {
            type: GET_GEOFENCE_DATA_CLEANER
        }
    }

    static AddGeofence(payload, id, cb) {
        return {
            type: ADD_GEOFENCE,
            payload,
            id,
            cb
        }
    }
    static EditGeofence(payload, geofenceId, cb) {
        return {
            type: EDIT_GEOFENCE,
            payload,
            geofenceId,
            cb
        }
    }

    static AddPhone(payload, id, cb) {
        return {
            type: ADD_PHONE,
            payload,
            id,
            cb
        }
    }
    static EditPhone(payload, id, cb) {
        return {
            type: EDIT_PHONE,
            payload,
            id,
            cb
        }
    }

    static AddAddressType(payload){
        return {
            type: ADD_ADDRESS_TYPE,
            payload
        }
    }

    static AddAddresTypeSuccess(payload){
        return {
            type:ADD_ADDRESS_TYPE_SUCCESS,
            payload
        }
    }

    static LoaderTrue() {
        return {
            type: LOADER_TRUE
        }
    }
    static LoaderFalse() {
        return {
            type: LOADER_FALSE
        }
    }




}