import React, { useState, useEffect, useCallback } from 'react'
import { Row, Col, Avatar, } from "antd";
import { CameraOutlined } from '@ant-design/icons';
import { Button, Modal, Text, TextField } from '..';
import { FaArrowLeftLong } from "react-icons/fa6";
import LabelWithField from '../LabelWithField';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { UserSignin } from '../../config/Schema';
import LabelWithSelect from '../LabelWithSelect';
import { FaPlus } from "react-icons/fa6";
import GoogleMap from '../GoogleMap';
import { AddAddress } from '../../config/Schema';
import { Colors } from '../../config';
import { UseDispatch, useDispatch, useSelector } from 'react-redux';
import { SiteManagementAction } from '../../store/actions';
import { useParams } from 'react-router-dom';
import GoogleMapComp from '../GoogleMap';
import LabelWithCustomSelect from '../LabelWithCustomSelect';

const Address = ({ addressData, onCloseDrawer, isAddressDetail = false, title }) => {

    const [showModal, setShowModal] = useState(false)

    const [position, setPosition] = useState([])

    const getAddressLoader = useSelector(state => state?.SiteManagementReducer.loader);

    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (title == 'Add') {
            getLocationJs();
        }
        else {
            return
        }
    }, [title]);


    useEffect(() => {
        if (addressData) {
            reset({
                streetNumber: addressData?.streetNumber,
                streetName: addressData?.streetName,
                streetType: addressData?.streetType,
                city: addressData?.city,
                state: addressData?.state,
                zip: addressData?.zip,
                buildingNumber: addressData?.buildingNumber,
                addressType: addressData?.addressType,
                latitude: addressData?.latitude,
                longitude: addressData?.longitude
            })

            setPosition([{
                lat: Number(addressData?.latitude),
                lng: Number(addressData?.longitude)
            }])

        }

        else {
            return
        }
    }, [addressData])


    const getLocationJs = () => {
        navigator.geolocation.getCurrentPosition(position => {
            const { latitude, longitude } = position.coords;
            setPosition([{
                lat: latitude,
                lng: longitude
            }]);
            setValue('latitude', latitude)
            setValue('longitude', longitude)
        });
    };

    const mapClickHandler = useCallback(
        (e) => {
            setPosition([e.latLng.toJSON()]);
            setValue('latitude', e?.latLng.toJSON()?.lat);
            setValue('longitude', e?.latLng.toJSON()?.lng);
        },
        [setPosition]
    );

    const schema = AddAddress;

    const { handleSubmit, control, reset, setValue, watch } = useForm(
        {
            resolver: yupResolver(schema),
            mode: "onChange"
        }
    )
    const handleChange = (data) => {
        console.log("addd", data);
    };

    const handleSubmitHandler = (data) => {
        // if user is adding more address from the detail section then this case will run
        if (title == 'Add') {
            dispatch(SiteManagementAction.AddAddress(data, id, () => {
                dispatch(SiteManagementAction.GetPropertyDetail(id));
                reset();
                onCloseDrawer();
            }))
        }

        // for edit case this section will run
        else {
            dispatch(SiteManagementAction.EditAddress(data, addressData?.id, () => {
                // if user is in the process of creating a company or site registration
                if (!isAddressDetail) {
                    dispatch(SiteManagementAction.GetAddress())
                }
                // if user is in detail section then this case will run
                else {
                    dispatch(SiteManagementAction.GetPropertyDetail(id))
                }
                onCloseDrawer()
            }))
        }
    }

    return (
        <Row className=''>
            <form layout="vertical" className='w-full'>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            defaultValue={''}
                            name='streetNumber'
                            render={({ field, fieldState }) => (
                                <>
                                    <LabelWithField
                                        labeltext={'Street Number'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'h-10 rounded outline-none'} />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>
                            )}
                        />
                    </Col>
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            defaultValue={''}

                            name='streetName'
                            render={({ field, fieldState }) => (
                                <>
                                    <LabelWithField
                                        labeltext={'Street Name'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'h-10 rounded outline-none'} />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>

                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col lg={6} xs={24}>

                        <Controller
                            control={control}
                            name='streetType'
                            render={({ field, fieldState }) => (
                                <>
                                    {/* <LabelWithSelect
                                        options={streetType}
                                        labeltext={'Street Type'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'outline-none h-10 rounded'}
                                    /> */}
                                    <LabelWithCustomSelect
                                        // options={[...streetType, {
                                        //     label: addressData?.streetType,
                                        //     value: addressData?.streetType
                                        // }]}
                                        labeltext={'Street Type'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'outline-none h-10 rounded'}
                                    />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}

                                </>
                            )}
                        />
                    </Col>
                    <Col lg={6} xs={24}>
                        <Controller
                            control={control}
                            name='city'
                            defaultValue={''}

                            render={({ field, fieldState }) => (
                                <>
                                    <LabelWithField
                                        labeltext={'City'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'h-10 rounded outline-none'} />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>
                            )}
                        />
                    </Col>
                    <Col lg={6} xs={24}>
                        <Controller
                            control={control}
                            defaultValue={''}

                            name='state'
                            render={({ field, fieldState }) => (
                                <>
                                    <LabelWithField
                                        labeltext={'State'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'h-10 rounded outline-none'} />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>
                            )}
                        />
                    </Col>
                    <Col lg={6} xs={24}>
                        <Controller
                            control={control}
                            defaultValue={''}

                            name='zip'
                            render={({ field, fieldState }) => (
                                <>
                                    <LabelWithField
                                        labeltext={'Zip'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'h-10 rounded outline-none'} />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>
                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            defaultValue={''}

                            name='buildingNumber'
                            render={({ field, fieldState }) => (
                                <>
                                    <LabelWithField
                                        labeltext={'Building Number'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'h-10 rounded outline-none'} />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>
                            )}
                        />
                    </Col>

                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            name='addressType'
                            render={({ field, fieldState }) => (
                                <>
                                    <LabelWithSelect
                                        options={addressType}
                                        labeltext={'Address Type'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'outline-none h-10 rounded'}

                                    />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>

                            )}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Controller
                            control={control}
                            defaultValue={''}

                            name='longitude'
                            render={({ field, fieldState }) => (
                                <>
                                    <LabelWithField
                                        labeltext={'GPS Longitude'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'h-10 rounded outline-none'} />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>
                            )}
                        />
                    </Col>

                    <Col lg={8} xs={24}>
                        <Controller
                            control={control}
                            name='latitude'
                            defaultValue={''}

                            render={({ field, fieldState }) => (
                                <>
                                    <LabelWithField
                                        labeltext={'GPS Latittude'}
                                        field={field}
                                        labelClass={'font-normal'}
                                        inputClass={'h-10 rounded outline-none'} />
                                    {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                </>
                            )}
                        />
                    </Col>
                    <Col lg={4} xs={24} className='pt-7 max-[600px]:pt-0 my-2' >
                        <Button.Basic
                            text={'View Map'}
                            onClick={() => setShowModal(true)}
                            className="w-full h-10 green-bg border-none text-white border-transparent"
                        />
                    </Col>

                </Row>
                <br />
                <Row gutter={16} className='justify-end'>
                    <Col lg={24} className='flex justify-end'>
                        <Button.Basic
                            onClick={handleSubmit(handleSubmitHandler)}

                            htmlType="submit"
                            className="h-12 px-6 yellow-bg border-none text-white border-transparent"
                            text={title == 'Edit' ? 'Edit' : "Save"}
                            type={"submit"}
                            loader={getAddressLoader}
                        />
                    </Col>
                </Row>
            </form>


            <Modal.Basic
                open={showModal}
                className={""}
                onCancel={() => setShowModal(false)}
                closable={() => setShowModal(false)}
                title={title == 'Add' ? 'Add Address' : 'Edit Address'}
                width={1000}
                Children={
                    <Row style={{ height: "60vh" }}>
                        <Col span={24}>
                            <GoogleMapComp
                                pointerPosition={position}
                                mapClickHandler={mapClickHandler}
                            />
                        </Col>
                    </Row>
                }
            />

        </Row >
    )
}

export default Address


// let streetType = [
//     {
//         label: 'Road',
//         value: 'Road'
//     },
//     {
//         label: 'Street',
//         value: 'Street'
//     },
//     {
//         label: 'Parkway',
//         value: 'Parkway'
//     },
//     {
//         label: 'Avenue',
//         value: 'Avenue'
//     },
//     {
//         label: 'Way',
//         value: 'Way'
//     },
//     {
//         label: 'Boulevard',
//         value: 'Boulevard'
//     },
//     {
//         label: 'Place',
//         value: 'Place'
//     },
//     {
//         label: 'Lane',
//         value: 'Lane'
//     },
//     {
//         label: 'Drive',
//         value: 'Drive'
//     },
//     {
//         label: 'Court',
//         value: 'Court'
//     },
//     {
//         label: 'Terrace',
//         value: 'Terrace'
//     },
//     {
//         label: 'Alley',
//         value: 'Alley'
//     },
//     {
//         label: 'Connector',
//         value: 'Connector'
//     },
//     {
//         label: 'Highway',
//         value: 'Highway'
//     },
//     {
//         label: 'Route',
//         value: 'Route'
//     },
//     {
//         label: 'Thruway',
//         value: 'Thruway'
//     },
//     {
//         label: 'Frontage Road',
//         value: 'Frontage Road'
//     }
// ]

let addressType = [
    {
        value: 'Commercial',
        label: 'Commercial',
    },
    {
        value: 'Residential',
        label: 'Residential',
    },
    {
        value: 'Other',
        label: 'Other',
    }
]