import { put } from "redux-saga/effects"
import { SettingAction } from "../actions"
import { ApiCaller, Utils } from "../../config"

export default class SettingMiddleware {
    static *GetSchedules(payload) {
        try {
            let res = yield ApiCaller.Get(`/schedules?page=${payload?.payload?.current}&perPage=${payload?.payload?.limit}&sortBy=id&sortOrder=${payload?.payload?.sortOrder}&search=${payload?.payload?.searchVal}`);
            if (res.status == 200) {
                yield put(SettingAction.GetSchedulesSuccess(res));
                return
            }
        }
        catch (err) {
            yield put(SettingAction.GetSchedulesFailure());
            Utils.showMessage('error', err?.response?.data?.message)
        }

    }

    static *GetEligibilites(payload) {
        try {
            let res = yield ApiCaller.Get(`/eligibilities?page=${payload?.payload?.current}&perPage=${payload?.payload?.limit}&sortBy=id&sortOrder=${payload?.payload?.sortOrder}&search=${payload?.payload?.searchVal}`);
            if (res.status == 200){
                yield put(SettingAction.GetEligibilitiesSuccess(res));
                return
            }
        }
        catch(err){
            yield put(SettingAction.GetEligibilitiesFailure());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }
}