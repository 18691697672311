import React, { useEffect, useState } from 'react'
import { Row, Col, Avatar, Form } from "antd";
import { CameraOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Text, TextField } from '..';
import { FaArrowLeftLong } from "react-icons/fa6";
import LabelWithField from '../LabelWithField';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { UserSignin } from '../../config/Schema';
import LabelWithSelect from '../LabelWithSelect';
import { FaPlus } from "react-icons/fa6";
import { Colors } from '../../config';
import CheckBoxField from '../Select/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { SiteManagementAction } from '../../store/actions';
import { useParams } from 'react-router-dom';
import Circular from '../Loader/Circular';

const Incidenttype = ({ incidentData, onCloseDrawer }) => {

    const [incidentTypeId, setIncidentTypeId] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(SiteManagementAction.GetIncidentTypes(searchValue));
    }, [incidentData])


    // to checkmark the edit ones
    const val = incidentData.map((item) => {
        return item.id
    })

    const { id } = useParams()

    const incidentTypesOptions = useSelector(state => state?.SiteManagementReducer?.incidentTypes || []);
    const incidentTypesLoader = useSelector(state => state?.SiteManagementReducer?.loader);
    const propertyIncidentLoader = useSelector(state => state?.SiteManagementReducer?.propertyIncidentLoader)

    const { handleSubmit } = useForm(
        {
            // resolver: yupResolver(schema),
            mode: "onChange"
        }
    )

    const handleChange = (data) => {
        if (incidentTypeId.length == 0) {
            return
        }
        else {
            const payload = {
                incidentTypeId
            }
            dispatch(SiteManagementAction.AddPropertyIncident(payload, id, () => {
                dispatch(SiteManagementAction.GetPropertyDetail(id));
                onCloseDrawer()
            }))
            return
        }
    };

    const incidentCheckedHandler = (checkedValues) => {
        setIncidentTypeId(checkedValues)
    }

    // debouce function for searching functionality
    const debounce = (query, delay) => {
        let timeout;
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            return (
                dispatch(SiteManagementAction.GetIncidentTypes(query))
            )
        }, delay)
    }

    const handleInputChange = (e) => {
        let query = e.target.value;
        setSearchValue(query)
        debounce(query, 2000)
    }

    return (
        <Row>
            <Row gutter={16} className='mb-5 md:mb-4'>
                <Col span={24}>
                    <label style={{ color: Colors.LabelColor }}>search</label>
                    <TextField.Basic
                        style={{
                            background: "#eff2f5",
                            width: "100%",

                        }}
                        suffix={<SearchOutlined size={48} />}
                        placeholder="Search"
                        onChange={(e) => handleInputChange(e)}
                        id="search"
                        name="search"
                        value={searchValue}
                        className={'h-10 rounded outline-none w-full'}
                    />
                </Col>
            </Row>

            {
                !incidentTypesLoader ?
                    <form className='w-full'>
                        <Row gutter={16}>
                            <Col span={24} style={{ height: "50vh" }} className='overflow-auto scroll-smooth'>
                                <CheckBoxField
                                    defaultValue={val && val}
                                    options={incidentTypesOptions}
                                    value={incidentTypeId}
                                    onChange={incidentCheckedHandler}
                                />
                            </Col>
                        </Row>
                    </form>
                    :
                    <Circular
                        spinning={incidentTypesLoader}
                        size={40}
                        color={Colors.Yellow}
                        containerStyle={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    />

            }


            <br />
            <Row gutter={16} className='justify-end w-full my-4'>
                <Col lg={24} className='flex justify-end'>
                    <Button.Basic
                        onClick={handleSubmit(handleChange)}
                        htmlType="submit"
                        className="h-12 px-6 yellow-bg border-none text-white border-transparent"
                        text={"Save"}
                        type={"submit"}
                        loader={propertyIncidentLoader}
                    />
                </Col>
            </Row>

        </Row>
    )
}

export default Incidenttype