import { ApiCaller } from "../../config"
import { put } from "redux-saga/effects"
import { Utils } from "../../config"
import { ManageIncidentsAction } from "../actions";

export default class ManageIncidentsMiddleware {
    static *GetManageIncidents(params) {
        const { payload } = params;
        try {
            let res = yield ApiCaller.Get(`/incidents?perPage=${payload?.limit}&page=${payload?.page}&search=${payload?.search}&sortBy=createdAt&startDate=${payload?.filterDrawer?.startingDate}&endDate=${payload?.filterDrawer?.endingDate}&property_id=${payload?.filterDrawer?.property}&incident_type_id=${payload?.filterDrawer?.incidentType}&level=${payload?.filterDrawer?.level}&status=${payload?.filterDrawer?.status}`);
            if (res.status == 200) {
                yield put(ManageIncidentsAction.GetManageIncidentsSuccess(res?.data));
            }
        }
        catch (err) {
            yield put(ManageIncidentsAction.GetManageIncidentsFailure());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }

    static *GetManageIncidentsDetail(params) {
        const { payload } = params;
        let id = Number(payload);
        try {
            let res = yield ApiCaller.Get(`/incident/${id}`);
            console.log("response of api",res)
            if (res.status == 200) {
                yield put(ManageIncidentsAction.GetManageIncidentDetailSuccess(res?.data?.data));
            }
        } catch (error) {
            yield put(ManageIncidentsAction.GetManageIncidentDetailfailure());
            Utils.showMessage('error', error?.response?.data?.message);
        }

    }

    static *GetAssignIncidentDetail(params) {
        const { payload, cb } = params;
        const { propertyReportingThisIssue, issueType } = payload;
        const propertyId = Number(propertyReportingThisIssue);
        const incidentId = Number(issueType);
        try {
            let res = yield ApiCaller.Get(`/property/${propertyId}/incident-type/${incidentId}`);
            console.log(res)
            if (res.status == 200) {
                yield put(ManageIncidentsAction.GetAssignIncidentDetailSuccess(res.data));
                cb && cb()
            }
        }
        catch (err) {
            yield put(ManageIncidentsAction.GetAssignIncidentDetailFailure());
            Utils.showMessage('error', err?.response?.data?.message);
        }
    }
}