import React from 'react'
import { Empty } from 'antd';

const pageNotFound = ({desc}) => {
    return (
        <div className='flex items-center justify-center' style={{ height: '90vh', width: '100%' }}>
            <Empty
                description={desc}
            />
        </div>
    )
}

export default pageNotFound