import { GET_COMPANIES, GET_COMPANIES_SUCCESS, GET_COMPANIES_FAILURE, GET_COMPANIES_DETAIL, GET_COMPANIES_DETAIL_SUCCESS, GET_COMPANIES_DETAIL_FAILURE, GET_INVITED_CLIENTS, GET_INVITED_CLIENTS_SUCCESS, GET_INVITED_CLIENTS_FAILURE, GET_INVITED_CLIENT_DETAIL, GET_INVITED_CLIENT_DETAIL_SUCCESS, GET_INVITED_CLIENT_DETAIL_FAILURE, COMPANIES_STATUS_UPDATE, COMPANIES_STATUS_UPDATE_SUCCESS, COMPANIES_STATUS_UPDATE_FAILURE } from "../constants";

const initialState = {
    detailLoader: false,
    loader: false,
    invitedClientsData: [],
    invitedClientDetailData: [],
    companiesData: [],
    companiesDetailData: [],
}

export default function ClientManagementReducer(state = initialState, action) {

    switch (action.type) {

        case GET_INVITED_CLIENTS:
            state = {
                ...state,
                loader: true
            }
            break;

        case GET_INVITED_CLIENTS_SUCCESS:
            state = {
                ...state,
                loader: false,
                invitedClientsData: action.payload
            }
            break;

        case GET_INVITED_CLIENTS_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;

        case GET_INVITED_CLIENT_DETAIL:
            state = {
                ...state,
                detailLoader: true
            }
            break;

        case GET_INVITED_CLIENT_DETAIL_SUCCESS:
            state = {
                ...state,
                detailLoader: false,
                invitedClientDetailData: action.payload
            }
            break;

        case GET_INVITED_CLIENT_DETAIL_FAILURE:
            state = {
                ...state,
                detailLoader: false
            }
            break;

        case GET_COMPANIES:
            state = {
                ...state,
                loader: true
            }
            break;

        case GET_COMPANIES_SUCCESS:
            state = {
                ...state,
                loader: false,
                companiesData: action.payload
            }
            break;

        case GET_COMPANIES_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;

        case GET_COMPANIES_DETAIL:
            state = {
                ...state,
                detailLoader: true,
            }
            break;

        case GET_COMPANIES_DETAIL_SUCCESS:
            state = {
                ...state,
                detailLoader: false,
                companiesDetailData: action.payload
            }
            break;

        case GET_COMPANIES_DETAIL_FAILURE:
            state = {
                ...state,
                detailLoader: false
            }
            break;

        case COMPANIES_STATUS_UPDATE:
            state = {
                ...state,
                loader: true,
            }
            break;

        case COMPANIES_STATUS_UPDATE_SUCCESS:
            state = {
                ...state,
                loader: false,
            }
            break;

        case COMPANIES_STATUS_UPDATE_FAILURE:
            state = {
                ...state,
                loader: false,
            }
            break;

        default:
            state = { ...state }
            break
    }
    return state
}