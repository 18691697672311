import { GET_SCHEDULES, GET_SCHEDULES_SUCCESS, GET_SCHEDULES_FAILURE, GET_ELEGIBILITIES, GET_ELEGIBILITIES_SUCCESS, GET_ELEGIBILITIES_FAILURE } from "../constants";

export default class SettingAction {
    static GetSchedules(payload) {
        return {
            type: GET_SCHEDULES,
            payload
        }
    }

    static GetSchedulesSuccess(payload) {
        return {
            type: GET_SCHEDULES_SUCCESS,
            payload
        }
    }

    static GetSchedulesFailure() {
        return {
            type: GET_SCHEDULES_FAILURE
        }
    }

    static GetEligibilities(payload) {
        return {
            type: GET_ELEGIBILITIES,
            payload
        }
    }

    static GetEligibilitiesSuccess(payload){
        return{
            type: GET_ELEGIBILITIES_SUCCESS,
            payload
        }
    }

    static GetEligibilitiesFailure() {
        return {
            type: GET_ELEGIBILITIES_FAILURE
        }
    }
}