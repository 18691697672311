import React, { useEffect, useState, useRef, useMemo } from 'react'
import BasicDrawer from '../../../components/Drawer'
import { useForm, Controller } from 'react-hook-form'
import { Col, ConfigProvider, Row, Select } from 'antd'
import LabelWithSelect from '../../../components/LabelWithSelect'
import { Button, Text, TextField } from '../../../components'
import { ApiCaller, Colors, Utils } from '../../../config'
import { useMutation } from 'react-query'
import BasicText from '../../../components/Text/BasicText'
import { yupResolver } from '@hookform/resolvers/yup';
import { AssignIncidentSchema } from '../../../config/Schema'
import { useDispatch, useSelector } from 'react-redux'
import { ManageIncidentsAction } from '../../../store/actions'
import { useQuery } from 'react-query'
import { getAllIncidents, getAllProperties, getAllUsers } from '../../../config/getApis'


const AssignIncidentDrawer = ({ drawerStates, setDrawerStates, onClose }) => {

    const dispatch = useDispatch();
    const { assignIncidentDetail, detailLoader, assignLoader, incidentDetailData } = useSelector(state => state?.ManageIncidentReducer);

    // destructuring the incident options.
    const incidentOptions = useMemo(() => {
        let incidentOptions = assignIncidentDetail?.data?.map((incident) => {
            return {
                label: `${incident.name ? incident.name : '-'} - (${incident.floor} ${' '} ${incident.unit})`,
                value: incident?.id
            }
        })
        return incidentOptions
    }, [assignIncidentDetail])

    console.log(incidentDetailData)

    // getting the data for users -- roles wise and properties and also incidents for the select box.
    const roles = { guard: true, supervisor: true };
    const { data: usersOptions } = useQuery('users', () => getAllUsers(roles), { enabled: drawerStates.assignIncidentDrawer });
    const { data: propertiesOptions } = useQuery('properties', getAllProperties, { enabled: drawerStates.assignIncidentDrawer });
    const { data: incidentsOptions } = useQuery('incident', getAllIncidents, { enabled: drawerStates.assignIncidentDrawer });


    const defaultValues = {
        assignId: '',
        level: '',
        notes: ''
    }

    const schema = AssignIncidentSchema;
    const { handleSubmit, control, reset } = useForm(
        {
            resolver: yupResolver(schema),
            mode: 'onChange',
            defaultValues: defaultValues
        }
    )

    // setting the values of those pre-filled fields.
    const [incidents, setIncidents] = useState({
        problemUnit: '',
        problemAddress: '',
        problemLocation: '',
    })


    // state for handling issue types used to filled the data.
    const [fieldValues, setFieldValues] = useState({
        propertyReportingThisIssue: '',
        issueType: '',
        incidentType: '',
    })


    // custom validation for those issues type.
    const [fieldIsRequired, setFieldIsRequired] = useState({
        propertyReportingThisIssueRequired: '',
        issueTypeRequired: ''
    })

    // updating the pre-filled fields once the data is received for the specific incident
    useEffect(() => {
        setIncidents({
            problemAddress: incidentDetailData?.address ? `${incidentDetailData?.address?.state}-${incidentDetailData?.address?.city}-${incidentDetailData?.address?.zip}` : '',
            problemUnit: incidentDetailData?.unit ? incidentDetailData?.unit : '',
            problemLocation: incidentDetailData?.address ? `${incidentDetailData?.address?.streetNumber}, ${incidentDetailData?.address?.streetName}, ${incidentDetailData?.address?.buildingNumber}` : ''
        })
    }, [incidentDetailData])


    // form submission for assign incident
    const assignIncidentMutation = useMutation(async (data) => {
        let id = Number(fieldValues.incidentType);
        let res = await ApiCaller.Patch(`/incident/${id}/assign`, data);
        return res;
    },
        {
            onSuccess: (res) => {
                setDrawerStates({ ...drawerStates, assignIncidentDrawer: false })
                reset(defaultValues)
                Utils.showMessage('success', res?.data?.message)
            },
            onError: (error) => {
                Utils.showMessage('error', error?.response?.data?.message);
            }
        }
    )

    const assignIncidentHandler = (data) => {
        console.log(data)
        assignIncidentMutation.mutate(data)
    }


    // Function trigger when the find btn got clicked and it checks those custom validations for those three fields.
    const findIncidentHandler = () => {
        if (fieldValues.propertyReportingThisIssue == '' && fieldValues.issueType == '') {
            setFieldIsRequired({ propertyReportingThisIssueRequired: 'Field is required', issueTypeRequired: 'Field is required' })
        }

        else if (fieldValues.propertyReportingThisIssue == '') {
            setFieldIsRequired({ issueTypeRequired: '', propertyReportingThisIssueRequired: 'Field is required' })
        }

        else if (fieldValues.issueType == '') {
            setFieldIsRequired({ propertyReportingThisIssueRequired: '', issueTypeRequired: 'Field is required' })
        }

        // after satisfying none of the two fields is empty this else will run to get the multiple incidents.
        else {
            dispatch(ManageIncidentsAction.GetAssignIncidentDetail(fieldValues, () => {
                setFieldIsRequired({
                    propertyReportingThisIssueRequired: '',
                    issueTypeRequired: ''
                })
            }))
        }

    }

    // from those multiple incidents, to select one incident to fill those fields.
    const incidentTypeChangeHandler = (value) => {
        setFieldValues((prev) => ({ ...prev, incidentType: value }));
        // setIncidents((prev) => ({ ...prev, incidentId: value }))
        dispatch(ManageIncidentsAction.GetManageIncidentDetail(value));
    }

    // form cleaner function after drawer get closed
    const assignIncidentAfterCloseHandler = () => {
        if (!drawerStates?.assignIncidentDrawer) {
            reset(defaultValues)
            setIncidents({
                problemAddress: '',
                problemUnit: '',
                problemLocation: ''
            })
            setFieldValues({
                propertyReportingThisIssue: '',
                issueType: '',
                incidentType: ''
            })
            setFieldIsRequired({
                propertyReportingThisIssueRequired: '',
                issueTypeRequired: ''
            })
            dispatch(ManageIncidentsAction.GetAssignIncidentDetailCleaner())
        }

    }

    return (
        <>
            <BasicDrawer
                openDrawer={drawerStates?.assignIncidentDrawer}
                onClose={onClose}
                afterOpenChange={assignIncidentAfterCloseHandler}
                width={600}
                title={'Assign Incident/Issues'}
                children={
                    <>
                        <Row justify={'space-between'} className='mb-2'>
                            <Col lg={14} md={14} sm={24} xs={24} className='mr-2'>
                                <div className='flex items-top gap-1'>
                                    <div className='w-full'>
                                        <label className='font-normal' style={{ color: Colors.LabelColor }}>Property Reporting This Issue</label>
                                        <ConfigProvider
                                            theme={{
                                                components: {
                                                    Select: {
                                                        optionSelectedBg: Colors.Yellow,
                                                        optionActiveBg: Colors.LightGray,
                                                        optionSelectedColor: Colors.White
                                                    },
                                                },
                                            }}
                                        >
                                            <Select
                                                className='w-full h-9 outline-none rounded'
                                                value={fieldValues?.propertyReportingThisIssue}
                                                onChange={(value) => setFieldValues((prev) => ({ ...prev, propertyReportingThisIssue: value }))}
                                                options={propertiesOptions ? propertiesOptions : []}
                                            />
                                        </ConfigProvider>
                                        <span style={{ color: Colors.Error, width: '100%' }} className='inline-block'>{fieldIsRequired?.propertyReportingThisIssueRequired ? fieldIsRequired.propertyReportingThisIssueRequired : ''}</span>
                                    </div>
                                    <Button.Basic loader={assignLoader || detailLoader} className={'mt-5 h-9 px-5'} style={{ backgroundColor: '#2B9943', color: '#fff' }} text={'Find'} onClick={findIncidentHandler} />
                                </div>
                            </Col>
                            <Col lg={9} md={9} sm={24} xs={24}>
                                <label className='font-normal' style={{ color: Colors.LabelColor }}>Issue Type</label>
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Select: {
                                                optionSelectedBg: Colors.Yellow,
                                                optionActiveBg: Colors.LightGray,
                                                optionSelectedColor: Colors.White
                                            },
                                        },
                                    }}
                                >
                                    <Select
                                        className='w-full h-9 outline-none rounded'
                                        value={fieldValues?.issueType}
                                        onChange={(value) => setFieldValues((prev) => ({ ...prev, issueType: value }))}
                                        options={incidentsOptions ? incidentsOptions : []}
                                    />
                                </ConfigProvider>
                                <span style={{ color: Colors.Error, width: '100%' }} className='inline-block'>{fieldIsRequired?.issueTypeRequired ? fieldIsRequired.issueTypeRequired : ''}</span>

                            </Col>
                        </Row>

                        <Row className='mb-4'>
                            <Col span={24}>
                                <label className='font-normal' style={{ color: Colors.LabelColor }}>Incident Type</label>
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Select: {
                                                optionSelectedBg: Colors.Yellow,
                                                optionActiveBg: Colors.LightGray,
                                                optionSelectedColor: Colors.White
                                            },
                                        },
                                    }}
                                >
                                    <Select
                                        className='w-full h-9 outline-none rounded'
                                        value={incidentOptions?.length == 0 ? null : fieldValues?.incidentType}
                                        onChange={(value) => incidentTypeChangeHandler(value)}
                                        options={incidentOptions ? incidentOptions : []}
                                    />
                                </ConfigProvider>

                            </Col>
                        </Row>


                        <Row gutter={[16, 32]} className='mb-2' >
                            <Col lg={13} md={13} sm={12} xs={12}>

                                <label>Problem Address</label>
                                <BasicText text={incidents.problemAddress} className='overflow-y-auto overflow-x-hidden w-full px-2 rounded h-9 flex items-center' style={{ width: '100%', backgroundColor: '#EFF2F5', paddingTop: '5px', paddingBottom: '5px' }} />
                                {/* <Text.Basic text={'Problem unit'} className='w-full' style={{border:'1px solid red', width:'100%'}}  /> */}

                            </Col>
                            <Col lg={11} md={11} sm={12} xs={12} className=''>
                                <label>Problem Unit</label>
                                <BasicText text={incidents.problemUnit} className='overflow-y-auto overflow-x-hidden w-full px-2 rounded h-9 flex items-center' style={{ width: '100%', backgroundColor: '#EFF2F5', paddingTop: '5px', paddingBottom: '5px' }} />
                                {/* <Text.Basic text={'Problem unit'} className='w-full' style={{border:'1px solid red', width:'100%'}}  /> */}
                                {/* <LabelWithSelect options={''} labeltext={'problem Unit'} labelClass={'font-normal'} inputClass={'outline-none py-1 rounded h-9'} /> */}
                            </Col>
                        </Row>

                        <Row gutter={[16, 32]} className='mb-2'>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <label>Location</label>
                                <BasicText text={incidents.problemLocation} className='overflow-y-auto overflow-x-hidden w-full px-2 rounded h-9 flex items-center' style={{ width: '100%', backgroundColor: '#EFF2F5', }} />
                            </Col>
                        </Row>

                        <form>
                            <Row gutter={[16, 32]} className='mb-4'>
                                <Col lg={13} md={13} sm={24} xs={24}>
                                    <Controller
                                        name='assignId'
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <LabelWithSelect options={usersOptions ? usersOptions : []} labeltext={'Assign This Issue To'} field={field} labelClass={'font-normal'} inputClass={'outline-none py-1 rounded h-9'} />
                                                {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error?.message}</p>}
                                            </>
                                        )}
                                    />
                                </Col>

                                <Col lg={11} md={11} sm={12} xs={12}>
                                    <Controller
                                        name='level'
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <div className=''>
                                                <LabelWithSelect options={
                                                    [
                                                        {
                                                            value: '3',
                                                            label: 'High',
                                                        },
                                                        {
                                                            value: '1',
                                                            label: 'Low',
                                                        },
                                                        {
                                                            value: '2',
                                                            label: 'Medium',
                                                        }
                                                    ]
                                                } labelColor={Colors.Error} labeltext={'Level'} field={field} labelClass={'font-normal'} inputClass={'outline-none py-1 rounded h-9'} />
                                                {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                            </div>
                                        )}

                                    />

                                </Col>

                            </Row>


                            <Row gutter={[16, 32]}>
                                <Col span={24}>
                                    <Controller
                                        name='notes'
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState }) => (
                                            <div className=''>
                                                <TextField.Textarea
                                                    style={{ color: Colors.LightGray }}
                                                    placeholder={''}
                                                    value={value}
                                                    onChange={onChange}
                                                    labeltext={'Issue Details (Please describe the problem)'}
                                                    className={'outline-none h-10 rounded'}
                                                />
                                                {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                            </div>
                                        )}
                                    />

                                </Col>

                            </Row>

                            <Row gutter={[16, 32]} className='my-6'>
                                <Col span={24} className='flex items-center justify-end'>
                                    <Button.Basic
                                        onClick={handleSubmit((data) => assignIncidentHandler(data))}
                                        htmlType="submit"
                                        loader={assignIncidentMutation.isLoading}
                                        disabled={
                                            fieldValues.incidentType == '' || fieldValues.propertyReportingThisIssue == '' || fieldValues.issueType == '' ? true : false
                                        }
                                        size={'large'} text={'Save'} buttonStyle={{ backgroundColor: Colors.Yellow, color: 'white' }} className={'px-12 rounded-md h-12'} />
                                </Col>
                            </Row>
                        </form>
                    </>
                }
            />

        </>
    )
}

export default AssignIncidentDrawer




{/* <>
<Row justify={'space-between'}>
    <Col lg={13} md={13} sm={24} xs={24} className='mr-2'>
        <div className='flex items-center gap-1'>
            <LabelWithSelect options={''} labeltext={'Property Reporting This Issue'} labelClass={'font-normal'} inputClass={'outline-none py-1 rounded h-9'} />
            <Button.Basic className={'mt-8 h-9 px-5'} style={{ backgroundColor: '#2B9943', color: '#fff' }} text={'Find'} />
        </div>
    </Col>
    <Col lg={10} md={10} sm={24} xs={24}>
        <>
            <LabelWithSelect options={''} labeltext={'Issue Type'} labelClass={'font-normal'} inputClass={'outline-none py-1 rounded h-9'} />
        </>
    </Col>
</Row>
<form>
    <Row gutter={[16, 32]}>
        <Col lg={13} md={13} sm={12} xs={12}>
            <Controller
                name='problemAddress'
                control={control}
                render={(field, fieldState) => (
                    <div className='flex items-center gap-1'>
                        <LabelWithSelect options={''} labeltext={'problem Address'} field={field} labelClass={'font-normal'} inputClass={'outline-none py-1 rounded h-9'} />
                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                    </div>
                )}
            />

        </Col>
        <Col lg={11} md={11} sm={12} xs={12}>
            <Controller
                name='problemUnit'
                control={control}
                render={(field, fieldState) => (
                    <>
                        <LabelWithSelect options={''} labeltext={'problem Unit'} field={field} labelClass={'font-normal'} inputClass={'outline-none py-1 rounded h-9'} />
                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                    </>
                )}
            />
        </Col>
    </Row>

    <Row gutter={[16, 32]}>
        <Col lg={13} md={13} sm={12} xs={12}>
            <Controller
                name='location'
                control={control}
                render={(field, fieldState) => (
                    <div className='flex items-center gap-1'>
                        <LabelWithSelect options={''} labeltext={'Location'} field={field} labelClass={'font-normal'} inputClass={'outline-none py-1 rounded h-9'} />
                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                    </div>
                )}
            />

        </Col>
        <Col lg={11} md={11} sm={12} xs={12}>
            <Controller
                name='issueAssign'
                control={control}
                render={(field, fieldState) => (
                    <>
                        <LabelWithSelect options={''} labeltext={'Assign This Issue To'} field={field} labelClass={'font-normal'} inputClass={'outline-none py-1 rounded h-9'} />
                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                    </>
                )}
            />
        </Col>
    </Row>

    <Row gutter={[16, 32]} className='mb-5'>
        <Col lg={13} md={13} sm={24} xs={24}>
            <Controller
                name='Level'
                control={control}
                render={(field, fieldState) => (
                    <div className='flex items-center gap-1'>
                        <LabelWithSelect options={''} labelColor={Colors.Error} labeltext={'Level'} field={field} labelClass={'font-normal'} inputClass={'outline-none py-1 rounded h-9'} />
                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                    </div>
                )}
            />

        </Col>

    </Row>


    <Row gutter={[16, 32]}>
        <Col lg={24}>
            <Controller
                name='issueDetails'
                control={control}
                render={({ field: { value, onChange }, fieldState }) => (
                    <div className=''>
                        <TextField.Textarea
                            style={{ color: Colors.LightGray }}
                            placeholder={''}
                            value={value}
                            onChange={onChange}
                            labeltext={'Issue Details (Please describe the problem)'}
                            className={'outline-none h-10 rounded'}
                        />
                        {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                    </div>
                )}
            />

        </Col>

    </Row>

    <Row gutter={[16, 32]} className='my-6'>
        <Col span={24} className='flex items-center justify-end'>
            <Button.Basic onClick={handleSubmit((data) => assignIncidentHandler(data))} size={'large'} text={'Save'} buttonStyle={{ backgroundColor: Colors.Yellow, color: 'white' }} className={'px-12 rounded-md h-12'} />
        </Col>
    </Row>
</form>
</> */}