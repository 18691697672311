export default function DateTimeComponent(datetimeStr, type) {

    // Parse the datetime string
    const parsedDatetime = new Date(datetimeStr);

    // Extract year, month, and date components
    const year = parsedDatetime.getFullYear();
    const month = String(parsedDatetime.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1
    const date = String(parsedDatetime.getDate()).padStart(2, '0');

    // Format the date as YYYY-MM-DD
    const formattedDate = `${year}-${month}-${date}`;

    // Extract time component
    const time = parsedDatetime.toTimeString().split(' ')[0];

    let completeDateTime = formattedDate + ' ' +  time;

    if(type == 'date'){
        return formattedDate
    }
    else if(type == 'time'){
        return time
    }
    else {
        return completeDateTime
    }
    
}

