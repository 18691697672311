import React from 'react'
import { Colors } from '../../config'

const Label = ({

    className,
    text,
    style,
    ...props
}) => {
    return (
        <div>
            <label
                style={{ color: Colors.LabelColor, ...style }}
                className={className}
            >{text}
            </label>

        </div >
    )
}

export default Label