import { ApiCaller } from "../../config"
import { put } from "redux-saga/effects"
import { Utils } from "../../config"
import { ClientManagementAction } from "../actions";

export default class ClientManagementMiddleware {
    static *GetInvitedClient(payload) {
        console.log(payload)
        try {
            let res = yield ApiCaller.Get(`/clients?search=${payload?.payload?.searchVal}&perPage=${payload?.payload?.limit}&page=${payload?.payload?.current}&status=${payload?.payload?.filterStatus}`);
            if (res.status == 200) {
                yield put(ClientManagementAction.GetInvitedClientsSuccess(res));
                return
            }
        }
        catch (err) {
            yield put(ClientManagementAction.GetInvitedClientsFailure())
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }

    static *GetInvitedClientDetail(payload) {
        const { id } = payload;
        console.log(id)
        try {
            let res = yield ApiCaller.Get(`/client/${id}`);
            if (res.status == 200) {
                yield put(ClientManagementAction.GetInvitedClientDetailSuccess(res?.data?.data));
                return
            }
        }
        catch (err) {
            yield put(ClientManagementAction.GetInvitedClientDetailFailure());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }

    static *GetCompanies(payload) {
        try {
            let res = yield ApiCaller.Get(`/companies?search=${payload?.payload?.searchVal}&perPage=${payload?.payload?.limit}&page=${payload?.payload?.current}&status=${payload?.payload?.filterStatus}`);
            if (res.status == 200) {
                yield put(ClientManagementAction.GetCompaniesSuccess(res))
                return
            }
        }

        catch (err) {
            yield put(ClientManagementAction.GetCompaniesFailure())
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }

    static *GetCompaniesDetails(payload) {
        const { id, cb } = payload;
        try {
            let res = yield ApiCaller.Get(`/company/${id}`);
            if (res.status == 200) {
                yield put(ClientManagementAction.GetCompanyDetailsSuccess(res?.data?.data))
            }
        }
        catch (err) {
            yield put(ClientManagementAction.GetCompanyDetailsFailure());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }

    static *CompaniesStatusUpdate(params) {
        const { payload, cb } = params;
        const { id } = payload;
        try {
            let res = yield ApiCaller.Patch(`/company/${id}/status`, { status: payload?.status });
            if (res.status == 200) {
                yield put(ClientManagementAction.CompanyStatusUpdateSuccess());
                if (cb) {
                    cb()
                }
                Utils.showMessage('success', res?.data?.message)
            }

        }
        catch (err) {
            yield put(ClientManagementAction.CompanyStatusUpdateFailure());
            Utils.showMessage('error', err?.response?.data?.message)
        }
    }
}